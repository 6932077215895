import { t } from "../../../services/utils";
import React from "react";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
// import TextField from "@material-ui/core/TextField";
import { HotKeys } from "react-hotkeys";
import { connect } from "react-redux";
import { CREATE_ALTERNATE_PASSWORD } from "../../../constants/action-types";
import moment from "moment";
import { toastr } from "react-redux-toastr";
import { NewDialog } from "../../../components/Dialog";
import { replace, push } from "connected-react-router";
import TextField from "../../../components/TextField";
import { primaryDark80 } from "../../../constants/colors";
class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      showSuccessDialog: false,
      emailError: null,
      showErrors: false,
    };
    this.requestSentTime = null;
  }
  componentDidMount = () => {
    this.emailField.focus();
  };
  componentWillReceiveProps = (nextProps) => {
    if (this.requestSentTime !== null && nextProps.lastForgotPasswordRequest) {
      if (!nextProps.lastForgotPasswordRequest.inProcess) {
        if (
          moment(this.requestSentTime).isBefore(
            nextProps.lastForgotPasswordRequest.completedAt
          )
        ) {
          if (nextProps.lastForgotPasswordRequest.success) {
            this.setState({
              showSuccessDialog: true,
            });
          } else if (
            nextProps.lastForgotPasswordRequest.failedWithUserNotFoundError
          ) {
            toastr.error(
              t("emailDoesNotExist"),
              t("pleaseMakeSureThatTheEmailYouEnteredIsCorrect")
            );
          }
          this.requestSentTime = null;
        }
      }
    }
  };
  componentDidUpdate = (prevProps, prevState) => {};
  onEmailChange = (e) => {
    this.setState({
      email: e.target.value,
    });
  };
  onSend = (e) => {
    e.preventDefault();
    this.setState({
      emailError: "",
    });
    let hasError = false;
    const { email } = this.state;
    if (email.trim().length === 0) {
      hasError = true;
      this.setState({
        showErrors: true,
        emailError: t("thisFieldIsRequired"),
      });
    }
    if (hasError) {
      return;
    }
    this.send();
  };
  onEnter = (e) => {
    this.send();
  };
  send = () => {
    this.requestSentTime = new Date();
    this.props.onSend(this.state.email.trim());
  };
  render() {
    const styles = {
      container: {
        minWidth: 320,
        maxWidth: 1440,
        height: "auto",
        position: "absolute",
        left: 0,
        right: 0,
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        padding: "25px 100px",
        paddingRight: "7%",
        paddingLeft: "7%",
      },
      paper: {
        width: "36%",
        minWidth: 320,
        maxWidth: 442,
        padding: 50,
        marginLeft: 10,
        marginRight: 10,
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
      },
      row: {
        display: "flex",
        justifyContent: "space-between",
      },
      logo: {
        display: "flex",
        alignItems: "center",
      },
      logoImg: {
        height: 29,
        width: 29,
      },
      logoText: {
        fontSize: 24,
        fontWeight: "bold",
        marginLeft: 4,
        marginTop: 10,
      },
      fieldContainer: {
        display: "flex",
        flexDirection: "column",
      },
      toolbar: {
        display: "flex",
        marginTop: 16,
        flexDirection: "row-reverse",
      },
      loginForm: {
        marginTop: "15%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      },
      sendBtn: {
        marginLeft: 24,
      },
      signUp: {
        alignSelf: "center",
        fontSize: "16px",
        cursor: "pointer",
        color: "#343AB4",
      },
      title: {
        // fontFamily: "'Montserrat', sans-serif",
        fontSize: "2.5vw",
        fontWeight: "bold",
        color: primaryDark80,
        marginBottom: 35,
      },
    };
    return (
      <div style={styles.container} className="login-container-responsive">
        <div
          style={{
            ...styles.row,
            ...{
              alignItems: "center",
            },
          }}
        >
          <div style={styles.logo}>
            <img style={styles.logoImg} src={ require( "../../../img/logo2.png") } />
            <span style={styles.logoText}>{t("trainerFu")}</span>
          </div>
          <div>
            {/* <MediaQuery minDeviceWidth={600}>
              <span style={{ color: "#787878" }}>
                Already Have an account?{" "}
              </span>
             </MediaQuery> */}
            <span
              style={{
                color: "#787878",
              }}
              className="header-text"
            >
              {t("dontHaveAnAccount")}{" "}
            </span>
            <span
              style={styles.signUp}
              className="hover-primary"
              onClick={this.props.onSignup}
            >
              {t("signUp")}
            </span>
          </div>
        </div>
        <div style={styles.loginForm}>
          <Paper
            style={styles.paper}
            elevation={5}
            className="login-form-responsive"
          >
            <div style={styles.title} className="signup-title-responsive">
              {t("resetPassword")}
            </div>
            <HotKeys
              style={styles.fieldContainer}
              handlers={{
                return: this.onEnter,
              }}
            >
              <TextField
                label="E-mail"
                helperText={this.state.showErrors ? this.state.emailError : ""}
                error={this.state.emailError ? true : false}
                fullWidth={true}
                onChange={this.onEmailChange}
                value={this.state.email}
                inputRef={(emailField) => {
                  this.emailField = emailField;
                }}
              />
            </HotKeys>

            <div style={styles.toolbar}>
              <Button
                variant="contained"
                color="primary"
                style={styles.sendBtn}
                onClick={this.onSend}
                disabled={
                  this.props.lastForgotPasswordRequest &&
                  this.props.lastForgotPasswordRequest.inProcess
                }
              >
                {t("sendNewPassword")}
              </Button>
            </div>
          </Paper>
        </div>
        <NewDialog
          title={t("success")}
          message={t("weHaveSentYouAnEmailWithYourNewPassword")}
          actions={[
            {
              label: t("ok"),
              primary: true,
              onClick: (e) => {
                e.stopPropagation();
                this.props.login();
              },
            },
          ]}
          open={this.state.showSuccessDialog}
          onRequestClose={() => this.props.login()}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    lastForgotPasswordRequest: state.requests.lastForgotPasswordRequest,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onSend: (email) => {
      dispatch({
        type: CREATE_ALTERNATE_PASSWORD.REQUEST,
        payload: {
          email,
        },
      });
    },
    login: () => {
      dispatch(replace("/login"));
    },
    onSignup: () => {
      dispatch(push("/signup"));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
