import { t } from "../../../services/utils";
import React from "react";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import { HotKeys } from "react-hotkeys";
import { push } from "connected-react-router";
import { grey800, primaryDark60, blue700 } from "../../../constants/colors";
import {
  validateRegistrationValues,
  validateEmail,
} from "../../../services/auth-utils";
import {
  GET_SIGNUP_FORM,
  CREATE_CLIENT_SIGNUP,
  CREATE_PENDING_CLIENT_SIGNUP,
  PRE_CLIENT_SIGNUP_CHECK,
  EXISTING_CLIENT_SIGNIN_AUTH_CHECK,
} from "../../../constants/action-types";
import paymentTypes from "../../../constants/payment-types";
import paymentFrequencies from "../../../constants/payment-frequency";
import { getModalStyle } from "../../../services/modal-util";
import signupFormTargets from "../../../constants/signup-form-target";
import errorCodes from "../../../constants/error-codes";
import { toastr } from "react-redux-toastr";
import getSymbolFromCurrency from "currency-symbol-map";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import {
  Editor,
  EditorState,
  convertFromRaw,
  CompositeDecorator,
} from "draft-js";
import "./clientSignup.css";
import "../../../img/signup.jpg";
import utils from "./utils";
import { Link } from "./components";
import PaymentView from "./PaymentView";
import styles from "./clientSignupStyles";
import ReactPlayer from "react-player";
import Modal from "react-modal";
import { isMobile } from "react-device-detect";
import moment from "moment";
import _ from "lodash";
// import { trialData } from "./Trials";
import { Trans } from "react-i18next";
const decorator = new CompositeDecorator([
  {
    strategy: utils.findLinkEntities,
    component: Link,
  },
]);
import {convertNumberToLocaleString, convertLocaleStringToNumber} from '../../../services/utils';

class ClientSignup extends React.Component {
  constructor(props) {
    super(props);
    let imageWidth = window.innerWidth > 1600 ? 1600 : window.innerWidth;
    let imageHeight = parseInt(imageWidth / 1.91);
    this.state = {
      name: "",
      email: props.router.location?.query?.email || "",
      password: "",
      lastName: "",
      nameError: null,
      emailError: null,
      passwordError: null,
      lastNameError: null,
      showPaymentView: false,
      signupAsExistingClientDialogOpen: false,
      showPassword: false,
      showSignUp: props.preClientSignupCheckStatus
        ? props.preClientSignupCheckStatus.can_signup
        : false,
      requirePayment: false,
      submitForm: false,
      isExistingClient: false,
      pendingClientSignupToken: "",
      isPaypal: false,
      isPlaying: false,
      imageWidth,
      imageHeight,
      preview: this.props.preview,
    };
    this.ticker = props.ticker;
    this.didSetInitialState = false;
    this.prevClientSignupRequestCompletedAt = new Date();
    this.authCheckRequestCompletedAt = new Date();
  }
  componentDidMount = () => {
    if (!this.props.preview) {
      let form = this.props.signupForms[this.ticker];
      if (!form) {
        this.props.getSignupForm(this.ticker);
      }
    }
    this.setup(this.props);
    window.addEventListener("resize", this.setImageDimensions);
  };
  setImageDimensions = () => {
    let imageWidth = window.innerWidth > 1600 ? 1600 : window.innerWidth;
    let imageHeight = parseInt(imageWidth / 1.91);
    this.setState({
      imageWidth,
      imageHeight,
    });
  };
  componentWillReceiveProps = (nextProps) => {
    this.setup(nextProps);
    if (
      nextProps.requests.lastClientSignupRequest &&
      !nextProps.requests.lastClientSignupRequest.inProcess &&
      this.prevClientSignupRequestCompletedAt <
        nextProps.requests.lastClientSignupRequest.completedAt
    ) {
      this.prevClientSignupRequestCompletedAt =
        nextProps.requests.lastClientSignupRequest.completedAt;
      if (nextProps.requests.lastClientSignupRequest.success) {
        let form = this.props.signupForms[this.ticker];
        if (form.payment_gateway === 1) {
          this.setState({
            showPaymentView: true,
            submitForm: true,
          });
        } else {
          this.props.sayThanks(this.ticker, this.state.isExistingClient);
        }
      } else {
        let error = nextProps.requests.lastClientSignupRequest.error;
        if (error.error_code) {
          if (error.error_code === errorCodes.AUTHENTICATION_FAILED) {
            toastr.error(t("loginError"), t("wrongEmailOrPasswordTryAgain"));
          }
        }
      }
    }
    if (
      !this.props.preClientSignupCheckStatus &&
      nextProps.preClientSignupCheckStatus
    ) {
      this.setState(nextProps.preClientSignupCheckStatus);
      const { can_signup, is_existing_client, reason } =
        nextProps.preClientSignupCheckStatus;
      if (can_signup) {
        this.props.onSignup(this.ticker, this.state.email);
        this.setState({
          showSignUp: true,
          isExistingClient: is_existing_client,
        });
      } else {
        toastr.error(t("error-0"), reason);
      }
    }
    if (
      nextProps.requests.clientSigninAuthCheckRequest &&
      !nextProps.requests.clientSigninAuthCheckRequest.inProcess &&
      this.authCheckRequestCompletedAt <
        nextProps.requests.clientSigninAuthCheckRequest.completedAt
    ) {
      this.authCheckRequestCompletedAt =
        nextProps.requests.clientSigninAuthCheckRequest.completedAt;
      if (!nextProps.requests.clientSigninAuthCheckRequest.failed) {
        this.signup();
      } else {
        toastr.error(t("error-0"), t("incorrectEmailOrPassword"));
      }
    }
  };
  setup = (props) => {
    let form = {};
    if (!props.preview) {
      form = props.signupForms[this.ticker];
    } else {
      form = props.sampleForm;
    }
    if (!this.didSetInitialState && form) {
      this.setState({
        currentTarget:
          form.target === signupFormTargets.BOTH_NEW_AND_EXISTING
            ? signupFormTargets.NEW_CLIENTS
            : form.target,
        requirePayment: form.require_payment,
        backgroundImageUrl: form.background_image_url,
        isPaypal: form.payment_gateway === 1 ? true : false,
      });
      this.didSetInitialState = true;
    }
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (!this.state.showSignUp) {
      !isMobile && this.signupEmailField && this.signupEmailField.focus();
    }
    if (!prevState.showSignUp && this.state.showSignUp) {
      if (this.state.isExistingClient) {
        this.passwordField && this.passwordField.focus();
      } else {
        this.passwordField && this.nameField.focus();
      }
    }
  };
  onNameChange = (e) => {
    this.setState({
      name: e.target.value,
      nameError: null,
    });
  };
  onLastNameChange = (e) => {
    this.setState({
      lastName: e.target.value,
      lastNameError: null,
    });
  };
  onEmailChange = (e) => {
    this.setState({
      email: e.target.value,
      emailError: null,
    });
  };
  onPasswordChange = (e) => {
    this.setState({
      password: e.target.value,
      passwordError: null,
    });
  };
  onSignup = (e) => {
    e.preventDefault();
    const { email, password, name, lastName, isExistingClient } = this.state;
    const result = isExistingClient
      ? validateRegistrationValues("Dummy", email, password, "Dummy")
      : validateRegistrationValues(name, email, password, lastName);
    if (!result.isValid) {
      this.setState(result.errors);
      return;
    }
    if (isExistingClient) {
      this.props.authCheck({
        email,
        password,
      });
      return;
    }
    this.signup();
  };
  signup = () => {
    const { email, password, name, lastName, isExistingClient } = this.state;
    let params = {
      email: email,
      password: password,
      is_existing_client: isExistingClient,
      ticker: this.ticker,
    };
    if (!isExistingClient) {
      params = {
        first_name: name,
        last_name: lastName,
        ...params,
      };
    }
    let form = this.props.signupForms[this.ticker];
    if (form.require_payment) {
      if (form.payment_gateway === 1) {
        this.props.createPendingSignup(params);
      } else {
        this.props.onCheckout(this.ticker, params, this.state.isExistingClient);
      }
    } else {
      this.props.signup(params);
    }
  };
  toggleTarget = (e) => {
    e.stopPropagation();
    this.setState({
      currentTarget:
        this.state.currentTarget === signupFormTargets.NEW_CLIENTS
          ? signupFormTargets.EXISTING_CLIENTS
          : signupFormTargets.NEW_CLIENTS,
      signupAsExistingClientDialogOpen: false,
    });
  };
  closeSignupAsExistingClientDialog = (e) => {
    this.setState({
      signupAsExistingClientDialogOpen: false,
    });
  };
  onPaymentViewClosed = () => {
    this.setState({
      showPaymentView: false,
    });
  };
  onForgotPassword = () => {
    this.props.onForgotPassword();
  };
  handleClickShowPassword = () => {
    this.setState((state) => ({
      showPassword: !state.showPassword,
    }));
  };
  showSignUp = () => {
    const { email } = this.state;
    let errors = {};
    let isValid = true;
    if (email) {
      if (email.trim().length === 0) {
        errors.emailError = t("emailIsRequired");
        isValid = false;
      } else {
        if (!validateEmail(email.trim())) {
          errors.emailError = t("invalidEmail");
          isValid = false;
        }
      }
    } else {
      errors.emailError = t("emailIsRequired");
      isValid = false;
    }
    if (!isValid) {
      this.setState(errors);
      return;
    }
    this.props.preClientSignupCheck({
      email,
      ticker: this.ticker,
    });
  };
  hideSignUp = () => {
    this.setState({
      showSignUp: false,
    });
  };
  onSubmitCardDetails = (result) => {
    if (result.token) {
      this.props.sayThanksAfterPayment(
        this.ticker,
        this.state.pendingClientSignupToken,
        result.token.id
      );
      this.onPaymentViewClosed();
    }
  };
  openPlayerModal = (e) => {
    if (!isMobile) {
      e.stopPropagation();
      this.player.showPreview();
      this.setState({
        showPlayerModal: true,
      });
    }
  };
  render() {
    let modalStyle1 = getModalStyle(600, 340);
    modalStyle1.overlay = {
      ...modalStyle1.overlay,
      backgroundColor: "rgba(0, 0, 0, 0.8)",
    };
    const { preview } = this.state;
    let form = {};
    if (!preview) {
      form = this.props.signupForms[this.ticker];
    } else {
      form = this.props.sampleForm;
    }
    let paymentModalStyle = getModalStyle(320, 400);
    paymentModalStyle.overlay.backgroundColor = "rgba(0, 0, 0, 0.8)";
    if (!form) {
      return <div />;
    }
    if (form.not_found) {
      return (
        <div style={styles.container}>
          <Paper style={styles.paper}>
            <div style={styles.title}>{t("registrationClosed")}</div>
            <div style={styles.description}>
              <p>
                {t(
                  "noLongerAcceptingNewRegistrationPleaseContactYourPersonalTrainerIfYouThinkThisIsAMistake"
                )}
              </p>
            </div>
          </Paper>
        </div>
      );
    }
    let editorState;
    if (form.description !== null) {
      editorState = EditorState.createWithContent(
        convertFromRaw(JSON.parse(form.description)),
        decorator
      );
    }
    const {
      showSignUp,
      requirePayment,
      isPaypal,
      submitForm,
      backgroundImageUrl,
      isPlaying,
      imageHeight,
      imageWidth,
    } = this.state;
    const {
      payment_currency_code,
      payment_amount,
      payment_type,
      payment_interval,
      payment_start_date,
      video_url,
    } = form;
    let currencySymbol, paymentAmount, amountSuffix, paymentLabel;
    let trial = false;
    if (requirePayment) {
      const trialData = _.range(1, 31).map((i) => {
        let date = i < 10 ? "0" + i : i;
        let value = `1970-01-${date}`;
        let label;
        if (i === 1) {
          label = t("1DayFreeTrial");
        } else {
          // label = `${i}-days free trial`;
          label = t("iDaysFreeTrial", { i });
        }
        return {
          value,
          label,
        };
      });
      currencySymbol = getSymbolFromCurrency(payment_currency_code);
      paymentAmount = payment_amount;
      amountSuffix =
        payment_type === paymentTypes.ONE_TIME
          ? ""
          : payment_interval === paymentFrequencies.MONTHLY
          ? " " + t("mo-0")
          : payment_interval === paymentFrequencies.YEARLY
          ? " " + t("year-0")
          : payment_interval === paymentFrequencies.THREE_MONTHLY
          ? " " + t("3mo")
          : payment_interval === paymentFrequencies.SIX_MONTHLY
          ? " " + t("6mo")
          : " " + t("week-0");
      if (payment_start_date) {
        if (moment(payment_start_date).year() === 1970) {
          let index = trialData.findIndex(
            (trial) => trial.value === payment_start_date.toString()
          );
          let trialLabel = trialData[index].label;
          paymentLabel = t("afterTriallabel", { trialLabel });
          trial = true;
        } else {
          paymentLabel = `from ${moment(payment_start_date).format("MMM D")}`;
        }
      }
    }
    return (
      <div>
        {!showSignUp && (
          <div style={styles.background} className="bg-image-cs">
            <img
              style={{
                height: imageHeight,
                width: imageWidth,
                objectFit: "cover",
              }}
              src={backgroundImageUrl || require(`../../../img/signup.jpg`)}
            />
          </div>
        )}
        <div style={styles.container} className="container-responsive-cs">
          {!showSignUp && (
            <div style={styles.mobileBackground} className="mobile-bg-image-cs">
              <img
                style={{
                  height: imageHeight,
                  width: imageWidth,
                  objectFit: "cover",
                }}
                src={backgroundImageUrl || require(`../../../img/signup.jpg`)}
              />
            </div>
          )}
          <Paper
            style={{
              ...styles.paper,
              ...(!showSignUp
                ? {
                    marginTop: 300,
                    marginBottom: 40,
                  }
                : {
                    marginTop: 57,
                  }),
            }}
            elevation={8}
            className="paper-responsive-cs"
          >
            {!showSignUp && (
              <div>
                <div style={styles.title}>
                  <Typography
                    style={styles.heading}
                    variant="h1"
                    className="title-responsive-cs"
                  >
                    <span style={styles.title}>{form.title}</span>
                  </Typography>
                </div>
                {requirePayment && (
                  <div
                    style={styles.paymentDetails}
                    className="payment-responsive-cs"
                  >
                    <span
                      style={styles.currencySymbol}
                      className="symbol-responsive-cs"
                    >
                      {currencySymbol}
                    </span>
                    <span
                      style={styles.paymentAmount}
                      className="amount-responsive-cs"
                    >
                      {convertNumberToLocaleString(paymentAmount)}
                    </span>
                    <span
                      style={styles.amountSuffix}
                      className="suffix-responsive-cs"
                    >
                      {amountSuffix}
                    </span>
                    <span style={styles.paymentLabel}>
                      {trial ? `(${paymentLabel})` : paymentLabel}
                    </span>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    marginTop: requirePayment ? 20 : 30,
                  }}
                  className="signup-responsive-cs"
                >
                  {video_url && (
                    <div
                      onClick={this.openPlayerModal}
                      style={styles.videoContainer}
                      className="video-responsive-cs"
                    >
                      <ReactPlayer
                        className="react-player"
                        playing={false}
                        light={!isMobile ? true : false}
                        playsInline={0}
                        url={video_url}
                        style={styles.video}
                        ref={(player) => {
                          this.player = player;
                        }}
                        width="100%"
                        height="100%"
                        config={{
                          youtube: {
                            playerVars: {
                              playsinline: isMobile ? 0 : 1,
                            },
                          },
                        }}
                        controls={true}
                      />
                      {/* {!isPlaying && (
                      <img
                        src={ require( "../../../img/play-video-white.png") }
                        style={styles.playBtn}
                        onClick={() => this.setState({ isPlaying: true })}
                       />
                       )} */}
                    </div>
                  )}
                  <div
                    style={
                      video_url
                        ? {
                            ...styles.itemRight,
                          }
                        : {
                            ...{
                              width: "100%",
                            },
                          }
                    }
                    className="signup-form-responsive-cs"
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                      }}
                    >
                      <HotKeys
                        className="signup"
                        handlers={{
                          return: this.showSignUp,
                        }}
                        style={{
                          flex: 1,
                          maxWidth: 330,
                        }}
                      >
                        <TextField
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          placeholder={t("email")}
                          style={{
                            margin: 0,
                          }}
                          value={this.state.email}
                          onChange={this.onEmailChange}
                          helperText={this.state.emailError || ""}
                          error={this.state.emailError ? true : false}
                          InputProps={{
                            style: styles.signupEmailInput,
                          }}
                          inputRef={(emailField) =>
                            (this.signupEmailField = emailField)
                          }
                          disabled={this.props.preview}
                        />
                      </HotKeys>
                      <Button
                        className="signup-btn"
                        color="primary"
                        variant="contained"
                        onClick={this.showSignUp}
                        disabled={
                          !!this.props.loadingBar?.default ||
                          form.inactive ||
                          this.props.preview
                        }
                        style={styles.signupBtnWithInput}
                      >
                        {requirePayment ? t("signUp-0") : t("signUpForFree")}
                      </Button>
                    </div>

                    {form.inactive && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: 20,
                        }}
                      >
                        <img
                          style={{ height: 28, width: 28 }}
                          src={require("../../../img/icon-warning.png")}
                        />
                        {t(
                          "thisPackageIsNotCurrentlyAcceptingSignupPleaseCheckBackLater"
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <Divider
                  style={{
                    marginTop: 40,
                  }}
                />
                <div style={styles.description}>
                  <Editor
                    id={2}
                    editorState={editorState}
                    style={{
                      minHeight: 50,
                      marginBottom: 20,
                    }}
                    onChange={() => {}}
                    readOnly={true}
                  />
                </div>
              </div>
            )}

            {showSignUp && (
              <div style={styles.signUp}>
                <Typography style={styles.heading} variant="h1">
                  <span style={styles.title}>
                    {this.state.isExistingClient ? t("logIn") : t("signUp")}
                  </span>
                </Typography>
                {!this.state.isExistingClient && (
                  <div style={styles.row}>
                    <HotKeys
                      style={styles.fieldContainer}
                      handlers={{
                        return: this.onSignup,
                      }}
                    >
                      <TextField
                        helperText={
                          this.state.nameError ? this.state.nameError : ""
                        }
                        error={this.state.nameError ? true : false}
                        label={t("firstName")}
                        fullWidth={true}
                        onChange={this.onNameChange}
                        value={this.state.name}
                        inputRef={(nameField) => {
                          this.nameField = nameField;
                        }}
                        style={styles.nameField}
                        InputProps={{
                          style: {
                            paddingTop: 2,
                            paddingBottom: 4,
                          },
                        }}
                      />
                    </HotKeys>
                    <HotKeys
                      style={styles.fieldContainer}
                      handlers={{
                        return: this.onSignup,
                      }}
                    >
                      <TextField
                        helperText={
                          this.state.lastNameError
                            ? this.state.lastNameError
                            : ""
                        }
                        error={this.state.lastNameError ? true : false}
                        label={t("lastName")}
                        fullWidth={true}
                        onChange={this.onLastNameChange}
                        value={this.state.lastName}
                        inputRef={(lastNameField) => {
                          this.lastNameField = lastNameField;
                        }}
                        style={styles.nameField}
                        InputProps={{
                          style: {
                            paddingTop: 2,
                            paddingBottom: 4,
                          },
                        }}
                      />
                    </HotKeys>
                  </div>
                )}
                <div
                  style={{
                    ...styles.row,
                    ...{
                      marginTop: 75,
                    },
                  }}
                  className="row-mobile"
                >
                  <HotKeys
                    style={styles.fieldContainer}
                    handlers={{
                      return: this.onSignup,
                    }}
                    className="email-field-mobile"
                  >
                    <TextField
                      helperText={
                        this.state.emailError ? this.state.emailError : ""
                      }
                      disabled
                      label="E-mail"
                      fullWidth={true}
                      onChange={this.onEmailChange}
                      value={this.state.email}
                      error={this.state.emailError ? true : false}
                      InputProps={{
                        style: {
                          paddingTop: 2,
                          paddingBottom: 4,
                          color: "black",
                        },
                        className: "email-disabled-input",
                      }}
                      InputLabelProps={{
                        style: {
                          color: "rgba(0,0,0,0.54)",
                        },
                      }}
                      className="email-disabled"
                    />
                  </HotKeys>

                  <HotKeys
                    style={{
                      ...styles.fieldContainer,
                    }}
                    handlers={{
                      return: this.onSignup,
                    }}
                    className="password-field-mobile"
                  >
                    <TextField
                      helperText={
                        this.state.passwordError
                          ? this.state.passwordError
                          : t(
                              "minimum_6CharactersAlphanumericAndSpecialCharacters"
                            )
                      }
                      label={t("passwordForLoginToTheApp")}
                      fullWidth={true}
                      type={this.state.showPassword ? "text" : "password"}
                      onChange={this.onPasswordChange}
                      value={this.state.password}
                      inputRef={(passwordField) => {
                        this.passwordField = passwordField;
                      }}
                      error={this.state.passwordError ? true : false}
                      InputProps={{
                        style: {
                          paddingTop: 2,
                          paddingBottom: 4,
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label={t("togglePasswordVisibility")}
                              onClick={this.handleClickShowPassword}
                            >
                              {this.state.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </HotKeys>
                </div>

                <div style={styles.toolbar}>
                  <Button onClick={this.hideSignUp} color="primary">
                    {t("backToDescription")}
                  </Button>
                  <div
                    style={{
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {isPaypal ? (
                      <PaymentView
                        form={form}
                        token={
                          this.props.requests.lastClientSignupRequest
                            ? this.props.requests.lastClientSignupRequest.token
                            : ""
                        }
                        onClose={this.onPaymentViewClosed}
                        requirePayment={requirePayment}
                        onSignup={this.onSignup}
                        submitForm={submitForm}
                        disabled={
                          this.props.requests.lastClientSignupRequest &&
                          this.props.requests.lastClientSignupRequest.inProcess
                        }
                      />
                    ) : (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={this.onSignup}
                        style={styles.signupBtn}
                        disabled={
                          (this.props.requests.lastClientSignupRequest &&
                            this.props.requests.lastClientSignupRequest
                              .inProcess) ||
                          (this.props.requests.clientSigninAuthCheckRequest &&
                            this.props.requests.clientSigninAuthCheckRequest
                              .inProcess)
                        }
                      >
                        <span className="payment-mobile-btn-cs">
                          {requirePayment ? t("toPayment") : t("signUp-0")}
                        </span>
                        <span className="payment-btn-cs">
                          {requirePayment
                            ? t("proceedToPayment")
                            : t("signUp-0")}
                        </span>
                      </Button>
                    )}

                    {this.state.isExistingClient && (
                      <div style={styles.toolbarLinks}>
                        <a
                          onClick={this.onForgotPassword}
                          style={{
                            ...styles.targetLink,
                            ...styles.forgotPassword,
                          }}
                        >
                          {t("forgotPassword")}
                        </a>
                      </div>
                    )}
                  </div>
                </div>
                <div style={styles.agreement}>
                  <Trans
                    i18nKey="terms_pp" // optional -> fallbacks to defaults if not provided
                    defaults="By creating an account, you agree to our <t1>Terms of Service</t1> and <t2>Privacy Policy</t2>"
                    components={{
                      t1: (
                        <a
                          href="http://www.trainerfu.com/blog/terms/"
                          target="_blank"
                          style={styles.link}
                        ></a>
                      ),
                      t2: (
                        <a
                          href="http://www.trainerfu.com/blog/privacy/"
                          target="_blank"
                          style={styles.link}
                        ></a>
                      ),
                    }}
                  />
                  {/* {t("byCreatingAnAccountYouAgreeToOur")}{" "}
                  <a
                    href="http://www.trainerfu.com/blog/terms/"
                    target="_blank"
                    style={styles.link}
                  >
                    {t("termsOfService")}
                  </a>{" "}
                  {t("and")}{" "}
                  <a
                    href="http://www.trainerfu.com/blog/privacy/"
                    target="_blank"
                    style={styles.link}
                  >
                    {t("privacyPolicy")}
                  </a>
                  . */}
                </div>
              </div>
            )}
          </Paper>
        </div>

        <Dialog open={this.state.signupAsExistingClientDialogOpen}>
          <DialogTitle id="alert-dialog-title">
            {t("accountWithThisEmailAlreadyExist")}
          </DialogTitle>
          <DialogContent>
            {t("ifYouAreAnExistingClient")}{" "}
            <a onClick={this.toggleTarget} style={styles.dialogLink}>
              {t("clickHereToSignup")}
            </a>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeSignupAsExistingClientDialog}>
              {t("close")}
            </Button>
          </DialogActions>
        </Dialog>
        <Modal
          isOpen={this.state.showPlayerModal}
          onRequestClose={() =>
            this.setState({
              showPlayerModal: false,
            })
          }
          style={modalStyle1}
          contentLabel="Modal"
          ariaHideApp={false}
        >
          <div
            style={{
              paddingBottom: "56.25%",
              position: "relative",
            }}
          >
            <ReactPlayer
              playing={isPlaying}
              url={video_url}
              style={styles.modalVideo}
              width="100%"
              height="100%"
              fileConfig={{
                attributes: {
                  style: {
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  },
                },
              }}
              controls={true}
              onReady={() =>
                this.setState({
                  isPlaying: true,
                })
              }
              onPause={() =>
                this.setState({
                  isPlaying: false,
                })
              }
              onEnded={() =>
                this.setState({
                  isPlaying: false,
                })
              }
              onStart={() =>
                this.setState({
                  isPlaying: true,
                })
              }
              onPlay={() =>
                this.setState({
                  isPlaying: true,
                })
              }
            />
          </div>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    preClientSignupCheckStatus: state.preClientSignupCheck,
    signupForms: state.signupForms,
    loadingBar: state.loadingBar,
    requests: state.requests,
    router: state.router,
    sampleForm: state.savedRegisters.sampleForm,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getSignupForm: (ticker) => {
      dispatch({
        type: GET_SIGNUP_FORM.REQUEST,
        payload: {
          ticker: ticker,
        },
      });
    },
    createPendingSignup: (params) => {
      dispatch({
        type: CREATE_PENDING_CLIENT_SIGNUP.REQUEST,
        payload: params,
      });
    },
    signup: (params) => {
      dispatch({
        type: CREATE_CLIENT_SIGNUP.REQUEST,
        payload: params,
      });
    },
    sayThanks: (ticker, isExistingClient) => {
      let existing = isExistingClient ? 1 : 0;
      dispatch(push(`/signup/${ticker}/thankyou?existing=${existing}`));
    },
    sayThanksAfterPayment: (ticker, pendingSignupToken, stripeToken) => {
      dispatch(
        push(`/signup/${ticker}/thankyou/${pendingSignupToken}`, {
          stripeToken,
        })
      );
    },
    authCheck: (params) => {
      dispatch({
        type: EXISTING_CLIENT_SIGNIN_AUTH_CHECK.REQUEST,
        payload: params,
      });
    },
    onForgotPassword: () => {
      dispatch(push("/forgot-password"));
    },
    onCheckout: (ticker, params, isExistingClient) => {
      let existing = isExistingClient ? 1 : 0;
      dispatch(
        push(`/signup/${ticker}/checkout?existing=${existing}`, {
          params,
        })
      );
    },
    onSignup: (ticker, email) => {
      dispatch(
        push({
          pathname: `/signup/${ticker}`,
          search: `?email=${email}`,
        })
      );
    },
    preClientSignupCheck: (params) => {
      dispatch({
        type: PRE_CLIENT_SIGNUP_CHECK.REQUEST,
        payload: params,
      });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ClientSignup);
