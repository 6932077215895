import {
  blue500,
  blue700,
  blueGrey600,
  primary,
  primaryDark80,
  primaryDark5,
  primaryDark10,
  primaryDark40,
} from "./constants/colors";
import { createTheme } from "@material-ui/core/styles";
let zIndex = {
  zIndex: {
    popover: 5001,
    layer: 5000,
  },
};
const themeDefault = createTheme(
  {
    overrides: {
      MuiButton: {
        contained: {
          // apply your style here
          color: "#524D70",
          backgroundColor: "white",
          border: "1px solid #A3A0B3",
          borderRadius: "6px",
          textTransform: "none",
          padding: "10px 12px",
          "&:hover": {
            backgroundColor: "#F4F4F6",
          },
          "&:active": {
            backgroundColor: "#EAE9EF",
          },
          "&:disabled": {
            backgroundColor: "#EAE9EF",
            border: "none",
            color: "#A3A0B3",
          },
          minWidth: "40px",
        },
        containedPrimary: {
          border: "none",
          color: "white",
        },
        containedSecondary: {
          border: "1px solid #CCC9D5",
          backgroundColor: "white",
          color: primaryDark80,
          "&:hover": {
            backgroundColor: primaryDark5,
          },
          "&:active": {
            backgroundColor: primaryDark10,
          },
          "&:disabled": {
            backgroundColor: primaryDark10,
            border: "none",
            color: primaryDark40,
          },
        },
        text: {
          padding: "8px 12px",
          textTransform: "none",
          color: primary,
          "&:hover": {
            backgroundColor: "#F2EFFC",
            color: primary + " !important",
          },
          "&:active": {
            backgroundColor: "#DCD6F3",
            color: primary + " !important",
          },
          "&:disabled": {
            color: "#A3A0B3",
          },
        },
        textPrimary: {
          textTransform: "none",
          borderRadius: "6px",
          color: primary,
          "&:hover": {
            backgroundColor: "#F2EFFC",
          },
          "&:active": {
            backgroundColor: "#DCD6F3",
          },
          "&:disabled": {
            color: "#A3A0B3",
          },
        },
        startIcon: {
          marginLeft: 0,
        },
        containedSizeSmall: {
          padding: "8px 12px",
          fontSize: "14px",
          lineHeight: "20px",
          fontWeight: "500",
        },
      },
      MuiIconButton: {
        root: {
          // backgroundColor: "#F4F4F6",
          borderRadius: "6px",
          padding: "10px",
          "&:hover": {
            padding: "10px",
            borderRadius: "6px",
            backgroundColor: "#EAE9EF",
          },
          "&:active": {
            padding: "10px",
            borderRadius: "6px",
            backgroundColor: "#CCC9D5",
          },
          "&:disabled": {
            padding: "10px",
            borderRadius: "6px",
            backgroundColor: "#EAE9EF",
          },
        },
      },
      MuiSelect: {
        root: {
          "&:focus": {
            backgroundColor: "transparent !important",
          },
        },
      },
      MuiFormControlLabel: {
        label: {
          fontSize: "14px",
        },
      },
    },
    props: {
      MuiButton: {
        disableElevation: true,
      },
      MuiIconButton: {
        disableRipple: true,
        disableTouchRipple: true,
        disableFocusRipple: true,
      },
    },
    typography: {
      fontFamily: "Inter, sans-serif",
    },
    palette: {
      primary: {
        main: "#7B62E1",
        dark: "#6A4FD6",
      },
      default: {
        main: "red",
      },
      primary1Color: blue500,
      primary2Color: blue700,
      accent1Color: blue700,
      pickerHeaderColor: blue500,
    },
    appBar: {
      color: blueGrey600,
    },
    paper: {
      rounded: false,
    },
  },
  zIndex
);
export default themeDefault;
