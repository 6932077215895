import { t } from "../../services/utils";
import { CREATE_PENDING_CLIENT_SIGNUP, CREATE_CLIENT_SIGNUP, COMPLETE_PENDING_CLIENT_SIGNUP, GET_PENDING_CLIENT_SIGNUP, EXISTING_CLIENT_SIGNIN_AUTH_CHECK } from '../../constants/action-types';
import { takeEvery } from 'redux-saga/effects';
import { postAPI, putAPI, getAPI } from '../api';
import { today, toISOfromDate } from '../../services/date-utils';
function* createPendingClientSignup(action) {
  let params = {
    ...action.payload,
    date: toISOfromDate(today())
  };
  yield* putAPI(action.type, '/pending_client_signups/new', {
    params: params,
    redirectOn401: false
  });
}
function* getPendingClientSignup(action) {
  yield* getAPI(action.type, `/pending_client_signups/${action.payload.token}`);
}
function* existingClientSigninAuthCheck(action) {
  yield* getAPI(action.type, `/auth_check`, {
    params: action.payload
  });
}
function* createClientSignup(action) {
  let params = {
    ...action.payload,
    date: toISOfromDate(today())
  };
  yield* putAPI(action.type, '/client_signups/new', {
    params: params,
    redirectOn401: false
  });
}
function* completePendingClientSignup(action) {
  yield* postAPI(action.type, `/pending_client_signups/${action.payload.token}/complete`, {
    params: {
      token: action.payload.stripeToken
    }
  });
}
const sagas = function* sagas() {
  yield takeEvery(CREATE_PENDING_CLIENT_SIGNUP.REQUEST, createPendingClientSignup);
  yield takeEvery(CREATE_CLIENT_SIGNUP.REQUEST, createClientSignup);
  yield takeEvery(COMPLETE_PENDING_CLIENT_SIGNUP.REQUEST, completePendingClientSignup);
  yield takeEvery(GET_PENDING_CLIENT_SIGNUP.REQUEST, getPendingClientSignup);
  yield takeEvery(EXISTING_CLIENT_SIGNIN_AUTH_CHECK.REQUEST, existingClientSigninAuthCheck);
};
export default sagas;