import { t } from "../../../services/utils";
import React from "react";
import MediaQuery from "react-responsive";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { blue700 } from "../../../constants/colors";
import { connect } from "react-redux";
import { GET_SIGNUP_FORM } from "../../../constants/action-types";
const FREE_TRIAL_TICKER = "free-trial";
class Thankyou extends React.Component {
  constructor(props) {
    super(props);
    this.ticker = props.ticker;
  }
  componentDidMount = () => {
    if (this.ticker === FREE_TRIAL_TICKER) {
    } else {
      let form = this.props.signupForms[this.ticker];
      if (!form) {
        this.props.getSignupForm(this.ticker);
      }
    }
  };
  render() {
    const styles = {
      container: {
        minWidth: 320,
        maxWidth: 800,
        height: "auto",
        position: "absolute",
        top: "10%",
        left: 0,
        right: 0,
        margin: "auto",
        display: "flex",
        flexDirection: "column",
      },
      paper: {
        padding: "6%",
        marginLeft: 10,
        marginRight: 10,
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
      },
      description: {
        fontSize: 18,
      },
      linkContainer: {
        marginTop: 40,
        display: "flex",
        alignItems: "center",
      },
      emailLink: {
        marginTop: 40,
        color: blue700,
        marginBottom: 10,
        fontSize: 16,
      },
      heading: {
        color: "#2A234E",
        fontSize: 32,
        fontWeight: "bold",
        marginBottom: 45,
      },
      text: {
        fontSize: 16,
        color: "#2A234E",
      },
    };
    let isExistingClient = this.props.router.location?.query?.existing === "1";
    let iosAppId = "807834256";
    let internalAppId = "android";
    if (this.ticker !== "free-trial" && this.ticker !== "client-login") {
      let form = this.props.signupForms[this.ticker];
      if (!form) {
        return <div />;
      }
      if (form.ios_app_id) {
        iosAppId = form.ios_app_id;
        internalAppId = form.internal_app_id;
      }
    }
    let p1;
    let p2;
    let p3;
    let note;
    let linkEmail;
    let iosAppUrl = "http://itunes.apple.com/app/id" + iosAppId;
    let androidAppUrl =
      "https://play.google.com/store/apps/details?id=com.trainerfu." +
      internalAppId;
    let webUrl = "https://app.trainerfu.com";
    let appDownloadLinkBody;
    if (this.ticker === FREE_TRIAL_TICKER) {
      appDownloadLinkBody = `iOS App ==> ${iosAppUrl}\n\nAndroid App ==> ${androidAppUrl}\n\nWeb App ==> ${webUrl}`;
    } else {
      appDownloadLinkBody = `iOS App ==> ${iosAppUrl}\n\nAndroid App ==> ${androidAppUrl}`;
    }
    appDownloadLinkBody = encodeURIComponent(appDownloadLinkBody);
    let appDownloadLinkSubject = encodeURIComponent("App Download Links");
    let mailto = `mailto:?body=${appDownloadLinkBody}&subject=${appDownloadLinkSubject}`;
    if (this.ticker === FREE_TRIAL_TICKER) {
      p1 = "";
      p2 =
        t("For best experience on mobile device, please download TrainerFu iOS or Android app.");
      p3 =
        t("To use TrainerFu on desktop, please login to the TrainerFu web app from your desktop computer.");
      note =
        t("Login to the app with same email and password that you just used to create the account.");
      linkEmail = t("Email the app download links to yourself");
    } else {
      if (isExistingClient) {
        p1 =
          t("Your have successfully signed up. To get the most out of your training, please download the training app on your mobile device.");
        p2 =
          t("The app will help you get faster results. You will use the app to see your workout plan, log workouts, track progress, chat and more.");
        linkEmail = t("Email the app download links to yourself");
      } else {
        p1 = t("To get started, download the training app on your mobile device.");
        p2 =
          t("The app will help you get faster results. You will use the app to see your workout plan, log workouts, track progress, chat and more.");
        note =
          t("Login to the app with same email and password that you just used to create the account.");
        linkEmail = t("Email the app download links to yourself");
      }
    }
    return (
      <div>
        <div style={styles.container}>
          <Paper style={styles.paper} elevation={8}>
            <Typography style={styles.heading} variant="h1">
              {t("yourAccountIsSuccessfullyCreated\uD83D\uDCAA")}
            </Typography>
            <div style={styles.description}>
              <p style={styles.text}>{p1}</p>
              <p style={styles.text}>{p2}</p>
              {p3 && <p style={styles.text}>{p3}</p>}
              {note && <p style={styles.text}>{note}</p>}
              <div style={styles.linkContainer}>
                <a href={iosAppUrl} target="_blank">
                  <img
                    src={ require( "../../../img/download_ios_store.png") }
                    alt="Download iOS app"
                  />
                </a>
                <a href={androidAppUrl} target="_blank">
                  <img
                    src={ require( "../../../img/download_play_store.png") }
                    alt="Download Android app"
                  />
                </a>
              </div>
            </div>

            <a href={mailto} target="_blank" style={styles.emailLink}>
              {linkEmail}
            </a>
          </Paper>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    router: state.router,
    signupForms: state.signupForms,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getSignupForm: (ticker) => {
      dispatch({
        type: GET_SIGNUP_FORM.REQUEST,
        payload: {
          ticker: ticker,
        },
      });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Thankyou);
