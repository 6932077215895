import { t } from "../../services/utils";
import { GET_PENDING_BRANDED_APP, GENERATE_BRANDED_APP_FILES, GENERATE_BRANDED_APP_FILES_WITH_TFA_CODE, POLL_PENDING_BRANDED_APP_FOR_END_STATE } from '../../constants/action-types';
import appFilesGenStatus from "../../constants/app-files-gen-status";
export function pendingBrandedApp(state = null, action) {
  switch (action.type) {
    case GET_PENDING_BRANDED_APP.SUCCESS:
      {
        return {
          ...action.payload
        };
      }
    case GENERATE_BRANDED_APP_FILES.SUCCESS:
    case GENERATE_BRANDED_APP_FILES_WITH_TFA_CODE.SUCCESS:
      {
        return {
          ...action.payload
        };
      }
    case POLL_PENDING_BRANDED_APP_FOR_END_STATE.SUCCESS:
      {
        if (action.payload.app_files_gen_stage === appFilesGenStatus.ERROR || action.payload.app_files_gen_stage === appFilesGenStatus.SUCCESS) {
          return {
            ...action.payload
          };
        } else {
          return state;
        }
      }
    case GENERATE_BRANDED_APP_FILES.REQUEST:
    case GENERATE_BRANDED_APP_FILES_WITH_TFA_CODE.REQUEST:
      {
        return {
          'app_files_gen_stage': appFilesGenStatus.GENERATING
        };
      }
    case GENERATE_BRANDED_APP_FILES.ERROR:
    case GENERATE_BRANDED_APP_FILES_WITH_TFA_CODE.ERROR:
      {
        if (state === null) {
          return {
            ...action.payload
          };
        } else {
          return {
            ...state,
            ...action.payload
          };
        }
      }
    default:
      return state;
  }
}