export default {
  "/": {},
  "/clients": {},
  "/login": {},
  "/signup": {},
  "/client-portal": {},
  "/client/:clientId/programs/:programId/days/:day": {},
  "/programs/:programId/days/:day": {},
  "/programs/:programId/print": {},
  "/exercises/:exerciseId": {},
  "/clients/:clientId/programs": {},
  "/clients/:clientId/settings/account": {},
  "/clients/:clientId/settings": {},
  "/clients/:clientId/assessments/exercise/progression/:id": {},
  "/clients/:clientId/assessments/:type/:id": {},
  "/clients/:clientId/assessments/progress_photos": {},
  "/clients/:clientId/assessments/checkins": {},
  "/clients/:clientId/assessments/exercise": {},
  "/clients/:clientId/assessments/nutrition": {},
  "/clients/:clientId/assessments/edit-nutrition": {},
  "/clients/:clientId/assessments": {},
  "/clients/:clientId/programs/past": {},
  "/clients/:clientId/diary": {},
  "/clients/:clientId/messages": {},
  "/clients/:clientId/messages/new": {},
  "/clients/:clientId/messages/:messageId/edit": {},
  "clients/:clientId/habits": {},
  "/clients/:clientId/app-links": {},
  "/plan-templates/:orderedProgramListId": {},
  "/exercises": {},
  "/workout-templates": {},
  "/leaderBoard/:groupId": {},
  "/leaderBoard": {},
  "/leaderBoard/:groupId/assessments/:type/:id": {},
  "/leaderBoard/assessments/:type/:id": {},
  "/leaderBoard/assessments/weight/:id": {},
  "/leaderBoard/:groupId/assessments/checkins": {},
  "/leaderBoard/assessments/checkins": {},
  "/leaderBoard/assessments/progress_photos": {},
  "/leaderBoard/:groupId/assessments/workoutLogged": {},
  "/leaderBoard/assessments/workoutLogged": {},
  "/plan-templates": {},
  "/groups": {},
  "/groups/new": {},
  "/groups/:groupId/edit": {},
  "/groups/:groupId/members": {},
  "/groups/:groupId/members/new": {},
  "/groups/:groupId/files-links": {},
  "/trainers": {},
  "/auto-messaging/:groupId": {},
  "/auto-messaging/clients/:clientId": {},
  "/auto-messaging/:groupId/new": {},
  "/auto-messaging/:groupId/group-message": {},
  "/auto-messaging/:messageId/edit": {},
  "/stories": {},
  "/stories/:storyId": {},
  "/posts/:postId": {},
  "/signup/:ticker": {},
  "/previewForm": {},
  "/signup/:ticker/thankyou": {},
  "/signup/:ticker/checkout": {},
  "/signup/:ticker/thankyou/:token": {},
  "/stripe": {},
  "/forms": {},
  "/forms/:ticker": {},
  "/pspayments/:pendingSignupId": {},
  "/settings/payment": {},
  "/settings": {},
  "/settings/billing": {},
  "/settings/billing/edit": {},
  "/settings/integrations": {},
  "/settings/app-links/": {},
  "/settings/app-links/new": {},
  "/settings/app-settings/": {},
  "/settings/news-feed/": {},
  "/settings/news-feed/clients": {},
  "/settings/in-app-purchase": {},
  "/settings/language": {},
  "/branded-app-files": {},
  "/auto-responders": {},
  "/auto-responders/:groupId/members": {},
  "/auto-responders/:groupId/members/new": {},
  "/auto-responders/:groupId/messages": {},
  "/auto-responders/:groupId/messages/new": {},
  "/auto-responders/:groupId/:messageId/edit": {},
  "/habits": {},
  "/habits/new": {},
  "/habits/:habitId/edit": {},
  "/habits/:groupId/members": {},
  "/habits/:groupId/members/new": {},
};
