import { t } from "../../services/utils";
import { GET_SUBSCRIPTION, UPDATE_SUBSCRIPTION, GET_UPDATE_SUBSCRIPTION_PREVIEW } from "../../constants/action-types";
export function subscription(state = null, action) {
  switch (action.type) {
    case GET_SUBSCRIPTION.SUCCESS:
      {
        return action.payload;
      }
    case UPDATE_SUBSCRIPTION.SUCCESS:
      {
        return action.payload;
      }
    default:
      return state;
  }
}
export function subscriptionUpdatePreview(state = {}, action) {
  switch (action.type) {
    case GET_UPDATE_SUBSCRIPTION_PREVIEW.SUCCESS:
      {
        return {
          ...state,
          [action.payload.token]: action.payload
        };
      }
    default:
      return state;
  }
}