import { t } from "../../services/utils";
import { PERFORMED_SKILL } from '../../constants/action-types';
export function skills(state = {}, action) {
  switch (action.type) {
    case PERFORMED_SKILL:
      {
        let skill = state[action.payload.skillType.toString()];
        let numPerformed = skill ? skill.numPerformed + 1 : 1;
        return {
          ...state,
          [action.payload.skillType]: {
            numPerformed: numPerformed,
            lastPerformed: Math.round(new Date().getTime() / 1000)
          }
        };
      }
    default:
      return state;
  }
}
export function getSkillsStatus(state, skills) {
  let status = {};
  for (let i = 0; i < skills.length; i++) {
    let skill = skills[i];
    if (!state[skill.toString()]) {
      status[skill.toString()] = false;
    } else {
      let ss = state[skill.toString()];
      let ts = Math.round(new Date().getTime() / 1000);
      let didntPerformSinceDays = Math.floor((ts - ss.lastPerformed) / 86400);
      status[skill.toString()] = didntPerformSinceDays < 7 || ss.numPerformed > 20;
    }
  }
  return status;
}