import { t } from "../services/utils";
import React from "react";
import { blue200 } from "../constants/colors";
import LoadingBar from "react-redux-loading-bar";
const LoadableLoadingView = (props) => {
  if (props.error) {
    return (
      <div>
        {t("error")}
        <button onClick={props.retry}>{t("retry")}</button>
      </div>
    );
  } else {
    return null;
  }
};
export default LoadableLoadingView;
