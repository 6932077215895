import { t } from "../../services/utils";
import { GET_EXERCISES, GET_RECENT_EXERCISES, CREATE_EXERCISE, UPDATE_EXERCISE, DELETE_EXERCISE } from "../../constants/action-types";
import { takeEvery } from "redux-saga/effects";
import { getAPI, putAPI, postAPI } from "../api";
function* getExercises(action) {
  yield* getAPI(action.type, "/exercises", {
    params: {
      include_instructions: true,
      include_overrides: true
    },
    getSuccessPayload: function (jsonResp) {
      return jsonResp;
    }
  });
}
function* getRecentExercises(action) {
  yield* getAPI(action.type, "/exercises", {
    params: {
      recent: 1
    },
    getSuccessPayload: function (jsonResp) {
      return jsonResp["exercises"];
    }
  });
}
function* saveExercise(action) {
  let params = {
    ...action.payload.exercise
  };
  if (params.youtube_url) {
    params.youtube_url = `https://www.youtube.com/watch?v=${params.youtube_url}`;
  }
  if (action.payload.exercise.id !== null) {
    if (action.payload.exercise.is_custom) {
      yield* postAPI(action.type, `/exercises/${action.payload.exercise.id}`, {
        params: params,
        getSuccessPayload: resp => {
          return action.payload.exercise;
        }
      });
    } else {
      yield* postAPI(action.type, `/exercises_overrides/${action.payload.exercise.id}`, {
        params: params,
        getSuccessPayload: resp => {
          return action.payload.exercise;
        }
      });
    }
  } else {
    yield* putAPI(action.type, "/exercises/new", {
      params: params,
      getSuccessPayload: resp => {
        return {
          ...action.payload.exercise,
          id: resp.id
        };
      }
    });
  }
}
function* deleteExercise(action) {
  yield* postAPI(action.type, `/exercises/${action.payload.id}/status`, {
    params: {
      is_active: false
    }
  });
}
export default function* sagas() {
  yield takeEvery(GET_EXERCISES.REQUEST, getExercises);
  yield takeEvery(GET_RECENT_EXERCISES.REQUEST, getRecentExercises);
  yield takeEvery(CREATE_EXERCISE.REQUEST, saveExercise);
  yield takeEvery(UPDATE_EXERCISE.REQUEST, saveExercise);
  yield takeEvery(DELETE_EXERCISE.REQUEST, deleteExercise);
}