import { t } from "../../../services/utils";
import React from "react";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { toastr } from "react-redux-toastr";
import { primary } from "../../../constants/colors";
import {
  GET_CLIENT_NOTES,
  ADD_CLIENT_NOTE,
} from "../../../constants/action-types";
import { STORY_VIEW_WIDTH } from "../../../constants/values";
import { getModalStyle } from "../../../services/modal-util";
import channelTypes from "../../../constants/channel-type";
import uuidv4 from "uuid/v4";
import NoteRow from "./NoteRow";
import IconMoon from "../../../components/IconMoon";
class ClientsNoteView extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initState();
  }
  initState = () => {
    return {
      newNote: "",
      open: false,
      selectValue: "",
      notes: [],
      channelId: "",
      showError: false,
    };
  };
  componentDidMount() {
    const { clientId, clients } = this.props;
    const channelId = `${channelTypes.ASSESSMENT_NOTES}-${clientId}`;
    const client = clients[clientId];
    this.setState({
      channelId,
      client,
    });
    this.props.getClientNotes({
      channelId,
    });
  }

  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  componentWillReceiveProps(nextProps) {
    const { clientId } = this.props;
    const channelId = `${channelTypes.ASSESSMENT_NOTES}-${clientId}`;
    const notes = nextProps.notes[channelId];
    this.setState({
      notes,
    });
  }
  handleChange = (event) => {
    this.setState({
      showError: false,
      newNote: event.target.value,
    });
  };
  openModal = () => {
    this.setState({
      open: true,
    });
  };
  closeModal = (e) => {
    e.stopPropagation();
    if (!this.state.isUploading) {
      this.props.closeClientNotes(e);
    }
  };
  addClientNote = (e) => {
    e.stopPropagation();
    const { newNote, channelId } = this.state;
    if (newNote === "") {
      this.setState({
        showError: true,
      });
      return;
    }
    const message = {
      _id: uuidv4(),
      text: newNote,
      message: newNote,
      createdAt: new Date(),
      user: {
        _id: this.props.me.id,
      },
      mimeType: 0,
    };
    this.props.addClientNote({
      message,
      channelId,
    });
    this.setState({
      newNote: "",
    });
  };
  render = () => {
    const styles = {
      container: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
      },
      innerContainer: {
        marginLeft: 32,
        // TO SHOW CLOSE ICON INSIDE
        padding: "14px 20px 20px 20px",
        boxShadow: "0px 2px 36px rgba(0, 0, 0, 0.1)",
        display: "flex",
        flexDirection: "column",
        flex: 1,
      },
      divider: {
        marginLeft: -20,
        marginRight: -20,
        marginBottom: 16,
      },
      row: {
        display: "flex",
        flexWrap: "wrap",
        flex: 1,
        alignItems: "center",
      },
      button: {
        height: 40,
      },
      closeIcon: {
        position: "fixed",
        top: 0,
        width: 32,
        height: 32,
        backgroundColor: primary,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        zIndex: 99999,
      },
      textFieldRow: {
        marginBottom: 14,
      },
      textField: {
        fontSize: 16,
        display: "flex",
        flex: 1,
        padding: 0,
      },
    };
    let modalStyle1 = getModalStyle(STORY_VIEW_WIDTH, 600);
    modalStyle1.content = {
      ...modalStyle1.content,
      top: 97,
      marginTop: 0,
      maxHeight: 700,
      borderRadius: 4,
    };
    modalStyle1.overlay = {
      ...modalStyle1.overlay,
      zIndex: 1000,
    };
    const { clientId } = this.props;
    const { client, showError } = this.state;
    return (
      <div style={styles.container}>
        <div style={styles.closeIcon} onClick={this.closeModal}>
          <IconMoon icon="close" color="white" />
        </div>
        <Paper square={true} elevation={0} style={styles.innerContainer}>
          <div>
            <div style={styles.textFieldRow}>
              <Paper elevation={0} style={styles.row}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                  }}
                >
                  <InputBase
                    placeholder={t("writeYourNoteHere")}
                    multiline={true}
                    rows={1}
                    maxRows={10}
                    // fullWidth={true}
                    onChange={this.handleChange}
                    // onClick={this.openModal}
                    style={styles.textField}
                    value={this.state.newNote}
                    autoFocus={true}
                    inputProps={{
                      style: {
                        padding: 0,
                        lineHeight: "20px",
                      },
                    }}
                    variant="outlined"
                  />
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.addClientNote}
                  style={{
                    ...styles.button,
                  }}
                >
                  {t("addNote")}
                </Button>
              </Paper>
              {showError && (
                <div
                  style={{
                    color: "#f44336",
                    fontSize: 12,
                  }}
                >
                  {t("pleaseEnterNote")}
                </div>
              )}
            </div>
            <Divider style={styles.divider} />
          </div>
          <div style={styles.notesContainer}>
            {this.state.notes.length > 0 &&
              this.state.notes.map((note) => {
                return (
                  <div>
                    <NoteRow note={note} clientId={clientId} />
                    <Divider style={styles.divider} />
                  </div>
                );
              })}
          </div>
          {/* </Modal> */}
        </Paper>
      </div>
    );
  };
}
const mapStateToProps = (state) => {
  return {
    clients: state.clients,
    router: state.router,
    notes: state.notes,
    me: state.me,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addClientNote: (payload) => {
      dispatch({
        type: ADD_CLIENT_NOTE.REQUEST,
        payload,
      });
    },
    getClientNotes: (payload) => {
      dispatch({
        type: GET_CLIENT_NOTES.REQUEST,
        payload,
      });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ClientsNoteView);
