import { t } from "../../services/utils";
import { GET_CAMPAIGNS, ADD_CAMPAIGN, DELETE_CAMPAIGN } from "../../constants/action-types";
export function campaigns(state = {}, action) {
  switch (action.type) {
    case GET_CAMPAIGNS.SUCCESS:
      {
        let campaigns = {};
        action.payload.campaigns.map(c => {
          campaigns[c.id] = c;
        });
        return campaigns;
      }
    case ADD_CAMPAIGN.SUCCESS:
      {
        return {
          ...state,
          [action.payload.id]: {
            ...action.payload
          }
        };
      }
    case DELETE_CAMPAIGN.REQUEST:
      {
        let archivedCampaign = state[action.payload.id];
        return {
          ...state,
          [action.payload.id]: {
            ...archivedCampaign,
            is_archived: true
          }
        };
      }
    default:
      return state;
  }
}