import { t, getLocale } from "../services/utils";
import "whatwg-fetch";
import { push } from "connected-react-router";
import { toastr } from "react-redux-toastr";
import qs from "qs";
import { API_VERSION, VERSION } from "../constants/values";
import {
  getPendingActionType,
  getSuccessActionType,
  getErrorActionType,
} from "../constants/action-types";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { call, put } from "redux-saga/effects";
import {
  RESET_APP_REQUEST,
  DELETE_ACCESS_TOKEN,
  WORKER_STOP,
} from "../constants/action-types";
import errorCodes from "../constants/error-codes";
import bowser from "bowser";
const isPhone = typeof bowser.mobile !== "undefined";
const token = new URL(window.location.href).searchParams.get("token");
// console.log("token is", token);

export const http = function (url, method, params) {
  let credential = "same-origin";
  if (process.env.NODE_ENV == "stage") {
    url = process.env.ROOT_URL + url;
    credential = "include";
  }
  let newHeaders = {};
  if (isPhone && token) {
    newHeaders = {
      "X-AUTHTKT": token,
    };
  }
  return fetch(url, {
    method: method,
    body: params ? JSON.stringify(params) : null,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // "X-AUTHTKT": token
    },

    credentials: credential,
  });
};
export function* getAPI(requestActionType, url, options) {
  return yield* api(requestActionType, "GET", url, options);
}
export function* putAPI(requestActionType, url, options) {
  return yield* api(requestActionType, "PUT", url, options);
}
export function* postAPI(requestActionType, url, options) {
  return yield* api(requestActionType, "POST", url, options);
}
export function* deleteAPI(requestActionType, url, options) {
  return yield* api(requestActionType, "DELETE", url, options);
}
function* api(requestActionType, method, url, options) {
  if (!options) {
    options = {};
  }
  if (options.showLoadingIndicator == undefined) {
    options.showLoadingIndicator = true;
  }
  if (options.showLoadingIndicator) {
    yield put(showLoading());
  }
  if (options.redirectOn401 == undefined) {
    options.redirectOn401 = true;
  }
  let showErrorToast = true;
  if (options.showErrorToast !== undefined) {
    showErrorToast = options.showErrorToast;
  }
  let params = options.params ? options.params : {};
  params["api_version"] = API_VERSION;
  params["version"] = process.env.RELEASE_VERSION;
  params["lang"] = getLocale(); // Set locale here
  yield put({
    type: getPendingActionType(requestActionType),
  });
  let jsonResp = {};
  let ok = false;
  let status = -1;
  try {
    if (method === "GET") {
      url = url.trim() + "?" + qs.stringify(params);
    }
    const resp = yield call(
      http,
      url,
      method,
      method === "GET" ? null : params
    );
    let contentType = resp.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      jsonResp = yield resp.json();
    }
    if (resp.ok) {
      let payload = jsonResp;
      if (options.getSuccessPayload) {
        payload = options.getSuccessPayload(jsonResp);
      }
      let successActionType = getSuccessActionType(requestActionType);
      if (successActionType === DELETE_ACCESS_TOKEN.SUCCESS) {
        yield put(push("/login"));
        yield put({
          type: WORKER_STOP,
        });
      }
      yield put({
        type: successActionType,
        payload: payload,
      });
      ok = true;
    } else {
      if (resp.status === 401) {
        yield put({
          type: getErrorActionType(requestActionType),
          payload: jsonResp,
        });
        if (options.redirectOn401) {
          let lcpathname = window.location.pathname.toLowerCase();
          let pn = lcpathname.replace("/web/", "/");
          yield put(push(`/login?redirectTo=${pn}`));
          yield put({
            type: RESET_APP_REQUEST,
            payload: {},
          });
        }
      } else if (
        resp.status === 403 &&
        jsonResp.error_code === errorCodes.RESET_PASSWORD_REQUIRED
      ) {
        yield put(push("/reset-password"));
      } else if (resp.status === 400) {
        let payload = jsonResp;
        if (options.getErrorPayload) {
          payload = options.getErrorPayload(jsonResp);
        }
        yield put({
          type: getErrorActionType(requestActionType),
          payload: payload,
        });
      } else {
        yield put({
          type: getErrorActionType(requestActionType),
          payload: options.params,
        });
        if (showErrorToast) {
          toastr.error(
            "Server Error",
            "Please try after sometime. If problem persists, contact us at support@trainerfu.com"
          );
        }
      }
      status = resp.status;
    }
  } catch (error) {
    yield put({
      type: getErrorActionType(requestActionType),
    });
    if (showErrorToast) {
      toastr.error(
        "Network Error",
        "Please try after sometime. If problem persists, contact us at support@trainerfu.com"
      );
    }
  } finally {
    if (options.showLoadingIndicator) {
      yield put(hideLoading());
    }
  }
  return {
    ok,
    status,
    jsonResp,
  };
}
