import { t } from "../../services/utils";
import { UPDATE_APP_TITLE_REQUEST, UPDATE_CHAT_STATE } from '../../constants/action-types';
export const updateAppTitle = title => {
  return {
    type: UPDATE_APP_TITLE_REQUEST,
    payload: {
      title: title
    }
  };
};
export const updateChatState = chatState => {
  return {
    type: UPDATE_CHAT_STATE,
    payload: {
      chatState
    }
  };
};