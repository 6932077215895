"use strict";

import { t } from "../../../services/utils";
import moment from "moment";
import triggerTypes from "../../../constants/trigger-types";
import { Label, LabelImportant } from "@material-ui/icons";
let tmUtils = {
  triggerToString: (triggerType, data1, weekData, str) => {
    if (triggerType == triggerTypes.DAYS_AFTER_CREATION) {
      let label;
      if (data1 === 0) {
        label = t("sameDay");
      } else if (data1 === 1) {
        label = t("iDays", { i: 1 });
      } else {
        label = t("iDays", { i: data1 });
      }
      return t("labelAfterClientsAccountCreation", { label });
    } else if (triggerType == triggerTypes.DAYS_AFTER_JOINED_GROUP) {
      let label;
      if (data1 === 0) {
        label = t("sameDay");
      } else if (data1 === 1) {
        label = t("iDays", { i: 1 });
      } else {
        label = t("iDays", { i: data1 });
      }
      return t("labelAfterJoiningTheGroup", { label });
    } else if (triggerType == triggerTypes.AFTER_COMPLETING_NTH_WORKOUT) {
      let data1Str;
      if (data1 == 1) {
        data1Str = "1st";
      } else {
        data1Str = `${data1}th`;
      }
      return t("afterCompletingData1strWorkout", { data1Str });
    } else if (
      triggerType == triggerTypes.AFTER_NOT_LOGGING_WORKOUT_IN_PAST_N_DAYS
    ) {
      return t("afterNotLoggingWorkoutForData1Days", { data1 });
    } else if (triggerType == triggerTypes.ON_EVERY_WEEKDAYS_FOR_N_WEEKS) {
      if (Array.isArray(data1)) {
        return `Every ${data1
          .sort((a, b) => a - b)
          .map((data) => `${getWeekDay[data]}`)
          .join(", ")
          .trim(",")} ${str} for ${weekData} weeks`;
      }
      if (Number.isInteger(data1)) {
        return `Every ${tmUtils
          .getDaysSelected(data1)
          .join(", ")
          .trim(".")} ${str} for ${weekData} weeks`;
      }
    } else if (triggerType == triggerTypes.ON_DATE) {
      return moment(data1).format("ll");
    }
  },
  getDaysSelected: (days) => {
    if (days === DAYS.None) return [];
    var selected = [];
    for (var flag in DAYS) {
      if (flag === "None") continue;
      var val = DAYS[flag];
      if ((days & val) === val) selected.push(flag);
    }
    return selected;
  },
  findLinkEntities: (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === "LINK"
      );
    }, callback);
  },
  getWeekDaysList: () => {
    return [
      {
        label: t("sunday"),
        displayLabel: "Sun",
        value: 2,
      },
      {
        label: t("monday"),
        displayLabel: "Mon",
        value: 4,
      },
      {
        label: t("tuesday"),
        displayLabel: "Tues",
        value: 8,
      },
      {
        label: t("wednesday"),
        displayLabel: "Wed",
        value: 16,
      },
      {
        label: t("thursday"),
        displayLabel: "Thurs",
        value: 32,
      },
      {
        label: t("friday"),
        displayLabel: "Fri",
        value: 64,
      },
      {
        label: t("saturday"),
        displayLabel: "Sat",
        value: 128,
      },
    ];
  },
  getSelectedWeekDaysString: (selectedDays) => {
    let getWeekDay = {
      2: "Sun",
      4: "Mon",
      8: "Tues",
      16: "Wed",
      32: "Thurs",
      64: "Fri",
      128: "Sat",
    };
    let selectedWeekDaysString = t("selectDays");
    if (selectedDays.length > 0) {
      selectedWeekDaysString = selectedDays
        .sort((a, b) => a - b)
        .map((data) => `${getWeekDay[data]}`)
        .join(", ")
        .trim(",");
    }
    return selectedWeekDaysString;
  },
};
let getWeekDay = {
  1: "Sun",
  2: "Mon",
  4: "Tues",
  8: "Wed",
  16: "Thurs",
  32: "Fri",
  64: "Sat",
};
const DAYS = {
  None: 0x0,
  // 0 *
  Sunday: 0x2,
  // 2
  Monday: 0x4,
  // 4
  Tuesday: 0x8,
  // 8
  Wednesday: 0x10,
  // 16
  Thursday: 0x20,
  // 32
  Friday: 0x40,
  // 64
  Saturday: 0x80, // 128
};

export default tmUtils;
