import { t } from "./utils";
export function getModalStyle(width, height) {
  let modalHeight = window.innerHeight > height + 40 ? height : window.innerHeight - 40;
  let modalStyle = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)"
    },
    content: {
      position: "absolute",
      top: "50%",
      left: "50%",
      width: `${width}px`,
      height: `${modalHeight}px`,
      overflow: "auto",
      WebkitOverflowScrolling: "touch",
      borderRadius: "0px",
      outline: "none",
      padding: "0px",
      border: "none",
      backgroundColor: "rgba(0, 0, 0, 0.0)",
      marginLeft: `-${width / 2}px`,
      marginTop: `-${modalHeight / 2}px`
    }
  };
  return modalStyle;
}
export function getModalContentStyle() {
  return {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  };
}