import { getLocale, t } from "../../../services/utils";
import React from "react";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
// import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CheckCircle from "@material-ui/icons/CheckCircle";
import { connect } from "react-redux";
import { HotKeys } from "react-hotkeys";
import { push } from "connected-react-router";
import {
  grey800,
  grey500,
  blue700,
  primaryDark40,
} from "../../../constants/colors";
import {
  GET_GEO,
  GOOGLE_SIGNUP,
  PROVISION_NEW_COMPANY,
} from "../../../constants/action-types";
import {
  validateEmail,
  validatePassword,
  splitName,
  validateRegistrationValues,
} from "../../../services/auth-utils";
import MediaQuery from "react-responsive";
import "./signup.css";
import { ReCaptcha } from "react-recaptcha-v3";
import TextField from "../../../components/TextField";
import { Trans } from "react-i18next";
import { GoogleLogin } from "@react-oauth/google";
import countriesWithCustomPricing from "../../../constants/countries-with-custom-pricing";
import Cookies from 'js-cookie';

class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.plan = this.props.router.location?.query?.plan || "310";


    this.fbc = Cookies.get('_fbc');
    this.fbp = Cookies.get('_fbp');
    if(this.props.geo != null && countriesWithCustomPricing.includes(this.props.geo.country)) {
      this.plan = "1";
    }
    this.recaptchaToken = "";
    if (this.plan)
      this.state = {
        name: "",
        email: "",
        password: "",
        lastName: "",
        nameError: null,
        emailError: null,
        passwordError: null,
        lastNameError: null,
        showPassword: false,
      };
  }
  componentDidMount = () => {
    this.nameField.focus();
    this.interval = setInterval(this.updateToken, 60000);
    if(this.props.geo === null) {
      this.props.getGeo();
    }
  };
  componentWillUnmount = () => {
    clearInterval(this.interval);
  };

  componentWillReceiveProps = (nextProps) => {
    if(this.props.geo === null && nextProps.geo != null) {
      if(countriesWithCustomPricing.includes(nextProps.geo.country)) {
        this.plan = "1";
      }
    }
  };

  componentDidUpdate = () => {};
  onNameChange = (e) => {
    this.setState({
      name: e.target.value,
      nameError: null,
    });
  };
  onLastNameChange = (e) => {
    this.setState({
      lastName: e.target.value,
      lastNameError: null,
    });
  };
  onEmailChange = (e) => {
    this.setState({
      email: e.target.value,
      emailError: null,
    });
  };
  onPasswordChange = (e) => {
    this.setState({
      password: e.target.value,
      passwordError: null,
    });
  };
  checkForError = () => {};
  onSignup = (e) => {
    e.preventDefault();
    this.checkForError();
    this.signup();
  };
  onEnter = (e) => {
    this.signup();
  };
  signup = () => {
    let split = splitName(this.state.name);
    let firstName = split[0];
    let lastName = split[1];
    let result = validateRegistrationValues(
      this.state.name,
      this.state.email,
      this.state.password,
      this.state.lastName
    );
    if (!result.isValid) {
      this.setState(result.errors);
      return;
    }
    this.props.signup(
      this.state.name,
      this.state.lastName,
      this.state.email,
      this.state.password,
      this.plan,
      this.recaptchaToken,
      this.fbc,
      this.fbp

    );
    this.updateToken();
  };
  handleClickShowPassword = () => {
    this.updateToken();
    this.setState((state) => ({
      showPassword: !state.showPassword,
    }));
  };
  verifyCallback = (recaptchaToken) => {
    this.recaptchaToken = recaptchaToken;
  };
  updateToken = () => {
    // you will get a new token in verifyCallback
    this.recaptcha.execute();
  };
  render() {
    const styles = {
      container: {
        minWidth: 320,
        maxWidth: 1440,
        height: "auto",
        position: "absolute",
        left: 0,
        right: 0,
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        padding: "25px 100px",
        paddingRight: "7%",
        paddingLeft: "7%",
      },
      paper: {
        width: "36%",
        maxWidth: 442,
        padding: 50,
        marginLeft: 10,
        marginRight: 10,
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
      },
      logoImg: {
        alignSelf: "center",
        marginBottom: 24,
      },
      fieldContainer: {
        display: "flex",
        flexDirection: "column",
      },
      toolbar: {
        display: "flex",
        marginTop: 32,
        flexDirection: "row-reverse",
      },
      signupBtn: {
        width: "100%",
        marginLeft: "auto",
      },
      login: {
        alignSelf: "center",
        fontSize: "16px",
        cursor: "pointer",
        color: "#343AB4",
      },
      title: {
        // fontFamily: "'Montserrat', sans-serif",
        fontSize: "2.5vw",
        fontWeight: "bold",
        color: grey800,
        marginBottom: 35,
        marginLeft: "auto",
        marginRight: "auto",
      },
      agreement: {
        fontSize: 13,
        color: grey500,
        marginTop: 42,
        fontFamily: "Roboto",
      },
      link: {
        color: grey500,
        textDecoration: "underline",
      },
      field: {
        marginBottom: 20,
      },
      row: {
        display: "flex",
        justifyContent: "space-between",
      },
      leftContent: {
        marginTop: 45,
        width: "44%",
        maxWidth: 545,
      },
      lists: {
        marginTop: 27,
      },
      listItem: {
        display: "flex",
        alignItems: "center",
        fontSize: 24,
        fontWeight: 300,
        marginBottom: 0,
        marginTop: 20,
      },
      logo: {
        display: "flex",
        alignItems: "center",
      },
      logoImg: {
        height: 29,
        width: 29,
      },
      logoText: {
        fontSize: 24,
        fontWeight: "bold",
        marginLeft: 4,
        marginTop: 10,
      },
      company: {
        height: 70,
        width: 80,
      },
      companies: {
        width: "100%",
        marginTop: 30,
      },
      orText: {
        textAlign: "left",
        marginTop: 20,
        fontSize: 12,
        color: primaryDark40,
        marginBottom: 20,
      },
    };

    let headline = '';
    if(this.props.geo != null) {
      if(countriesWithCustomPricing.includes(this.props.geo.country)) {
        headline = t('startYourFreeTrial');
      } else {
        headline = t('startFree14DayTrial');
      }
    }
    return (
      <div style={styles.container} className="container-responsive">
        <div
          style={{
            ...styles.row,
            ...{
              alignItems: "center",
            },
          }}
        >
          <div style={styles.logo}>
            <img
              style={styles.logoImg}
              src={require("../../../img/logo2.png")}
            />
            <span style={styles.logoText}>{t("trainerFu")}</span>
          </div>
          <div>
            {/* <MediaQuery minDeviceWidth={600}>
              <span style={{ color: "#787878" }}>
                Already Have an account?{" "}
              </span>
             </MediaQuery> */}
            <span
              style={{
                color: "#787878",
              }}
              className="header-text"
            >
              {t("alreadyHaveAnAccount")}{" "}
            </span>
            <span
              style={styles.login}
              className="hover-primary"
              onClick={this.props.onLogin}
            >
              {t("logIn")}
            </span>
          </div>
        </div>
        <div
          style={{
            ...styles.row,
            ...{
              marginTop: 50,
            },
          }}
          className="content-responsive"
        >
          <MediaQuery minDeviceWidth={850}>
            <div style={styles.leftContent} className="left-content">
              <div style={styles.title} className="signup-title-responsive">
                {t("unlockTheSuperpower")}
              </div>
              <div style={styles.lists}>
                <p style={styles.listItem}>
                  <CheckCircle
                    style={{
                      color: "#09BC6B",
                      marginRight: 15,
                    }}
                  />
                  <span>{t("noCreditCard")}</span>
                </p>
                <p style={styles.listItem}>
                  <CheckCircle
                    style={{
                      color: "#09BC6B",
                      marginRight: 15,
                    }}
                  />
                  <span>{t("noContracts")}</span>
                </p>
                <p style={styles.listItem}>
                  <CheckCircle
                    style={{
                      color: "#09BC6B",
                      marginRight: 15,
                    }}
                  />
                  <span>{t("noFuss")}</span>
                </p>
              </div>
              <div style={styles.companies}>
                <div
                  style={{
                    ...styles.row,
                    ...{
                      alignItems: "center",
                    },
                  }}
                >
                  <img
                    style={{ ...styles.company, ...{ height: 35 } }}
                    src={require("../../../img/c1.png")}
                  />
                  <img
                    style={styles.company}
                    src={require("../../../img/c2.png")}
                  />
                  <img
                    style={styles.company}
                    src={require("../../../img/c3.png")}
                  />
                  <img
                    style={styles.company}
                    src={require("../../../img/c4.png")}
                  />
                </div>
                <div
                  style={{
                    ...styles.row,
                    ...{
                      alignItems: "center",
                      marginTop: 60,
                    },
                  }}
                >
                  <img
                    style={styles.company}
                    src={require("../../../img/c5.png")}
                  />
                  <img
                    style={styles.company}
                    src={require("../../../img/c6.png")}
                  />
                  <img
                    style={styles.company}
                    src={require("../../../img/c7.png")}
                  />
                  <img
                    style={styles.company}
                    src={require("../../../img/c8.png")}
                  />
                </div>
              </div>
            </div>
          </MediaQuery>

          <Paper
            style={styles.paper}
            elevation={5}
            className="paper-responsive"
          >
            <div style={styles.title} className="signup-title-responsive">
              {headline}
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <GoogleLogin
                onSuccess={(credentialResponse) => {
                  this.props.onGoogleLogin(
                    this.state.firstName,
                    credentialResponse?.credential,
                    this.plan,
                    this.recaptchaToken,
                    this.fbc,
                    this.fbp
                  );
                  console.log(credentialResponse);
                }}
                onError={() => {
                  console.log("Login Failed");
                }}
                text={"signup_with"}
                theme={"filled_blue"}
                locale={getLocale()}
                size={"large"}
              />
            </div>
            <div className="timer-divider" style={styles.orText}>
              {t("Or Sign Up With Email")}
            </div>
            <div style={styles.row} className="form-responsive">
              <HotKeys
                style={{
                  ...styles.fieldContainer,
                  ...{
                    width: "45%",
                  },
                }}
                handlers={{
                  return: this.onEnter,
                }}
              >
                <TextField
                  label={t("firstName")}
                  fullWidth={true}
                  onChange={this.onNameChange}
                  value={this.state.name}
                  inputRef={(nameField) => {
                    this.nameField = nameField;
                  }}
                  style={styles.field}
                  helperText={this.state.nameError ? this.state.nameError : ""}
                  error={this.state.nameError ? true : false}
                  // InputProps={{
                  //   style: { paddingTop: 2, paddingBottom: 4 },
                  // }}
                />
              </HotKeys>

              <HotKeys
                style={{
                  ...styles.fieldContainer,
                  ...{
                    width: "45%",
                  },
                }}
                handlers={{
                  return: this.onEnter,
                }}
              >
                <TextField
                  helperText={
                    this.state.lastNameError ? this.state.lastNameError : ""
                  }
                  error={this.state.lastNameError ? true : false}
                  label={t("lastName")}
                  fullWidth={true}
                  onChange={this.onLastNameChange}
                  value={this.state.lastName}
                  inputRef={(lastNameField) => {
                    this.lastNameField = lastNameField;
                  }}
                  style={styles.field}
                  // InputProps={{
                  //   style: { paddingTop: 2, paddingBottom: 4 },
                  // }}
                />
              </HotKeys>
            </div>

            <HotKeys
              style={styles.fieldContainer}
              handlers={{
                return: this.onEnter,
              }}
            >
              <TextField
                label="E-mail"
                fullWidth={true}
                onChange={this.onEmailChange}
                value={this.state.email}
                inputRef={(emailField) => {
                  this.emailField = emailField;
                }}
                helperText={this.state.emailError ? this.state.emailError : ""}
                error={this.state.emailError ? true : false}
                style={{
                  ...styles.field,
                  ...{
                    marginTop: 30,
                  },
                }}
                // InputProps={{
                //   style: { paddingTop: 2, paddingBottom: 4 },
                // }}
              />
            </HotKeys>

            <HotKeys
              style={styles.fieldContainer}
              handlers={{
                return: this.onEnter,
              }}
            >
              <TextField
                label={t("password")}
                fullWidth={true}
                type={this.state.showPassword ? "text" : "password"}
                onChange={this.onPasswordChange}
                value={this.state.password}
                inputRef={(passwordField) => {
                  this.passwordField = passwordField;
                }}
                helperText={
                  this.state.passwordError
                    ? this.state.passwordError
                    : t("minimum_6CharactersAlphanumericAndSpecialCharacters")
                }
                error={this.state.passwordError ? true : false}
                style={{
                  ...styles.field,
                  ...{
                    marginTop: 30,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={this.handleClickShowPassword}
                      >
                        {this.state.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  className: "modal-input",
                  style: {
                    paddingLeft: 20,
                    fontSize: 14,
                    lineHeight: "20px",
                    paddingTop: 9,
                    paddingBottom: 9,
                  },
                }}
              />
            </HotKeys>

            <div style={styles.toolbar}>
              <Button
                variant="contained"
                color="primary"
                style={styles.signupBtn}
                onClick={this.onSignup}
              >
                {t("createYourAccount")}
              </Button>

              {/* <div
               style={styles.login}
               className="hover-primary"
               onClick={this.props.onLogin}
               >
               Log In
               </div> */}
            </div>
            <ReCaptcha
              ref={(ref) => (this.recaptcha = ref)}
              sitekey={process.env.RECAPTCHA_KEY}
              action="signup"
              verifyCallback={this.verifyCallback}
            />
            <div style={styles.agreement}>
              <Trans
                i18nKey="terms_pp" // optional -> fallbacks to defaults if not provided
                defaults="By creating an account, you agree to our <t1>Terms of Service</t1> and <t2>Privacy Policy</t2>"
                components={{
                  t1: (
                    <a
                      href="http://www.trainerfu.com/blog/terms/"
                      target="_blank"
                      style={styles.link}
                    ></a>
                  ),
                  t2: (
                    <a
                      href="http://www.trainerfu.com/blog/privacy/"
                      target="_blank"
                      style={styles.link}
                    ></a>
                  ),
                }}
              />

              <br></br>
              <br></br>

              <Trans
                i18nKey="recaptcha_pp" // optional -> fallbacks to defaults if not provided
                defaults="This site is protected by reCAPTCHA and the Google <t1>Privacy Policy</t1> and <t2>Terms of Service</t2> apply."
                components={{
                  t1: (
                    <a
                      href="https://policies.google.com/privacy"
                      target="_blank"
                      style={styles.link}
                    ></a>
                  ),
                  t2: (
                    <a
                      href="https://policies.google.com/terms"
                      target="_blank"
                      style={styles.link}
                    ></a>
                  ),
                }}
              />
            </div>
          </Paper>
          <div style={styles.agreement}></div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    router: state.router,
    geo: state.geo
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onGoogleLogin: (firstName, credential, plan, recaptchaToken, fbc, fbp) => {
      dispatch({
        type: GOOGLE_SIGNUP.REQUEST,
        payload: {
          company: "FitBiz",
          id_token: credential,
          plan,
          recaptchaToken,
          fbc,
          fbp
        },
      });
    },

    signup: (firstName, lastName, email, password, plan, recaptchaToken, fbc, fbp) => {
      dispatch({
        type: PROVISION_NEW_COMPANY.REQUEST,
        payload: {
          firstName,
          lastName,
          email,
          password,
          plan,
          recaptchaToken,
          fbc,
          fbp
        },
      });
    },
    onLogin: () => {
      dispatch(push("/login"));
    },
    getGeo: () => {
      dispatch({
        type: GET_GEO.REQUEST
      })
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Signup);
