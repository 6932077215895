import { t } from "../../services/utils";
import { GET_GROUPS, ADD_GROUP, UPDATE_GROUP, DELETE_GROUP, GET_GROUP_MEMBERS, UPDATE_GROUP_MEMBERS } from '../../constants/action-types';
export function groups(state = {}, action) {
  switch (action.type) {
    case GET_GROUPS.SUCCESS:
      {
        const {
          segments
        } = action.payload;
        let groups = {};
        segments.map(group => {
          groups[group.id] = group;
        });
        return groups;
      }
    case ADD_GROUP.SUCCESS:
      {
        return {
          ...state,
          [action.payload.id]: action.payload
        };
      }
    case UPDATE_GROUP.SUCCESS:
      {
        return {
          ...state,
          [action.payload.id]: action.payload
        };
      }
    case DELETE_GROUP.SUCCESS:
      {
        const groups = Object.assign({}, state);
        delete groups[action.payload];
        return groups;
      }
    default:
      return state;
  }
}
export function groupMembers(state = {}, action) {
  switch (action.type) {
    case GET_GROUP_MEMBERS.SUCCESS:
    case UPDATE_GROUP_MEMBERS.REQUEST:
      {
        const {
          id,
          members
        } = action.payload;
        return {
          ...state,
          [id]: members
        };
      }
    default:
      return state;
  }
}