import { t } from "../../services/utils";
import _ from 'lodash';
import { GET_ACCOUNT } from '../../constants/action-types';
export function account(state = null, action) {
  switch (action.type) {
    case GET_ACCOUNT.SUCCESS:
      {
        return action.payload;
      }
    default:
      return state;
  }
}