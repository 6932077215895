import { t } from "../../services/utils";
import { GET_TRAINERS, ADD_TRAINER, DELETE_TRAINER, UPDATE_TRAINER } from "../../constants/action-types";
export function trainers(state = null, action) {
  switch (action.type) {
    case GET_TRAINERS.SUCCESS:
      {
        const {
          users
        } = action.payload;
        let trainers = {};
        users.map(user => {
          trainers[user.id] = user;
        });
        return trainers;
      }
    case UPDATE_TRAINER.SUCCESS:
    case ADD_TRAINER.SUCCESS:
      {
        return {
          ...state,
          [action.payload.id]: action.payload
        };
      }
    case DELETE_TRAINER.SUCCESS:
      {
        const trainers = Object.assign({}, state);
        delete trainers[action.payload.id];
        return trainers;
      }
    default:
      return state;
  }
}