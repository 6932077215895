import { t } from "../../../services/utils";
import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { primaryDark60, primaryDark } from "../../../constants/colors";
import { DELETE_CLIENT_NOTE, EDIT_CLIENT_NOTE } from "../../../constants/action-types";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import channelTypes from "../../../constants/channel-type";
import { NewDialog } from "../../../components/Dialog";
import IconMoon from "../../../components/IconMoon";
import Modal from "react-modal";
import { getModalStyle } from "../../../services/modal-util";
import ClientNoteEditView from "./ClientNoteEditView";
class NoteRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      fullMessage: false,
      isTruncated: false,
      editNote: false,
      showDeleteDialog: false,
      editNoteText: props.note?.message || ""
    };
  }
  componentDidMount = () => {
    if (this.message) {
      this.setState({
        messageHeight: this.message.clientHeight
      });
    }
  };
  handleClick = event => {
    event.stopPropagation();
    this.setState({
      anchorEl: event.currentTarget
    });
  };
  handleClose = event => {
    const {
      value
    } = event.target;
    event.stopPropagation();
    this.setState({
      anchorEl: null
    });
    if (value === 1) {
      this.setState({
        editNote: true,
        editNoteText: this.props.note.message
      });
    }
    if (value === 2) {
      this.setState({
        showDeleteDialog: true
      });
    }
  };
  toggleFullMessage = e => {
    e.stopPropagation();
    this.setState({
      isTruncated: !this.state.isTruncated
    });
  };
  confirmDelete = e => {
    const {
      clientId,
      note
    } = this.props;
    const channelId = `${channelTypes.ASSESSMENT_NOTES}-${clientId}`;
    e.stopPropagation();
    this.props.onDeleteNote(this.props.note._id, channelId);
    this.setState({
      showDeleteDialog: false
    });
  };
  editClientNote = editedNote => {
    const {
      clientId,
      note
    } = this.props;
    const channelId = `${channelTypes.ASSESSMENT_NOTES}-${clientId}`;
    this.props.onEditNote(this.props.note._id, channelId, editedNote);
    this.setState({
      editNote: false
    });
  };
  onCancelEdit = e => {
    this.setState({
      editNote: false,
      editNoteText: ""
    });
  };
  render = () => {
    const {
      anchorEl
    } = this.state;
    const open = Boolean(anchorEl);
    let styles = {
      container: {
        display: "flex",
        paddingBottom: 2,
        marginBottom: 10
      },
      content: {
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto"
      },
      date: {
        fontSize: 14,
        lineHeight: "16px",
        fontWeight: "400",
        color: primaryDark60
      },
      preview: {
        marginTop: 4,
        borderWidth: 0,
        backgroundColor: "transparent",
        minHeight: "unset",
        padding: 0,
        height: "unset",
        color: primaryDark,
        fontWeight: "500"
      },
      row: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
      }
    };
    const {
      message,
      createdAt
    } = this.props.note;
    let modalStyle = getModalStyle(560, 500);
    modalStyle.content = {
      ...modalStyle.content,
      top: "60%",
      borderRadius: 6
    };
    return <div style={styles.container}>
        <div style={styles.content}>
          <div style={styles.row}>
            <div style={styles.date}>
              {moment(createdAt).format("MMM D YYYY")}
            </div>
            <div>
              <IconButton aria-label="More" aria-owns={open ? "long-menu" : undefined} aria-haspopup="true" onClick={this.handleClick}>
                <IconMoon icon="moreV" color={primaryDark60} />
              </IconButton>
              <Menu id="long-menu" anchorEl={anchorEl} open={open} onClose={this.handleClose} style={{
              zIndex: 9999
            }} PaperProps={{
              style: {
                maxHeight: 48 * 4.5,
                minWidth: 100
              }
            }} anchorOrigin={{
              horizontal: "right",
              vertical: "top"
            }} transformOrigin={{
              horizontal: "right",
              vertical: "top"
            }}>
                <MenuItem key={"edit"} value="1" onClick={this.handleClose}>{t("edit")}</MenuItem>
                <MenuItem key={"delete"} value="2" onClick={this.handleClose}>{t("delete")}</MenuItem>
              </Menu>
            </div>
          </div>
          <div className="RichEditor-root" style={styles.preview}>
            <div dangerouslySetInnerHTML={{
            __html: message
          }} />
          </div>
          {this.state.editNote && <Modal isOpen={this.state.editNote} onRequestClose={this.onCancelEdit} style={modalStyle} contentLabel="Modal" ariaHideApp={false}>
              <ClientNoteEditView note={this.state.editNoteText} onCancelEdit={this.onCancelEdit} onSave={this.editClientNote} />
            </Modal>}
        </div>

        {this.state.showDeleteDialog && <NewDialog open={this.state.showDeleteDialog} message={t("areYouSureYouWantToDeleteThisNote")} actions={[{
        label: t("delete"),
        primary: true,
        onClick: this.confirmDelete
      }, {
        label: t("cancel"),
        onClick: () => this.setState({
          showDeleteDialog: false
        })
      }]} onRequestClose={() => this.setState({
        showDeleteDialog: false
      })} />}
      </div>;
  };
}
const mapStateToProps = state => {
  return {
    requests: state.requests
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onDeleteNote: (messageId, channelId) => {
      dispatch({
        type: DELETE_CLIENT_NOTE.REQUEST,
        payload: {
          messageId,
          channelId
        }
      });
    },
    onEditNote: (messageId, channelId, message) => {
      dispatch({
        type: EDIT_CLIENT_NOTE.REQUEST,
        payload: {
          messageId,
          channelId,
          message
        }
      });
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NoteRow);