import { t } from "../../../services/utils";
import React from "react";
import MediaQuery from "react-responsive";
import Button from "@material-ui/core/Button";
import { green500, primaryDark60 } from "../../../constants/colors";
import paymentTypes from "../../../constants/payment-types";
class PaymentView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
    };
  }
  componentDidMount = () => {};

  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  componentWillReceiveProps(nextProps) {
    if (nextProps.submitForm && !this.props.submitForm) {
      this.setState(
        {
          token: nextProps.token,
        },
        () => {
          document.getElementById("paymentForm").submit();
        }
      );
    }
  }
  render() {
    const styles = {
      background: {
        display: "flex",
        flex: "1 1 auto",
        flexDirection: "column",
        width: "100%",
        position: "relative",
      },
      container: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        marginTop: 40,
        paddingTop: 55,
        paddingBottom: 20,
        borderRadius: 5,
        position: "relative",
      },
      sealContainer: {
        display: "flex",
        position: "absolute",
        width: "100%",
        height: 70,
      },
      seal: {
        marginLeft: "auto",
        marginRight: "auto",
        width: 70,
        height: 70,
        zIndex: 100,
        backgroundColor: green500,
        display: "flex",
      },
      sealIcon: {
        color: "white",
        fontSize: 36,
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: 16,
      },
      title: {
        fontWeight: 600,
        marginLeft: "auto",
        marginRight: "auto",
        fontSize: 32,
        textAlign: "center",
      },
      detail: {
        marginLeft: "auto",
        marginRight: "auto",
        fontSize: 18,
        marginTop: 5,
        textAlign: "center",
      },
      paymentButtonContainer: {
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: 30,
        marginBottom: 10,
      },
      closeButton: {
        position: "absolute",
        top: 0,
        right: 0,
        width: 36,
        height: 36,
        padding: 4,
      },
      closeIcon: {
        color: primaryDark60,
        width: 24,
        height: 24,
      },
      signupBtn: {
        height: 50,
        width: 310,
      },
    };
    let submit_url;
    let cancel_return_url;
    let return_url;
    let notify_url;
    if (process.env.NODE_ENV === "production") {
      submit_url = "https://www.paypal.com/cgi-bin/webscr";
      cancel_return_url = `https://app.trainerfu.com/web/signup/${this.props.form.ticker}`;
      return_url = `https://app.trainerfu.com/web/signup/${this.props.form.ticker}/thankyou/${this.state.token}`;
      notify_url = `https://app.trainerfu.com/pending_client_signups/${this.state.token}/complete`;
    } else {
      submit_url = "https://www.sandbox.paypal.com/cgi-bin/webscr";
      cancel_return_url = `http://localhost:6543/web/signup/${this.props.form.ticker}`;
      return_url = `http://localhost:6543/web/signup/${this.props.form.ticker}/thankyou/${this.state.token}`;
      notify_url = `https://webhook.site/7dc55ecb-1bdb-4856-a334-1b637349edef`;
    }
    const { onSignup } = this.props;
    return (
      <div>
        <div style={styles.background} />
        <div style={styles.paymentButtonContainer}>
          {this.props.form.payment_type === paymentTypes.ONE_TIME && (
            <form action={submit_url} method="post" id="paymentForm">
              <input
                type="hidden"
                name="business"
                value={this.props.form.paypal_email}
              />

              <input type="hidden" name="cmd" value="_xclick" />

              <input
                type="hidden"
                name="item_name"
                value={this.props.form.payment_title}
              />
              <input
                type="hidden"
                name="amount"
                value={this.props.form.payment_amount}
              />
              <input
                type="hidden"
                name="currency_code"
                value={this.props.form.payment_currency_code}
              />
              <input type="hidden" name="return" value={return_url} />
              <input
                type="hidden"
                name="cancel_return"
                value={cancel_return_url}
              />
              <input type="hidden" name="notify_url" value={notify_url} />
              <Button
                style={{
                  ...styles.signupBtn,
                  ...{
                    width: 147,
                  },
                }}
                onClick={onSignup}
                color="primary"
                variant="contained"
                className="payment-mobile-btn-cs"
                disabled={this.props.disabled}
              >
                {t("toPayment")}
              </Button>
              <Button
                style={styles.signupBtn}
                onClick={onSignup}
                color="primary"
                variant="contained"
                className="payment-btn-cs"
                disabled={this.props.disabled}
              >
                {t("proceedToPayment")}
              </Button>
              <img
                alt=""
                border="0"
                width="1"
                height="1"
                src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
              />
            </form>
          )}

          {this.props.form.payment_type === paymentTypes.SUBSCRIPTION && (
            <form action={submit_url} method="post" id="paymentForm">
              <input
                type="hidden"
                name="business"
                value={this.props.form.paypal_email}
              />

              <input type="hidden" name="cmd" value="_xclick-subscriptions" />

              <input
                type="hidden"
                name="item_name"
                value={this.props.form.payment_title}
              />
              <input
                type="hidden"
                name="currency_code"
                value={this.props.form.payment_currency_code}
              />
              <input
                type="hidden"
                name="a3"
                value={this.props.form.payment_amount}
              />
              <input type="hidden" name="p3" value="1" />
              <input type="hidden" name="t3" value="M" />
              <input type="hidden" name="src" value="1" />
              <input type="hidden" name="return" value={return_url} />
              <input
                type="hidden"
                name="cancel_return"
                value={cancel_return_url}
              />
              <input type="hidden" name="notify_url" value={notify_url} />
              <MediaQuery maxDeviceWidth={600}>
                <Button
                  style={{
                    ...styles.signupBtn,
                    ...{
                      width: 147,
                    },
                  }}
                  onClick={onSignup}
                  color="primary"
                  variant="contained"
                  disabled={this.props.disabled}
                >
                  {t("toPayment")}
                </Button>
              </MediaQuery>
              <MediaQuery minDeviceWidth={600}>
                <Button
                  style={styles.signupBtn}
                  onClick={onSignup}
                  color="primary"
                  variant="contained"
                  disabled={this.props.disabled}
                >
                  {t("proceedToPayment")}
                </Button>
              </MediaQuery>
              <img
                alt=""
                width="1"
                height="1"
                src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
              />
            </form>
          )}
        </div>
      </div>
    );
  }
}
export default PaymentView;
