import { t } from "../../../services/utils";
import React, { Component } from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe,
  Elements,
} from "react-stripe-elements";
import getSymbolFromCurrency from "currency-symbol-map";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { push, goBack } from "connected-react-router";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { toastr } from "react-redux-toastr";
import { grey800 } from "../../../constants/colors";
import paymentFrequencies from "../../../constants/payment-frequency";
import errorCodes from "../../../constants/error-codes";
import "./StripeCheckout.css";
import { CREATE_CLIENT_SIGNUP } from "../../../constants/action-types";
import { trialData } from "./Trials";
import {convertNumberToLocaleString, convertLocaleStringToNumber} from '../../../services/utils';

const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: "16px",
        color: "#424770",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
    },
  };
};
import paymentTypes from "../../../constants/payment-types";
class _CardForm extends Component {
  state = {
    isLoading: false,
    errorMessage: "",
    address_zip: "",
  };
  handleChange = ({ error }) => {
    if (error) {
      this.setState({
        errorMessage: error.message,
      });
    }
  };
  handleZipChange = (e) => {
    this.setState({
      address_zip: e.target.value,
    });
  };
  handleSubmit = (evt) => {
    evt.preventDefault();
    this.props.handleSubmit();
    if (!this.state.address_zip || this.state.address_zip.length < 4) {
      this.props.handleError();
      this.setState({
        errorMessage: t("incorrectPostalCode"),
      });
      return;
    }
    if (this.props.stripe) {
      this.props.stripe
        .createToken({
          address_zip: this.state.address_zip,
        })
        .then((resp) => {
          if (resp.error) {
            this.setState({
              errorMessage: resp.error.message,
            });
            this.props.handleError(resp);
          } else {
            this.setState({
              errorMessage: "",
            });
            this.props.handleResult(resp);
          }
        })
        .catch((error) => {
          this.props.handleError();
          this.setState({
            errorMessage: error.message || "",
          });
        });
    } else {
      this.setState({
        errorMessage: t(
          "weAreUnableToProcessYourPaymentAtThisTimePleaseRefreshTheAppAndTryAgainLater"
        ),
      });
      this.props.handleError();
    }
  };
  goBack = () => {
    this.props.goBack();
  };
  render() {
    const {
      amount,
      currencySymbol,
      paymentType,
      paymentInterval,
      paymentNumIntervals,
      paymentStartDate,
    } = this.props;
    const styles = {
      container: {
        minWidth: 320,
        maxWidth: 800,
        height: "auto",
        position: "absolute",
        top: "10%",
        left: 0,
        right: 0,
        margin: "auto",
        display: "flex",
        flexDirection: "column",
      },
      paperContainer: {
        padding: "6%",
        marginLeft: 10,
        marginRight: 10,
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
      },
      cardContainer: {
        marginTop: 10,
        marginBottom: 5,
        padding: 15,
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "rgba(0,0,0,0.24)",
        borderRadius: 5,
        flex: 1,
      },
      btnContainer: {
        flex: 1,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 40,
        flexDirection: "row",
        marginRight: 15,
      },
      btn: {
        height: 50,
        paddingLeft: 40,
        paddingRight: 40,
      },
      label: {
        flex: 1,
        // marginLeft: 15,
        marginRight: 15,
      },
      zipInput: {
        height: 19.2,
      },
      heading: {
        color: "#2A234E",
        fontSize: 32,
        fontWeight: "bold",
        marginBottom: 25,
      },
      title: {
        fontFamily: "'Inter', sans-serif",
        fontSize: 32,
        fontWeight: 600,
        color: grey800,
      },
    };
    let amountPrefix = "";
    let paymentStopText = "";
    let paymentDateText = "";
    if (paymentType === paymentTypes.SUBSCRIPTION) {
      if (paymentInterval === paymentFrequencies.MONTHLY) {
        amountPrefix = t("mo-0");
        if (paymentNumIntervals) {
          paymentStopText = t(
            "thePaymentWillAutomaticallyStopAfterPaymentnumintervalsMonths",
            { paymentNumIntervals }
          );
        } else {
          paymentStopText = "";
        }
      } else if (paymentInterval === paymentFrequencies.YEARLY) {
        amountPrefix = t("yr");
        if (paymentNumIntervals) {
          paymentStopText = t(
            "thePaymentWillAutomaticallyStopAfterPaymentnumintervalsYears",
            { paymentNumIntervals }
          );
        } else {
          paymentStopText = "";
        }
      } else if (paymentInterval === paymentFrequencies.THREE_MONTHLY) {
        amountPrefix = t("3mo");
        if (paymentNumIntervals) {
          paymentStopText = t(
            "thePaymentWillAutomaticallyStopAfterPaymentnumintervalsMonths",
            { paymentNumIntervals: paymentNumIntervals * 3 }
          );
        } else {
          paymentStopText = "";
        }
      } else if (paymentInterval === paymentFrequencies.SIX_MONTHLY) {
        amountPrefix = t("6mo");
        if (paymentNumIntervals) {
          paymentStopText = t(
            "thePaymentWillAutomaticallyStopAfterPaymentnumintervalsMonths",
            { paymentNumIntervals: paymentNumIntervals * 6 }
          );
        } else {
          paymentStopText = "";
        }
      } else {
        amountPrefix = t("week-0");
        if (paymentNumIntervals) {
          paymentStopText = t(
            "thePaymentWillAutomaticallyStopAfterPaymentnumintervalsWeeks",
            { paymentNumIntervals }
          );
        } else {
          paymentStopText = "";
        }
      }
      if (paymentStartDate) {
        if (moment(paymentStartDate).year() === 1970) {
          let index = trialData.findIndex(
            (trial) => trial.value === paymentStartDate.toString()
          );
          let paymentDateLabel = trialData[index].label;
          paymentDateText = t(
            "the_1stPaymentWillBeChargedAfterPaymentdatelabel",
            { paymentDateLabel }
          );
        } else {
          let paymentChargeDate = moment(paymentStartDate).format("MMMM D");
          paymentDateText = t(
            "the_1stPaymentWillBeChargedOnPaymentchargedate",
            { paymentChargeDate }
          );
        }
      } else {
        paymentDateText = t("the_1stPaymentWillBeChargedOnSignup");
      }
    }
    let amountLabel = `${currencySymbol}${convertNumberToLocaleString(amount)}${amountPrefix}`;
    let btnText = t("payAmountLabel", { amountLabel });
    // let btnText = `PAY ${amountLabel}`;
    if (this.props.isLoading) {
      btnText = t("processing");
    }
    return (
      <div className="container-responsive-cs" style={styles.container}>
        <Paper
          style={styles.paperContainer}
          elevation={8}
          className="paper-responsive-cs"
        >
          <Typography style={styles.heading} variant="h1">
            <span style={styles.title}>{t("payment")}</span>
          </Typography>
          <form onSubmit={this.handleSubmit.bind(this)}>
            <div
              style={{
                display: "flex",
                marginTop: 20,
              }}
            >
              <label style={styles.label}>
                {t("cardNumber")}
                <div style={styles.cardContainer}>
                  <CardNumberElement
                    {...createOptions()}
                    onChange={this.handleChange}
                  />
                </div>
              </label>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: 20,
              }}
              className="mobile-fields"
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                }}
              >
                <label style={styles.label}>
                  {t("expirationDate")}
                  <div style={styles.cardContainer}>
                    <CardExpiryElement
                      {...createOptions()}
                      onChange={this.handleChange}
                    />
                  </div>
                </label>
                <label style={styles.label}>
                  {t("cvc")}
                  <div style={styles.cardContainer}>
                    <CardCVCElement
                      {...createOptions()}
                      onChange={this.handleChange}
                    />
                  </div>
                </label>
              </div>
              <div className="mobile-postal-code">
                <label style={styles.label}>
                  {t("postalCode")}
                  <div style={styles.cardContainer}>
                    <input
                      name="name"
                      type="text"
                      style={styles.zipInput}
                      placeholder="94115"
                      className="stripe-zip-input"
                      required
                      maxLength="7"
                      onChange={this.handleZipChange}
                    />
                  </div>
                </label>
              </div>
            </div>

            <div
              className="error"
              role="alert"
              style={{
                color: "red",
                marginLeft: 0,
                marginRight: 15,
                marginTop: 15,
              }}
            >
              {this.state.errorMessage}
            </div>
            {paymentType === paymentTypes.SUBSCRIPTION && (
              <div
                style={{
                  color: "#aab7c4",
                  fontSize: 16,
                  marginTop: 5,
                  fontFamily: "sans-serif",
                  fontWeight: 400,
                }}
              >
                <div>
                  {t("note")}
                  {paymentStopText} {paymentDateText}
                </div>
              </div>
            )}
            <div style={styles.btnContainer}>
              <Button onClick={this.goBack} color="primary">
                {t("backToSignup")}
              </Button>
              <Button
                color="primary"
                style={styles.btn}
                variant="contained"
                disabled={this.props.isLoading}
                onClick={this.handleSubmit.bind(this)}
              >
                {btnText}
              </Button>
            </div>
          </form>
        </Paper>
      </div>
    );
  }
}
const CardForm = injectStripe(_CardForm);
class Checkout extends Component {
  constructor(props) {
    super(props);
    this.didSetInitialState = false;
    this.ticker = this.props.router.location?.state?.params?.ticker;
    this.prevClientSignupRequestCompletedAt = new Date();
    this.isExistingClient = this.props.router.location.query.existing === "1";
    this.params = this.props.router.location?.state?.params
      ? this.props.router.location?.state?.params
      : {};
  }
  state = {
    isLoading: false,
  };
  componentDidMount = () => {
    console.log("ticker is", this.ticker);
    let form = this.props.signupForms[this.ticker];
    if (!form) {
      this.props.goBack();
    }
  };
  componentWillReceiveProps = (nextProps) => {
    if (
      nextProps.requests.lastClientSignupRequest &&
      !nextProps.requests.lastClientSignupRequest.inProcess &&
      this.prevClientSignupRequestCompletedAt <
        nextProps.requests.lastClientSignupRequest.completedAt
    ) {
      this.prevClientSignupRequestCompletedAt =
        nextProps.requests.lastClientSignupRequest.completedAt;
      if (nextProps.requests.lastClientSignupRequest.success) {
        this.setState({
          isLoading: false,
        });
        this.props.sayThanks(this.ticker, this.isExistingClient);
      }
    }
    if (
      nextProps.requests.lastClientSignupRequest &&
      !nextProps.requests.lastClientSignupRequest.inProcess &&
      !nextProps.requests.lastClientSignupRequest.success
    ) {
      let error = nextProps.requests.lastClientSignupRequest.error;
      if (error.error_code) {
        if (error.error_code === errorCodes.STRIPE_TRANSACTION_FAILED) {
          toastr.error(
            t("transactionFailed"),
            t(
              "unableToChargeThisCreditCardThisCouldBeDueToIncorrectCardDetailsOrInsufficientFunds"
            )
          );
        } else if (error.error_code === errorCodes.STRIPE_CONNECT_ERROR) {
          toastr.error(
            t("transactionFailed"),
            t("unableToConnectToStripePleaseTryAgain")
          );
        }
      }
      this.setState({
        isLoading: false,
      });
    }
  };
  handleResult = (result) => {
    this.setState({
      isLoading: true,
    });
    if (result.token) {
      const params = {
        ...this.params,
        token: result.token.id,
      };
      this.props.signup(params);
    }
  };
  handleSubmit = () => {
    this.setState({
      isLoading: true,
    });
  };
  handleError = () => {
    this.setState({
      isLoading: false,
    });
  };
  render() {
    const { signupForms } = this.props;
    const form = signupForms[this.ticker];
    if (!form) {
      return <div />;
    }
    const {
      payment_amount,
      payment_currency_code,
      payment_type,
      payment_interval,
      payment_num_intervals,
      payment_start_date,
    } = form || {};
    const currencySymbol = getSymbolFromCurrency(payment_currency_code);
    return (
      <Elements>
        <CardForm
          amount={payment_amount}
          handleResult={this.handleResult}
          handleSubmit={this.handleSubmit}
          handleError={this.handleError}
          currencySymbol={currencySymbol}
          paymentType={payment_type}
          paymentInterval={payment_interval}
          paymentNumIntervals={payment_num_intervals}
          paymentStartDate={payment_start_date}
          goBack={this.props.goBack}
          isLoading={this.state.isLoading}
        />
      </Elements>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    router: state.router,
    signupForms: state.signupForms,
    requests: state.requests,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    sayThanks: (ticker, isExistingClient) => {
      let existing = isExistingClient ? 1 : 0;
      dispatch(push(`/signup/${ticker}/thankyou?existing=${existing}`));
    },
    signup: (params) => {
      dispatch({
        type: CREATE_CLIENT_SIGNUP.REQUEST,
        payload: params,
      });
    },
    goBack: () => {
      dispatch(goBack());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
