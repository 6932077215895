import { t } from "../../../services/utils";
import React from "react";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "../../../components/TextField";
import IconButton from "@material-ui/core/IconButton";
import { Close } from "@material-ui/icons";
import { primaryDark5 } from "../../../constants/colors";
import { Divider } from "@material-ui/core";
class ClientNoteEditView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      note: this.props.note == null ? "" : this.props.note
    };
  }
  componentDidMount = () => {
    this.noteField.focus();
  };
  onCancel = e => {
    this.props.onCancelEdit();
    e.stopPropagation();
  };
  onSave = e => {
    e.stopPropagation();
    let outNote = this.state.note.trim() === "" ? null : this.state.note.trim();
    this.props.onSave(outNote);
  };
  onClick = e => {
    this.props.onCancelEdit();
  };
  stopClick = e => {
    e.stopPropagation();
  };
  onNoteEdit = e => {
    this.setState({
      note: e.target.value
    });
  };
  render = () => {
    let styles = {
      container: {
        display: "flex",
        flexDirection: "column"
      },
      inputWrapper: {
        // padding: 20,
      },
      input: {
        padding: 0,
        width: "100%"
      },
      actionBar: {
        display: "flex",
        backgroundColor: primaryDark5,
        padding: "16px 20px",
        alignItems: "center",
        justifyContent: "flex-end"
      },
      actionButton: {
        marginLeft: 8,
        height: 36
      },
      title: {
        fontFamily: "'Inter', sans-serif",
        fontSize: 22,
        fontWeight: "bold"
      },
      modalTitle: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
      },
      field: {
        width: "100%",
        marginTop: 20
      },
      divider: {
        marginLeft: -20,
        marginRight: -20
      }
    };
    return <div style={styles.container} onClick={this.onClick}>
        <Paper elevation={1} style={styles.inputWrapper} onClick={this.stopClick}>
          <div style={{
          padding: "6px 20px 20px 20px"
        }}>
            <Typography style={styles.modalTitle} variant="headline">
              <span style={styles.title}>{t("editNote")}</span>
              <IconButton style={{
              marginRight: "-14px"
            }} onClick={this.onCancel}>
                <Close />
              </IconButton>
            </Typography>
            <Divider style={styles.divider} />

            <TextField variant="outlined" inputRef={noteField => {
            this.noteField = noteField;
          }} style={styles.field} value={this.state.note} onChange={this.onNoteEdit} helperText={this.state.showErrors ? this.state.nameError : null} error={this.state.nameError ? true : false} multiline rowsMax="4" rows="1" InputProps={{
            style: {
              padding: "10px 20px"
            }
          }} inputProps={{
            style: {
              padding: 0
            }
          }} />
          </div>
          <Divider />
          <div style={styles.actionBar}>
            <Button variant="contained" color="secondary" style={styles.actionButton} onClick={this.onCancel}>{t("cancel")}</Button>
            <Button variant="contained" color="primary" style={styles.actionButton} onClick={this.onSave}>{t("save")}</Button>
          </div>
        </Paper>
      </div>;
  };
}
export const getNoteEditViewHeight = function () {
  return 200;
};
export default ClientNoteEditView;