import { t } from "../../services/utils";
import { UPDATE_SEE_ALL_CLIENTS_DEFAULT } from '../../constants/action-types';
export function defaults(state = {}, action) {
  switch (action.type) {
    case UPDATE_SEE_ALL_CLIENTS_DEFAULT:
      {
        return {
          ...state,
          seeAllClients: action.payload.seeAllClients
        };
      }
    default:
      return state;
  }
}