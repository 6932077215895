import { t } from "../../services/utils";
import React from "react";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
// import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import { HotKeys } from "react-hotkeys";
import { GET_STRIPE_URL } from "../../constants/action-types";
import TextField from "../../components/TextField";
import { primaryDark80 } from "../../constants/colors";
class StripeSubscription extends React.Component {
  state = {
    email: "",
    password: "",
    showErrors: false,
    emailError: null,
    passwordError: null,
  };
  componentDidMount = () => {
    this.emailField.focus();
  };
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.accessToken.error_code) {
      this.state = {
        ...this.state,
        password: "",
      };
      if (this.state.email.length === 0) {
        this.emailField.focus();
      } else if (this.state.password.length === 0) {
        this.passwordField.focus();
      }
    }
  };

  // componentDidUpdate = () => {
  //   if (this.props.accessToken.error_code) {
  //     if (this.state.email.length === 0) {
  //       this.emailField.focus();
  //     } else if (this.state.password.length === 0) {
  //       this.passwordField.focus();
  //     }
  //   }
  // };

  onEmailChange = (e) => {
    this.setState({
      email: e.target.value,
    });
  };
  onPasswordChange = (e) => {
    this.setState({
      password: e.target.value,
    });
  };
  onLogin = (e) => {
    e.preventDefault();
    this.setState({
      emailError: "",
      passwordError: "",
    });
    let hasError = false;
    const { email, password } = this.state;
    if (email.trim().length === 0) {
      hasError = true;
      this.setState({
        showErrors: true,
        emailError: t("thisFieldIsRequired"),
      });
    }
    if (password.trim().length === 0) {
      hasError = true;
      this.setState({
        showErrors: true,
        passwordError: t("thisFieldIsRequired"),
      });
    }
    if (hasError) {
      return;
    }
    this.setState({
      showErrors: false,
    });
    this.props.getStripeUrl(this.state.email, this.state.password);
  };
  onEnter = (e) => {
    this.onLogin(e);
    // this.props.onLogin(this.state.email, this.state.password);
  };

  render() {
    const styles = {
      container: {
        minWidth: 320,
        maxWidth: 1440,
        height: "100%",
        position: "absolute",
        left: 0,
        right: 0,
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        padding: "25px 100px",
        paddingRight: "7%",
        paddingLeft: "7%",
      },
      paper: {
        width: "36%",
        minWidth: 320,
        maxWidth: 442,
        padding: 50,
        marginLeft: 10,
        marginRight: 10,
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
      },
      logo: {
        alignSelf: "center",
        marginBottom: 24,
      },
      fieldContainer: {
        display: "flex",
        flexDirection: "column",
      },
      toolbar: {
        display: "flex",
        marginTop: 16,
        flexDirection: "row-reverse",
        alignItems: "center",
      },
      loginBtn: {
        marginLeft: "auto",
      },
      linksContainer: {
        display: "flex",
        flexDirection: "column",
      },
      link: {
        fontSize: "14px",
        cursor: "pointer",
        color: "#7B62E1",
      },
      field: {
        marginBottom: 20,
      },
      appTitle: {
        fontSize: 32,
        fontWeight: "bold",
        margin: "auto",
        marginBottom: 30,
      },
      form: {
        // marginTop: "12%",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      },
      title: {
        // fontFamily: "'Montserrat', sans-serif",
        fontSize: "2.5vw",
        fontWeight: "bold",
        color: primaryDark80,
        marginBottom: 35,
      },
    };
    return (
      <div style={styles.container}>
        <div style={styles.form}>
          <Paper style={styles.paper} elevation={5}>
            <div style={styles.title} className="signup-title-responsive">
              {t("manageYourSubscription")}
            </div>
            <HotKeys
              style={styles.fieldContainer}
              handlers={{
                return: this.onEnter,
              }}
            >
              <TextField
                className="login-email"
                label="E-mail"
                fullWidth={true}
                onChange={this.onEmailChange}
                value={this.state.email}
                inputRef={(emailField) => {
                  this.emailField = emailField;
                }}
                helperText={
                  this.state.showErrors
                    ? this.state.emailError
                    : t("enterEmailThatYouUseToLoginToYourTrainingApp")
                }
                error={this.state.emailError ? true : false}
                style={styles.field}
              />
            </HotKeys>

            <HotKeys
              style={styles.fieldContainer}
              handlers={{
                return: this.onEnter,
              }}
            >
              <TextField
                className="login-password"
                label={t("password")}
                fullWidth={true}
                type="password"
                onChange={this.onPasswordChange}
                value={this.state.password}
                inputRef={(passwordField) => {
                  this.passwordField = passwordField;
                }}
                helperText={
                  this.state.showErrors
                    ? this.state.passwordError
                    : t("enterPasswordThatYouUseToLoginToYourTrainingApp")
                }
                error={this.state.passwordError ? true : false}
                inputProps={{
                  className: "modal-input",
                  style: {
                    paddingLeft: 20,
                    fontSize: 14,
                    lineHeight: "20px",
                    paddingTop: 9,
                    paddingBottom: 9,
                  },
                }}
                style={styles.field}
              />
            </HotKeys>

            <div style={styles.toolbar}>
              <Button
                className="login-btn"
                variant="contained"
                color="primary"
                style={styles.loginBtn}
                onClick={this.onLogin}
              >
                {t("manageSubscription")}
              </Button>
            </div>
          </Paper>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    accessToken: state.accessToken,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getStripeUrl: (email, password) => {
      dispatch({
        type: GET_STRIPE_URL.REQUEST,
        payload: {
          email,
          password,
        },
      });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(StripeSubscription);
