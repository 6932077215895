import { t } from "../../services/utils";
import { GET_ALL_SIGNUP_FORMS, GET_SIGNUP_FORM, CREATE_SIGNUP_FORM, UPDATE_SIGNUP_FORM, DELETE_SIGNUP_FORM, PRE_CLIENT_SIGNUP_CHECK, CONNECT_STRIPE_ACCOUNT } from "../../constants/action-types";
export function signupForms(state = {}, action) {
  switch (action.type) {
    case GET_ALL_SIGNUP_FORMS.SUCCESS:
      {
        let forms = {};
        for (let i = 0; i < action.payload.forms.length; i++) {
          forms[action.payload.forms[i].ticker] = action.payload.forms[i];
        }
        return forms;
      }
    case GET_SIGNUP_FORM.SUCCESS:
      {
        return {
          ...state,
          [action.payload.ticker]: action.payload
        };
      }
    case CREATE_SIGNUP_FORM.SUCCESS:
    case UPDATE_SIGNUP_FORM.SUCCESS:
      {
        return {
          ...state,
          [action.payload.ticker]: {
            ...action.payload,
            lastUpdated: new Date().getTime()
          }
        };
      }
    case DELETE_SIGNUP_FORM.REQUEST:
      {
        let forms = {
          ...state
        };
        delete forms[action.payload.ticker];
        return forms;
      }
    default:
      return state;
  }
}
export function signupFormOrderedList(state = null, action) {
  switch (action.type) {
    case GET_ALL_SIGNUP_FORMS.SUCCESS:
      {
        let ls = [];
        for (let i = 0; i < action.payload.forms.length; i++) {
          ls.push(action.payload.forms[i].ticker);
        }
        return ls;
      }
    case CREATE_SIGNUP_FORM.SUCCESS:
      {
        if (state === null) {
          return state;
        }
        return [...state, action.payload.ticker];
      }
    case DELETE_SIGNUP_FORM.REQUEST:
      {
        if (state === null) {
          return state;
        }
        return state.filter(id => id !== action.payload.ticker);
      }
    default:
      return state;
  }
}
export const connectedToStripe = (state = false, action) => {
  switch (action.type) {
    case GET_ALL_SIGNUP_FORMS.SUCCESS:
      {
        return action.payload.connected_to_stripe;
      }
    case CONNECT_STRIPE_ACCOUNT.SUCCESS:
      {
        return true;
      }
    default:
      return state;
  }
};
export const preClientSignupCheck = (state = null, action) => {
  switch (action.type) {
    case PRE_CLIENT_SIGNUP_CHECK.REQUEST:
      {
        return null;
      }
    case PRE_CLIENT_SIGNUP_CHECK.SUCCESS:
      {
        return action.payload;
      }
    default:
      return state;
  }
};