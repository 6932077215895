import { t } from "../services/utils";
const triggerTypes = {
  DAYS_AFTER_CREATION: 1,
  DAYS_AFTER_JOINED_GROUP: 2,
  AFTER_COMPLETING_NTH_WORKOUT: 3,
  AFTER_NOT_LOGGING_WORKOUT_IN_PAST_N_DAYS: 4,
  ON_DATE: 5,
  ON_EVERY_WEEKDAYS_FOR_N_WEEKS: 6,
  WEEK: 7
};
export default triggerTypes;