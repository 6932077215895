import { t } from "../../../services/utils";
import React, { Component } from "react";
import { showLoading, resetLoading } from "react-redux-loading-bar";
import { goBack } from "connected-react-router";
import { toastr } from "react-redux-toastr";
import uploadcare from "uploadcare-widget";
import { connect } from "react-redux";
import Select from "react-select";
import moment from "moment";
import {
  Editor,
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromRaw,
  CompositeDecorator,
} from "draft-js";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Typography from "@material-ui/core/Typography";
import RadioButton from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import LoadingOverlay from "../../../components/LoadingOverlay";
import { getModalStyle } from "../../../services/modal-util";
import triggerTypes from "../../../constants/trigger-types";
import { updateAppTitle } from "../../../data/app/actions";
import FileChip from "../../../components/FileChip";
import {
  GET_GROUPS,
  ADD_CAMPAIGN,
  ADD_TRIGGERED_MESSAGE,
  UPDATE_TRIGGERED_MESSAGE,
  GET_TRIGGERED_MESSAGES,
  GET_EMAIL_INTEGRATION,
  DELETE_EMAIL_INTEGRATION,
} from "../../../constants/action-types";
import tmUtils from "./tm-utils";
import styles from "./styles";
import { Link } from "./components";
import { isMobile, isTablet } from "react-device-detect";
import EditorComponent from "../../../components/Editor";
import GmailLogin from "../../../components/GmailLogin";
import ClientsAndGroupsFilter from "./ClientsAndGroupsFilter";
import GroupOptions from "./GroupOptions";
import ClientOptions from "./ClientOptions";
import CMNav from "../../../components/CMNav";
const DELIVERY_CHANNEL = {
  CHAT: 1,
  EMAIL: 2,
};
class AddOrEditTM extends Component {
  constructor(props) {
    super(props);
    const decorator = new CompositeDecorator([
      {
        strategy: tmUtils.findLinkEntities,
        component: Link,
      },
    ]);
    const { messageId, triggeredMessages } = props;
    const showWarning =
      this.props.router.location.state &&
      this.props.router.location.state.showWarning;
    let triggeredMessage = null;
    if (messageId && !_.isEmpty(triggeredMessages)) {
      triggeredMessage = triggeredMessages[messageId];
    }
    let editorState;
    if (triggeredMessage) {
      editorState = EditorState.createWithContent(
        convertFromRaw(JSON.parse(triggeredMessage.message)),
        decorator
      );
    } else {
      editorState = EditorState.createEmpty(decorator);
    }
    this.state = {
      [triggerTypes.DAYS_AFTER_CREATION]:
        triggeredMessage &&
        triggeredMessage.trigger_type === triggerTypes.DAYS_AFTER_CREATION
          ? triggeredMessage.data_1
          : 1,
      [triggerTypes.DAYS_AFTER_JOINED_GROUP]:
        triggeredMessage &&
        triggeredMessage.trigger_type === triggerTypes.DAYS_AFTER_JOINED_GROUP
          ? triggeredMessage.data_1
          : 1,
      [triggerTypes.AFTER_COMPLETING_NTH_WORKOUT]:
        triggeredMessage &&
        triggeredMessage.trigger_type ===
          triggerTypes.AFTER_COMPLETING_NTH_WORKOUT
          ? triggeredMessage.data_1
          : 1,
      [triggerTypes.AFTER_NOT_LOGGING_WORKOUT_IN_PAST_N_DAYS]:
        triggeredMessage &&
        triggeredMessage.trigger_type ===
          triggerTypes.AFTER_NOT_LOGGING_WORKOUT_IN_PAST_N_DAYS
          ? triggeredMessage.data_1
          : 7,
      [triggerTypes.ON_EVERY_WEEKDAYS_FOR_N_WEEKS]:
        triggeredMessage &&
        triggeredMessage.trigger_type ===
          triggerTypes.ON_EVERY_WEEKDAYS_FOR_N_WEEKS
          ? triggeredMessage.data_1
          : 0,
      [triggerTypes.WEEK]:
        triggeredMessage &&
        triggeredMessage.trigger_type ===
          triggerTypes.ON_EVERY_WEEKDAYS_FOR_N_WEEKS
          ? triggeredMessage.data_2
          : 1,
      [triggerTypes.ON_DATE]:
        triggeredMessage &&
        triggeredMessage.trigger_type === triggerTypes.ON_DATE
          ? triggeredMessage.data_1
          : new Date(),
      showTriggerModal: false,
      showAddCampaignModal: false,
      isEditMode: messageId !== undefined,
      id: messageId,
      message: triggeredMessage ? triggeredMessage.message : "",
      segment_id: triggeredMessage
        ? triggeredMessage.segment_id
        : this.props.groupId,
      for_everyone:
        this.props.groupId == 0
          ? true
          : triggeredMessage
          ? triggeredMessage.for_everyone
          : false,
      wait: triggeredMessage ? triggeredMessage.wait : 30,
      delivery_window: triggeredMessage
        ? triggeredMessage.delivery_window || null
        : null,
      // Default campaign id as default campaign
      new_campaign_name: "",
      trigger_type: triggeredMessage ? triggeredMessage.trigger_type : null,
      data_1: triggeredMessage ? triggeredMessage.data_1 : null,
      week_data: 1,
      uploadedFiles:
        triggeredMessage &&
        triggeredMessage.files &&
        triggeredMessage.files.length > 0
          ? triggeredMessage.files
          : [],
      selectedDays: this.getSelectedDays(triggeredMessage),
      dateDisabled: false,
      isCheckinMessage: triggeredMessage ? triggeredMessage.for_checkin : false,
      checkinDescriptionModal: false,
      showTimeModal: false,
      editMode: false,
      editorState: editorState,
      isUploading: false,
      anchorEl: null,
      waitTime:
        triggeredMessage && triggeredMessage.wait ? triggeredMessage.wait : 30,
      deliveryTime:
        triggeredMessage && triggeredMessage.delivery_window
          ? triggeredMessage.delivery_window
          : 18,
      waitType: this.getWaitType(triggeredMessage),
      showURLInput: false,
      urlValue: "",
      showWarning,
      email_subject_line:
        triggeredMessage && triggeredMessage.email_subject_line
          ? triggeredMessage.email_subject_line
          : "",
      delivery_channel:
        triggeredMessage && triggeredMessage.delivery_channel
          ? triggeredMessage.delivery_channel
          : DELIVERY_CHANNEL.CHAT,
      filter: props.filter,
    };
    this.choices = {
      [triggerTypes.DAYS_AFTER_CREATION]: _.range(0, 121).map((i) => {
        let label;
        if (i === 0) {
          label = t("sameDay");
        } else if (i === 1) {
          label = "1 day";
        } else {
          label = t("iDays", { i });
        }
        return {
          value: i,
          label,
        };
      }),
      [triggerTypes.DAYS_AFTER_JOINED_GROUP]: _.range(0, 121).map((i) => {
        let label;
        if (i === 0) {
          label = t("sameDay");
        } else if (i === 1) {
          label = "1 day";
        } else {
          label = t("iDays", { i });
        }
        return {
          value: i,
          label,
        };
      }),
      [triggerTypes.AFTER_COMPLETING_NTH_WORKOUT]: _.range(0, 13).map((i) => {
        return {
          value: i == 0 ? 1 : i * 25,
          label: i == 0 ? "1st workout" : i * 25 + "th workout",
        };
      }),
      [triggerTypes.AFTER_NOT_LOGGING_WORKOUT_IN_PAST_N_DAYS]: _.range(
        3,
        11
      ).map((i) => {
        return {
          value: i,
          label: i + " days",
        };
      }),
      [triggerTypes.WEEK]: _.range(1, 52).map((i) => {
        return {
          value: i,
          label: i + " week",
        };
      }),
    };
    this.disableClickOnRow = false;
  }
  componentDidMount() {
    const {
      updateAppTitle,
      groups,
      getGroups,
      messageId,
      triggeredMessages,
      getTriggeredMessages,
      emailIntegration,
    } = this.props;

    // if (_.isEmpty(groups)) {
    //   getGroups();
    // }

    if (messageId && _.isEmpty(triggeredMessages)) {
      getTriggeredMessages();
    }
    this.props.getEmailIntegration();
    updateAppTitle(
      messageId ? t("editScheduledMessage") : t("scheduleNewMessage")
    );
  }
  componentWillReceiveProps(nextProps) {
    const oldRequest = this.props.requests;
    const newRequest = nextProps.requests;
    const showWarning = this.props.router.location?.state?.showWarning;
    const decorator = new CompositeDecorator([
      {
        strategy: tmUtils.findLinkEntities,
        component: Link,
      },
    ]);
    if (
      oldRequest.addCampaignRequest &&
      oldRequest.addCampaignRequest.inProcess &&
      !newRequest.addCampaignRequest.inProcess
    ) {
      this.setState({
        new_campaign_name: "",
        showAddCampaignModal: false,
        campaign_id: newRequest.addCampaignRequest.campaign_id,
      });
    }
    if (
      oldRequest.addOrUpdateTMRequest &&
      oldRequest.addOrUpdateTMRequest.inProcess &&
      !newRequest.addOrUpdateTMRequest.inProcess &&
      !newRequest.addOrUpdateTMRequest.failed
    ) {
      if (this.state.showWarning) {
        toastr.info(
          t("info"),
          t("thisMessageIsGettingRescheduledAndCanTakeUpTo_30Mins")
        );
      }
      if (isMobile && !isTablet) {
        window.postMessage("goBack");
      } else {
        this.props.goBack();
      }
    }
    if (
      nextProps.messageId &&
      _.isEmpty(this.props.triggeredMessages) &&
      !_.isEmpty(nextProps.triggeredMessages)
    ) {
      const triggeredMessage = nextProps.triggeredMessages[nextProps.messageId];
      this.setState({
        id: triggeredMessage.id,
        wait: triggeredMessage.wait,
        data_1: triggeredMessage.data_1,
        message: triggeredMessage.message,
        segment_id: triggeredMessage.segment_id,
        campaign_id: triggeredMessage.campaign_id,
        trigger_type: triggeredMessage.trigger_type,
        for_everyone: triggeredMessage.for_everyone,
        delivery_window: triggeredMessage.delivery_window || null,
        uploadedFiles:
          triggeredMessage &&
          triggeredMessage.files &&
          triggeredMessage.files.length > 0
            ? triggeredMessage.files
            : [],
        [triggerTypes.ON_DATE]:
          triggeredMessage.trigger_type === triggerTypes.ON_DATE
            ? triggeredMessage.data_1
            : new Date(),
        selectedDays: this.getSelectedDays(triggeredMessage),
        waitTime: triggeredMessage.wait || 30,
        deliveryTime: triggeredMessage.delivery_window || 18,
        waitType: this.getWaitType(triggeredMessage),
        isCheckinMessage: triggeredMessage.for_checkin,
        delivery_channel: triggeredMessage.delivery_channel,
        email_subject_line: triggeredMessage.email_subject_line,
        showWarning,
      });
    }
    const { messageId, triggeredMessages } = nextProps;
    let triggeredMessage = null;
    if (messageId && !_.isEmpty(triggeredMessages)) {
      triggeredMessage = triggeredMessages[messageId];
    }
    let editorState;
    if (triggeredMessage) {
      editorState = EditorState.createWithContent(
        convertFromRaw(JSON.parse(triggeredMessage.message)),
        decorator
      );
      this.setState({
        editorState,
      });
    } else {
      let { editorState } = this.state;
      let contentState = editorState.getCurrentContent();
      if (!contentState.hasText()) {
        editorState = EditorState.createEmpty(decorator);
      }
      this.setState({
        editorState,
      });
    }
  }
  getSelectedDays = (triggeredMessage) => {
    let tempArr = [];
    if (
      triggeredMessage &&
      triggeredMessage.trigger_type ===
        triggerTypes.ON_EVERY_WEEKDAYS_FOR_N_WEEKS
    ) {
      const selectedDays = tmUtils.getDaysSelected(triggeredMessage.data_1);
      const weekdaysList = tmUtils.getWeekDaysList();
      weekdaysList.map((day, index) => {
        if (selectedDays.indexOf(day.label) >= 0) {
          tempArr.push(weekdaysList[index].value);
        }
      });
    }
    this.setState({
      [triggerTypes.ON_EVERY_WEEKDAYS_FOR_N_WEEKS]: tempArr,
    });
    return tempArr;
  };
  getWaitType = (triggeredMessage) => {
    if (triggeredMessage && triggeredMessage.wait) {
      return "wait";
    }
    if (triggeredMessage && triggeredMessage.delivery_window) {
      return "time";
    }
    if (triggeredMessage && triggeredMessage.wait === 0) {
      return "asap";
    }
    return "time";
  };
  onChange = (editorState) => {
    // alert("called");
    this.setState({
      editorState,
    });
  };
  onEmailSubjectChange = (e) => {
    this.setState({
      email_subject_line: e.target.value,
    });
  };
  onSelectTrigger = (trigger_type, trigger_data) => () => {
    // if (!this.disableClickOnRow) {

    const { messageId, triggeredMessages } = this.props;
    let triggeredMessage = triggeredMessages[messageId];
    if (!this.state.isEditMode) {
      this.setState(
        {
          trigger_type,
          data_1: trigger_data,
          showTriggerModal: false,
        },
        () => {
          this.onSelectDelay(null, 18, "time");
          this.setState({
            deliveryTime: 18,
            waitTime: 30,
          });
        }
      );
    } else if (trigger_type !== triggeredMessage.trigger_type) {
      toastr.error(
        t("error-0"),
        t("cantChangeTriggerTypeWhenUpdatingAScheduledMessage")
      );
    } else if (trigger_type === triggeredMessage.trigger_type) {
      this.setState({
        trigger_type,
        data_1: trigger_data,
        showTriggerModal: false,
      });
    }

    // }
  };

  onSelectDelay = (wait, delivery_window, waitType) => {
    const { trigger_type, data_1 } = this.state;
    if (trigger_type === null || data_1 === null) {
      toastr.error(
        t("error-0"),
        t(
          "pleaseSelectWhenTheyWillReceiveThisMessageBeforeSelectingDelayOptions"
        )
      );
      return;
    }
    this.setState({
      wait,
      delivery_window,
      waitType,
    });
  };
  onSelectWeek = (week) => {
    this.setState({
      [triggerTypes.WEEK]: week,
    });
  };
  onCheckinMessageChange = (isCheckinMessage) => {
    this.setState({
      isCheckinMessage,
    });
  };
  disableClickOnTriggerRow = () => {
    this.disableClickOnRow = true;
    this.setState({
      dateDisabled: true,
    });
  };
  enableClickOnTriggerRow = () => {
    setTimeout(() => {
      this.disableClickOnRow = false;
      this.setState({
        dateDisabled: false,
      });
    }, 300);
  };
  onAddorEdit = () => {
    let hasError = false;
    const {
      id,
      wait,
      isEditMode,
      segment_id,
      trigger_type,
      for_everyone,
      delivery_window,
      waitType,
      delivery_channel,
      email_subject_line,
      filter,
    } = this.state;
    let { data_1 } = this.state;
    const plainText = this.state.editorState.getCurrentContent().getPlainText();
    const pauseMessage = this.props.router.location?.state?.pauseMessage;
    let group_id, user_id, everyone;
    everyone = filter.forGroup ? (filter.id == 0 ? true : false) : false;
    if (plainText.trim().length === 0) {
      hasError = true;
      this.setState({
        showErrors: true,
        messageError: t("thisFieldIsRequired"),
      });
      toastr.error(t("error-0"), t("pleaseTypeMessage"));
    } else if (trigger_type === null || data_1 === null) {
      hasError = true;
      toastr.error(
        t("error-0"),
        t("pleaseSelectWhenTheyWillReceiveThisMessage")
      );
    } else if (trigger_type == 2 && everyone) {
      hasError = true;
      toastr.error(
        t("error-0"),
        t(
          "selectGroupsThatShouldReceiveThisMessageThisTypeOfMessageCantBeSentToEveryone"
        )
      );
    } else if (
      trigger_type == triggerTypes.ON_EVERY_WEEKDAYS_FOR_N_WEEKS &&
      (data_1.length == 0 || data_1 == 0)
    ) {
      hasError = true;
      toastr.error(
        t("error-0"),
        t("selectWeekdaysOnWhichUserShouldReceiveMessage")
      );
    } else if (waitType === "") {
      hasError = true;
      toastr.error(t("error-0"), t("pleaseSelectTime"));
    }
    if (hasError) {
      return;
    }
    const uploadedFilesUrl = [];
    this.state.uploadedFiles.map((file) => {
      uploadedFilesUrl.push({
        file_name: file.file_name,
        file_url: file.file_url,
      });
    });
    let weekVal = 0;
    if (
      this.state.trigger_type === triggerTypes.ON_EVERY_WEEKDAYS_FOR_N_WEEKS
    ) {
      // CHECKING IF SELECTED TRIGGER IS ON_EVERY_WEEKDAYS_FOR_N_WEEKS type
      if (Array.isArray(data_1)) {
        data_1.map((val) => {
          return (weekVal = weekVal | val);
        });
      }
    }
    if (this.state.trigger_type === triggerTypes.ON_DATE) {
      data_1 = moment(data_1).format("YYYY-MM-DD");
    }
    const editorContent = this.state.editorState.getCurrentContent();
    const rawState = convertToRaw(editorContent);
    const message = JSON.stringify(rawState);
    group_id = everyone
      ? null
      : isMobile && !isTablet
      ? this.props.router.location?.state?.groupId
      : filter.forGroup
      ? filter.id
      : null;
    user_id = filter.forClient ? filter.id : null;
    const data2 = this.state[triggerTypes.WEEK];
    const payload = {
      id,
      wait,
      data_1: weekVal ? weekVal : data_1,
      message,
      trigger_type,
      for_everyone: everyone,
      segment_id: group_id,
      user_id,
      delivery_window,
      for_checkin: this.state.isCheckinMessage,
      files: uploadedFilesUrl,
      data_2:
        this.state.trigger_type === triggerTypes.ON_EVERY_WEEKDAYS_FOR_N_WEEKS
          ? this.state[triggerTypes.WEEK]
          : 0,
      is_active: pauseMessage ? false : true,
      delivery_channel,
      email_subject_line,
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    if (isEditMode) {
      this.props.updateTriggeredMessage(payload);
    } else {
      this.props.addTriggeredMessage(payload);
    }
  };
  handleRequestDelete = (index) => {
    const tempArray = this.state.uploadedFiles;
    tempArray.splice(index, 1);
    this.setState({
      uploadedFiles: tempArray,
    });
  };
  fileUpload = () => {
    let dialog = uploadcare.openDialog(null, {
      publicKey: process.env.UPLOADCARE_PUBLIC_KEY,
    });
    dialog.done((result) => {
      result.progress((info) => {
        this.setState({
          isUploading: true,
        });
        this.props.showLoading();
      });
      result.fail((info) => {
        this.setState({
          isUploading: false,
        });
        this.props.resetLoading();
        toastr.error(
          t("serverError"),
          t(
            "pleaseTryAfterSometimeIfProblemPersistsContactUsAtSupportTrainerfuCom"
          )
        );
      });
      result.done((info) => {
        const tempArray = this.state.uploadedFiles;
        tempArray.push({
          file_name: info.name,
          file_url: info.cdnUrl,
          info,
        });
        this.props.resetLoading();
        this.setState({
          uploadedFiles: tempArray,
          isUploading: false,
        });
      });
    });
  };

  // DRAFTJS EDITOR FUNCTIONALITY
  focus = () => this.refs.editor.focus();
  onFilterChange = (filter) => {
    const { wait, delivery_window, waitType, isEditMode, data_1 } = this.state;
    this.setState({
      filter,
    });
    if (filter.forClient) {
      this.setState({
        trigger_type: triggerTypes.ON_DATE,
        data_1: isEditMode ? data_1 : new Date(),
      });
      this.setState({
        wait,
        delivery_window: 18,
        waitType: "time",
      });
    } else {
      this.setState({
        wait,
        delivery_window,
        waitType,
      });
    }
  };
  render() {
    const {
      anchorEl,
      trigger_type,
      data_1,
      email_subject_line,
      isCheckinMessage,
      delivery_channel,
      selectedDays,
      filter,
      isEditMode,
    } = this.state;
    const open = Boolean(anchorEl);
    const { messageId, triggeredMessages, emailIntegration } = this.props;
    let triggeredMessage = null;
    if (messageId && !_.isEmpty(triggeredMessages)) {
      triggeredMessage = triggeredMessages[messageId];
    }
    let en = [
      triggerTypes.DAYS_AFTER_CREATION,
      triggerTypes.DAYS_AFTER_JOINED_GROUP,
      triggerTypes.AFTER_COMPLETING_NTH_WORKOUT,
      triggerTypes.AFTER_NOT_LOGGING_WORKOUT_IN_PAST_N_DAYS,
      triggerTypes.WEEK,
    ];
    let initValues = en.map((e) => {
      let initIdx = _.findIndex(
        this.choices[e],
        (x) => x.value === this.state[e]
      );
      return this.choices[e][initIdx].value;
    });
    const afterJoiningStr =
      this.state.segment_id > 0 || !this.state.for_everyone
        ? t("afterJoiningTheGroupFor") + " "
        : t("afterJoiningFor");

    // RICH EDITOR STYLES
    const { editorState } = this.state;

    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let className = "RichEditor-editor";
    var contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== "unstyled") {
        className += " RichEditor-hidePlaceholder";
      }
    }

    // END RICH EDITOR STYLES

    let height = 300;
    let modalStyle = getModalStyle(375, height);
    let emailDisabled = !emailIntegration || isCheckinMessage;
    const clientId = this.props.router.location?.state?.clientId;
    return (
      <div className="flex-container" style={styles.scene}>
        {this.state.isUploading && <LoadingOverlay />}
        <div style={styles.container}>
          {/* {!isMobile && ( */}
          <div style={styles.toolbar}>
            <Typography style={styles.pageTitle} variant="headline">
              {/* <IconButton
                onClick={this.props.goBack}
                style={{
                  backgroundColor: "unset",
                  marginLeft: -19,
                }}
                disabled={
                  this.props.requests.addOrUpdateTMRequest &&
                  this.props.requests.addOrUpdateTMRequest.inProcess
                }
               >
                <ArrowBackIcon />
               </IconButton> */}
              {/* <span style={styles.title}>Messaging Center</span> */}
            </Typography>
            {this.state.showWarning && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  style={styles.imgIcon}
                  src={require("../../../img/icon-warning.png")}
                />
                <div style={styles.note}>
                  {t("changesToThisSmartMessageWillBeAppliedToEntireGroup")}
                </div>
              </div>
            )}
          </div>
          {/* )} */}
          <ExpansionPanel defaultExpanded style={styles.contentContainer}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon fontSize="large" />}
              style={
                isMobile
                  ? {
                      padding: "0px 15px",
                    }
                  : {}
              }
            >
              <Typography style={styles.sectionHeading} variant="h2">
                {t("typeYourMessage")}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={styles.column}>
              <EditorComponent
                editorState={this.state.editorState}
                onChange={this.onChange}
                fileUpload={this.fileUpload}
              />
              {this.state.uploadedFiles.length > 0 && (
                <div
                  style={{
                    marginTop: 10,
                  }}
                >
                  <Typography variant="subheading">
                    {t("attachments")}
                  </Typography>
                  <div style={styles.attachments}>
                    {this.state.uploadedFiles.map((fileInfo, index) => (
                      <FileChip
                        key={fileInfo.file_url}
                        name={fileInfo.file_name}
                        url={fileInfo.file_url}
                        info={fileInfo.info}
                        style={styles.fileChip}
                        handleRequestDelete={(e) => {
                          e.stopPropagation();
                          this.handleRequestDelete(index);
                        }}
                        deleteable
                      />
                    ))}
                  </div>
                </div>
              )}
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel style={styles.contentContainer}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon fontSize="large" />}
              style={
                isMobile
                  ? {
                      padding: "0px 15px",
                    }
                  : {}
              }
            >
              <Typography style={styles.sectionHeading} variant="h2">
                {t("whomToSendThisMessage")}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={styles.column}>
              <ClientsAndGroupsFilter
                onFilterChange={this.onFilterChange}
                filterGroups
                isEditMode={isEditMode}
                groupId={triggeredMessage?.segment_id || this.props.groupId}
                style={{
                  width: 250,
                }}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
          {filter?.forClient && (
            <ClientOptions
              triggeredMessage={triggeredMessage}
              messageId={messageId}
              onSelectDelay={this.onSelectDelay}
              onSelectTrigger={this.onSelectTrigger}
            />
          )}
          {filter?.forGroup && (
            <GroupOptions
              triggeredMessage={triggeredMessage}
              groupId={this.props.groupId}
              messageId={messageId}
              onSelectTrigger={this.onSelectTrigger}
              onSelectDelay={this.onSelectDelay}
              onSelectWeek={this.onSelectWeek}
              onCheckinMessageChange={this.onCheckinMessageChange}
              filter={filter}
            />
          )}

          {/* {!isMobile && ( */}
          <ExpansionPanel style={styles.contentContainer}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon fontSize="large" />}
              style={
                isMobile
                  ? {
                      padding: "0px 15px",
                    }
                  : {}
              }
            >
              <Typography
                style={{
                  ...styles.sectionHeading,
                }}
                variant="title"
              >
                {t("howToSendThisMessage")}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={styles.column}>
              <div>
                <RadioButton
                  checked={delivery_channel === DELIVERY_CHANNEL.CHAT}
                  color="primary"
                  style={{
                    ...styles.radioButton,
                    ...styles.checkinRadioButton,
                  }}
                  value={false}
                  onChange={() =>
                    this.setState({
                      delivery_channel: DELIVERY_CHANNEL.CHAT,
                    })
                  }
                />
                {t("byChat")}
              </div>
              <div>
                <RadioButton
                  checked={delivery_channel === DELIVERY_CHANNEL.EMAIL}
                  color="primary"
                  style={{
                    ...styles.radioButton,
                    ...styles.checkinRadioButton,
                  }}
                  value={true}
                  onChange={() =>
                    this.setState({
                      delivery_channel: DELIVERY_CHANNEL.EMAIL,
                    })
                  }
                  disabled={emailDisabled}
                />
                <span style={emailDisabled ? styles.disabled : {}}>
                  {t("byEmail")}
                </span>
              </div>
              {!emailIntegration ? (
                <div style={emailDisabled ? styles.disabled : {}}>
                  {t(
                    "integrateTrainerfuWithGmailOrGoogleMailToEnableThisOption"
                  )}{" "}
                  <div
                    style={{
                      marginTop: 20,
                    }}
                  >
                    <GmailLogin />
                  </div>
                  {/* <span
                      style={{ cursor: "pointer", color: primary }}
                      onClick={this.onLoginClick}
                    >
                      Click here{" "}
                    </span> */}
                </div>
              ) : (
                delivery_channel === DELIVERY_CHANNEL.EMAIL && (
                  <TextField
                    label={t("emailSubjectOptional")}
                    fullWidth={true}
                    onChange={this.onEmailSubjectChange}
                    value={email_subject_line}
                    style={{
                      marginTop: 10,
                    }}
                    disabled={isCheckinMessage}
                    InputProps={{
                      style: {
                        paddingTop: 2,
                        paddingBottom: 4,
                      },
                    }}
                  />
                )
              )}
            </ExpansionPanelDetails>
          </ExpansionPanel>
          {/* )} */}

          <div
            style={{
              display: "flex",
              alignSelf: "flex-end",
              marginTop: 15,
            }}
          >
            <Button
              color="primary"
              variant="contained"
              disabled={
                this.props.requests.addOrUpdateTMRequest &&
                this.props.requests.addOrUpdateTMRequest.inProcess
              }
              style={styles.addClientButton}
              onClick={this.onAddorEdit}
            >
              {this.state.isEditMode ? t("save") : t("schedule")}
            </Button>
          </div>
        </div>
        {clientId && <CMNav clientId={clientId} />}
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    groups: state.groups,
    requests: state.requests,
    campaigns: state.campaigns,
    triggeredMessages: state.triggeredMessages,
    router: state.router,
    emailIntegration: state.me.email_integration,
    filter: state.registers.filter,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateAppTitle: (title) => {
      dispatch(updateAppTitle(title));
    },
    getGroups: () => {
      dispatch({
        type: GET_GROUPS.REQUEST,
      });
    },
    getTriggeredMessages: () => {
      dispatch({
        type: GET_TRIGGERED_MESSAGES.REQUEST,
      });
    },
    addCampaign: (payload) => {
      dispatch({
        type: ADD_CAMPAIGN.REQUEST,
        payload,
      });
    },
    addTriggeredMessage: (payload) => {
      dispatch({
        type: ADD_TRIGGERED_MESSAGE.REQUEST,
        payload,
      });
    },
    updateTriggeredMessage: (payload) => {
      dispatch({
        type: UPDATE_TRIGGERED_MESSAGE.REQUEST,
        payload,
      });
    },
    goBack: () => {
      dispatch(goBack());
    },
    showLoading: () => {
      dispatch(showLoading());
    },
    resetLoading: () => {
      dispatch(resetLoading());
    },
    getEmailIntegration: () => {
      dispatch({
        type: GET_EMAIL_INTEGRATION.REQUEST,
      });
    },
    deleteEmailIntegration: () => {
      dispatch({
        type: DELETE_EMAIL_INTEGRATION.REQUEST,
      });
    },
  };
};
AddOrEditTM.modules = {
  toolbar: {
    container: "#toolbar",
  },
  clipboard: {
    matchVisual: false,
  },
};
export default connect(mapStateToProps, mapDispatchToProps)(AddOrEditTM);
