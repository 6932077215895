import { t } from "../services/utils";
import React from "react";
import { connect } from "react-redux";
import { primaryDark60, primaryDark40, primary } from "../constants/colors";
import Paper from "@material-ui/core/Paper";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Icon from "@material-ui/core/Icon";
import ReactTooltip from "react-tooltip";
import { push } from "connected-react-router";
import { updateChatState } from "../data/app/actions";
import channelTypes from "../constants/channel-type";
import ClientsNoteView from "../scenes/clients/components/ClientsNoteView";
import Modal from "react-modal";
import { getModalStyle } from "../services/modal-util";
import { getPhotoUrl } from "../services/utils";
import { withRouter } from "react-router";
import IconMoon from "./IconMoon";
class CMNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: this.getSelectedItem(props),
      showClientNotes: false,
    };
  }
  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  componentWillReceiveProps(nextProps) {
    const { router } = nextProps;
    if (router.location.pathname === this.props.router.location.pathname) {
      this.setState({
        selectedItem: this.getSelectedItem(nextProps),
      });
    }
  }
  onMenuItemClicked = (e) => {
    const { value } = e.currentTarget;
    const { clientId } = this.props;
    this.setState({
      selectedItem: value,
    });
    switch (value) {
      case 1: {
        this.props.loadPlan(clientId);
        break;
      }
      case 2: {
        this.props.loadDiary(clientId);
        break;
      }
      case 3: {
        this.props.loadClientAssessments(clientId);
        break;
      }
      case 5: {
        this.props.loadClientSettings(clientId);
        break;
      }
      case 4: {
        this.setState({
          selectedItem: 4,
        });
        this.props.loadChat({
          channelType: channelTypes.CHAT,
          isOpen: true,
          clientId,
        });
        break;
      }
      case 6: {
        this.props.loadClientMessages(clientId);
        break;
      }
      case 7: {
        this.setState({
          selectedItem: this.state.selectedItem,
        });
        this.openClientNotes(clientId);
        break;
      }
      case 8: {
        this.props.loadCheckins(clientId);
        break;
      }
      case 9: {
        this.props.loadClientNutrition(clientId);
        break;
      }
      case 10: {
        this.props.loadFilesAndLinks(clientId);
        break;
      }
    }
  };
  getSelectedItem = (props) => {
    const { router } = props;
    if (router.location.pathname.indexOf("checkins") > 0) {
      return 8;
    }
    if (router.location.pathname.indexOf("programs") > 0) {
      return 1;
    }
    if (router.location.pathname.indexOf("diary") > 0) {
      return 2;
    }
    if (router.location.pathname.indexOf("assessments") > 0) {
      return 3;
    }
    if (router.location.pathname.indexOf("auto-messaging") > 0) {
      return 6;
    }
    if (router.location.pathname.indexOf("settings") > 0) {
      return 5;
    }
    if (router.location.pathname.indexOf("nutrition") > 0) {
      return 9;
    }
    return 0;
  };
  openClientNotes = () => {
    this.setState({
      showClientNotes: true,
    });
  };
  closeClientNotes = () => {
    this.setState({
      showClientNotes: false,
    });
  };
  getMemberPhoto = (photoId) => {
    const memberPhoto = getPhotoUrl(photoId);
    return memberPhoto;
  };
  loadChat = () => {
    const { clientId } = this.props;
    this.props.loadChat({
      channelType: channelTypes.CHAT,
      isOpen: true,
      clientId,
    });
  };
  render() {
    let styles = {
      container: {
        position: "fixed",
        // width: 60,
        top: "57px",
        left: 0,
        backgroundColor: "white",
        display: "flex",
        zIndex: 100,
        height: "100%",
        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
      },
      menuTitle: {
        fontSize: 8,
        color: primaryDark60,
        margin: "auto",
      },
      iconStyle: {
        color: primaryDark40,
      },
      selectedItem: {
        // backgroundColor: "#EAE9EF",
        // width: "53%",
        paddingTop: 15,
        paddingBottom: 15,
      },
      menuItem: {
        paddingTop: 15,
        paddingBottom: 15,
      },
      selectedIcon: {
        color: primary,
      },
      appBar: {
        position: "fixed",
        top: 57,
        overflow: "hidden",
        maxHeight: 60,
        backgroundColor: "white",
        boxShadow: "0 1px 4px 0 rgba(0,0,0,0.25)  ",
      },
      toolBar: {
        paddingLeft: 16,
        paddingRight: 40,
      },
      tab: {
        minWidth: 60,
        minHeight: 58,
      },
    };
    let modalStyle1 = getModalStyle(700);
    modalStyle1.overlay = {
      ...modalStyle1.overlay,
      backgroundColor: "transparent",
    };
    modalStyle1.content = {
      ...modalStyle1.content,
      top: 0,
      right: 0,
      margin: 0,
      left: "auto",
      height: "100%",
      display: "flex",
    };
    let selectedTab = "";
    const { clientId, clients } = this.props;
    const { selectedItem } = this.state;
    const client = clients && clients[clientId];
    return (
      <div>
        <Paper elevation={0} style={styles.container}>
          {/* NEW WEB APP NAVIGATION */}
          {/* <AppBar style={styles.appBar} elevation={1}>
            <Toolbar style={styles.toolBar}>
              <Tabs
                value={selectedItem}
                indicatorColor="primary"
                textColor="primary"
                TabIndicatorProps={{
                  style: {
                    height: "5px",
                    borderRadius: "3px"
                  }
                }}
                onChange={this.handleTabChange}
                style={{ minHeight: 60 }}
              >
                <Tab
                  value={1}
                  onClick={() => loadPlan(clientId)}
                  style={styles.tab}
                  label="Plan"
                />
                <Tab
                  value={2}
                  onClick={() => loadDiary(clientId)}
                  style={styles.tab}
                  label="Diary"
                />
                <Tab
                  value={3}
                  onClick={() => loadClientAssessments(clientId)}
                  style={styles.tab}
                  label="Assesment"
                />
                <Tab
                  value={6}
                  onClick={() => loadClientMessages(clientId)}
                  style={styles.tab}
                  label="Sceduled Messages"
                />
              </Tabs>
              <div style={{ flexGrow: 1 }} />
              <Typography
                variant="subheading"
                style={styles.appTitle}
                color="default"
                onClick={() => loadClientSettings(clientId)}
              >
                {client && (
                  <span style={styles.title}>
                    {client.first_name} {client.last_name}
                  </span>
                )}
              </Typography>
              {client && client.user_photo_id ? (
                <Avatar
                  src={this.getMemberPhoto(client.user_photo_id)}
                  style={{
                    marginLeft: 11,
                    width: "unset",
                    height: 32,
                    width: 32
                  }}
                />
              ) : (
                <AccountCircle
                  style={{ marginLeft: 11, fontSize: 32, color: "#757575" }}
                />
              )}
              <IconButton style={{ marginLeft: 30 }} onClick={this.loadChat}>
                <ChatBubble />
              </IconButton>
            </Toolbar>
           </AppBar> */}

          {/* SIDE WEB NAVIGATION */}

          <MenuList
            style={{
              width: "100%",
            }}
          >
            <MenuItem
              value="1"
              data-for="cmNav"
              data-tip={t("plan")}
              onClick={this.onMenuItemClicked}
              style={selectedItem == 1 ? styles.selectedItem : styles.menuItem}
            >
              <Icon
                style={{
                  ...styles.iconStyle,
                  ...(selectedItem == 1 ? styles.selectedIcon : {}),
                }}
                className={"material-icons"}
              >
                <IconMoon icon="plan-1" />
                {/* assignment */}
              </Icon>
            </MenuItem>
            <MenuItem
              value="2"
              data-for="cmNav"
              data-tip={t("diary")}
              onClick={this.onMenuItemClicked}
              style={selectedItem == 2 ? styles.selectedItem : styles.menuItem}
            >
              <Icon
                style={{
                  ...styles.iconStyle,
                  ...(selectedItem == 2 ? styles.selectedIcon : {}),
                }}
                className={"material-icons"}
              >
                <IconMoon icon="diary" />
                {/* book */}
              </Icon>
            </MenuItem>
            <MenuItem
              value="3"
              data-for="cmNav"
              data-tip={t("assessment")}
              onClick={this.onMenuItemClicked}
              style={selectedItem == 3 ? styles.selectedItem : styles.menuItem}
            >
              <Icon
                style={{
                  ...styles.iconStyle,
                  ...(selectedItem == 3 ? styles.selectedIcon : {}),
                }}
                className={"material-icons"}
              >
                <IconMoon icon="assessment" />
                {/* insert_chart */}
              </Icon>
            </MenuItem>
            <MenuItem
              value="4"
              data-for="cmNav"
              data-tip={t("chat")}
              onClick={this.onMenuItemClicked}
              style={selectedItem == 4 ? styles.selectedItem : styles.menuItem}
            >
              <Icon
                style={{
                  ...styles.iconStyle,
                  ...(selectedItem == 4 ? styles.selectedIcon : {}),
                }}
                className={"material-icons"}
              >
                <IconMoon icon="chat" />
                {/* forum */}
              </Icon>
            </MenuItem>
            <MenuItem
              value="9"
              data-for="cmNav"
              data-tip={t("nutrition")}
              onClick={this.onMenuItemClicked}
              style={selectedItem == 9 ? styles.selectedItem : styles.menuItem}
            >
              <Icon
                style={{
                  ...styles.iconStyle,
                  ...(selectedItem == 9 ? styles.selectedIcon : {}),
                }}
                className={"material-icons"}
              >
                <IconMoon icon="habits" />
                {/* forum */}
              </Icon>
            </MenuItem>
            <MenuItem
              value="8"
              data-for="cmNav"
              data-tip={t("habitCheckins")}
              onClick={this.onMenuItemClicked}
              style={selectedItem == 8 ? styles.selectedItem : styles.menuItem}
            >
              <Icon
                style={{
                  ...styles.iconStyle,
                  ...(selectedItem == 8 ? styles.selectedIcon : {}),
                }}
                className={"material-icons"}
              >
                <IconMoon icon="habitscheckin" />
                {/* check */}
              </Icon>
            </MenuItem>
            <MenuItem
              value="7"
              data-for="cmNav"
              data-tip={t("notes1")}
              onClick={this.onMenuItemClicked}
              style={selectedItem == 7 ? styles.selectedItem : styles.menuItem}
            >
              <Icon
                style={{
                  ...styles.iconStyle,
                  ...(selectedItem == 7 ? styles.selectedIcon : {}),
                }}
                className={"material-icons"}
              >
                <IconMoon icon="notes" />
                {/* notes */}
              </Icon>
            </MenuItem>
            <MenuItem
              value="6"
              data-for="cmNav"
              data-tip={t("scheduledMessages")}
              onClick={this.onMenuItemClicked}
              style={selectedItem == 6 ? styles.selectedItem : styles.menuItem}
            >
              <Icon
                style={{
                  ...styles.iconStyle,
                  ...(selectedItem == 6 ? styles.selectedIcon : {}),
                }}
                className={"material-icons"}
              >
                <IconMoon icon="mail" />
                {/* mail */}
              </Icon>
            </MenuItem>

            <MenuItem
              value="10"
              data-tip={t("filesLinks")}
              data-for="cmNav"
              onClick={this.onMenuItemClicked}
              style={selectedItem == 5 ? styles.selectedItem : styles.menuItem}
            >
              <Icon
                style={{
                  ...styles.iconStyle,
                  ...(selectedItem == 5 ? styles.selectedIcon : {}),
                }}
                className={"material-icons"}
              >
                <IconMoon icon="link" />
                {/* settings */}
              </Icon>
            </MenuItem>

            <MenuItem
              value="5"
              data-tip={t("settings")}
              data-for="cmNav"
              onClick={this.onMenuItemClicked}
              style={selectedItem == 5 ? styles.selectedItem : styles.menuItem}
            >
              <Icon
                style={{
                  ...styles.iconStyle,
                  ...(selectedItem == 5 ? styles.selectedIcon : {}),
                }}
                className={"material-icons"}
              >
                <IconMoon icon="settings" />
                {/* settings */}
              </Icon>
            </MenuItem>
          </MenuList>
        </Paper>
        {/* {this.state.showClientNotes && ( */}
        <Modal
          isOpen={this.state.showClientNotes}
          onRequestClose={this.closeClientNotes}
          style={modalStyle1}
          contentLabel="Modal"
        >
          <ClientsNoteView
            ref="clientsNoteView"
            clientId={this.props.clientId}
            closeClientNotes={this.closeClientNotes}
          />
        </Modal>
        {/* )} */}
        <ReactTooltip place="left" type="dark" effect="solid" id="cmNav" />
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    loadPlan: (clientId) => {
      dispatch(push(`/clients/${clientId}/programs`));
    },
    loadDiary: (clientId) => {
      dispatch(push(`/clients/${clientId}/diary`));
    },
    loadChat: (chatState) => {
      dispatch(updateChatState(chatState));
    },
    loadClientSettings: (clientId) => {
      dispatch(push(`/clients/${clientId}/settings`));
    },
    loadClientAssessments: (clientId) => {
      dispatch(push(`/clients/${clientId}/assessments`));
    },
    loadClientMessages: (clientId) => {
      dispatch(
        push({
          pathname: `/auto-messaging/clients/${clientId}`,
          query: {
            fromClients: true,
          },
        })
      );
    },
    loadCheckins: (clientId) => {
      dispatch(push(`/clients/${clientId}/assessments/checkins`));
    },
    loadClientNutrition: (clientId) => {
      dispatch(push(`/clients/${clientId}/nutrition`));
    },
    loadFilesAndLinks: (clientId) => {
      dispatch(push(`/clients/${clientId}/app-links`));
    },
  };
};
const mapStateToProps = (state) => {
  return {
    router: state.router,
    isOpen: state.chatState.isOpen,
    me: state.me,
    clients: state.clients,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CMNav));
