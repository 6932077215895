import { t } from "../../../services/utils";
import React, { Component } from "react";
import { toastr } from "react-redux-toastr";
import Select from "react-select";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import { Icon, InputAdornment } from "@material-ui/core";
import triggerTypes from "../../../constants/trigger-types";
import { timeData } from "../../../constants/wait-time";
import styles from "./styles";

class ClientOptions extends Component {
  constructor(props) {
    super(props);
    const { triggeredMessage, messageId } = props;
    this.state = {
      showTriggerModal: false,
      showAddCampaignModal: false,
      isEditMode: messageId !== undefined,
      id: triggeredMessage && triggeredMessage.triggered_message_id,
      message: triggeredMessage ? triggeredMessage.message : "",
      for_everyone: false,
      wait: 30,
      delivery_window: triggeredMessage
        ? triggeredMessage.delivery_window || 18
        : 18,
      trigger_type: triggerTypes.ON_DATE,
      data_1: triggeredMessage ? triggeredMessage.data_1 : new Date(),
      week_data: 1,
      uploadedFiles:
        triggeredMessage &&
        triggeredMessage.files &&
        triggeredMessage.files.length > 0
          ? triggeredMessage.files
          : [],
      dateDisabled: false,
      showTimeModal: false,
      editMode: false,
      isUploading: false,
      anchorEl: null,
      waitType: "time",
      showURLInput: false,
      urlValue: "",
      isEmail: false,
      email_subject_line: triggeredMessage
        ? triggeredMessage.email_subject_line
        : "",
    };
  }
  handleDateChange = (date) => {
    this.setState({
      // [triggerTypes.ON_DATE]: date,
      date: date,
      data_1: date,
      trigger_type: triggerTypes.ON_DATE,
    });
    this.props.onSelectTrigger(triggerTypes.ON_DATE, date)();
  };
  onSelectDelay = (wait, delivery_window, waitType) => {
    const { trigger_type, data_1 } = this.state;
    if (trigger_type === null || data_1 === null) {
      toastr.error(
        t("error-0"),
        t(
          "pleaseSelectWhenTheyWillReceiveThisMessageBeforeSelectingDelayOptions"
        )
      );
      return;
    }
    this.setState({
      wait,
      delivery_window,
      waitType,
    });
    this.props.onSelectDelay(wait, delivery_window, waitType);
  };
  render() {
    const translatedTimeData = timeData.map((time) => ({
      ...time,
      label: t(time.label),
    }));
    return (
      <ExpansionPanel style={styles.contentContainer}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon fontSize="large" />}>
          <Typography style={styles.sectionHeading} variant="h2">
            {t("whenToSendThisMessage")}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={styles.column}>
          {/* REMOVED AFTER CLIENTS ACCOUNT CREATION FOR GROUPS */}

          <div
            className="triggerRow"
            style={{
              ...styles.listItem,
              ...{
                padding: 20,
              },
            }}
          >
            {t("date")}
            <div className="date-picker">
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  onChange={this.handleDateChange}
                  minDate={new Date()}
                  disabled={this.state.dateDisabled}
                  format="ll"
                  value={this.state.data_1 || new Date()}
                  style={{
                    marginLeft: 10,
                    width: 150,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          style={{
                            backgroundColor: "unset",
                            marginRight: -20,
                          }}
                        >
                          <Icon>arrow_drop_down</Icon>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <Divider style={styles.dividerStyle} />
          <Typography
            style={{
              ...styles.sectionHeading,
              ...{
                marginBottom: 30,
                marginTop: 30,
              },
            }}
            variant="h2"
          >
            {t("timeOfTheDay")}
          </Typography>

          <div
            className="triggerRow"
            style={{
              ...styles.listItem,
              ...{
                padding: 20,
              },
            }}
            onClick={() => {
              this.onSelectDelay(0, this.state.delivery_window, "time");
            }}
          >
            {t("at")}
            <div className="select-up">
              <Select
                style={styles.selectBoxRight}
                value={this.state.delivery_window}
                clearable={false}
                searchable={false}
                onChange={(option) => {
                  this.onSelectDelay(0, option.value, "time");
                  this.setState({
                    delivery_window: option.value,
                  });
                }}
                classNamePrefix="trigger-row"
                options={translatedTimeData}
              />
            </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}
export default ClientOptions;
