import { t } from "../services/utils";
import React, { Component } from "react";
import { TextField as Input } from "@material-ui/core/";
import { primaryDark40 } from "../constants/colors";
class TextField extends Component {
  render() {
    const styles = {
      field: {
        width: "100%"
      },
      label: {
        fontSize: 14,
        color: primaryDark40,
        fontWeight: "500"
      }
    };
    return <Input size="small" variant="outlined" style={styles.field} InputProps={{
      style: {
        borderRadius: 6
      }
    }} inputProps={{
      className: "modal-input",
      style: {
        paddingLeft: 20,
        fontSize: 14,
        lineHeight: "20px"
      }
    }} InputLabelProps={{
      style: styles.label
    }} {...this.props} />;
  }
}
export default TextField;