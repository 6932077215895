import { takeEvery } from "redux-saga/effects";
import {
    GET_GEO
} from "../../constants/action-types";
import { call, put } from "redux-saga/effects";
import "whatwg-fetch";

const getGeoApi = function () {
    return fetch("https://ipinfo.io/json?token=d21b194f2d8235");
}

function* getGeo(action) {
    const resp = yield call(getGeoApi);
    const json = yield resp.json();
    if(resp.ok) {
        yield put({
            type: GET_GEO.SUCCESS,
            payload: json,
        });
    } else {
        yield put({
            type: GET_GEO.ERROR
        });
    }
}

export default function* sagas() {
    yield takeEvery(GET_GEO.REQUEST, getGeo);
}
