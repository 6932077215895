import { t } from "../services/utils";
import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import DialogNew from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
export class NewDialog extends Component {
  componentDidMount() {}
  render() {
    let styles = {
      dialogActionsContainerStyle: {
        display: "flex",
        flexDirection: "row-reverse",
        justifyContent: "flex-start",
      },
      dialogContentStyle: {
        width: "400px",
        maxWidth: "400px",
      },
    };
    const { title, open, onRequestClose, message } = this.props;
    return (
      <DialogNew
        style={{
          zIndex: 99999,
        }}
        open={open}
        onClose={onRequestClose}
      >
        {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
        <DialogContent style={styles.dialogContentStyle}>
          {message}
        </DialogContent>
        <DialogActions style={styles.dialogActionsContainerStyle}>
          {this.props.actions.map((action, index) => {
            return (
              <Button
                action={(button) => {
                  action.focus && button && button.focusVisible();
                }}
                key={index}
                color={action.primary ? "primary" : "default"}
                onClick={action.onClick}
              >
                {action.label}
              </Button>
            );
          })}
        </DialogActions>
      </DialogNew>
    );
  }
}
