import { t } from "../../services/utils";
import { GET_NEW_NOTIFICATIONS_COUNT, GET_NOTIFICATIONS, MARK_NOTIFICATION_READ } from '../../constants/action-types';
export function newNotificationsCount(state = 0, action) {
  switch (action.type) {
    case GET_NEW_NOTIFICATIONS_COUNT.SUCCESS:
      {
        return action.payload.count;
      }
    case GET_NOTIFICATIONS.SUCCESS:
      {
        return 0;
      }
    default:
      return state;
  }
}
export function notifications(state = null, action) {
  switch (action.type) {
    case GET_NOTIFICATIONS.SUCCESS:
      {
        return action.payload.notifications;
      }
    case MARK_NOTIFICATION_READ.REQUEST:
      {
        let notifs = [...state];
        for (let i = 0; i < notifs.length; i++) {
          let n = notifs[i];
          if (n.id === action.payload.notification.id) {
            n.read = true;
            break;
          }
        }
        return notifs;
      }
    default:
      return state;
  }
}