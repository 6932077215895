import { t } from "../../services/utils";
import {
  GET_CLIENTS,
  GET_TRAINERS,
  GET_ME,
  UPDATE_CLIENT,
  DELETE_CLIENT,
  GET_EMAIL_INTEGRATION,
  DELETE_EMAIL_INTEGRATION,
  PUT_EMAIL_INTEGRATION,
  GET_CLIENT_STRIPE_ID,
  GET_APP_LINKS,
  SAVE_CLIENT_APP_SETTINGS,
  GET_CLIENT_APP_SETTINGS,
  ADD_APP_LINK,
  UPDATE_CLIENT_HABITS,
  GET_NEWS_FEED_SETTINGS,
  SAVE_NEWS_FEED_SETTINGS,
  GET_IN_APP_PURCHASE_SETTINGS,
  SAVE_IN_APP_PURCHASE_SETTINGS,
} from "../../constants/action-types";
import fetchState from "../../constants/fetch-state";
export function clients(state = null, action) {
  switch (action.type) {
    case GET_CLIENTS.SUCCESS:
      return action.payload.reduce((acc, c) => {
        acc[c.id] = c;
        return acc;
      }, {});
    case UPDATE_CLIENT.SUCCESS: {
      return {
        ...state,
        [action.payload.id]: action.payload,
      };
    }
    case DELETE_CLIENT.REQUEST: {
      let cls = {
        ...state,
      };
      delete cls[action.payload.id];
      return cls;
    }
    case GET_CLIENT_STRIPE_ID.SUCCESS: {
      const { id, stripe_customer_id } = action.payload;
      return {
        ...state,
        [id]: {
          ...state[id],
          stripe_customer_id,
        },
      };
    }
    case UPDATE_CLIENT_HABITS.SUCCESS: {
      const { clientId, segmentIds } = action.payload;
      return {
        ...state,
        [clientId]: {
          ...state[clientId],
          segment_ids: segmentIds,
        },
      };
    }
    default:
      return state;
  }
}
export function clientIds(state = [], action) {
  switch (action.type) {
    case GET_CLIENTS.SUCCESS:
      return action.payload.map(function (client) {
        return client.id;
      });
    default:
      return state;
  }
}

// export function trainers(state = null, action) {
//   switch (action.type) {
//     case GET_TRAINERS.SUCCESS:
//       return action.payload.reduce((acc, t) => {
//         acc[t.id] = t;
//         return acc;
//       }, {});
//     default:
//       return state;
//   }
// }

export function me(state, action) {
  if (state === undefined) {
    return {
      fetchState: fetchState.NOT_LOADED,
    };
  }
  switch (action.type) {
    case GET_ME.PENDING:
      return {
        fetchState: fetchState.LOADING,
      };
    case GET_ME.SUCCESS:
      return {
        fetchState: fetchState.LOADED,
        ...action.payload,
        language: action.payload.language || "en",
      };
    case GET_ME.ERROR:
      return {
        fetchState: fetchState.NOT_LOADED,
      };
    case "UPDATE_ME":
      return {
        ...state,
        ...action.payload,
      };
    case GET_EMAIL_INTEGRATION.SUCCESS:
      return {
        ...state,
        email_integration: action.payload.live,
      };
    case DELETE_EMAIL_INTEGRATION.SUCCESS:
      return {
        ...state,
        email_integration: false,
      };
    case PUT_EMAIL_INTEGRATION.SUCCESS:
      return {
        ...state,
        email_integration: true,
      };
    default:
      return state;
  }
}

export function appLinks(state = {}, action) {
  switch (action.type) {
    case GET_APP_LINKS.SUCCESS:
      if (action.payload.id) {
        return {
          ...state,
          [action.payload.id]: action.payload.files_and_links,
        };
      }
      return {
        ...state,
        companyLinks: action.payload.files_and_links,
      };
    case ADD_APP_LINK.SUCCESS: {
      if (action.payload.id) {
        return {
          ...state,
          [action.payload.id]: action.payload.files_and_links,
        };
      }
      return {
        ...state,
        companyLinks: action.payload.files_and_links,
      };
    }
    default:
      return state;
  }
}
export function clientAppSettings(state = {}, action) {
  switch (action.type) {
    case GET_CLIENT_APP_SETTINGS.SUCCESS:
      return {
        ...state,
        canSeeUpcomingPlans: action.payload.can_see_upcoming_plans,
        canSeePastPlans: action.payload.can_see_past_plans,
        visibleFutureWeeks: action.payload.visible_future_weeks,
      };
    case SAVE_CLIENT_APP_SETTINGS.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export function newsFeedSettings(state = {}, action) {
  switch (action.type) {
    case GET_NEWS_FEED_SETTINGS.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case SAVE_NEWS_FEED_SETTINGS.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export function inAppPurchaseSettings(state = {}, action) {
  switch (action.type) {
    case GET_IN_APP_PURCHASE_SETTINGS.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case SAVE_IN_APP_PURCHASE_SETTINGS.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
