import { t } from "../services/utils";
const asyncActionType = type => ({
  REQUEST: `${type}_REQUEST`,
  PENDING: `${type}_PENDING`,
  SUCCESS: `${type}_SUCCESS`,
  ERROR: `${type}_ERROR`
});
export const MESSAGES = asyncActionType("MESSAGES");
export const SEND_MESSAGE = asyncActionType("SEND_MESSAGE");
export const RECEIVE_NEW_MESSAGE = "RECEIVE_NEW_MESSAGE";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const UPDATE_CHAT_STATE = "UPDATE_CHAT_STATE";
export const ADD_NOTE = "ADD_NOTE";
export const GET_CAMPAIGNS = asyncActionType("GET_CAMPAIGNS");
export const ADD_CAMPAIGN = asyncActionType("ADD_CAMPAIGN");
export const DELETE_CAMPAIGN = asyncActionType("DELETE_CAMPAIGN");
export const GET_TRIGGERED_MESSAGES = asyncActionType("GET_TRIGGERED_MESSAGES");
export const ADD_TRIGGERED_MESSAGE = asyncActionType("ADD_TRIGGERED_MESSAGE");
export const DELETE_TRIGGERED_MESSAGE = asyncActionType("DELETE_TRIGGERED_MESSAGE");
export const SEND_GROUP_MESSAGE = asyncActionType("SEND_GROUP_MESSAGE");
export const UPDATE_TRIGGERED_MESSAGE = asyncActionType("UPDATE_TRIGGERED_MESSAGE");
export const PAUSE_MESSAGE = asyncActionType("PAUSE_MESSAGE");
export const CREATE_ACCESS_TOKEN = asyncActionType("CREATE_ACCESS_TOKEN");
export const GET_CLIENTS = asyncActionType("GET_CLIENTS");
export const UPDATE_CLIENT = asyncActionType("UPDATE_CLIENT");
export const GET_TRAINERS = asyncActionType("GET_TRAINERS");
export const ADD_TRAINER = asyncActionType("ADD_TRAINER");
export const UPDATE_TRAINER = asyncActionType("UPDATE_TRAINER");
export const DELETE_TRAINER = asyncActionType("DELETE_TRAINER");
export const GET_ME = asyncActionType("GET_ME");
export const DELETE_ACCESS_TOKEN = asyncActionType("DELETE_ACCESS_TOKEN");
export const GET_PROGRAM_DAY_DETAILS = asyncActionType("GET_PROGRAM_DAY_DETAILS");
export const GET_PROGRAM_DAY_NOTE = asyncActionType("GET_PROGRAM_DAY_NOTE");
export const GET_PROGRAM_DAY_DRILL = asyncActionType("GET_PROGRAM_DAY_DRILL");
export const GET_PROGRAM_DAY_DRILLS_ORDER = asyncActionType("GET_PROGRAM_DAY_DRILLS_ORDER");
export const GET_EXERCISES = asyncActionType("GET_EXERCISES");
export const GET_RECENT_EXERCISES = asyncActionType("GET_RECENT_EXERCISES");
export const UPDATE_PROGRAM_DRILL = asyncActionType("UPDATE_PROGRAM_DRILL");
export const UPDATE_PROGRAM_DAY_NOTE = asyncActionType("UPDATE_PROGRAM_DAY_NOTE");
export const CREATE_PROGRAM_DRILL_TASK = "CREATE_PROGRAM_DRILL_TASK";
export const MOVE_PROGRAM_DRILL_TASK = "MOVE_PROGRAM_DRILL_TASK";
export const CREATE_PROGRAM_DRILL = asyncActionType("CREATE_PROGRAM_DRILL");
export const UPDATE_PROGRAM_DAY_DRILL_ORDER = asyncActionType("UPDATE_PROGRAM_DAY_DRILL_ORDER");
export const GET_WORKOUT_TEMPLATE_LIST = asyncActionType("GET_WORKOUT_TEMPLATE_LIST");
export const COPY_PASTE_PROGRAM_DAY = asyncActionType("COPY_PASTE_PROGRAM_DAY");
export const COPY_PASTE_PROGRAM_DAY_TO_NEW_TEMPLATE = asyncActionType("COPY_PASTE_PROGRAM_DAY_TO_NEW_TEMPLATE");
export const CREATE_WORKOUT_TEMPLATE = asyncActionType("CREATE_WORKOUT_TEMPLATE");
export const DELETE_WORKOUT_TEMPLATE = asyncActionType("DELETE_WORKOUT_TEMPLATE");
export const EDIT_WORKOUT_TEMPLATE_NAME = asyncActionType("EDIT_WORKOUT_TEMPLATE_NAME");
export const COPY_PASTE_PROGRAM = asyncActionType("COPY_PASTE_PROGRAM");
export const DELETE_PROGRAM_DRILL = asyncActionType("DELETE_PROGRAM_DRILL");
export const DELETE_PROGRAM_DRILL_TASK = "DELETE_PROGRAM_DRILL_TASK";
export const GET_PROGRAM = asyncActionType("GET_PROGRAM");
export const UPDATE_APP_TITLE_REQUEST = "UPDATE_APP_TITLE_REQUEST";
export const PERFORMED_SKILL = "PERFORMED_SKILL";
export const CREATE_EXERCISE = asyncActionType("CREATE_EXERCISE");
export const UPDATE_EXERCISE = asyncActionType("UPDATE_EXERCISE");
export const GET_CLIENT_PROGRAMS = asyncActionType("GET_CLIENT_PROGRAMS");
export const LOAD_PROGRAM_TASK = "LOAD_PROGRAM_TASK";
export const LOAD_CLIENT_PROGRAMS_TASK = "LOAD_CLIENT_PROGRAMS_TASK";
export const LOAD_ORDERED_PROGRAM_LIST_ITEMS_TASK = "LOAD_ORDERED_PROGRAM_LIST_ITEMS_TASK";
export const REGISTER_PROGRAM_DAY_FOR_COPY = "REGISTER_PROGRAM_DAY";
export const REGISTER_PROGRAM_FOR_COPY = "REGISTER_PROGRAM";
export const REGISTER_PROGRAM_VIEW_EXIT_FOCUS_DAY = "REGISTER_PROGRAM_VIEW_EXIT_FOCUS_DAY";
export const REMOVE_REGISTERED_PROGRAM_VIEW_EXIT_FOCUS_DAY = "REMOVE_REGISTERED_PROGRAM_VIEW_EXIT_FOCUS_DAY";
export const UPDATE_PROGRAM_NOTE = asyncActionType("UPDATE_PROGRAM_NOTE");
export const UPDATE_PROGRAM_ACTIVATION_DATE = asyncActionType("UPDATE_PROGRAM_ACTIVATION_DATE");
export const DELETE_CLIENT_PROGRAM = asyncActionType("DELETE_CLIENT_PROGRAM");
export const DELETE_SCHEDULED_PROGRAM = asyncActionType(
  "DELETE_SCHEDULED_PROGRAM"
);
export const CREATE_CLIENT_PROGRAM = asyncActionType("CREATE_CLIENT_PROGRAM");
export const RESET_APP_REQUEST = "RESET_APP_REQUEST";
export const GET_ORDERED_PROGRAM_LISTS = asyncActionType("GET_ORDERED_PROGRAM_LISTS");
export const GET_ORDERED_PROGRAM_LIST_ITEMS = asyncActionType("GET_ORDERED_PROGRAM_LIST_ITEMS");
export const CREATE_ORDERED_PROGRAM_LIST_ITEM = asyncActionType("CREATE_ORDERED_PROGRAM_LIST_ITEM");
export const DELETE_ORDERED_PROGRAM_LIST_ITEM = asyncActionType("DELETE_ORDERED_PROGRAM_LIST_ITEM");
export const CREATE_ORDERED_PROGRAM_LIST = asyncActionType("CREATE_ORDERED_PROGRAM_LIST");
export const DELETE_ORDERED_PROGRAM_LIST = asyncActionType("DELETE_ORDERED_PROGRAM_LIST");
export const EDIT_PLAN_TEMPLATE_NAME = asyncActionType("EDIT_PLAN_TEMPLATE_NAME");
export const COPY_FROM_PLAN_TEMPLATE = asyncActionType("COPY_FROM_PLAN_TEMPLATE");
export const COPY_FROM_PLAN_TEMPLATE_STARTED = "COPY_FROM_PLAN_TEMPLATE_STARTED";
export const COPY_FROM_PLAN_TEMPLATE_ENDED = "COPY_FROM_PLAN_TEMPLATE_ENDED";
export const GET_ACCOUNT = asyncActionType("GET_ACCOUNT");
export const UPDATE_SEE_ALL_CLIENTS_DEFAULT = asyncActionType("UPDATE_SEE_ALL_CLIENTS_DEFAULT");
export const REGISTER_CLIENTS_VIEW_FILTER = asyncActionType("REGISTER_CLIENTS_VIEW_FILTER");
export const CREATE_CLIENT = asyncActionType("CREATE_CLIENT");
export const DELETE_CLIENT = asyncActionType("DELETE_CLIENT");
export const REGISTER_CLIENTS_VIEW_EXIT_FOCUS_ID = "REGISTER_CLIENTS_VIEW_EXIT_FOCUS_ID";
export const CREATE_INVITE = asyncActionType("CREATE_INVITE");
export const DELETE_EXERCISE = asyncActionType("DELETE_EXERCISE");
export const CREATE_ALTERNATE_PASSWORD = asyncActionType("CREATE_ALTERNATE_PASSWORD");
export const UPDATE_PASSWORD = asyncActionType("UPDATE_PASSWORD");
export const CLEAR_CACHED_CLIENTS_PROGRAMS_TASK = "CLEAR_CACHED_CLIENTS_PROGRAMS_TASK";
export const GET_GROUPS = asyncActionType("GET_GROUPS");
export const ADD_GROUP = asyncActionType("ADD_GROUP");
export const UPDATE_GROUP = asyncActionType("UPDATE_GROUP");
export const UPDATE_GROUP_SETTINGS = asyncActionType("UPDATE_GROUP_SETTINGS");
export const GET_GROUP_MEMBERS = asyncActionType("GET_GROUP_MEMBERS");
export const UPDATE_GROUP_MEMBERS = asyncActionType("UPDATE_GROUP_MEMBERS");
export const DELETE_GROUP = asyncActionType("DELETE_GROUP");
export const CREATE_APP_EVENT = asyncActionType("CREATE_APP_EVENT");
export const GET_STORIES = asyncActionType("GET_STORIES");
export const ADD_STORY_COMMENT = asyncActionType("ADD_STORY_COMMENT");
export const LIKE_STORY = asyncActionType("LIKE_STORY");
export const UNLIKE_STORY = asyncActionType("UNLIKE_STORY");
export const ARCHIVE_STORY = asyncActionType("ARCHIVE_STORY");
export const UPDATE_STORY_PRIVACY = asyncActionType("UPDATE_STORY_PRIVACY");
export const UPDATE_STORY = asyncActionType("UPDATE_STORY");
export const WORKER_START = "WORKER_START";
export const WORKER_STOP = "WORKER_STOP";
export const GET_HAS_NEW_STORIES = asyncActionType("GET_HAS_NEW_STORIES");
export const GET_NEW_NOTIFICATIONS_COUNT = asyncActionType("GET_NEW_NOTIFICATIONS_COUNT");
export const GET_NOTIFICATIONS = asyncActionType("GET_NOTIFICATIONS");
export const MARK_NOTIFICATION_READ = asyncActionType("MARK_NOTIFICATION_READ");
export const PROVISION_NEW_COMPANY = asyncActionType("PROVISION_NEW_COMPANY");
export const REGISTER_DID_DISMISS_SAMPLE_CLIENT_TIP = "REGISTER_DID_DISMISS_SAMPLE_CLIENT_TIP";
export const REGISTER_DID_DISMISS_SAMPLE_FORM_TIP = "REGISTER_DID_DISMISS_SAMPLE_FORM_TIP";
export const GET_ALL_SIGNUP_FORMS = asyncActionType("GET_ALL_SIGNUP_FORMS");
export const GET_SIGNUP_FORM = asyncActionType("GET_SIGNUP_FORM");
export const CREATE_SIGNUP_FORM = asyncActionType("CREATE_SIGNUP_FORM");
export const UPDATE_SIGNUP_FORM = asyncActionType("UPDATE_SIGNUP_FORM");
export const DELETE_SIGNUP_FORM = asyncActionType("DELETE_SIGNUP_FORM");
export const CREATE_CLIENT_SIGNUP = asyncActionType("CREATE_CLIENT_SIGNUP");
export const CREATE_PENDING_CLIENT_SIGNUP = asyncActionType("CREATE_PENDING_CLIENT_SIGNUP");
export const COMPLETE_PENDING_CLIENT_SIGNUP = asyncActionType("COMPLETE_PENDING_CLIENT_SIGNUP");
export const GET_PENDING_CLIENT_SIGNUP = asyncActionType("GET_PENDING_CLIENT_SIGNUP");
export const PRE_CLIENT_SIGNUP_CHECK = asyncActionType("PRE_CLIENT_SIGNUP_CHECK");
export const EXISTING_CLIENT_SIGNIN_AUTH_CHECK = asyncActionType("EXISTING_CLIENT_SIGNIN_AUTH_CHECK");
export const GET_ASSESSMENTS = asyncActionType("GET_ASSESSMENTS");
export const ADD_CUSTOM_ASSESSMENT = asyncActionType("ADD_CUSTOM_ASSESSMENT");
export const POST_PROCESS_ASSESSMENT = asyncActionType("POST_PROCESS_ASSESSMENT");
export const ADD_ASSESSMENT_MEASURE = asyncActionType("ADD_ASSESSMENT_MEASURE");
export const DELETE_ASSESSMENT_MEASURE = asyncActionType("DELETE_ASSESSMENT_MEASURE");
export const SET_GOAL = asyncActionType("SET_GOAL");
export const DELETE_GOAL = asyncActionType("DELETE_GOAL");
export const DELETE_CUSTOM_ASSESSMENT = asyncActionType("DELETE_CUSTOM_ASSESSMENT");
export const GET_EXERCISE_PROGRESSION = asyncActionType("GET_EXERCISE_PROGRESSION");
export const GET_EX_PROGRESSION_DETAILS = asyncActionType("GET_EX_PROGRESSION_DETAILS");
export const GET_PROGRESS_PHOTOS = asyncActionType("GET_PROGRESS_PHOTOS");
export const REGISTER_DID_OPEN_MAIN_MENU = "REGISTER_DID_OPEN_MAIN_MENU";
export const POST_NEWS = asyncActionType("POST_NEWS");
export const GET_PENDING_BRANDED_APP = asyncActionType("GET_PENDING_BRANDED_APP");
export const GENERATE_BRANDED_APP_FILES = asyncActionType("GENERATE_BRANDED_APP_FILES");
export const GENERATE_BRANDED_APP_FILES_WITH_TFA_CODE = asyncActionType("GENERATE_BRANDED_APP_FILES_WITH_TFA_CODE");
export const POLL_PENDING_BRANDED_APP_FOR_END_STATE = asyncActionType("POLL_PENDING_BRANDED_APP_FOR_END_STATE");
export const GET_CHECKINS = asyncActionType("GET_CHECKINS");
export const GET_LEADERBOARD_PROGRESS_PHOTOS = asyncActionType("GET_LEADERBOARD_PROGRESS_PHOTOS");
export const GET_LEADERBOARD_CHECKINS = asyncActionType("GET_LEADERBOARD_CHECKINS");
export const GET_LEADERBOARD_WEIGHT_DATA = asyncActionType("GET_LEADERBOARD_WEIGHT_DATA");
export const GET_LEADERBOARD_WORKOUTLOGGED = asyncActionType("GET_LEADERBOARD_WORKOUTLOGGED");
export const GET_ASSESSMENT_TYPES = asyncActionType(GET_ASSESSMENT_TYPES);
export const GET_CLIENT_SCHEDULED_MESSAGES = asyncActionType("GET_CLIENT_SCHEDULED_MESSAGES");
export const TOGGLE_SKIP_STATUS = asyncActionType("TOGGLE_SKIP_STATUS");
export const ADD_SCHEDULED_MESSAGE = asyncActionType("ADD_SCHEDULED_MESSAGE");
export const UPDATE_SCHEDULED_MESSAGE = asyncActionType("UPDATE_SCHEDULED_MESSAGE");
export const ADD_PROGRESS_PHOTO = asyncActionType("ADD_PROGRESS_PHOTO");
export const DELETE_SCHEDULED_MESSAGE = asyncActionType("DELETE_SCHEDULED_MESSAGE");
export const GET_CLIENT_NOTES = asyncActionType("GET_CLIENT_NOTES");
export const ADD_CLIENT_NOTE = asyncActionType("ADD_CLIENT_NOTE");
export const DELETE_CLIENT_NOTE = asyncActionType("DELETE_CLIENT_NOTE");
export const EDIT_CLIENT_NOTE = asyncActionType("EDIT_CLIENT_NOTE");
export const DELETE_MY_UNREAD_CHAT_NOTIFICATIONS = asyncActionType("DELETE_MY_UNREAD_CHAT_NOTIFICATIONS");
export const CONNECT_STRIPE_ACCOUNT = asyncActionType("CONNECT_STRIPE_ACCOUNT");
export const REGISTER_SHOW_WORKOUT_DETAILS = "REGISTER_SHOW_WORKOUT_DETAILS";
export const REGISTER_FOR_POWER_EDITOR = "REGISTER_FOR_POWER_EDITOR";
export const REGISTER_FOR_CURRENT_AND_UPCOMING = "REGISTER_FOR_CURRENT_AND_UPCOMING";
export const GET_SUBSCRIPTION = asyncActionType("GET_SUBSCRIPTION");
export const UPDATE_SUBSCRIPTION = asyncActionType("UPDATE_SUBSCRIPTION");
export const GET_UPDATE_SUBSCRIPTION_PREVIEW = asyncActionType("GET_UPDATE_SUBSCRIPTION_PREVIEW");
export const REGISTER_DID_DISMISS_RESOURCES = "REGISTER_DID_DISMISS_RESOURCES";
export const GET_EXERCISES_HISTORY = asyncActionType("GET_EXERCISES_HISTORY");
export const PUT_EMAIL_INTEGRATION = asyncActionType("PUT_EMAIL_INTEGRATION");
export const GET_EMAIL_INTEGRATION = asyncActionType("GET_EMAIL_INTEGRATION");
export const DELETE_EMAIL_INTEGRATION = asyncActionType("DELETE_EMAIL_INTEGRATION");
export const GET_CLIENT_STRIPE_ID = asyncActionType("GET_CLIENT_STRIPE_ID");
export const GET_STRIPE_URL = asyncActionType("GET_STRIPE_URL");
export const DELETE_WORKOUT = asyncActionType("DELETE_WORKOUT");
export const ADD_HABIT = asyncActionType("ADD_HABIT");
export const UPDATE_HABIT = asyncActionType("UPDATE_HABIT");
export const DELETE_HABIT = asyncActionType("DELETE_HABIT");
export const GET_APP_LINKS = asyncActionType("GET_APP_LINKS");
export const ADD_APP_LINK = asyncActionType("ADD_APP_LINK");
export const SAVE_CLIENT_APP_SETTINGS = asyncActionType("SAVE_CLIENT_APP_SETTINGS");
export const GET_CLIENT_APP_SETTINGS = asyncActionType("GET_CLIENT_APP_SETTINGS");
export const GET_NEWS_FEED_SETTINGS = asyncActionType("GET_NEWS_FEED_SETTINGS");
export const SAVE_NEWS_FEED_SETTINGS = asyncActionType("SAVE_NEWS_FEED_SETTINGS");
export const GET_IN_APP_PURCHASE_SETTINGS = asyncActionType("GET_IN_APP_PURCHASE_SETTINGS");
export const SAVE_IN_APP_PURCHASE_SETTINGS = asyncActionType("SAVE_IN_APP_PURCHASE_SETTINGS");
export const GET_CLIENT_DAILY_NUTRITION = asyncActionType("GET_CLIENT_DAILY_NUTRITION");
export const SAVE_CLIENT_DAILY_NUTRITION = asyncActionType("SAVE_CLIENT_DAILY_NUTRITION");
export const UPDATE_CLIENT_HABITS = asyncActionType("UPDATE_CLIENT_HABITS");
export const EMAIL_PLAN_TO_CLIENT = asyncActionType("EMAIL_PLAN_TO_CLIENT");
export const GOOGLE_LOGIN = asyncActionType("GOOGLE_LOGIN");
export const GOOGLE_SIGNUP = asyncActionType("GOOGLE_SIGNUP");
export const GET_LOGIN_SERVICE = asyncActionType("GET_LOGIN_SERVICE");
export const SAVE_SIGNUP_FORM = "SAVE_SIGNUP_FORM";
export const SAVE_FILTER = "SAVE_FILTER";
export const REGISTER_DRILL_TO_COPY = "REGISTER_DRILL_TO_COPY";
export const LOAD_SCHEDULED_CLIENT_PROGRAMS_TASK = "LOAD_SCHEDULED_CLIENT_PROGRAMS_TASK";
export const GET_GEO = asyncActionType("GET_GEO");

function getCorrespondingActionType(forType, suffix) {
  let split = forType.split("_");
  if (split.length >= 2) {
    split.pop();
  }
  split.push(suffix);
  return split.join("_");
}
export function getRequestActionType(forType) {
  return getCorrespondingActionType(forType, "REQUEST");
}
export function getPendingActionType(forType) {
  return getCorrespondingActionType(forType, "PENDING");
}
export function getSuccessActionType(forType) {
  return getCorrespondingActionType(forType, "SUCCESS");
}
export function getErrorActionType(forType) {
  return getCorrespondingActionType(forType, "ERROR");
}
