import { t } from "../../services/utils";
import { CREATE_EXERCISE, UPDATE_EXERCISE, CREATE_CLIENT_PROGRAM, CREATE_ORDERED_PROGRAM_LIST_ITEM, COPY_FROM_PLAN_TEMPLATE_STARTED, COPY_FROM_PLAN_TEMPLATE_ENDED, CREATE_CLIENT, DELETE_CLIENT, CREATE_ALTERNATE_PASSWORD, UPDATE_PASSWORD, ADD_GROUP, UPDATE_GROUP_SETTINGS, ADD_TRAINER, UPDATE_TRAINER, ADD_CAMPAIGN, ADD_TRIGGERED_MESSAGE, UPDATE_TRIGGERED_MESSAGE, GET_TRIGGERED_MESSAGES, CREATE_SIGNUP_FORM, UPDATE_SIGNUP_FORM, CREATE_CLIENT_SIGNUP, CREATE_PENDING_CLIENT_SIGNUP, ADD_CUSTOM_ASSESSMENT, ADD_ASSESSMENT_MEASURE, GENERATE_BRANDED_APP_FILES, GENERATE_BRANDED_APP_FILES_WITH_TFA_CODE, SET_GOAL, SEND_GROUP_MESSAGE, PAUSE_MESSAGE, GET_LEADERBOARD_WEIGHT_DATA, ADD_SCHEDULED_MESSAGE, UPDATE_SCHEDULED_MESSAGE, TOGGLE_SKIP_STATUS, DELETE_SCHEDULED_MESSAGE, POST_NEWS, CREATE_WORKOUT_TEMPLATE, EXISTING_CLIENT_SIGNIN_AUTH_CHECK, UPDATE_SUBSCRIPTION, GET_UPDATE_SUBSCRIPTION_PREVIEW, COPY_FROM_PLAN_TEMPLATE, ADD_APP_LINK, GET_APP_LINKS, SAVE_CLIENT_APP_SETTINGS, SAVE_NEWS_FEED_SETTINGS, SAVE_IN_APP_PURCHASE_SETTINGS, SAVE_CLIENT_DAILY_NUTRITION, UPDATE_GROUP_MEMBERS, UPDATE_CLIENT_HABITS, UPDATE_STORY } from "../../constants/action-types";
import errorCodes from "../../constants/error-codes";
import moment from "moment";
export function requests(state = {}, action) {
  switch (action.type) {
    case ADD_TRAINER.REQUEST:
    case UPDATE_TRAINER.REQUEST:
      return {
        ...state,
        addOrUpdateTrainerRequest: {
          inProcess: true
        }
      };
    case ADD_TRAINER.SUCCESS:
    case UPDATE_TRAINER.SUCCESS:
      return {
        ...state,
        addOrUpdateTrainerRequest: {
          success: true,
          inProcess: false
        }
      };
    case ADD_TRAINER.ERROR:
    case UPDATE_TRAINER.ERROR:
      return {
        ...state,
        addOrUpdateTrainerRequest: {
          inProcess: false,
          success: false,
          errorMessage: action.payload.errorMessage
        }
      };
    case ADD_CUSTOM_ASSESSMENT.REQUEST:
      return {
        ...state,
        addAssessmentRequest: {
          inProcess: true
        }
      };
    case ADD_CUSTOM_ASSESSMENT.SUCCESS:
      const {
        id,
        name
      } = action.payload;
      return {
        ...state,
        addAssessmentRequest: {
          success: true,
          inProcess: false,
          id
        }
      };
    case ADD_CUSTOM_ASSESSMENT.ERROR:
      return {
        ...state,
        addAssessmentRequest: {
          inProcess: false,
          success: false
        }
      };
    case SET_GOAL.REQUEST:
      return {
        ...state,
        setGoalRequest: {
          inProcess: true
        }
      };
    case SET_GOAL.SUCCESS:
      return {
        ...state,
        setGoalRequest: {
          success: true,
          inProcess: false
        }
      };
    case SET_GOAL.ERROR:
      return {
        ...state,
        setGoalRequest: {
          inProcess: false,
          success: false
        }
      };
    case ADD_ASSESSMENT_MEASURE.REQUEST:
      return {
        ...state,
        addAssessmentMeasureRequest: {
          inProcess: true
        }
      };
    case ADD_ASSESSMENT_MEASURE.SUCCESS:
      return {
        ...state,
        addAssessmentMeasureRequest: {
          success: true,
          inProcess: false
        }
      };
    case ADD_ASSESSMENT_MEASURE.ERROR:
      return {
        ...state,
        addAssessmentMeasureRequest: {
          inProcess: false,
          success: false,
          errorCode: action.payload.error_code,
          requestData: action.payload.request_payload
        }
      };
    case ADD_CAMPAIGN.REQUEST:
      return {
        ...state,
        addCampaignRequest: {
          inProcess: true
        }
      };
    case ADD_CAMPAIGN.SUCCESS:
      return {
        ...state,
        addCampaignRequest: {
          inProcess: false,
          campaign_id: action.payload.id
        }
      };
    case GET_TRIGGERED_MESSAGES.REQUEST:
      return {
        ...state,
        getTriggeredMessagesRequest: {
          inProcess: true
        }
      };
    case GET_TRIGGERED_MESSAGES.SUCCESS:
      return {
        ...state,
        getTriggeredMessagesRequest: {
          inProcess: false
        }
      };
    case PAUSE_MESSAGE.REQUEST:
      return {
        ...state,
        pauseMessageRequest: {
          inProcess: true
        }
      };
    case PAUSE_MESSAGE.SUCCESS:
      return {
        ...state,
        pauseMessageRequest: {
          inProcess: false
        }
      };
    case ADD_TRIGGERED_MESSAGE.REQUEST:
    case UPDATE_TRIGGERED_MESSAGE.REQUEST:
      return {
        ...state,
        addOrUpdateTMRequest: {
          inProcess: true
        }
      };
    case ADD_TRIGGERED_MESSAGE.SUCCESS:
    case UPDATE_TRIGGERED_MESSAGE.SUCCESS:
      return {
        ...state,
        addOrUpdateTMRequest: {
          inProcess: false
        }
      };
    case ADD_TRIGGERED_MESSAGE.ERROR:
    case UPDATE_TRIGGERED_MESSAGE.ERROR:
      return {
        ...state,
        addOrUpdateTMRequest: {
          inProcess: false,
          failed: true
        }
      };
    case SEND_GROUP_MESSAGE.REQUEST:
      return {
        ...state,
        sendGroupMessageRequest: {
          inProcess: true
        }
      };
    case SEND_GROUP_MESSAGE.SUCCESS:
      return {
        ...state,
        sendGroupMessageRequest: {
          inProcess: false
        }
      };
    case SEND_GROUP_MESSAGE.ERROR:
      return {
        ...state,
        sendGroupMessageRequest: {
          inProcess: false,
          failed: true
        }
      };
    case ADD_SCHEDULED_MESSAGE.REQUEST:
    case UPDATE_SCHEDULED_MESSAGE.REQUEST:
      return {
        ...state,
        addScheduledMessageRequest: {
          inProcess: true
        }
      };
    case ADD_SCHEDULED_MESSAGE.SUCCESS:
    case UPDATE_SCHEDULED_MESSAGE.SUCCESS:
      return {
        ...state,
        addScheduledMessageRequest: {
          inProcess: false
        }
      };
    case ADD_SCHEDULED_MESSAGE.ERROR:
    case UPDATE_SCHEDULED_MESSAGE.ERROR:
      return {
        ...state,
        addScheduledMessageRequest: {
          inProcess: false,
          failed: true
        }
      };
    case TOGGLE_SKIP_STATUS.REQUEST:
      {
        return {
          ...state,
          toggleSkipStatusRequest: {
            inProcess: true
          }
        };
      }
    case TOGGLE_SKIP_STATUS.SUCCESS:
      {
        return {
          ...state,
          toggleSkipStatusRequest: {
            inProcess: false,
            skipped: action.payload.skip,
            id: action.payload.id
          }
        };
      }
    case TOGGLE_SKIP_STATUS.ERROR:
      {
        return {
          ...state,
          toggleSkipStatusRequest: {
            inProcess: false,
            failed: true
          }
        };
      }
    case DELETE_SCHEDULED_MESSAGE.REQUEST:
      {
        return {
          ...state,
          deleteScheduledMessageRequest: {
            inProcess: true
          }
        };
      }
    case DELETE_SCHEDULED_MESSAGE.SUCCESS:
      {
        return {
          ...state,
          deleteScheduledMessageRequest: {
            inProcess: false,
            id: action.payload.id
          }
        };
      }
    case DELETE_SCHEDULED_MESSAGE.ERROR:
      {
        return {
          ...state,
          deleteScheduledMessageRequest: {
            inProcess: true,
            failed: true
          }
        };
      }
    case ADD_GROUP.REQUEST:
    case UPDATE_GROUP_SETTINGS.REQUEST:
      return {
        ...state,
        addOrUpdateGroupRequest: {
          inProcess: true
        }
      };
    case UPDATE_GROUP_SETTINGS.SUCCESS:
      return {
        ...state,
        addOrUpdateGroupRequest: {
          inProcess: false
        }
      };
    case ADD_GROUP.SUCCESS:
      return {
        ...state,
        addOrUpdateGroupRequest: {
          inProcess: false,
          success: true,
          id: action.payload.id
        }
      };
    case ADD_GROUP.ERROR:
    case UPDATE_GROUP_SETTINGS.ERROR:
      return {
        ...state,
        addOrUpdateGroupRequest: {
          inProcess: false
        }
      };
    case UPDATE_GROUP_MEMBERS.REQUEST:
      return {
        ...state,
        updateGroupMembersRequest: {
          inProcess: true
        }
      };
    case UPDATE_GROUP_MEMBERS.SUCCESS:
      return {
        ...state,
        updateGroupMembersRequest: {
          inProcess: false
        }
      };
    case UPDATE_CLIENT_HABITS.REQUEST:
      return {
        ...state,
        updateClientHabitsRequest: {
          inProcess: true
        }
      };
    case UPDATE_CLIENT_HABITS.SUCCESS:
      return {
        ...state,
        updateClientHabitsRequest: {
          inProcess: false
        }
      };
    case CREATE_EXERCISE.REQUEST:
    case UPDATE_EXERCISE.REQUEST:
      return {
        ...state,
        lastExerciseCURequest: {
          started: new Date().getTime()
        }
      };
    case CREATE_EXERCISE.SUCCESS:
    case UPDATE_EXERCISE.SUCCESS:
      return {
        ...state,
        lastExerciseCURequest: {
          started: state.lastExerciseCURequest.started,
          finished: new Date().getTime(),
          success: true,
          exercise_id: action.payload.id
        }
      };
    case UPDATE_EXERCISE.ERROR:
    case CREATE_EXERCISE.ERROR:
      return {
        ...state,
        lastExerciseCURequest: {
          started: state.lastExerciseCURequest.started,
          finished: new Date().getTime(),
          success: false
        }
      };
    case CREATE_CLIENT_PROGRAM.PENDING:
      return {
        ...state,
        creatingClientProgram: true
      };
    case CREATE_CLIENT_PROGRAM.SUCCESS:
    case CREATE_CLIENT_PROGRAM.ERROR:
      return {
        ...state,
        creatingClientProgram: false
      };
    case CREATE_ORDERED_PROGRAM_LIST_ITEM.PENDING:
      return {
        ...state,
        creatingOrderedPlanListItem: true
      };
    case CREATE_ORDERED_PROGRAM_LIST_ITEM.SUCCESS:
    case CREATE_ORDERED_PROGRAM_LIST_ITEM.ERROR:
      return {
        ...state,
        creatingOrderedPlanListItem: false
      };
    case COPY_FROM_PLAN_TEMPLATE.REQUEST:
      return {
        ...state,
        copyFromPlanTemplateRequest: {
          inProgress: true
        }
      };
    case COPY_FROM_PLAN_TEMPLATE.SUCCESS:
    case COPY_FROM_PLAN_TEMPLATE.ERROR:
      return {
        ...state,
        copyFromPlanTemplateRequest: {
          inProgress: false,
          lastFinishedAt: new Date().getTime(),
          isLastCopyRequestScheduledForLater: action.type === COPY_FROM_PLAN_TEMPLATE.SUCCESS ? action.payload.is_scheduled_for_later : false,
          didLastRequestFailed: action.type === COPY_FROM_PLAN_TEMPLATE.ERROR,
          failedDueToDuplicateJob: action.type === COPY_FROM_PLAN_TEMPLATE.ERROR ? action.payload.error_code === errorCodes.DUPLICATE_COPY_PLAN_TEMPLATE_JOB : false
        }
      };
    case CREATE_CLIENT.PENDING:
      return {
        ...state,
        lastCreateClientRequest: {
          inProcess: true
        }
      };
    case CREATE_CLIENT.SUCCESS:
    case CREATE_CLIENT.ERROR:
      return {
        ...state,
        lastCreateClientRequest: {
          inProcess: false,
          completedAt: new Date(),
          failedWithEmailExistError: action.type === CREATE_CLIENT.ERROR ? action.payload.error_code === errorCodes.USER_WITH_EMAIL_EXIST : false
        }
      };
    case DELETE_CLIENT.PENDING:
      return {
        ...state,
        deleteClientRequest: {
          inProcess: true
        }
      };
    case DELETE_CLIENT.SUCCESS:
    case DELETE_CLIENT.ERROR:
      return {
        ...state,
        deleteClientRequest: {
          inProcess: false
        }
      };
    case CREATE_ALTERNATE_PASSWORD.PENDING:
      return {
        ...state,
        lastForgotPasswordRequest: {
          inProcess: true
        }
      };
    case CREATE_ALTERNATE_PASSWORD.SUCCESS:
    case CREATE_ALTERNATE_PASSWORD.ERROR:
      return {
        ...state,
        lastForgotPasswordRequest: {
          inProcess: false,
          completedAt: new Date(),
          success: action.type === CREATE_ALTERNATE_PASSWORD.SUCCESS,
          failedWithUserNotFoundError: action.type === CREATE_ALTERNATE_PASSWORD.ERROR ? action.payload.error_code === errorCodes.USER_NOT_FOUND : false
        }
      };
    case UPDATE_PASSWORD.PENDING:
      return {
        ...state,
        lastUpdatePasswordRequest: {
          inProcess: true
        }
      };
    case UPDATE_PASSWORD.SUCCESS:
    case UPDATE_PASSWORD.ERROR:
      return {
        ...state,
        lastUpdatePasswordRequest: {
          inProcess: false,
          completedAt: new Date(),
          success: action.type === UPDATE_PASSWORD.SUCCESS,
          failedWithInvalidPasswordError: action.type === UPDATE_PASSWORD.ERROR ? action.payload.error_code === errorCodes.INVALID_PASSWORD_FORMAT : false
        }
      };
    case CREATE_SIGNUP_FORM.REQUEST:
    case UPDATE_SIGNUP_FORM.REQUEST:
      return {
        ...state,
        cuSignupFormRequest: {
          inProcess: true
        }
      };
    case CREATE_SIGNUP_FORM.SUCCESS:
    case UPDATE_SIGNUP_FORM.SUCCESS:
    case CREATE_SIGNUP_FORM.ERROR:
    case UPDATE_SIGNUP_FORM.ERROR:
      return {
        ...state,
        cuSignupFormRequest: {
          inProcess: false
        }
      };
    case CREATE_CLIENT_SIGNUP.REQUEST:
    case CREATE_PENDING_CLIENT_SIGNUP.REQUEST:
      return {
        ...state,
        lastClientSignupRequest: {
          inProcess: true
        }
      };
    case CREATE_CLIENT_SIGNUP.ERROR:
    case CREATE_PENDING_CLIENT_SIGNUP.ERROR:
      return {
        ...state,
        lastClientSignupRequest: {
          inProcess: false,
          completedAt: new Date(),
          success: false,
          error: action.payload
        }
      };
    case CREATE_CLIENT_SIGNUP.SUCCESS:
    case CREATE_PENDING_CLIENT_SIGNUP.SUCCESS:
      return {
        ...state,
        lastClientSignupRequest: {
          inProcess: false,
          completedAt: new Date(),
          success: true,
          token: action.payload.token ? action.payload.token : null
        }
      };
    case GET_LEADERBOARD_WEIGHT_DATA.REQUEST:
      {
        return {
          ...state,
          leaderboardWeightDataRequest: {
            inProcess: true
          }
        };
      }
    case GET_LEADERBOARD_WEIGHT_DATA.SUCCESS:
      {
        return {
          ...state,
          leaderboardWeightDataRequest: {
            inProcess: false
          }
        };
      }
    case POST_NEWS.REQUEST:
      {
        return {
          ...state,
          postNewsRequest: {
            inProcess: true
          }
        };
      }
    case POST_NEWS.SUCCESS:
      {
        return {
          ...state,
          postNewsRequest: {
            inProcess: false
          }
        };
      }
    case POST_NEWS.ERROR:
      {
        return {
          ...state,
          postNewsRequest: {
            inProcess: false,
            failed: true
          }
        };
      }
    case CREATE_WORKOUT_TEMPLATE.REQUEST:
      {
        return {
          ...state,
          workoutTemplateRequest: {
            inProcess: true
          }
        };
      }
    case CREATE_WORKOUT_TEMPLATE.SUCCESS:
      {
        return {
          ...state,
          workoutTemplateRequest: {
            inProcess: false
          }
        };
      }
    case CREATE_WORKOUT_TEMPLATE.ERROR:
      {
        return {
          ...state,
          workoutTemplateRequest: {
            inProcess: false,
            failed: true
          }
        };
      }
    case EXISTING_CLIENT_SIGNIN_AUTH_CHECK.REQUEST:
      {
        return {
          ...state,
          clientSigninAuthCheckRequest: {
            inProcess: true,
            failed: false
          }
        };
      }
    case EXISTING_CLIENT_SIGNIN_AUTH_CHECK.SUCCESS:
      {
        return {
          ...state,
          clientSigninAuthCheckRequest: {
            inProcess: false,
            failed: !action.payload.ok,
            completedAt: new Date()
          }
        };
      }
    case EXISTING_CLIENT_SIGNIN_AUTH_CHECK.ERROR:
      {
        return {
          ...state,
          clientSigninAuthCheckRequest: {
            inProcess: false,
            failed: true,
            completedAt: new Date()
          }
        };
      }
    case UPDATE_SUBSCRIPTION.REQUEST:
      {
        return {
          ...state,
          updateSubscriptionRequest: {
            [action.payload.token]: {
              inProcess: true
            }
          }
        };
      }
    case UPDATE_SUBSCRIPTION.SUCCESS:
      {
        return {
          ...state,
          updateSubscriptionRequest: {
            [action.payload.token]: {
              inProcess: false,
              success: true
            }
          }
        };
      }
    case UPDATE_SUBSCRIPTION.ERROR:
      {
        return {
          ...state,
          updateSubscriptionRequest: {
            [action.payload.token]: {
              ...action.payload,
              inProcess: false,
              success: false,
              ts: moment().unix()
            }
          }
        };
      }
    case GET_APP_LINKS.REQUEST:
      {
        return {
          ...state,
          getAppLinksRequest: {
            inProcess: true
          }
        };
      }
    case GET_APP_LINKS.SUCCESS:
      {
        return {
          ...state,
          getAppLinksRequest: {
            inProcess: false
          }
        };
      }
    case ADD_APP_LINK.REQUEST:
      {
        return {
          ...state,
          addAppLinkRequest: {
            inProcess: true
          }
        };
      }
    case ADD_APP_LINK.SUCCESS:
      {
        return {
          ...state,
          addAppLinkRequest: {
            inProcess: false
          }
        };
      }
    case SAVE_CLIENT_APP_SETTINGS.REQUEST:
      {
        return {
          ...state,
          saveClientAppSettingsRequest: {
            inProcess: true
          }
        };
      }
    case SAVE_CLIENT_APP_SETTINGS.SUCCESS:
      {
        return {
          ...state,
          saveClientAppSettingsRequest: {
            inProcess: false
          }
        };
      }
    case SAVE_NEWS_FEED_SETTINGS.REQUEST:
      {
        return {
          ...state,
          saveNewsFeedSettingsRequest: {
            inProcess: true
          }
        };
      }
    case SAVE_NEWS_FEED_SETTINGS.SUCCESS:
      {
        return {
          ...state,
          saveNewsFeedSettingsRequest: {
            inProcess: false
          }
        };
      }
    case SAVE_IN_APP_PURCHASE_SETTINGS.REQUEST:
      {
        return {
          ...state,
          saveInAppPurchaseSettingsRequest: {
            inProcess: true
          }
        };
      }
    case SAVE_IN_APP_PURCHASE_SETTINGS.SUCCESS:
      {
        return {
          ...state,
          saveInAppPurchaseSettingsRequest: {
            inProcess: false
          }
        };
      }
    case SAVE_CLIENT_DAILY_NUTRITION.REQUEST:
      {
        return {
          ...state,
          saveClientDailyNutritionRequest: {
            inProcess: true
          }
        };
      }
    case SAVE_CLIENT_DAILY_NUTRITION.SUCCESS:
      {
        return {
          ...state,
          saveClientDailyNutritionRequest: {
            inProcess: false
          }
        };
      }
    case UPDATE_STORY.REQUEST:
      {
        return {
          ...state,
          updateStoryRequest: {
            inProcess: true
          }
        };
      }
    case UPDATE_STORY.SUCCESS:
      {
        return {
          ...state,
          updateStoryRequest: {
            inProcess: false
          }
        };
      }
    case "LOAD_CLIENT_PROGRAMS_PENDING":
      {
        return {
          ...state,
          loadingPastPlans: true
        };
      }
    case "LOAD_CLIENT_PROGRAMS_SUCCESS":
    case "LOAD_CLIENT_PROGRAMS_ERROR":
      {
        return {
          ...state,
          loadingPastPlans: false
        };
      }
    default:
      return state;
  }
}