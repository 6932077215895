import { t } from "../../services/utils";
import { MESSAGES, SEND_MESSAGE, RECEIVE_NEW_MESSAGE, ADD_NOTE, GET_CLIENT_NOTES, ADD_CLIENT_NOTE, DELETE_CLIENT_NOTE, EDIT_CLIENT_NOTE } from "../../constants/action-types";
import { takeEvery, put, select } from "redux-saga/effects";
import { getAPI, putAPI, postAPI, deleteAPI } from "../api";
import * as Utils from "../../services/utils";
import uuidv4 from "uuid/v4";
import moment from "moment";
function* messages(action) {
  const data = yield* getAPI(action.type, `/channels/${action.payload.channelId}/messages`, {
    getSuccessPayload: resp => {
      const {
        messages
      } = resp;
      const formatedMessages = messages.map(Utils.formatMessageObject);
      return {
        channelId: action.payload.channelId,
        messages: formatedMessages.reverse()
      };
    }
  });
}
function* getClientNotes(action) {
  const data = yield* getAPI(action.type, `/channels/${action.payload.channelId}/messages`, {
    getSuccessPayload: resp => {
      const {
        messages
      } = resp;
      const formatedMessages = messages.map(Utils.formatMessageObject);
      return {
        channelId: action.payload.channelId,
        messages: formatedMessages.reverse()
      };
    }
  });
}
function* addMessage(message, channelId) {
  const newMessage = Object.assign({}, message);
  yield put({
    type: ADD_NOTE,
    payload: {
      channelId: channelId,
      message: newMessage
    }
  });
}
function* sendMessageRequest(action) {
  const {
    type,
    payload
  } = action;
  const {
    channelId,
    message
  } = payload;
  yield* putAPI(type, `/channels/${channelId}/messages/new`, {
    params: {
      message: message.text
    },
    getSuccessPayload: resp => {
      return {
        channelId: channelId,
        messageId: resp.id,
        message: message
      };
    }
  });
}
function* sendMessage(action) {
  const {
    channelId,
    message
  } = action.payload;
  yield addMessage(message, channelId);
  if (message.text && message.text !== "") {
    yield sendMessageRequest(action);
  }
}
function* receiveMessage(action) {
  const {
    date,
    message,
    photo_id,
    sender_first_name,
    sender_id,
    mime_type
  } = action.data;
  const channelId = action.channel.substr(8);
  const contextUser = yield select(state => state.me);
  if (contextUser.id !== sender_id) {
    const {
      text,
      image,
      video
    } = Utils.extractMediaLink(message);
    const receivedMessage = {
      _id: uuidv4(),
      text,
      image,
      video,
      createdAt: moment.utc(message.date_time).local(),
      user: {
        _id: sender_id,
        name: sender_first_name,
        avatar: photo_id ? Utils.getPhotoUrl(photo_id) : ""
      },
      files: message.files,
      isCheckin: message.is_checkin,
      didCheckin: message.did_checkin,
      featuredLink: message.featured_link,
      mimeType: mime_type,
      message
    };

    // import("../../services/chat-utils").then(utils => {
    //   utils.renderMessage(receivedMessage, true);
    // });
    yield addMessage(receivedMessage, channelId);
  }
}
function* deleteMessage(action) {
  const {
    channelId,
    messageId
  } = action.payload;
  yield* deleteAPI(action.type, `/channels/${channelId}/messages/${messageId}`, {
    getSuccessPayload: resp => {
      return action.payload;
    }
  });
}
function* editMessage(action) {
  const {
    channelId,
    messageId,
    message
  } = action.payload;
  yield* postAPI(action.type, `/channels/${channelId}/messages/${messageId}/message_text`, {
    params: {
      message: message
    },
    getSuccessPayload: resp => {
      return action.payload;
    }
  });
}
const sagas = function* sagas() {
  yield takeEvery(GET_CLIENT_NOTES.REQUEST, getClientNotes);
  yield takeEvery(RECEIVE_NEW_MESSAGE, receiveMessage);
  yield takeEvery(ADD_CLIENT_NOTE.REQUEST, sendMessage);
  yield takeEvery(DELETE_CLIENT_NOTE.REQUEST, deleteMessage);
  yield takeEvery(EDIT_CLIENT_NOTE.REQUEST, editMessage);
};
export default sagas;