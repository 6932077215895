import { t } from "../../../services/utils";
import { grey800, primaryDark60, blue700 } from "../../../constants/colors";
const styles = {
  container: {
    minWidth: 320,
    maxWidth: 800,
    height: "auto",
    position: "absolute",
    left: 0,
    right: 0,
    margin: "auto",
    display: "flex",
    flexDirection: "column"
  },
  paper: {
    padding: "6%",
    marginLeft: 10,
    marginRight: 10,
    overflow: "auto",
    display: "flex",
    flexDirection: "column"
  },
  logo: {
    alignSelf: "center",
    marginBottom: 24
  },
  fieldContainer: {
    display: "flex",
    flexDirection: "column",
    width: "45%"
  },
  toolbar: {
    display: "flex",
    marginTop: 45,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  toolbarLinks: {
    display: "flex",
    flexDirection: "column",
    marginRight: "auto",
    alignItems: "center",
    marginTop: 10
  },
  // signUp: {
  //   marginTop: 57,
  // },

  signupBtn: {
    height: 50,
    width: "auto"
  },
  signupBtnWithInput: {
    height: 51,
    maxWidth: 330,
    marginTop: 15
  },
  signupEmailInput: {
    height: 50,
    borderRadius: 5,
    paddingLeft: 15,
    paddingRight: 15
  },
  login: {
    alignSelf: "center",
    fontSize: "16px",
    cursor: "pointer"
  },
  description: {
    fontSize: 16,
    marginTop: 40
  },
  agreement: {
    fontSize: 13,
    color: "#524D70",
    marginTop: 50
  },
  link: {
    color: blue700
  },
  targetLink: {
    color: blue700,
    textDecoration: "underline",
    cursor: "pointer"
  },
  forgotPassword: {
    marginTop: 5
  },
  nameField: {
    marginTop: 10
  },
  background: {
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    textAlign: "center"
  },
  backgroundImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover"
  },
  mobileBackground: {
    width: "100%"
    // backgroundColor: blue700,
    // height: 210
  },

  mobileBackgroundImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover"
  },
  passwordHint: {
    color: primaryDark60,
    fontSize: 13,
    marginTop: 10
  },
  dialogLink: {
    color: blue700,
    textDecoration: "underline",
    fontWeight: 600,
    cursor: "pointer"
  },
  heading: {
    color: "#2A234E",
    fontSize: 32,
    fontWeight: "bold"
  },
  row: {
    display: "flex",
    justifyContent: "space-between"
  },
  paymentDetails: {
    display: "flex",
    // marginBottom: 15,
    marginTop: 15
  },
  currencySymbol: {
    fontSize: 24,
    marginTop: 16,
    fontWeight: "500",
    marginRight: 5
  },
  paymentAmount: {
    fontFamily: "'Inter', sans-serif",
    fontSize: 60,
    fontWeight: "bold"
  },
  free: {
    fontFamily: "'Inter', sans-serif",
    fontSize: 32,
    fontWeight: "bold"
  },
  amountSuffix: {
    fontSize: 24,
    alignSelf: "flex-end",
    marginBottom: 14,
    fontWeight: "500"
  },
  paymentLabel: {
    alignSelf: "flex-end",
    marginLeft: 15,
    marginBottom: 15
  },
  title: {
    fontFamily: "'Inter', sans-serif",
    fontSize: 32
  },
  videoContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // backgroundColor: "#f3f3f3",
    borderRadius: 5,
    // marginTop: 35,
    overflow: "hidden",
    width: "50%",
    marginRight: 30,
    justifyContent: "center"
  },
  video: {
    height: "100%"
  },
  modalVideo: {
    display: "flex",
    overflow: "hidden",
    borderRadius: 10,
    position: "absolute"
  },
  playBtn: {
    height: 56,
    width: 75,
    position: "absolute",
    top: "40%",
    padding: 0,
    cursor: "pointer"
  },
  itemRight: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  }
};
export default styles;