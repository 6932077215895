import { t } from "../../services/utils";
import { MESSAGES, SEND_MESSAGE, RECEIVE_NEW_MESSAGE, ADD_MESSAGE, DELETE_MY_UNREAD_CHAT_NOTIFICATIONS } from "../../constants/action-types";
import { takeEvery, put, select, call, all } from "redux-saga/effects";
import { getAPI, putAPI, postAPI, deleteAPI } from "../api";
import * as Utils from "../../services/utils";
import mimeTypes from "../../constants/mime-types";
import uuidv4 from "uuid/v4";
// import mime from "mime-types";
// import {RNS3} from 'react-native-aws3';
import moment from "moment";

// const AWS_S3_OPTIONS = {
//   keyPrefix: "chat/",
//   bucket: "app-trainerfu-com",
//   region: "us-east-1",
//   accessKey: "AKIAIL64UWVNMSUPHHMQ",
//   secretKey: "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",
//   successActionStatus: 201
// };

function* messages(action) {
  const data = yield* getAPI(action.type, `/channels/${action.payload.channelId}/messages/chat`, {
    getSuccessPayload: resp => {
      const {
        messages
      } = resp;
      const formatedMessages = messages.map(Utils.formatMessageObject);
      return {
        channelId: action.payload.channelId,
        messages: formatedMessages.reverse()
      };
    }
  });
}
function* addMessage(message, channelId) {
  const newMessage = Object.assign({}, message);
  if (newMessage.mime_type && newMessage.mime_type.indexOf("image") >= 0) {
    newMessage.mimeType = mimeTypes.IMAGE;
  }
  if (newMessage.mime_type && newMessage.mime_type.indexOf("video") >= 0) {
    newMessage.mimeType = mimeTypes.VIDEO;
  }
  yield put({
    type: ADD_MESSAGE,
    payload: {
      channelId: channelId,
      message: newMessage
    }
  });
}
function* sendMessageRequest(action) {
  const {
    type,
    payload
  } = action;
  const {
    channelId,
    message
  } = payload;
  yield* putAPI(type, `/channels/${channelId}/messages/new`, {
    params: {
      message: message.text,
      mime_type: message.mime_type
    }
  });
}
function* sendMessage(action) {
  const {
    channelId,
    message
  } = action.payload;
  yield addMessage(message, channelId);
  if (message.text && message.text !== "") {
    yield sendMessageRequest(action);
  } else if (message.image || message.video) {
    const uri = message.image || message.video;
    const mime_type = message.mime_type;
    // const type = mime.lookup(uri);
    // const name = `${uuidv4()}.${mime.extension(type)}`;

    // This will be the url for the uploaded resource
    // const resourceUrl = `https://app-trainerfu-com.s3.amazonaws.com/chat%2F${name}`;
    // let resourceResp = yield call(RNS3.put, {name, uri, type}, AWS_S3_OPTIONS);
    yield sendMessageRequest({
      type: action.type,
      payload: {
        channelId: action.payload.channelId,
        message: {
          text: uri,
          mime_type
        }
      }
    });
    // if (resourceResp.status === 201) {
    //     yield sendMessageRequest({type: action.type, payload: {
    //         channelId: action.payload.channelId,
    //         message: {text: resourceUrl}
    //     }})
    // }
  }
}

function* receiveMessage(action) {
  const {
    date,
    message,
    photo_id,
    sender_first_name,
    sender_id,
    mime_type
  } = action.data;
  const channelId = action.channel.substr(8);
  const contextUser = yield select(state => state.me);
  if (contextUser.id !== sender_id) {
    const {
      text,
      image,
      video
    } = Utils.extractMediaLink(message);
    const receivedMessage = {
      _id: uuidv4(),
      text,
      image,
      video,
      createdAt: moment.utc(message.date_time).local(),
      user: {
        _id: sender_id,
        name: sender_first_name,
        avatar: photo_id ? Utils.getPhotoUrl(photo_id) : ""
      },
      files: message.files,
      isCheckin: message.is_checkin,
      didCheckin: message.did_checkin,
      featuredLink: message.featured_link,
      mimeType: mime_type,
      message
    };
    yield addMessage(receivedMessage, channelId);
    yield* deleteAPI(DELETE_MY_UNREAD_CHAT_NOTIFICATIONS.REQUEST, `/channels/${channelId}/my_unread_chat_notifications`);
  }
}
const sagas = function* sagas() {
  yield takeEvery(MESSAGES.REQUEST, messages);
  yield takeEvery(SEND_MESSAGE.REQUEST, sendMessage);
  yield takeEvery(RECEIVE_NEW_MESSAGE, receiveMessage);
};
export default sagas;