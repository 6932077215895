import { t } from "../../services/utils";
import {
  GET_CLIENTS,
  GET_TRAINERS,
  GET_ME,
  GET_ACCOUNT,
  CREATE_CLIENT,
  DELETE_CLIENT,
  CREATE_INVITE,
  UPDATE_CLIENT,
  GET_EMAIL_INTEGRATION,
  DELETE_EMAIL_INTEGRATION,
  GET_CLIENT_STRIPE_ID,
  GET_STRIPE_URL,
  GET_APP_LINKS,
  ADD_APP_LINK,
  SAVE_CLIENT_APP_SETTINGS,
  GET_CLIENT_APP_SETTINGS,
  GET_NEWS_FEED_SETTINGS,
  SAVE_NEWS_FEED_SETTINGS,
  GET_IN_APP_PURCHASE_SETTINGS,
  SAVE_IN_APP_PURCHASE_SETTINGS,
  UPDATE_CLIENT_HABITS,
} from "../../constants/action-types";
import {
  buildInviteEmailForClient,
  buildInviteEmailForTrainer,
} from "../../services/utils";
import errorCodes from "../../constants/error-codes";
import { getAPI, putAPI, postAPI, deleteAPI } from "../api";
import { call, put, takeEvery } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
function* getClients(action) {
  const { ok, status, jsonResp } = yield* getAPI(action.type, "/clients/ver3", {
    getSuccessPayload: (resp) => resp.users,
  });
  if (ok) {
    // yield put({
    //   type: GET_TRAINERS.SUCCESS,
    //   payload: jsonResp.trainers
    // });

    yield put({
      type: GET_ACCOUNT.SUCCESS,
      payload: {
        askForUpgrade: jsonResp.ask_for_upgrade,
        canAddClients: jsonResp.can_add_clients,
        askToResumeSubscription: jsonResp.ask_to_resume_subscription,
      },
    });
  }
}
function* getMe(action) {
  yield* getAPI(action.type, "/users/myself/basic_info");
}
function* createClient(action) {
  const { ok, status, jsonResp } = yield* putAPI(
    action.type,
    "/users/new_client",
    {
      params: {
        first_name: action.payload.firstName,
        last_name: action.payload.lastName,
        email: action.payload.email,
        primary_trainer_id: action.payload.primaryTrainerId,
        segment_ids: action.payload.groupIds,
      },
    }
  );
  if (ok) {
    yield* getClients({
      type: GET_CLIENTS.REQUEST,
    });
  }
}
function* updateUserSettings(action) {
  const { id, forMySettings, user_role } = action.payload;
  const postResp = yield* postAPI(action.type, `/users/${id}/basic_info`, {
    params: action.payload,
    getSuccessPayload: () => action.payload,
  });
}
function* getClientStripeId(action) {
  const { id } = action.payload;
  const resp = yield* getAPI(action.type, ` /users/${id}/stripe_customer_id`, {
    getSuccessPayload: (resp) => ({
      id: action.payload.id,
      stripe_customer_id: resp.stripe_customer_id,
    }),
  });
}
function* deleteClient(action) {
  yield* deleteAPI(action.type, `/users/${action.payload.id}`);
}
function* getEmailIntegration(action) {
  yield* getAPI(action.type, "/email_integration");
}
function* deleteEmailIntegration(action) {
  yield* deleteAPI(action.type, "/email_integration");
}
function* getAppLinks(action) {
  const { id, type } = action.payload;
  let url = "/company_files_and_links";
  if (id) {
    if (type === "client") {
      url = `/users/${id}/files_and_links`;
    } else if (type === "group") {
      url = `/segments/${id}/files_and_links`;
    }
  }
  yield* getAPI(action.type, url, {
    getSuccessPayload: (resp) => {
      return {
        files_and_links: resp.files_and_links,
        id,
      };
    },
  });
}
function* addAppLink(action) {
  const { links, id, type } = action.payload;
  let url = "/company_files_and_links";
  if (id) {
    if (type === "client") {
      url = `/users/${id}/files_and_links`;
    } else if (type === "group") {
      url = `/segments/${id}/files_and_links`;
    }
  }
  const { ok, status, jsonResp } = yield* postAPI(action.type, url, {
    params: {
      files_and_links: links,
    },
    getSuccessPayload: () => {
      return {
        files_and_links: links,
        id,
      };
    },
  });
}
function* getClientAppSettings(action) {
  yield* getAPI(action.type, "/company_settings/client_app_settings");
}
function* getNewsFeedSettings(action) {
  yield* getAPI(action.type, "/company_settings/news_feed_settings");
}
function* getInAppPurchaseSettings(action) {
  yield* getAPI(action.type, "/company_settings/inapp_purchase_settings");
}
function* updateClientHabits(action) {
  const { clientId, habits } = action.payload;
  const { ok, status, jsonResp } = yield* postAPI(
    action.type,
    `/users/${clientId}/segments`,
    {
      params: {
        segment_ids: habits,
        segment_type: 2 /* habit */,
      },

      getSuccessPayload: () => action.payload,
    }
  );
}
function* saveClientAppSettings(action) {
  const { can_see_past_plans, can_see_upcoming_plans, visible_future_weeks } =
    action.payload;
  const { ok, status, jsonResp } = yield* postAPI(
    action.type,
    "/company_settings/client_app_settings",
    {
      params: {
        can_see_past_plans,
        can_see_upcoming_plans,
        visible_future_weeks,
      },
      getSuccessPayload: () => action.payload,
    }
  );
}
function* saveNewsFeedSettings(action) {
  const { ok, status, jsonResp } = yield* postAPI(
    action.type,
    "/company_settings/news_feed_settings",
    {
      params: action.payload,
      getSuccessPayload: () => action.payload,
    }
  );
}
function* saveInAppPurchaseSettings(action) {
  const { ok, status, jsonResp } = yield* postAPI(
    action.type,
    "/company_settings/inapp_purchase_settings",
    {
      params: action.payload,
      getSuccessPayload: () => action.payload,
    }
  );
}
function* getStripeUrl(action) {
  const { email, password } = action.payload;
  const resp = yield* getAPI(action.type, "/stripe_client_portal", {
    params: {
      email,
      password,
    },
    redirectOn401: false,
  });
  const { jsonResp, ok } = resp;
  console.log("response is", resp);
  if (ok) {
    // REDIRECT TO URL
    const { redirect_url } = jsonResp;
    let win = window.open(redirect_url, "_self");
    win.focus();
  } else {
    let errorCode = jsonResp.error_code;
    if (errorCode === errorCodes.AUTHENTICATION_FAILED) {
      toastr.error("Error", "Email and password do not match");
    } else if (errorCode === errorCodes.STRIPE_CUSTOMER_DOESNT_EXIST) {
      toastr.error(
        "Error",
        "Can’t find any subscription linked to your account."
      );
    }
  }
}
export function sendInvite(client) {
  let request = new XMLHttpRequest();
  if (client) {
    request.open("PUT", `/users/${client.id}/invite2`, false);
    request.send(null);
  }
  if (request.status === 200) {
    let jsonResp = JSON.parse(request.responseText);
    let appName = jsonResp.custom_app_name
      ? jsonResp.custom_app_name
      : "TrainerFu";
    let iosAppUrl;
    if (jsonResp.ios_app_id) {
      iosAppUrl = `https://itunes.apple.com/us/app/trainerfu/id${jsonResp.ios_app_id}`;
    } else {
      iosAppUrl = "https://itunes.apple.com/us/app/trainerfu/id807834256";
    }
    let androidAppUrl;
    if (jsonResp.internal_app_id) {
      androidAppUrl = `https://play.google.com/store/apps/details?id=com.trainerfu.${jsonResp.internal_app_id}`;
    } else {
      androidAppUrl =
        "https://play.google.com/store/apps/details?id=com.trainerfu.android";
    }
    let subject = `Let's start using the ${appName} app`;
    let body = "";
    if (jsonResp.is_trainer) {
      body = buildInviteEmailForTrainer({
        firstName: jsonResp.first_name,
        appName: appName,
        iosAppUrl: iosAppUrl,
        androidAppUrl: androidAppUrl,
        email: jsonResp.email,
        password: jsonResp.password,
      });
    } else {
      body = buildInviteEmailForClient({
        firstName: jsonResp.first_name,
        appName: appName,
        iosAppUrl: iosAppUrl,
        androidAppUrl: androidAppUrl,
        email: jsonResp.email,
        password: jsonResp.password,
      });
    }
    window.open(
      `mailto:${jsonResp.email}?subject=${subject}&body=${body}`,
      "_blank"
    );
  }
}
export default function* sagas() {
  yield takeEvery(GET_CLIENTS.REQUEST, getClients);
  yield takeEvery(GET_ME.REQUEST, getMe);
  yield takeEvery(CREATE_CLIENT.REQUEST, createClient);
  yield takeEvery(DELETE_CLIENT.REQUEST, deleteClient);
  yield takeEvery(CREATE_INVITE.REQUEST, sendInvite);
  yield takeEvery(UPDATE_CLIENT.REQUEST, updateUserSettings);
  yield takeEvery(GET_CLIENT_STRIPE_ID.REQUEST, getClientStripeId);
  yield takeEvery(GET_EMAIL_INTEGRATION.REQUEST, getEmailIntegration);
  yield takeEvery(DELETE_EMAIL_INTEGRATION.REQUEST, deleteEmailIntegration);
  yield takeEvery(GET_STRIPE_URL.REQUEST, getStripeUrl);
  yield takeEvery(GET_APP_LINKS.REQUEST, getAppLinks);
  yield takeEvery(ADD_APP_LINK.REQUEST, addAppLink);
  yield takeEvery(SAVE_CLIENT_APP_SETTINGS.REQUEST, saveClientAppSettings);
  yield takeEvery(GET_CLIENT_APP_SETTINGS.REQUEST, getClientAppSettings);
  yield takeEvery(GET_NEWS_FEED_SETTINGS.REQUEST, getNewsFeedSettings);
  yield takeEvery(SAVE_NEWS_FEED_SETTINGS.REQUEST, saveNewsFeedSettings);
  yield takeEvery(
    GET_IN_APP_PURCHASE_SETTINGS.REQUEST,
    getInAppPurchaseSettings
  );
  yield takeEvery(
    SAVE_IN_APP_PURCHASE_SETTINGS.REQUEST,
    saveInAppPurchaseSettings
  );
  yield takeEvery(UPDATE_CLIENT_HABITS.REQUEST, updateClientHabits);
}
