import { t } from "../../services/utils";
import { GET_GROUPS, ADD_GROUP, UPDATE_GROUP, UPDATE_GROUP_SETTINGS, DELETE_GROUP, GET_GROUP_MEMBERS, UPDATE_GROUP_MEMBERS } from "../../constants/action-types";
import { takeEvery, put } from "redux-saga/effects";
import { getAPI, putAPI, postAPI, deleteAPI } from "../api";
function* getGroups(action) {
  yield* getAPI(action.type, "/segments");
}
function* addGroup(action) {
  const {
    members
  } = action.payload;
  const data = yield* putAPI(action.type, "/segments/new", {
    params: {
      ...action.payload
    },
    getSuccessPayload: resp => {
      return {
        id: resp.id,
        ...action.payload,
        is_default: false
      };
    }
  });
  if (members && members.length > 0) {
    yield put({
      type: UPDATE_GROUP_MEMBERS.REQUEST,
      payload: {
        id: data.jsonResp.id,
        members
      }
    });
  }
}
function* updateGroupSettings(action) {
  const {
    id
  } = action.payload;
  yield put({
    type: UPDATE_GROUP.SUCCESS,
    payload: action.payload
  });
  yield* postAPI(action.type, `/segments/${id}/settings`, {
    params: {
      ...action.payload
    }
  });
}
function* deleteGroup(action) {
  const {
    id
  } = action.payload;
  yield put({
    type: DELETE_GROUP.SUCCESS,
    payload: id
  });
  yield* deleteAPI(action.type, `/segments/${id}`);
}
function* getGroupMembers(action) {
  const {
    id
  } = action.payload;
  yield* getAPI(action.type, `/segments/${id}`, {
    getSuccessPayload: resp => ({
      id,
      members: resp.ids
    })
  });
}
function* updateGroupMembers(action) {
  const {
    id,
    members
  } = action.payload;
  yield* postAPI(action.type, `/segments/${id}/users`, {
    params: {
      ids: members
    }
  });
}
const sagas = function* sagas() {
  yield takeEvery(GET_GROUPS.REQUEST, getGroups);
  yield takeEvery(ADD_GROUP.REQUEST, addGroup);
  yield takeEvery(UPDATE_GROUP_SETTINGS.REQUEST, updateGroupSettings);
  yield takeEvery(DELETE_GROUP.REQUEST, deleteGroup);
  yield takeEvery(GET_GROUP_MEMBERS.REQUEST, getGroupMembers);
  yield takeEvery(UPDATE_GROUP_MEMBERS.REQUEST, updateGroupMembers);
};
export default sagas;