import { t } from "../../../services/utils";
import React from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";
class DownloadSupportedBrowser extends React.Component {
  downloadChrome = () => {
    window.open("https://www.google.com/chrome/browser/desktop/index.html", "_blank");
  };
  downloadFirefox = () => {
    window.open("https://www.mozilla.org/en-US/firefox/new", "_blank");
  };
  downloadSafari = () => {
    window.open("https://support.apple.com/downloads/safari", "_blank");
  };
  render = () => {
    const styles = {
      container: {
        maxWidth: 600,
        margin: "10px auto 10px auto"
      },
      ctaBtn: {
        paddingLeft: 8
      }
    };
    return <Card style={styles.container}>
        <CardHeader title={t("downloadSupportedBrowser")} />
        <CardContent>{t("yourBrowserIsCurrentlyNotSupportedPleaseDownloadOneOfTheSupportedBrowsersToAccessTrainerFuOnYourComputer")}</CardContent>
        <CardActions>
          <Button variant="text" color="primary" onClick={this.downloadChrome} style={styles.ctaBtn}>{t("downloadChrome")}</Button>
          <Button variant="text" color="primary" onClick={this.downloadFirefox}>{t("downloadFirefox")}</Button>
          <Button variant="text" color="primary" onClick={this.downloadSafari}>{t("downloadSafari")}</Button>
        </CardActions>
      </Card>;
  };
}
export default DownloadSupportedBrowser;