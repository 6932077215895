import { t } from "../../services/utils";
import { GET_TRAINERS, ADD_TRAINER, UPDATE_TRAINER, DELETE_TRAINER, SHOW_STATUS_BAR_MESSAGE } from "../../constants/action-types";
import { takeEvery, put } from "redux-saga/effects";
import { getAPI, deleteAPI, putAPI, postAPI } from "../api";
// import statusBarMessageTypes from "../../constants/status-bar-message-type";
import errorCodes from "../../constants/error-codes";
function getErrorMessage(error) {
  let message = 'Invalid values.';
  if (error.error_code == errorCodes.USER_WITH_EMAIL_EXIST) {
    message = 'User with this email already exists.';
  } else if (error.error_code == errorCodes.INVALID_FIRST_NAME || error.error_code == errorCodes.INVALID_LAST_NAME) {
    message = 'First name and last name cannot be empty.';
  } else if (error.error_code == errorCodes.INVALID_EMAIL) {
    message = 'Please enter a valid email address.';
  }
  return message;
}
function* getTrainers(action) {
  yield* getAPI(action.type, `/trainers`);
}
function* addTrainer(action) {
  const resp = yield* putAPI(action.type, `/users/new_trainer`, {
    params: action.payload,
    getSuccessPayload: resp => {
      return {
        id: resp.user_id,
        first_name: resp.first_name,
        last_name: action.payload.last_name,
        email: resp.email,
        phone_number: action.payload.phone,
        is_active: action.payload.is_active
      };
    },
    getErrorPayload: error => ({
      errorMessage: getErrorMessage(error)
    })
  });
}
function* updateTrainer(action) {
  const {
    id
  } = action.payload;
  const postResp = yield* postAPI(action.type, `/users/${id}/basic_info`, {
    params: action.payload,
    getSuccessPayload: resp => {
      return action.payload;
    },
    getErrorPayload: error => ({
      errorMessage: getErrorMessage(error)
    })
  });
}
function* deleteTrainer(action) {
  const {
    id
  } = action.payload;
  const response = yield* deleteAPI(action.type, `/users/${id}`, {
    getSuccessPayload: () => ({
      id
    })
  });
}
const sagas = function* sagas() {
  yield takeEvery(GET_TRAINERS.REQUEST, getTrainers);
  yield takeEvery(ADD_TRAINER.REQUEST, addTrainer);
  yield takeEvery(UPDATE_TRAINER.REQUEST, updateTrainer);
  yield takeEvery(DELETE_TRAINER.REQUEST, deleteTrainer);
};
export default sagas;