import { t } from "../../services/utils";
import { GET_LEADERBOARD_CHECKINS, GET_LEADERBOARD_PROGRESS_PHOTOS, GET_LEADERBOARD_WEIGHT_DATA, GET_LEADERBOARD_WORKOUTLOGGED, GET_ASSESSMENT_TYPES } from "../../constants/action-types";
export function leaderBoard(state = null, action) {
  switch (action.type) {
    case GET_ASSESSMENT_TYPES.SUCCESS:
      {
        return {
          ...state,
          ...action.payload
        };
      }
    case GET_LEADERBOARD_CHECKINS.SUCCESS:
      {
        return {
          ...state,
          ...action.payload
        };
      }
    case GET_LEADERBOARD_PROGRESS_PHOTOS.SUCCESS:
      {
        return {
          ...state,
          ...action.payload
        };
      }
    case GET_LEADERBOARD_WEIGHT_DATA.SUCCESS:
      {
        return {
          ...state,
          ...action.payload
        };
      }
    case GET_LEADERBOARD_WORKOUTLOGGED.SUCCESS:
      {
        return {
          ...state,
          ...action.payload
        };
      }
    default:
      return state;
  }
}