import { t } from "../../../services/utils";
import React from "react";
import { connect } from "react-redux";
import {
  GET_GROUPS,
  GET_TRAINERS,
  GET_CLIENTS,
  SAVE_FILTER,
} from "../../../constants/action-types";
import _ from "lodash";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core/styles";
import {
  primaryDark40,
  primaryDark80,
  primary5,
} from "../../../constants/colors";
import IconMoon from "../../../components/IconMoon";
const styles = {
  root: {
    padding: "10px 18px 10px 20px",
    lineHeight: "20px",
  },
  icon: {
    right: "5px !important",
  },
  select: {
    // padding: "7px 16px 7px 20px",
  },
  menuItem: {
    "&:hover": {
      backgroundColor: primary5,
    },
  },
  menuItemSelected: {
    backgroundColor: primary5 + " !important",
  },
};
class ClientsAndGroupsFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      forClient: false,
      forGroup: true,
      id: props.groupId,
      selectValue: "",
      filterValue: "",
    };
  }
  componentDidMount = () => {
    let fromGroup = this.props.router.location?.query?.fromGroup;
    let fromClients = this.props.router.location?.query?.fromClients;
    if (_.isEmpty(this.props.clients)) {
      this.props.getClients();
    }
    if (_.isEmpty(this.props.trainers)) {
      this.props.getTrainers();
    }
    if (_.isEmpty(this.props.groups)) {
      this.props.getGroups();
    }
    let filterFromNavigation = false;
    let filter, filterToApply;

    // if filter is there in props use that otherwise use filter from navigation

    if (this.props.clientId) {
      filterToApply = {
        forClient: true,
        forGroup: false,
        id: this.props.clientId,
      };
    } else {
      if (fromGroup) {
        filterToApply = {
          forClient: false,
          forGroup: true,
          id: this.props.groupId,
        };
      } else {
        if (this.props.filter) {
          filterToApply = this.props.filter;
          filterFromNavigation = false;
        } else if (
          this.props.groups[this.props.groupId] ||
          this.props.groupId === 0
        ) {
          filterFromNavigation = true;
          filterToApply = {
            forClient: false,
            forGroup: true,
            id: this.props.groupId,
          };
        } else {
          filterToApply = {
            forClient: false,
            forGroup: true,
            id: this.props.groupId,
          };
        }
      }
    }
    const filterValue = ClientsAndGroupsFilter.getSelectKey(
      filterToApply.forClient,
      filterToApply.forGroup,
      filterToApply.id
    );
    this.setState({
      filterValue,
    });
    this.props.saveFilter(filterToApply);
    this.props.onFilterChange(filterToApply);
  };

  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  componentWillReceiveProps(nextProps) {
    let filterToApply;
    if (!this.props.groupId && nextProps.groupId) {
      filterToApply = {
        forClient: false,
        forGroup: true,
        id: nextProps.groupId,
      };
      const filterValue = ClientsAndGroupsFilter.getSelectKey(
        filterToApply.forClient,
        filterToApply.forGroup,
        filterToApply.id
      );
      this.setState({
        filterValue,
      });
      this.props.saveFilter(filterToApply);
      this.props.onFilterChange(filterToApply);
    }
    if (!this.props.clientId && nextProps.clientId) {
      filterToApply = {
        forClient: true,
        forGroup: false,
        id: nextProps.clientId,
      };
      this.setState({
        filterValue: ClientsAndGroupsFilter.getSelectKey(
          filterToApply.forClient,
          filterToApply.forGroup,
          filterToApply.id
        ),
      });
      this.props.saveFilter(filterToApply);
      this.props.onFilterChange(filterToApply);
    }
  }
  handleChange = (e, child) => {
    const val = e.target.value;
    this.setState({
      filterValue: val,
    });
    const filter = ClientsAndGroupsFilter.getObjectFromKey(val);
    this.props.onFilterChange(filter);
    this.props.saveFilter(filter);
  };
  render = () => {
    const { classes } = this.props;
    const { filterValue } = this.state;
    const styles = {
      empty: {
        height: 62,
        width: this.props.width,
      },
      header: {
        color: primaryDark40,
        marginTop: 10,
        display: "flex",
        alignItems: "center",
        opacity: 1,
        fontSize: 14,
        lineHeight: "20px",
        fontWeight: "500",
        padding: "10px 16px 10px 16px",
      },
      selectBox: {
        width: "340px",
      },
      filter: {
        display: "flex",
        backgroundColor: "white",
        fontSize: 14,
        border: "1px solid #CCC9D5",
        borderRadius: 6,
        width: 160,
      },
      menuItem: {
        color: primaryDark80,
        borderBottom: "1px solid #EAE9EF",
        padding: "10px 16px 10px 16px",
        fontSize: 14,
        lineHeight: "20px",
        fontWeight: "500",
      },
    };
    if (_.isEmpty(this.props.clients) || _.isEmpty(this.props.groups)) {
      return <div style={styles.empty} />;
    }
    let items = [
      <MenuItem
        key={"gr-0"}
        value={"gr-0"}
        style={styles.menuItem}
        className={classes.menuItem}
        classes={{
          root: classes.menuItem,
          selected: classes.menuItemSelected,
        }}
      >
        {t("everyone")}
      </MenuItem>,
    ];
    items.push(
      <MenuItem key={"1"} value={"1"} disabled={true} style={styles.header}>
        <IconMoon
          icon="User"
          style={{
            color: primaryDark40,
          }}
        />
        <span
          style={{
            marginLeft: 12,
          }}
        >
          {t("groups")}
        </span>
      </MenuItem>
    );
    items.push(<Divider key={"2"} />);
    let groups = Object.values(this.props.groups);
    if (this.props.filterGroups) {
      groups = groups.filter((group) => !group.for_tm_only);
    }
    for (let i = 0; i < groups.length; i++) {
      let group = groups[i];
      let key = ClientsAndGroupsFilter.getSelectKey(false, true, group.id);
      items.push(
        <MenuItem
          key={key}
          value={key}
          style={styles.menuItem}
          classes={{
            root: classes.menuItem,
            selected: classes.menuItemSelected,
          }}
        >
          {group.name}
        </MenuItem>
      );
    }
    items.push(
      <MenuItem key={"3"} value={"3"} disabled={true} style={styles.header}>
        <IconMoon
          icon="Users"
          style={{
            color: primaryDark40,
          }}
        />
        <span
          style={{
            marginLeft: 12,
          }}
        >
          {t("clients")}
        </span>
      </MenuItem>
    );
    items.push(<Divider key={"4"} />);
    let clients = Object.values(this.props.clients);
    for (let i = 0; i < clients.length; i++) {
      let client = clients[i];
      let key = ClientsAndGroupsFilter.getSelectKey(true, false, client.id);
      items.push(
        <MenuItem
          key={key}
          value={key}
          style={styles.menuItem}
          classes={{
            root: classes.menuItem,
            selected: classes.menuItemSelected,
          }}
        >
          {`${client.first_name} ${client.last_name}`}
        </MenuItem>
      );
    }
    return (
      <div>
        <Select
          value={filterValue}
          style={{
            ...styles.filter,
            ...this.props.style,
          }}
          onChange={this.handleChange}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "bottom",
            },
            MenuListProps: {
              style: {
                padding: 0,
                height: 300,
              },
            },
          }}
          classes={{
            root: this.props.classes.root,
            icon: this.props.classes.icon,
          }}
          disableUnderline={true}
        >
          {items}
        </Select>
      </div>
    );
  };
  static getSelectKey(forClient, forGroup, id) {
    if (forClient) {
      return `cl-${id}`;
    } else {
      return `gr-${id}`;
    }
  }
  static getObjectFromKey(key) {
    if (key === "all") {
      return {
        forAll: true,
        forTrainer: false,
        id: 0,
      };
    } else {
      let split = key.split("-");
      if (split[0] === "cl") {
        return {
          forClient: true,
          forGroup: false,
          id: parseInt(split[1]),
        };
      } else {
        return {
          forClient: false,
          forGroup: true,
          id: parseInt(split[1]),
        };
      }
    }
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getTrainers: () => {
      dispatch({
        type: GET_TRAINERS.REQUEST,
      });
    },
    getGroups: () => {
      dispatch({
        type: GET_GROUPS.REQUEST,
      });
    },
    getClients: () => {
      dispatch({
        type: GET_CLIENTS.REQUEST,
      });
    },
    saveFilter: (filter) => {
      dispatch({
        type: SAVE_FILTER,
        payload: filter,
      });
    },
  };
};
const mapStateToProps = (state) => {
  return {
    clients: state.clients,
    trainers: state.trainers,
    groups: state.groups,
    me: state.me,
    router: state.router,
    filter: state.registers.filter,
  };
};
export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ClientsAndGroupsFilter)
);
