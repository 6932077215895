import { t } from "../../services/utils";
import { GET_CLIENT_SCHEDULED_MESSAGES, TOGGLE_SKIP_STATUS, ADD_SCHEDULED_MESSAGE, UPDATE_SCHEDULED_MESSAGE, DELETE_SCHEDULED_MESSAGE } from "../../constants/action-types";
import { takeEvery, put } from "redux-saga/effects";
import { getAPI, putAPI, postAPI, deleteAPI } from "../api";
function* getClientScheduledMessages(action) {
  const {
    userId
  } = action.payload;
  const data = yield* getAPI(action.type, `/users/${userId}/scheduled_triggered_messages`, {
    getSuccessPayload: resp => {
      const {
        scheduled_triggered_messages
      } = resp;
      let scheduledMessages = {};
      scheduled_triggered_messages.map(message => {
        scheduledMessages[message.id] = message;
      });
      return {
        [userId]: scheduledMessages
      };
    }
  });
}
function* addScheduledMessage(action) {
  yield* putAPI(action.type, "/triggered_messages/new", {
    params: action.payload,
    getSuccessPayload: resp => {
      return {
        ...action.payload,
        id: resp.id
      };
    }
  });
}
function* updateScheduledMessage(action) {
  const {
    id
  } = action.payload;
  yield* postAPI(action.type, `/triggered_messages/${id}`, {
    params: action.payload,
    getSuccessPayload: resp => {
      return {
        ...action.payload,
        id: id
      };
    }
  });
}
function* deleteScheduledMessage(action) {
  const {
    id
  } = action.payload;
  yield* deleteAPI(action.type, `/scheduled_triggered_messages/${id}`, {
    params: action.payload,
    getSuccessPayload: resp => {
      return {
        ...action.payload,
        id: id
      };
    }
  });
}
function* toggleSkipStatus(action) {
  const {
    skip,
    id
  } = action.payload;
  const data = yield* postAPI(action.type, `/scheduled_triggered_messages/${id}/skip_status`, {
    params: {
      skip
    },
    getSuccessPayload: resp => {
      return {
        ...action.payload,
        id: id
      };
    }
  });
}
const sagas = function* sagas() {
  yield takeEvery(GET_CLIENT_SCHEDULED_MESSAGES.REQUEST, getClientScheduledMessages);
  yield takeEvery(TOGGLE_SKIP_STATUS.REQUEST, toggleSkipStatus);
  yield takeEvery(ADD_SCHEDULED_MESSAGE.REQUEST, addScheduledMessage);
  yield takeEvery(UPDATE_SCHEDULED_MESSAGE.REQUEST, updateScheduledMessage);
  yield takeEvery(DELETE_SCHEDULED_MESSAGE.REQUEST, deleteScheduledMessage);
};
export default sagas;