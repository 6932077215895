import { t } from "../services/utils";
import React from "react";
import IcoMoon from "react-icomoon";
import iconSet from "./selection2.json";
const IconMoon = ({ ...props }) => {
  let { icon, ...rest } = props;
  icon = icon.toLowerCase();
  return <IcoMoon iconSet={iconSet} size={20} icon={icon} {...rest} />;
};
export default IconMoon;
