import { t } from "../../services/utils";
import {
  GET_ASSESSMENTS,
  ADD_CUSTOM_ASSESSMENT,
  DELETE_CUSTOM_ASSESSMENT,
  ADD_ASSESSMENT_MEASURE,
  DELETE_ASSESSMENT_MEASURE,
  SET_GOAL,
  DELETE_GOAL,
  GET_EXERCISE_PROGRESSION,
  GET_EX_PROGRESSION_DETAILS,
  POST_PROCESS_ASSESSMENT,
  GET_PROGRESS_PHOTOS,
  GET_CHECKINS,
  ADD_PROGRESS_PHOTO,
  GET_CLIENT_DAILY_NUTRITION,
  SAVE_CLIENT_DAILY_NUTRITION,
} from "../../constants/action-types";
import { takeEvery, put } from "redux-saga/effects";
import { getAPI, putAPI, postAPI, deleteAPI } from "../api";
import moment from "moment";
function* getAssessements(action) {
  const { userId } = action.payload;
  const today = new Date().toISOString().slice(0, 10);
  const data = yield* getAPI(action.type, `/users/${userId}/progress`, {
    params: {
      today: today,
    },
    getSuccessPayload: (resp) => {
      const {
        custom,
        girth,
        weight,
        bfp,
        sites,
        progress_photos,
        sleep,
        steps,
        water,
      } = resp;
      const customAssments = {};
      custom.map((assessment) => (customAssments[assessment.id] = assessment));
      const girthAssesments = {};
      girth.map((assessment) => (girthAssesments[assessment.id] = assessment));
      const siteAssessments = {};
      sites.map((assessment) => (siteAssessments[assessment.id] = assessment));
      return {
        // ...resp,
        clientId: userId,
        weight: {
          [weight.id]: weight,
        },
        bfp: {
          [bfp.id]: bfp,
        },
        custom: customAssments,
        girth: girthAssesments,
        sites: siteAssessments,
        progressPhotosCount: progress_photos,
        nutrition: [],
        sleep: {
          [sleep.id]: sleep,
        },
        steps: {
          [steps.id]: steps,
        },
        water: {
          [water.id]: water,
        },
      };
    },
  });
}
function* addCustomAssessment(action) {
  const { name } = action.payload;
  const data = yield* putAPI(action.type, "/assessment_types/new", {
    params: {
      name,
    },
    getSuccessPayload: (resp) => {
      return {
        name,
        id: resp.id,
      };
    },
  });
  if (data.ok && action.payload.onSuccess) {
    action.payload.onSuccess();
  }
}
function* deleteCustomAssessment(action) {
  const { id, type } = action.payload;
  yield* postAPI(action.type, `/assessment_types/${id}`, {
    params: {
      is_active: false,
    },
    getSuccessPayload: (resp) => {
      return {
        id,
        type,
      };
    },
  });
}
function* addAssessmentMeasure(action) {
  const { clientId } = action.payload;
  const data = yield* putAPI(
    action.type,
    `/users/${clientId}/assessment_measures/new`,
    {
      params: {
        ...action.payload,
      },
      getSuccessPayload: (resp) => {
        console.log(resp);
        return {
          ...action.payload,
          id: resp.id,
        };
      },
      getErrorPayload: (resp) => {
        return {
          error_code: resp.error_code,
          request_payload: action.payload,
        };
      },
    }
  );
  if (data.ok) {
    yield put({
      type: POST_PROCESS_ASSESSMENT.REQUEST,
      payload: {
        id: data.jsonResp.id,
      },
    });
  }
}
function* deleteAssessmentMeasure(action) {
  const { measureId } = action.payload;
  yield* deleteAPI(action.type, `/assessment_measures/${measureId}`, {
    getSuccessPayload: (resp) => {
      return action.payload;
    },
  });
}
function* setGoal(action) {
  const { clientId } = action.payload;
  yield* putAPI(action.type, `/users/${clientId}/goals/new`, {
    params: {
      ...action.payload,
    },
    getSuccessPayload: (resp) => {
      return {
        ...action.payload,
        id: resp.id,
      };
    },
  });
}
function* deleteGoal(action) {
  const { clientId, goalId, type } = action.payload;
  yield* deleteAPI(action.type, `/users/${clientId}/goals/${goalId}`, {
    getSuccessPayload: (resp) => {
      return action.payload;
    },
  });
}
function* postProcessAssessment(action) {
  yield* putAPI(action.type, "/tasks/new/post_process_assessment_story_task", {
    params: {
      id: action.payload.id,
    },
  });
}
function* getExerciseProgression(action) {
  const { clientId } = action.payload;
  const today = new Date().toISOString().slice(0, 10);
  yield* getAPI(
    action.type,
    `/users/${clientId}/exercise_stats/logged_strength_exercises`,
    {
      params: {
        date: today,
      },
      getSuccessPayload: (resp) => {
        const exercise_progression = {};
        resp.exercises.map((ex) => (exercise_progression[ex.exercise_id] = ex));
        return {
          exercise_progression,
        };
      },
    }
  );
}
function* getExProgressionDetails(action) {
  const { clientId, exerciseId } = action.payload;
  const today = new Date().toISOString().slice(0, 10);
  yield* getAPI(
    action.type,
    `/users/${clientId}/exercise_stats/${exerciseId}`,
    {
      params: {
        date: today,
      },
      getSuccessPayload: (resp) => ({
        exercise_id: exerciseId,
        ...resp,
      }),
    }
  );
}
function* getProgressPhotos(action) {
  const { clientId } = action.payload;
  const data = yield* getAPI(
    action.type,
    `/users/${clientId}/progress_photos`,
    {
      getSuccessPayload: (resp) => ({
        progress_photos: resp.progress_photos,
      }),
    }
  );
}
function* addProgressPhoto(action) {
  const { clientId } = action.payload;
  const { fileInfo } = action.payload;
  const data = yield* postAPI(action.type, `/users/${clientId}/photos/new`, {
    params: {
      photo_type: 4,
      is_public: false,
      image_url: fileInfo.cdnUrl,
      image_width: fileInfo.originalImageInfo.width,
      image_height: fileInfo.originalImageInfo.height,
      date: moment().format("YYYY-MM-DD"),
    },
    getSuccessPayload: (resp) => ({
      clientId: action.payload.clientId,
      progressPhoto: {
        date: moment().format("YYYY-MM-DD"),
        photo_id: resp.id,
        note: null,
        bfp: null,
        weight: null,
      },
    }),
  });
}
function* getCheckins(action) {
  const urlSuffix = process.env.NODE_ENV == "stage" ? "dummy" : "";
  const { userId } = action.payload;
  const data = yield* getAPI(action.type, `/users/${userId}/checkins`, {
    getSuccessPayload: (resp) => ({
      checkins: resp.checkins,
    }),
  });
}
function* getClientDailyNutrition(action) {
  const { userId } = action.payload;
  const data = yield* getAPI(action.type, `/users/${userId}/nutrition_plan`, {
    getSuccessPayload: (resp) => ({
      nutrition: resp[0],
    }),
  });
}
function* saveClientDailyNutrition(action) {
  const { userId, nutrition } = action.payload;
  const data = yield* postAPI(
    action.type,
    `/users/${userId}/nutrition_plan/0`,
    {
      params: {
        ...nutrition,
      },
      getSuccessPayload: (resp) => {
        return action.payload;
      },
    }
  );
}
const sagas = function* sagas() {
  yield takeEvery(GET_ASSESSMENTS.REQUEST, getAssessements);
  yield takeEvery(ADD_CUSTOM_ASSESSMENT.REQUEST, addCustomAssessment);
  yield takeEvery(DELETE_CUSTOM_ASSESSMENT.REQUEST, deleteCustomAssessment);
  yield takeEvery(ADD_ASSESSMENT_MEASURE.REQUEST, addAssessmentMeasure);
  yield takeEvery(DELETE_ASSESSMENT_MEASURE.REQUEST, deleteAssessmentMeasure);
  yield takeEvery(SET_GOAL.REQUEST, setGoal);
  yield takeEvery(DELETE_GOAL.REQUEST, deleteGoal);
  yield takeEvery(GET_EXERCISE_PROGRESSION.REQUEST, getExerciseProgression);
  yield takeEvery(GET_EX_PROGRESSION_DETAILS.REQUEST, getExProgressionDetails);
  yield takeEvery(POST_PROCESS_ASSESSMENT.REQUEST, postProcessAssessment);
  yield takeEvery(GET_PROGRESS_PHOTOS.REQUEST, getProgressPhotos);
  yield takeEvery(ADD_PROGRESS_PHOTO.REQUEST, addProgressPhoto);
  yield takeEvery(GET_CHECKINS.REQUEST, getCheckins);
  yield takeEvery(GET_CLIENT_DAILY_NUTRITION.REQUEST, getClientDailyNutrition);
  yield takeEvery(
    SAVE_CLIENT_DAILY_NUTRITION.REQUEST,
    saveClientDailyNutrition
  );
};
export default sagas;
