import { t } from "../../services/utils";
import { getAPI, postAPI } from "../api";
import { GET_SUBSCRIPTION, GET_UPDATE_SUBSCRIPTION_PREVIEW, UPDATE_SUBSCRIPTION } from "../../constants/action-types";
import { takeEvery } from "redux-saga/effects";
function* getSubscription(action) {
  yield* getAPI(action.type, `/subscription`);
}
function* updateSubscription(action) {
  yield* postAPI(action.type, `/subscription`, {
    params: action.payload,
    getSuccessPayload: resp => {
      return {
        ...resp,
        token: action.payload.token
      };
    },
    getErrorPayload: resp => {
      return {
        ...resp,
        token: action.payload.token
      };
    }
  });
}
function* getUpdateSubscriptionPreview(action) {
  yield* getAPI(action.type, `/subscription/preview`, {
    params: {
      new_plan: action.payload.newPlan
    },
    getSuccessPayload: resp => {
      return {
        token: action.payload.token,
        amount: resp.amount
      };
    }
  });
}
const sagas = function* sagas() {
  yield takeEvery(GET_SUBSCRIPTION.REQUEST, getSubscription);
  yield takeEvery(GET_UPDATE_SUBSCRIPTION_PREVIEW.REQUEST, getUpdateSubscriptionPreview);
  yield takeEvery(UPDATE_SUBSCRIPTION.REQUEST, updateSubscription);
};
export default sagas;