import { t } from "./utils";
import moment from 'moment';
export function toDateFromISO(fmtDate) {
  return moment(fmtDate).toDate();
}
export function toMomentFromISO(fmtDate) {
  return moment(fmtDate);
}
export function displayDateFromISO(fmtDate) {
  return moment(fmtDate).format("ll");
}
export function toISOfromMoment(m) {
  return m.format('YYYY-MM-DD');
}
export function toISOfromDate(date) {
  return moment(date).format('YYYY-MM-DD');
}
export function today() {
  let today = new Date();
  today.setHours(0, 0, 0, 0);
  return today;
}
export function todayM() {
  let t = today();
  return moment(t);
}
export function nextMonthDate() {
  return moment().add(1, 'months');
}
export function dateByMonth(monthAgo) {
  return moment().add(monthAgo, 'months').format('YYYY-MM-DD');
}
export function displayDate(d) {
  return moment(d).format("D MMM, YYYY");
}