import { t } from "../../services/utils";
import { GET_CLIENT_SCHEDULED_MESSAGES, ADD_SCHEDULED_MESSAGE, TOGGLE_SKIP_STATUS, DELETE_SCHEDULED_MESSAGE } from "../../constants/action-types";
export function scheduledMessages(state = null, action) {
  switch (action.type) {
    case GET_CLIENT_SCHEDULED_MESSAGES.SUCCESS:
      {
        return {
          ...state,
          ...action.payload
        };
      }
    case TOGGLE_SKIP_STATUS.SUCCESS:
      {
        return {
          ...state,
          [action.payload.userId]: {
            ...state[action.payload.userId],
            [action.payload.id]: {
              ...state[action.payload.userId][action.payload.id],
              skipped: action.payload.skip
            }
          }
        };
      }
    case DELETE_SCHEDULED_MESSAGE.SUCCESS:
      {
        let clone = Object.assign({}, state[action.payload.userId]);
        delete clone[action.payload.id];
        return {
          ...state,
          [action.payload.userId]: clone
        };
      }
    default:
      return state;
  }
}