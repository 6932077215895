import { t } from "../../../services/utils";
import React from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";
class DownloadApp extends React.Component {
  download = () => {
    window.open("https://app.trainerfu.com/ul/app_home", "_blank");
  };
  render = () => {
    const styles = {
      container: {
        maxWidth: 600,
        margin: "10px auto 10px auto"
      },
      ctaBtn: {
        paddingLeft: 8
      }
    };
    return <div>
        <Card style={styles.container}>
          <CardHeader title={t("downloadMobileApp")} />
          <CardContent>{t("trainerFuWebAppIsOnlyAvailableWhenViewedFromDesktopComputerOrTabletToGetBestExperienceOnMobilePhonePleaseDownloadOurMobileApp")}</CardContent>
          <CardActions>
            <Button variant="text" color="primary" onClick={this.download} style={styles.ctaBtn}>{t("downloadMobileApp")}</Button>
          </CardActions>
        </Card>
      </div>;
  };
}
export default DownloadApp;