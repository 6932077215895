import { t } from "../../services/utils";
import { grey900, primaryDark60, grey600, primaryDark5, primaryDark20, primaryDark10 } from "../../constants/colors";
const styles = {
  win: {
    display: "flex",
    marginTop: -57,
    overflow: "hidden",
    boxShadow: "inset 0 0 0 1000px #EAE9EF"
  },
  scene: {
    backgroundColor: primaryDark5,
    marginTop: 57,
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    overflow: "auto"
  },
  container: {
    display: "flex",
    width: 805,
    maxWidth: 805,
    flexDirection: "column",
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: 30,
    paddingTop: 20,
    paddingLeft: 2.5,
    paddingRight: 2.5
  },
  toolbar: {
    display: "flex",
    marginTop: 20,
    marginBottom: 40,
    // alignSelf: "flex-end",
    justifyContent: "space-between"
  },
  pageTitle: {
    display: "flex",
    alignItems: "center"
  },
  toolbarButton: {
    marginLeft: 20,
    height: 40
    // width: 160,
  },

  form: {
    marginTop: 20,
    padding: 20
  },
  textField: {
    marginTop: 16
  },
  groupsContainer: {
    marginTop: 10
  },
  subLabel: {
    color: primaryDark60,
    marginTop: 5,
    marginLeft: 2
  },
  planTemplateHint: {
    color: primaryDark60,
    marginTop: -5
  },
  primaryTrainerHint: {
    color: primaryDark60,
    marginTop: -5
  },
  paymentCheckbox: {
    marginTop: 40
  },
  selectHintStyle: {
    color: grey900
  },
  link: {
    marginTop: 0,
    color: grey900,
    textDecoration: "underline",
    cursor: "pointer"
  },
  paypalContainer: {
    display: "flex",
    flexDirection: "column"
  },
  radioButton: {
    width: "unset",
    padding: 8,
    marginBottom: 0
    // paddingTop: 0,
    // paddingBottom: 0,
  },

  note: {
    color: primaryDark60,
    padding: "16px 20px",
    backgroundColor: "#f4f4f4",
    marginTop: 40,
    borderRadius: 4,
    borderLeft: "4px solid #FFD400"
  },
  amountContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // marginTop: 30,
    flexWrap: "wrap"
  },
  paymentAmount: {
    width: 300,
    backgroundColor: "white"
    // marginRight: 30,
    // marginTop: 30,
  },

  field: {
    width: "100%",
    marginBottom: 20,
    marginTop: 20
  },
  attachments: {
    marginTop: 10,
    display: "flex",
    flexWrap: "wrap"
  },
  fileChip: {
    marginRight: 5
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "20px"
  },
  dateInput: {
    width: "270px",
    borderWidth: 1,
    borderStyle: "dashed",
    marginLeft: 15,
    paddingLeft: 10,
    borderColor: "#d9d9d9 #ccc #b3b3b3"
  },
  error: {
    fontSize: 12,
    color: "rgb(244, 67, 54)",
    marginBottom: 10
  },
  sectionHeading: {
    fontSize: 14,
    fontWeight: "500",
    color: "#2A234E",
    lineHeight: "20px"
  },
  sectionSubHeading: {
    fontSize: 14,
    fontWeight: "500",
    color: primaryDark60,
    lineHeight: "20px"
  },
  richEditor: {
    borderRadius: 4,
    borderColor: "#A0A0A0",
    marginTop: 35
  },
  menuItem: {
    // paddingTop: 10,
    // paddingBottom: 10,
    padding: "16px 20px",
    borderBottom: `1px solid ${primaryDark10}`,
    fontSize: 14,
    fontWeight: "500"
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    backgroundColor: "white",
    marginTop: 10,
    borderRadius: 6,
    padding: "15px 20px",
    border: `1px solid ${primaryDark20}`,
    fontSize: 14,
    fontWeight: "500"
  },
  selectBoxRight: {
    width: "110px",
    border: "1px solid #BBBBBB",
    marginLeft: 20,
    backgroundColor: "transparent",
    height: 42,
    paddingLeft: 15
  },
  divider: {
    // marginLeft: -40,
    // marginRight: -40,
    marginTop: 24,
    marginBottom: 24
  },
  groupRow: {
    marginBottom: 10
  },
  selectedListItem: {
    backgroundColor: "#E8E9F5"
  },
  icon: {
    fontSize: 20,
    color: grey600,
    marginRight: 20,
    cursor: "pointer"
  },
  stepBtn: {
    minHeight: 26,
    width: 124,
    padding: "4px 25px"
  },
  ctaContainer: {
    marginTop: 20,
    display: "flex",
    flexDirection: "row-reverse"
  },
  ctaButton: {
    marginLeft: 8
  },
  title: {
    fontFamily: "'Inter', sans-serif",
    fontSize: 22
  },
  coverImageContainer: {
    marginTop: 24
  },
  coverVideoContainer: {
    marginTop: 24
  },
  imageContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#f3f3f3",
    borderRadius: 5,
    marginTop: 35,
    overflow: "hidden"
  },
  coverImage: {
    // height: 450
    width: "100%"
  },
  deleteIcon: {
    position: "absolute",
    right: 0,
    marginTop: 16,
    marginRight: 15,
    fontSize: 22,
    cursor: "pointer",
    backgroundColor: grey900,
    display: "flex",
    padding: 5,
    borderRadius: 3
  },
  playBtn: {
    height: 56,
    width: 75,
    position: "absolute",
    top: "40%",
    padding: 0,
    cursor: "pointer"
  },
  selectTextField: {
    width: 130,
    marginRight: 10,
    marginLeft: 10
    // marginTop: 30,
  },

  row: {
    display: "flex",
    alignItems: "center"
  },
  paymentListItem: {
    display: "flex",
    cursor: "pointer",
    marginTop: 16,
    borderRadius: 6,
    border: `1px solid ${primaryDark20}`,
    fontSize: 14,
    fontWeight: "500",
    overflow: "hidden",
    flexDirection: "column"
  },
  paymentFrequency: {
    padding: "16px 20px",
    display: "flex",
    alignItems: "center"
  },
  paymentInfoContainer: {
    padding: "16px 20px",
    borderTop: `1px solid ${primaryDark20}`,
    backgroundColor: "#FAFAFB",
    display: "flex"
  },
  paymentInputs: {
    flex: 1,
    backgroundColor: "white"
  }
};
export default styles;