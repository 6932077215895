import { t } from "../services/utils";
export default {
  WORKOUT_LOG_UPDATED: 1,
  WORKOUT_PLAN_UPDATED: 2,
  MEAL_LOG_UPDATED: 3,
  FAVOURITED: 4,
  ACHIEVEMENT_UNLOCKED: 5,
  WORKOUT_LOG_COMMENT_ADDED: 6,
  INTERESTING_STATS_FOUND: 7,
  PHOTO_LOG_COMMENT_ADDED: 8,
  WORKOUT_LOG_COMMENT_ADDED_2: 9,
  NEW_MESSAGE: 10,
  NEW_LEAD: 11,
  STORY_LIKED: 12,
  STORY_COMMENT_ADDED: 13,
  POST_STORY_ADDED: 14,
  MFP_CONNECT_DISABLED: 15,
  MFP_CONNECT_FAILED: 16,
  CLIENT_IGNORED_INVITE: 17,
  CLIENT_FIRST_LOGIN: 18,
  TEST_DRIVE_CLIENT_APP: 19,
  TEST_DRIVE_MOBILE_APP: 20,
  TEST_DRIVE_WEB_APP: 21,
  NEW_CLIENT_SIGNUP: 22,
  TRAINERFU_ENGAGEMENT_MESSAGE: 23,
  PLAN_TEMPLATE_COPIED: 24,
  GMAIL_API_TOKEN_EXPIRED: 25,
  CLIENT_CANCELLED_SUBSCRIPTION: 26,
  DELETE_CLIENT_ACCOUNT_REQUEST: 27,
  CLIENT_ACCOUNT_DEACTIVATED_IAP: 28,
  CLIENT_ACCOUNT_ACCESS_EXTENDED_IAP: 29,
  CLIENT_ACCOUNT_REACTIVATED_IAP: 30,
  IAP_EXPIRED_CLIENT_ACCOUNT_NOT_DEACTIVATED: 31
};