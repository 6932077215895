import { t } from "../../services/utils";
import { GET_TRIGGERED_MESSAGES, ADD_TRIGGERED_MESSAGE, UPDATE_TRIGGERED_MESSAGE, DELETE_TRIGGERED_MESSAGE, SEND_GROUP_MESSAGE, PAUSE_MESSAGE, PUT_EMAIL_INTEGRATION } from "../../constants/action-types";
import { takeEvery, put } from "redux-saga/effects";
import { getAPI, putAPI, postAPI, deleteAPI } from "../api";
function* getTriggeredMessages(action) {
  yield* getAPI(action.type, "/triggered_messages/ver2");
}
function* addTriggeredMessage(action) {
  yield* putAPI(action.type, "/triggered_messages/new", {
    params: action.payload,
    getSuccessPayload: resp => {
      return {
        ...action.payload,
        id: resp.id
      };
    }
  });
}
function* sendGroupMessage(action) {
  let url = `/segments/${action.payload.segmentId}/new_message`;
  if (action.payload.segmentId == 0) {
    url = "/clients/new_message";
  }
  yield* putAPI(action.type, url, {
    params: action.payload,
    getSuccessPayload: resp => {
      return {
        ...action.payload,
        id: resp.id
      };
    }
  });
}
function* updateTriggeredMessage(action) {
  const {
    id
  } = action.payload;
  yield* postAPI(action.type, `/triggered_messages/${id}`, {
    params: action.payload,
    getSuccessPayload: resp => {
      return {
        ...action.payload,
        id: id
      };
    }
  });
}
function* pauseMessage(action) {
  const {
    messageId,
    is_active
  } = action.payload;
  yield* postAPI(action.type, `/triggered_messages/${messageId}/active_status`, {
    params: action.payload,
    getSuccessPayload: resp => {
      return {
        ...action.payload,
        id: messageId
      };
    }
  });
}
function* deleteTriggeredMessage(action) {
  const {
    id
  } = action.payload;
  yield put({
    type: DELETE_TRIGGERED_MESSAGE.SUCCESS,
    payload: id
  });
  yield* deleteAPI(action.type, `/triggered_messages/${id}`);
}
function* putEmailIntegration(action) {
  yield* putAPI(action.type, `/email_integration`, {
    params: action.payload,
    getSuccessPayload: resp => {
      console.log("response on success on email integration", resp);
    }
  });
}
const sagas = function* sagas() {
  yield takeEvery(GET_TRIGGERED_MESSAGES.REQUEST, getTriggeredMessages);
  yield takeEvery(ADD_TRIGGERED_MESSAGE.REQUEST, addTriggeredMessage);
  yield takeEvery(PAUSE_MESSAGE.REQUEST, pauseMessage);
  yield takeEvery(SEND_GROUP_MESSAGE.REQUEST, sendGroupMessage);
  yield takeEvery(UPDATE_TRIGGERED_MESSAGE.REQUEST, updateTriggeredMessage);
  yield takeEvery(DELETE_TRIGGERED_MESSAGE.REQUEST, deleteTriggeredMessage);
  yield takeEvery(PUT_EMAIL_INTEGRATION.REQUEST, putEmailIntegration);
};
export default sagas;