import { t } from "../../services/utils";
import _ from "lodash";
import { REGISTER_PROGRAM_DAY_FOR_COPY, REGISTER_PROGRAM_FOR_COPY, REGISTER_PROGRAM_VIEW_EXIT_FOCUS_DAY, REMOVE_REGISTERED_PROGRAM_VIEW_EXIT_FOCUS_DAY, REGISTER_CLIENTS_VIEW_FILTER, REGISTER_CLIENTS_VIEW_EXIT_FOCUS_ID, PROVISION_NEW_COMPANY, REGISTER_DID_DISMISS_SAMPLE_CLIENT_TIP, REGISTER_DID_DISMISS_SAMPLE_FORM_TIP, REGISTER_DID_OPEN_MAIN_MENU, REGISTER_SHOW_WORKOUT_DETAILS, REGISTER_FOR_POWER_EDITOR, REGISTER_DID_DISMISS_RESOURCES, SAVE_SIGNUP_FORM, GET_EXERCISES, SAVE_FILTER, REGISTER_DRILL_TO_COPY, REGISTER_FOR_CURRENT_AND_UPCOMING } from "../../constants/action-types";
export function registers(state = {
  forCurrentAndUpcoming: true
}, action) {
  switch (action.type) {
    case REGISTER_PROGRAM_DAY_FOR_COPY:
      {
        return {
          ...state,
          programDayForCopy: action.payload
        };
      }
    case REGISTER_PROGRAM_FOR_COPY:
      return {
        ...state,
        programForCopy: action.payload
      };
    case REGISTER_PROGRAM_VIEW_EXIT_FOCUS_DAY:
      return {
        ...state,
        programViewExitFocus: {
          programId: action.payload.programId,
          day: action.payload.day
        }
      };
    case REMOVE_REGISTERED_PROGRAM_VIEW_EXIT_FOCUS_DAY:
      return _.omit(state, ["programViewExitFocus"]);
    case REGISTER_CLIENTS_VIEW_FILTER:
      return {
        ...state,
        clientsViewFilter: action.payload.filter
      };
    case REGISTER_CLIENTS_VIEW_EXIT_FOCUS_ID:
      return {
        ...state,
        clientsViewExitFocusId: action.payload.id
      };
    case PROVISION_NEW_COMPANY.SUCCESS:
      {
        return {
          ...state,
          isFirstRun: true
        };
      }
    case REGISTER_DID_DISMISS_SAMPLE_CLIENT_TIP:
      {
        return {
          ...state,
          didDismissSampleClientTip: true
        };
      }
    case GET_EXERCISES.SUCCESS:
      {
        return {
          ...state,
          youtubeApiKey: action.payload.yt_api_key
        };
      }
    case SAVE_FILTER:
      {
        return {
          ...state,
          filter: action.payload
        };
      }
    case REGISTER_DRILL_TO_COPY:
      {
        return {
          ...state,
          drillToCopy: action.payload
        };
      }
    case REGISTER_FOR_CURRENT_AND_UPCOMING:
      {
        return {
          ...state,
          forCurrentAndUpcoming: action.payload.forCurrentAndUpcoming
        };
      }
    default:
      return state;
  }
}
export function savedRegisters(state = {
  showWorkoutDetails: true,
  forPowerEditor: true
}, action) {
  switch (action.type) {
    case REGISTER_DID_DISMISS_SAMPLE_FORM_TIP:
      {
        return {
          ...state,
          didDismissSampleFormTip: true
        };
      }
    case REGISTER_DID_OPEN_MAIN_MENU:
      {
        return {
          ...state,
          didOpenMainMenu: true
        };
      }
    case REGISTER_SHOW_WORKOUT_DETAILS:
      {
        return {
          ...state,
          showWorkoutDetails: action.payload.showWorkoutDetails
        };
      }
    case REGISTER_FOR_POWER_EDITOR:
      {
        return {
          ...state,
          forPowerEditor: action.payload.forPowerEditor
        };
      }
    case REGISTER_DID_DISMISS_RESOURCES:
      {
        return {
          ...state,
          didDismissResources: true
        };
      }
    case SAVE_SIGNUP_FORM:
      {
        return {
          ...state,
          sampleForm: {
            ...action.payload
          }
        };
      }
    default:
      return state;
  }
}