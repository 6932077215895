import { t } from "../../services/utils";
import { GET_PROGRAM_DAY_NOTE, GET_PROGRAM_DAY_DRILL, GET_PROGRAM_DAY_DRILLS_ORDER, UPDATE_PROGRAM_DRILL, UPDATE_PROGRAM_DAY_NOTE, CREATE_PROGRAM_DRILL, UPDATE_PROGRAM_DAY_DRILL_ORDER, GET_WORKOUT_TEMPLATE_LIST, GET_PROGRAM, GET_CLIENT_PROGRAMS, UPDATE_PROGRAM_NOTE, UPDATE_PROGRAM_ACTIVATION_DATE, DELETE_CLIENT_PROGRAM, CREATE_CLIENT_PROGRAM, GET_ORDERED_PROGRAM_LISTS, GET_ORDERED_PROGRAM_LIST_ITEMS, CREATE_ORDERED_PROGRAM_LIST_ITEM, DELETE_ORDERED_PROGRAM_LIST_ITEM, DELETE_WORKOUT_TEMPLATE, CREATE_ORDERED_PROGRAM_LIST, DELETE_ORDERED_PROGRAM_LIST, CLEAR_CACHED_CLIENTS_PROGRAMS_TASK, GET_EXERCISES_HISTORY, DELETE_WORKOUT } from "../../constants/action-types";
import moment from "moment";
export function programs(state = {}, action) {
  switch (action.type) {
    case GET_WORKOUT_TEMPLATE_LIST.SUCCESS:
      {
        let programs = action.payload.reduce((acc, p) => {
          acc[p.id.toString()] = p;
          return acc;
        }, {});
        return {
          ...state,
          ...programs
        };
      }
    case GET_ORDERED_PROGRAM_LIST_ITEMS.SUCCESS:
    case GET_CLIENT_PROGRAMS.SUCCESS:
      {
        let dict = action.payload.programs.reduce((acc, p) => {
          acc[p.id.toString()] = p;
          return acc;
        }, {});
        return {
          ...state,
          ...dict
        };
      }
    case GET_PROGRAM.SUCCESS:
      {
        return {
          ...state,
          [action.payload.id]: {
            ...state[action.payload.id],
            ...action.payload
          }
        };
      }
    case CREATE_CLIENT_PROGRAM.SUCCESS:
    case CREATE_ORDERED_PROGRAM_LIST_ITEM.SUCCESS:
      {
        return {
          ...state,
          [action.payload.id]: action.payload
        };
      }
    case UPDATE_PROGRAM_NOTE.REQUEST:
      {
        return {
          ...state,
          [action.payload.id]: {
            ...state[action.payload.id],
            note: action.payload.note
          }
        };
      }
    case UPDATE_PROGRAM_ACTIVATION_DATE.REQUEST:
      {
        return {
          ...state,
          [action.payload.id]: {
            ...state[action.payload.id],
            activation_date: action.payload.activationDate,
            ad_mod_ts: moment.utc().unix()
          }
        };
      }
    default:
      return state;
  }
}
export function clientProgramIds(state = {}, action) {
  switch (action.type) {
    case GET_CLIENT_PROGRAMS.SUCCESS:
      {
        // let keys = Object.keys(action.payload.programs);
        // programIds is an array which needs to be spread and contain previous ids as well
        const newProgramIds = action.payload.programs.map(p => p.id);
        return {
          ...state,
          [action.payload.clientId]: {
            programIds: [...new Set([...(state[action.payload.clientId] ? state[action.payload.clientId].programIds : []), ...newProgramIds])],
            clientProgramsLoadStatus: action.payload.clientProgramsLoadStatus
          }
        };
      }
    case DELETE_CLIENT_PROGRAM.REQUEST:
      {
        let pids = state[action.payload.clientId.toString()].programIds;
        return {
          ...state,
          [action.payload.clientId]: {
            ...state[action.payload.clientId],
            programIds: pids.filter(pid => pid !== action.payload.programId)
          }
        };
      }
    case CREATE_CLIENT_PROGRAM.SUCCESS:
      {
        return {
          ...state,
          [action.payload.owner_id]: {
            ...state[action.payload.owner_id],
            programIds: [...state[action.payload.owner_id.toString()].programIds, action.payload.id]
          }
        };
      }
    case CLEAR_CACHED_CLIENTS_PROGRAMS_TASK:
      {
        return {};
      }
    default:
      return state;
  }
}
export function programDayNotes(state = {}, action) {
  switch (action.type) {
    case GET_PROGRAM_DAY_NOTE.SUCCESS:
      {
        let key = getProgramDayKey(action.payload.programId, action.payload.day, action.payload.date);
        return {
          ...state,
          [key]: action.payload.note
        };
      }
    case UPDATE_PROGRAM_DAY_NOTE.REQUEST:
      {
        let key = getProgramDayKey(action.payload.programId, action.payload.day, action.payload.date);
        return {
          ...state,
          [key]: action.payload.note
        };
      }
    case CREATE_ORDERED_PROGRAM_LIST_ITEM.SUCCESS:
    case CREATE_CLIENT_PROGRAM.SUCCESS:
      {
        let dayNotes = {};
        for (let i = 1; i <= 7; i++) {
          let key = getProgramDayKey(action.payload.id, i);
          dayNotes[key] = null;
        }
        return {
          ...state,
          ...dayNotes
        };
      }
    case DELETE_WORKOUT.REQUEST:
      {
        let key = getProgramDayKey(action.payload.programId, action.payload.day, action.payload.date);
        return {
          ...state,
          [key]: null
        };
      }
    default:
      return state;
  }
}
export function workoutTemplates(state = null, action) {
  switch (action.type) {
    case GET_WORKOUT_TEMPLATE_LIST.SUCCESS:
      return action.payload.map(t => t.id);
    case DELETE_WORKOUT_TEMPLATE.REQUEST:
      return state.filter(id => id !== action.payload.id);
    default:
      return state;
  }
}
export function programsDayDrillOrder(state = {}, action) {
  switch (action.type) {
    case GET_PROGRAM_DAY_DRILLS_ORDER.SUCCESS:
      {
        let key = getProgramDayKey(action.payload.programId, action.payload.day, action.payload.date);
        return {
          ...state,
          [key]: action.payload.order
        };
      }
    case UPDATE_PROGRAM_DAY_DRILL_ORDER.REQUEST:
      {
        let key = getProgramDayKey(action.payload.programId, action.payload.day, action.payload.date);
        return {
          ...state,
          [key]: action.payload.order
        };
      }
    case CREATE_PROGRAM_DRILL.SUCCESS:
      {
        let key = getProgramDayKey(action.payload.programId, action.payload.day, action.payload.date);
        let newOrder = {
          ...state[key]
        };
        for (let tempId of Object.keys(action.payload.tempIdsMap)) {
          newOrder[action.payload.tempIdsMap[tempId].toString()] = newOrder[tempId];
        }
        for (let tempId of Object.keys(action.payload.tempIdsMap)) {
          delete newOrder[tempId];
        }
        return {
          ...state,
          [key]: newOrder
        };
      }
    case CREATE_ORDERED_PROGRAM_LIST_ITEM.SUCCESS:
    case CREATE_CLIENT_PROGRAM.SUCCESS:
      {
        let orders = {};
        for (let i = 1; i <= 7; i++) {
          let key = getProgramDayKey(action.payload.id, i);
          orders[key] = {};
        }
        return {
          ...state,
          ...orders
        };
      }
    case DELETE_WORKOUT.REQUEST:
      {
        let key = getProgramDayKey(action.payload.programId, action.payload.day, action.payload.date);
        return {
          ...state,
          [key]: {}
        };
      }
    default:
      return state;
  }
}
export function orderedProgramLists(state = null, action) {
  switch (action.type) {
    case GET_ORDERED_PROGRAM_LISTS.SUCCESS:
      return action.payload.reduce((acc, o) => {
        acc[o.id.toString()] = o;
        return acc;
      }, {});
    case CREATE_ORDERED_PROGRAM_LIST.SUCCESS:
      return {
        ...state,
        [action.payload.id]: {
          id: action.payload.id,
          name: action.payload.name
        }
      };
    case DELETE_ORDERED_PROGRAM_LIST.REQUEST:
      {
        let newState = {
          ...state
        };
        delete newState[action.payload.id];
        return newState;
      }
    case CREATE_ORDERED_PROGRAM_LIST_ITEM.REQUEST:
      {
        return {
          ...state,
          [action.payload.orderedProgramListId]: {
            ...state[action.payload.orderedProgramListId],
            length_in_weeks: state[action.payload.orderedProgramListId].length_in_weeks + 1
          }
        };
      }
    case DELETE_ORDERED_PROGRAM_LIST_ITEM.REQUEST:
      {
        return {
          ...state,
          [action.payload.orderedProgramListId]: {
            ...state[action.payload.orderedProgramListId],
            length_in_weeks: state[action.payload.orderedProgramListId].length_in_weeks - 1
          }
        };
      }
    default:
      return state;
  }
}
export function orderedProgramListItems(state = {}, action) {
  switch (action.type) {
    case GET_ORDERED_PROGRAM_LIST_ITEMS.SUCCESS:
      {
        let id = action.payload.orderedProgramListId.toString();
        return {
          ...state,
          [id]: action.payload.programs.map(p => p.id)
        };
      }
    case CREATE_ORDERED_PROGRAM_LIST_ITEM.SUCCESS:
      {
        let oid = action.payload.orderedProgramListId.toString();
        return {
          ...state,
          [oid]: [...state[oid], action.payload.id]
        };
      }
    case DELETE_ORDERED_PROGRAM_LIST_ITEM.REQUEST:
      {
        let oid = action.payload.orderedProgramListId.toString();
        let pid = action.payload.programId;
        return {
          ...state,
          [oid]: state[oid].filter(id => id !== pid)
        };
      }
    default:
      return state;
  }
}
export function getProgramDayDrillSequence(state, programId, day, date) {
  let key = getProgramDayKey(programId, day, date);
  let order = state[key];
  if (!order) {
    return null;
  }
  let drillIds = Object.keys(order);
  return drillIds.sort((d1, d2) => order[d1.toString()] < order[d2.toString()] ? -1 : 1);
}
export function getProgramDayKey(programId, day, date) {
  if (date) {
    return `${programId}-${day}-${date}`;
  }
  return `${programId}-${day}`;
}
export function programDrills(state = {}, action) {
  switch (action.type) {
    case GET_PROGRAM_DAY_DRILL.SUCCESS:
      {
        let drillId = action.payload.drill_id.toString();
        return {
          ...state,
          [drillId]: action.payload
        };
      }
    case CREATE_PROGRAM_DRILL.REQUEST:
      {
        return {
          ...state,
          [action.payload.drill_id]: action.payload
        };
      }
    case UPDATE_PROGRAM_DRILL.REQUEST:
      {
        let drillId = action.payload.drill_id.toString();
        return {
          ...state,
          [drillId]: {
            ...state[drillId],
            measures: action.payload.measures,
            note: action.payload.note,
            rest_time: action.payload.rest_time
          }
        };
      }
    case CREATE_PROGRAM_DRILL.SUCCESS:
      {
        let newState = {
          ...state
        };
        for (let tempId of Object.keys(action.payload.tempIdsMap)) {
          newState[action.payload.tempIdsMap[tempId].toString()] = {
            ...newState[tempId],
            alternateId: parseInt(tempId),
            drill_id: action.payload.tempIdsMap[tempId]
          };
        }
        for (let tempId of Object.keys(action.payload.tempIdsMap)) {
          delete newState[tempId];
        }
        return newState;
      }
    default:
      return state;
  }
}
export function clientExerciseHistory(state = {}, action) {
  switch (action.type) {
    case GET_EXERCISES_HISTORY.SUCCESS:
      {
        return {
          ...state,
          [action.payload.clientId]: action.payload.history
        };
      }
    default:
      return state;
  }
}
export function clientProgramsLoadedSchedule(state = {}, action) {
  switch (action.type) {
    case GET_CLIENT_PROGRAMS.SUCCESS:
      {
        let oldStartDate = state[action.payload.clientId]?.startDate;
        let oldEndDate = state[action.payload.clientId]?.endDate;
        let newStartDate = action.payload.startDate;
        let newEndDate = action.payload.endDate;
        let startDate, endDate;
        if (oldStartDate && newStartDate) {
          startDate = moment(oldStartDate).isBefore(moment(newStartDate)) ? oldStartDate : newStartDate;
        } else {
          startDate = oldStartDate || newStartDate;
        }
        if (oldEndDate && newEndDate) {
          endDate = moment(oldEndDate).isAfter(moment(newEndDate)) ? oldEndDate : newEndDate;
        } else {
          endDate = oldEndDate || newEndDate;
        }
        return {
          ...state,
          [action.payload.clientId]: {
            ...state[action.payload.clientId],
            clientProgramId: action.payload.clientProgramId,
            startDate: startDate,
            endDate: endDate
          }
        };
      }
    default:
      return state;
  }
}