import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)

import translationEN from "./locales/en/translation.json";
import translationES from "./locales/es/translation.json";
const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
};

let languageDetectorOptions = {
    order: ['localStorage', 'navigator'],
    lookupLocalStorage: 'language',
    caches: ['localStorage'],
    excludeCacheFor: ['cimode'],
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: languageDetectorOptions,
    debug: true,
    resources,
    supportedLngs: ["en", "es"],
    fallbackLng: "en",
    nonExplicitSupportedLngs: true,
    // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
      skipOnVariables: false,
    }
  });

i18n.on("languageChanged", (lng) => {
  console.log("Language changed to", lng);
  // window.location.reload();
  // i18n.changeLanguage(lng);
  // document.documentElement.lang = lng;
});
window.UPLOADCARE_LOCALE = i18n.language;

export default i18n;
