import { t } from "../../services/utils";
import { GET_STORIES, UPDATE_STORY_PRIVACY, ARCHIVE_STORY, ADD_STORY_COMMENT, LIKE_STORY, UNLIKE_STORY, GET_HAS_NEW_STORIES, GET_NEW_NOTIFICATIONS_COUNT, POST_NEWS, UPDATE_STORY } from "../../constants/action-types";
import storiesQueryTypes from "../../constants/stories-query-types";
export function stories(state = {}, action) {
  switch (action.type) {
    case GET_STORIES.SUCCESS:
      {
        const {
          stories
        } = action.payload;
        let dict = stories.reduce((m, c) => {
          m[c.story_id] = c;
          return m;
        }, {});
        return {
          ...state,
          ...dict
        };
      }
    case UPDATE_STORY_PRIVACY.REQUEST:
      {
        let story = state[action.payload.storyId];
        let updated = {
          ...story,
          is_public: action.payload.isPublic
        };
        return {
          ...state,
          [action.payload.storyId]: updated
        };
      }
    case ARCHIVE_STORY.REQUEST:
      {
        let copy = {
          ...state
        };
        delete copy[action.payload.storyId];
        return copy;
      }
    case LIKE_STORY.REQUEST:
      {
        let story = state[action.payload.storyId];
        let copy = {
          ...story
        };
        copy.likes = [...copy.likes];
        copy.likes.push({
          first_name: action.payload.firstName,
          user_id: action.payload.userId
        });
        return {
          ...state,
          [action.payload.storyId]: copy
        };
      }
    case UNLIKE_STORY.REQUEST:
      {
        let story = state[action.payload.storyId];
        let copy = {
          ...story
        };
        copy.likes = [...copy.likes];
        let index = -1;
        for (let i = 0; i < copy.likes.length; i++) {
          if (copy.likes[i].user_id === action.payload.userId) {
            index = i;
            break;
          }
        }
        if (index > -1) {
          copy.likes.splice(index, 1);
        }
        return {
          ...state,
          [action.payload.storyId]: copy
        };
      }
    case UPDATE_STORY.REQUEST:
      {
        let story = state[action.payload.storyId];
        let copy = {
          ...story,
          ...action.payload.params
        };
        return {
          ...state,
          [action.payload.storyId]: copy
        };
      }
    case ADD_STORY_COMMENT.REQUEST:
      {
        let story = state[action.payload.storyId];
        let copy = {
          ...story
        };
        copy.comments = [...copy.comments];
        copy.comments.push({
          user_id: action.payload.user_id,
          first_name: action.payload.firstName,
          comment: action.payload.comment,
          photo_id: action.payload.photo_id
        });
        return {
          ...state,
          [action.payload.storyId]: copy
        };
      }
    case POST_NEWS.REQUEST:
    case POST_NEWS.SUCCESS:
      {
        const {
          newPost,
          isPublic,
          isPinned,
          appNotification,
          pushNotification,
          pushNotificationMessage,
          files,
          image_url,
          image_width,
          image_height,
          story_type,
          date,
          me,
          story_id
        } = action.payload;
        let post = {
          show_to_everyone: true,
          story: {
            files,
            no: newPost,
            photo: image_url && {
              url: image_url,
              h: image_height,
              w: image_width
            },
            da: date,
            pnm: pushNotificationMessage,
            sn: appNotification,
            spn: pushNotification
          },
          likes: [],
          is_public: isPublic,
          user_id: me.id,
          story_type,
          first_name: me.first_name,
          photo_id: me.photo_id,
          story_id,
          comments: [],
          primary_trainer: null,
          pinned: isPinned,
          published: true
        };
        let copy = {
          ...state
        };
        if (action.payload.delete_id) {
          delete copy[action.payload.delete_id];
        }
        return {
          ...copy,
          [story_id]: post
        };
      }
    default:
      {
        return state;
      }
  }
}
export function storyIndex(state = {}, action) {
  switch (action.type) {
    case POST_NEWS.REQUEST:
    case POST_NEWS.SUCCESS:
      {
        const {
          story_id,
          trainerId
        } = action.payload;
        return {
          ...state,
          [storiesQueryTypes.FOR_TRAINER]: {
            ...state[storiesQueryTypes.FOR_TRAINER],
            [trainerId]: [story_id, ...state[storiesQueryTypes.FOR_TRAINER][trainerId]]
          }
        };
      }
    case GET_STORIES.SUCCESS:
      {
        const {
          query,
          stories
        } = action.payload;
        let sids = stories.map(s => s.story_id);
        switch (query.type) {
          case storiesQueryTypes.GET_ALL:
            {
              return {
                ...state,
                [storiesQueryTypes.GET_ALL]: sids
              };
            }
          case storiesQueryTypes.FOR_TRAINER:
            {
              let newState = {
                ...state
              };
              if (!(storiesQueryTypes.FOR_TRAINER in newState)) {
                newState[storiesQueryTypes.FOR_TRAINER] = {};
              }
              newState[storiesQueryTypes.FOR_TRAINER] = {
                ...newState[storiesQueryTypes.FOR_TRAINER],
                [query.trainerId]: sids
              };
              return newState;
            }
          case storiesQueryTypes.FOR_GROUP:
            {
              let newState = {
                ...state
              };
              if (!(storiesQueryTypes.FOR_GROUP in newState)) {
                newState[storiesQueryTypes.FOR_GROUP] = {};
              }
              newState[storiesQueryTypes.FOR_GROUP] = {
                ...newState[storiesQueryTypes.FOR_GROUP],
                [query.segmentId]: sids
              };
              return newState;
            }
          case storiesQueryTypes.FOR_CLIENT:
            {
              let newState = {
                ...state
              };
              if (!(storiesQueryTypes.FOR_CLIENT in newState)) {
                newState[storiesQueryTypes.FOR_CLIENT] = {};
              }
              newState[storiesQueryTypes.FOR_CLIENT] = {
                ...newState[storiesQueryTypes.FOR_CLIENT],
                [query.clientId]: sids
              };
              return newState;
            }
          case storiesQueryTypes.FOR_STORY_TYPE:
            {
              let newState = {
                ...state
              };
              if (!(storiesQueryTypes.FOR_STORY_TYPE in newState)) {
                newState[storiesQueryTypes.FOR_STORY_TYPE] = {};
              }
              newState[storiesQueryTypes.FOR_STORY_TYPE] = {
                ...newState[storiesQueryTypes.FOR_STORY_TYPE],
                [query.clientId]: sids
              };
              return newState;
            }
          case storiesQueryTypes.FOR_SINGLE_STORY:
            {
              return state;
            }
          default:
            return state;
        }
      }
    default:
      return state;
  }
}
export function hasNewStories(state = false, action) {
  switch (action.type) {
    case GET_HAS_NEW_STORIES.SUCCESS:
      {
        return action.payload.has_new_stories;
      }
    case GET_STORIES.SUCCESS:
      {
        let payload = action.payload;
        if (payload.query.type === storiesQueryTypes.GET_ALL) {
          return false;
        } else if (payload.query.type === storiesQueryTypes.FOR_TRAINER && payload.query.myId === payload.query.trainerId) {
          return false;
        }
        break;
      }
    default:
      return state;
  }
  return state;
}