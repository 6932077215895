import { t } from "../services/utils";
import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import { Icon, InputAdornment } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import ButtonNew from "@material-ui/core/Button";
import ReactTooltip from "react-tooltip";
const Button = (props) => {
  const style = {
    // padding: 2,
    color: props.active ? "#333" : "#999",
  };
  return (
    <IconButton
      tabIndex={-1}
      style={{
        width: "unset",
        height: "unset",
      }}
      data-tip={props.tooltip}
    >
      <Icon className="material-icons" style={style}>
        {props.iconName}
      </Icon>
    </IconButton>
  );
};
const Heading = (props) => {
  return (
    <Button
      active={props.active}
      tooltip={props.label}
      iconName="format_size"
    />
  );
};
const UL = (props) => {
  return (
    <Button
      tooltip={props.label}
      active={props.active}
      iconName="format_list_bulleted"
    />
  );
};
const OL = (props) => {
  return (
    <Button
      tooltip={props.label}
      active={props.active}
      iconName="format_list_numbered"
    />
  );
};
const BlockQuote = (props) => {
  return (
    <span>
      <Button
        tooltip={props.label}
        active={props.active}
        iconName="format_quote"
      />
    </span>
  );
};
const Attachment = (props) => {
  return (
    <Button
      tooltip={props.label}
      active={props.active}
      iconName="attach_file"
    />
  );
};
const InsertLink = (props) => {
  return (
    <Button
      tooltip={props.label}
      active={props.active}
      iconName="insert_link"
    />
  );
};
const Bold = (props) => {
  return (
    <Button
      active={props.active}
      iconName="format_bold"
      tooltip={props.label}
    />
  );
};
const Italic = (props) => {
  return (
    <Button
      active={props.active}
      iconName="format_italic"
      tooltip={props.label}
    />
  );
};
const Underline = (props) => {
  return (
    <Button
      active={props.active}
      tooltip={props.label}
      iconName="format_underline"
    />
  );
};
class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = (e) => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
    this.onFileUpload = (e) => {
      e.preventDefault();
      this.props.onFileUpload();
    };
    this.onInsertLink = (e) => {
      e.preventDefault();
      this.props.onInsertLink();
    };
    this.onRemoveLink = (e) => {
      e.preventDefault();
      this.props.onRemoveLink();
    };
  }
  componentDidUpdate(prevProps, prevState) {
    ReactTooltip.rebuild();
  }
  render() {
    let className = "RichEditor-styleButton";
    if (this.props.active) {
      className += " RichEditor-activeButton";
    }
    const Component = this.props.component;
    const active = this.props.active;
    return (
      <span>
        {this.props.identifier !== "attachments" &&
          this.props.identifier !== "link" && (
            <span className={className} onMouseDown={this.onToggle}>
              {this.props.component ? (
                <Component
                  active={this.props.active}
                  label={this.props.label}
                />
              ) : (
                <span>{this.props.label}</span>
              )}
            </span>
          )}
        {this.props.identifier === "attachments" && (
          <span>
            {!this.props.hideAttachment && (
              <span className={className} onMouseDown={this.onFileUpload}>
                {this.props.component ? (
                  <Component label={this.props.label} />
                ) : (
                  <span>{this.props.label}</span>
                )}
              </span>
            )}
          </span>
        )}
        {this.props.identifier === "link" && (
          <span>
            <span className={className} onMouseDown={this.onInsertLink}>
              {this.props.component ? (
                <Component
                  active={this.props.active}
                  label={this.props.label}
                />
              ) : (
                <span>{this.props.label}</span>
              )}
            </span>
          </span>
        )}
      </span>
    );
  }
}
const BLOCK_TYPES = [
  {
    label: t("heading"),
    style: "header-three",
    component: Heading,
  },
  {
    label: t("quote"),
    style: "blockquote",
    component: BlockQuote,
  },
  {
    label: t("bulletedList"),
    style: "unordered-list-item",
    component: UL,
  },
  {
    label: t("numberedList"),
    style: "ordered-list-item",
    component: OL,
  },
  {
    label: t("attachFile"),
    component: Attachment,
    identifier: "attachments",
  },
  {
    label: t("insertLink"),
    component: InsertLink,
    identifier: "link",
  },
];
const BlockStyleControls = (props) => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  let linkActive = false;
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  const contentState = editorState.getCurrentContent();
  const startKey = editorState.getSelection().getStartKey();
  const startOffset = editorState.getSelection().getStartOffset();
  const blockWithLinkAtBeginning =
    startKey && contentState.getBlockForKey(startKey);
  const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
  const linkInstance = linkKey && contentState.getEntity(linkKey);
  const url = linkInstance && linkInstance.getData().url;
  if (url) {
    linkActive = true;
  }
  const hideAttachment = props.hideAttachment ? true : false;
  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.label}
          active={
            type.style === blockType || (type.label === "Link" && linkActive)
          }
          label={type.label}
          component={type.component}
          onToggle={props.onToggle}
          onFileUpload={props.onFileUpload}
          style={type.style}
          hideAttachment={hideAttachment}
          onInsertLink={props.onInsertLink}
          onRemoveLink={props.onRemoveLink}
          identifier={type.identifier}
        />
      ))}
      <ReactTooltip place="top" type="dark" effect="solid" />
    </div>
  );
};
var INLINE_STYLES = [
  {
    label: t("bold"),
    style: "BOLD",
    component: Bold,
  },
  {
    label: t("italic"),
    style: "ITALIC",
    component: Italic,
  },
  {
    label: t("underline"),
    style: "UNDERLINE",
    component: Underline,
  },
];
export const InlineStyleControls = (props) => {
  var currentStyle = props.editorState.getCurrentInlineStyle();
  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          component={type.component}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
      <ReactTooltip place="top" type="dark" effect="solid" />
    </div>
  );
};
export class Link extends Component {
  render() {
    let styles = {
      link: {
        color: "#15c",
        textDecoration: "underline",
      },
      linkPreview: {
        border: "1px solid #ccc",
        boxShadow: "0px 0px 5px #ddd",
        padding: "5px 12px",
      },
    };
    const { url } = this.props.contentState
      .getEntity(this.props.entityKey)
      .getData();
    return (
      <span>
        <a
          href={url}
          onClick={(e) => {
            e.stopPropagation();
          }}
          target="_blank"
          style={styles.link}
        >
          {this.props.children}
        </a>
      </span>
    );
  }
}
export class AddLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      urlError: "",
    };
  }
  addLink = (e) => {
    try {
      new URL(this.props.urlValue);
      this.props.confirmLink();
    } catch (_) {
      this.setState({
        urlError: "Please Enter correct url, eg http://www.abc.com",
      });
      return false;
    }
  };
  render() {
    let styles = {
      modalContainer: {
        display: "flex",
        flexDirection: "column",
        padding: "20px",
      },
      field: {
        width: "100%",
        marginBottom: 20,
        marginTop: 20,
      },
      ctaContainer: {
        marginTop: 20,
        display: "flex",
        flexDirection: "row-reverse",
      },
      ctaButton: {
        marginLeft: 8,
      },
    };
    const {
      urlValue,
      onURLChange,
      onLinkInputKeyDown,
      confirmLink,
      onCancel,
      editLink,
      removeLink,
    } = this.props;
    return (
      <Paper elevation={1} style={styles.modalContainer}>
        <TextField
          label="Link To:"
          style={styles.field}
          value={urlValue}
          onChange={onURLChange}
          InputProps={{
            style: {
              paddingTop: 2,
              paddingBottom: 4,
            },
          }}
          onKeyDown={onLinkInputKeyDown}
          autoFocus
          helperText={this.state.urlError ? this.state.urlError : ""}
          error={this.state.urlError ? true : false}
        />
        <div style={styles.ctaContainer}>
          <ButtonNew
            variant="contained"
            color="primary"
            style={styles.ctaButton}
            onClick={this.addLink}
          >
            {t("add")}
          </ButtonNew>
          {editLink ? (
            <ButtonNew
              variant="contained"
              color="secondary"
              style={styles.ctaButton}
              onClick={removeLink}
            >
              {t("remove")}
            </ButtonNew>
          ) : (
            <ButtonNew
              variant="contained"
              style={styles.ctaButton}
              onClick={onCancel}
            >
              {t("cancel")}
            </ButtonNew>
          )}
        </div>
      </Paper>
    );
  }
}
export default BlockStyleControls;
