export default [
  {
    value: 0,
    label: "ASAP",
  },
  {
    value: 30,
    label: "30 mins",
  },
  {
    value: 60,
    label: "1 hr",
  },
  {
    value: 120,
    label: "12:00 PM",
  },
  {
    value: 180,
    label: "12:30 PM",
  },
];

export const waitData = [
  {
    value: 30,
    label: "30 mins",
  },
  {
    value: 60,
    label: "1 hr",
  },
  {
    value: 90,
    label: "1hr 30 mins",
  },
  {
    value: 120,
    label: "2 hr",
  },
  {
    value: 150,
    label: "2hr 30 mins",
  },
  {
    value: 180,
    label: "3 hr",
  },
  {
    value: 210,
    label: "3hr 30 mins",
  },
  {
    value: 240,
    label: "4 hr",
  },
  {
    value: 270,
    label: "4hr 30 mins",
  },
  {
    value: 300,
    label: "5 hr",
  },
  {
    value: 330,
    label: "5hr 30 mins",
  },
  {
    value: 360,
    label: "6 hr",
  },
];

export const timeData = [
  { value: 0, label: "12:00 AM" },
  { value: 1, label: "12:30 AM" },
  { value: 2, label: "01:00 AM" },
  { value: 3, label: "01:30 AM" },
  { value: 4, label: "02:00 AM" },
  { value: 5, label: "02:30 AM" },
  { value: 6, label: "03:00 AM" },
  { value: 7, label: "03:30 AM" },
  { value: 8, label: "04:00 AM" },
  { value: 9, label: "04:30 AM" },
  { value: 10, label: "05:00 AM" },
  { value: 11, label: "05:30 AM" },
  { value: 12, label: "06:00 AM" },
  { value: 13, label: "06:30 AM" },
  { value: 14, label: "07:00 AM" },
  { value: 15, label: "07:30 AM" },
  { value: 16, label: "08:00 AM" },
  { value: 17, label: "08:30 AM" },
  { value: 18, label: "09:00 AM" },
  { value: 19, label: "09:30 AM" },
  { value: 20, label: "10:00 AM" },
  { value: 21, label: "10:30 AM" },
  { value: 22, label: "11:00 AM" },
  { value: 23, label: "11:30 AM" },
  { value: 24, label: "12:00 PM" },
  { value: 25, label: "12:30 PM" },
  { value: 26, label: "01:00 PM" },
  { value: 27, label: "01:30 PM" },
  { value: 28, label: "02:00 PM" },
  { value: 29, label: "02:30 PM" },
  { value: 30, label: "03:00 PM" },
  { value: 31, label: "03:30 PM" },
  { value: 32, label: "04:00 PM" },
  { value: 33, label: "04:30 PM" },
  { value: 34, label: "05:00 PM" },
  { value: 35, label: "05:30 PM" },
  { value: 36, label: "06:00 PM" },
  { value: 37, label: "06:30 PM" },
  { value: 38, label: "07:00 PM" },
  { value: 39, label: "07:30 PM" },
  { value: 40, label: "08:00 PM" },
  { value: 41, label: "08:30 PM" },
  { value: 42, label: "09:00 PM" },
  { value: 43, label: "09:30 PM" },
  { value: 44, label: "10:00 PM" },
  { value: 45, label: "10:30 PM" },
  { value: 46, label: "11:00 PM" },
  { value: 47, label: "11:30 PM" },
];
