import { t } from "../../../services/utils";
import React, { Component } from "react";
import { toastr } from "react-redux-toastr";
import Select from "react-select";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import RadioButton from "@material-ui/core/Radio";
import Popover from "@material-ui/core/Popover";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Input from "@material-ui/core/Input";
import { Icon, InputAdornment } from "@material-ui/core";
import triggerTypes from "../../../constants/trigger-types";
import { waitData } from "../../../constants/wait-time";
import { timeData } from "../../../constants/wait-time";
import tmUtils from "./tm-utils";
import styles from "./styles";
import { WeekDayBtn } from "./components";
import { isMobile, isTablet } from "react-device-detect";
class GroupOptions extends Component {
  constructor(props) {
    super(props);
    const { triggeredMessage, messageId } = props;
    this.state = {
      [triggerTypes.DAYS_AFTER_CREATION]:
        triggeredMessage &&
        triggeredMessage.trigger_type === triggerTypes.DAYS_AFTER_CREATION
          ? triggeredMessage.data_1
          : 1,
      [triggerTypes.DAYS_AFTER_JOINED_GROUP]:
        triggeredMessage &&
        triggeredMessage.trigger_type === triggerTypes.DAYS_AFTER_JOINED_GROUP
          ? triggeredMessage.data_1
          : 1,
      [triggerTypes.AFTER_COMPLETING_NTH_WORKOUT]:
        triggeredMessage &&
        triggeredMessage.trigger_type ===
          triggerTypes.AFTER_COMPLETING_NTH_WORKOUT
          ? triggeredMessage.data_1
          : 1,
      [triggerTypes.AFTER_NOT_LOGGING_WORKOUT_IN_PAST_N_DAYS]:
        triggeredMessage &&
        triggeredMessage.trigger_type ===
          triggerTypes.AFTER_NOT_LOGGING_WORKOUT_IN_PAST_N_DAYS
          ? triggeredMessage.data_1
          : 7,
      [triggerTypes.ON_EVERY_WEEKDAYS_FOR_N_WEEKS]:
        triggeredMessage &&
        triggeredMessage.trigger_type ===
          triggerTypes.ON_EVERY_WEEKDAYS_FOR_N_WEEKS
          ? triggeredMessage.data_1
          : 0,
      [triggerTypes.WEEK]:
        triggeredMessage &&
        triggeredMessage.trigger_type ===
          triggerTypes.ON_EVERY_WEEKDAYS_FOR_N_WEEKS
          ? triggeredMessage.data_2
          : 1,
      [triggerTypes.ON_DATE]:
        triggeredMessage &&
        triggeredMessage.trigger_type === triggerTypes.ON_DATE
          ? triggeredMessage.data_1
          : new Date(),
      showTriggerModal: false,
      showAddCampaignModal: false,
      isEditMode: messageId !== undefined,
      id: messageId,
      message: triggeredMessage ? triggeredMessage.message : "",
      segment_id: triggeredMessage
        ? triggeredMessage.segment_id
        : this.props.groupId,
      for_everyone:
        this.props.groupId == 0
          ? true
          : triggeredMessage
          ? triggeredMessage.for_everyone
          : false,
      wait: triggeredMessage ? triggeredMessage.wait : 30,
      delivery_window: triggeredMessage
        ? triggeredMessage.delivery_window || null
        : null,
      // Default campaign id as default campaign
      new_campaign_name: "",
      trigger_type: triggeredMessage ? triggeredMessage.trigger_type : null,
      data_1: triggeredMessage ? triggeredMessage.data_1 : null,
      week_data: 1,
      uploadedFiles:
        triggeredMessage &&
        triggeredMessage.files &&
        triggeredMessage.files.length > 0
          ? triggeredMessage.files
          : [],
      selectedDays: this.getSelectedDays(triggeredMessage),
      dateDisabled: false,
      isCheckinMessage: triggeredMessage ? triggeredMessage.for_checkin : false,
      checkinDescriptionModal: false,
      showTimeModal: false,
      editMode: false,
      isUploading: false,
      anchorEl: null,
      waitTime:
        triggeredMessage && triggeredMessage.wait ? triggeredMessage.wait : 30,
      deliveryTime:
        triggeredMessage && triggeredMessage.delivery_window
          ? triggeredMessage.delivery_window
          : 18,
      waitType: this.getWaitType(triggeredMessage),
      showURLInput: false,
      urlValue: "",
      email_subject_line:
        triggeredMessage && triggeredMessage.email_subject_line
          ? triggeredMessage.email_subject_line
          : "",
    };
    this.choices = {
      [triggerTypes.DAYS_AFTER_CREATION]: _.range(0, 121).map((i) => {
        let label;
        if (i === 0) {
          label = t("sameDay");
        } else if (i === 1) {
          label = t("1Day");
        } else {
          label = t("iDays", { i });
        }
        return {
          value: i,
          label,
        };
      }),
      [triggerTypes.DAYS_AFTER_JOINED_GROUP]: _.range(0, 121).map((i) => {
        let label;
        if (i === 0) {
          label = t("sameDay");
        } else if (i === 1) {
          label = t("1Day");
        } else {
          label = t("iDays", { i });
        }
        return {
          value: i,
          label,
        };
      }),
      [triggerTypes.AFTER_COMPLETING_NTH_WORKOUT]: _.range(0, 13).map((i) => {
        let n = i === 0 ? 1 : i * 25;
        return {
          value: n,
          label: n === 1 ? t("1workout") : t("nworkouts", { n }),
        };
      }),
      [triggerTypes.AFTER_NOT_LOGGING_WORKOUT_IN_PAST_N_DAYS]: _.range(
        3,
        11
      ).map((i) => {
        return {
          value: i,
          label: t("iDays", { i }),
        };
      }),
      [triggerTypes.WEEK]: _.range(1, 52).map((i) => {
        return {
          value: i,
          label: i + " " + t("week"),
        };
      }),
    };
  }

  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  componentWillReceiveProps(nextProps) {
    if (!this.props.triggeredMessage && nextProps.triggeredMessage) {
      const { triggeredMessage } = nextProps;
      this.setState({
        id: triggeredMessage.id,
        wait: triggeredMessage.wait,
        data_1: triggeredMessage.data_1,
        message: triggeredMessage.message,
        segment_id: triggeredMessage.segment_id,
        campaign_id: triggeredMessage.campaign_id,
        trigger_type: triggeredMessage.trigger_type,
        for_everyone: triggeredMessage.for_everyone,
        delivery_window: triggeredMessage.delivery_window || null,
        uploadedFiles:
          triggeredMessage &&
          triggeredMessage.files &&
          triggeredMessage.files.length > 0
            ? triggeredMessage.files
            : [],
        [triggerTypes.ON_DATE]:
          triggeredMessage.trigger_type === triggerTypes.ON_DATE
            ? triggeredMessage.data_1
            : new Date(),
        selectedDays: this.getSelectedDays(triggeredMessage),
        waitTime: triggeredMessage.wait || 30,
        deliveryTime: triggeredMessage.delivery_window || 18,
        waitType: this.getWaitType(triggeredMessage),
        isCheckinMessage: triggeredMessage.for_checkin,
        delivery_channel: triggeredMessage.delivery_channel,
        email_subject_line: triggeredMessage.email_subject_line,
      });
    }
  }
  getSelectedDays = (triggeredMessage) => {
    let tempArr = [];
    if (
      triggeredMessage &&
      triggeredMessage.trigger_type ===
        triggerTypes.ON_EVERY_WEEKDAYS_FOR_N_WEEKS
    ) {
      const selectedDays = tmUtils.getDaysSelected(triggeredMessage.data_1);
      const weekdaysList = tmUtils.getWeekDaysList();
      weekdaysList.map((day, index) => {
        if (selectedDays.indexOf(day.label) >= 0) {
          tempArr.push(weekdaysList[index].value);
        }
      });
    }
    this.setState({
      [triggerTypes.ON_EVERY_WEEKDAYS_FOR_N_WEEKS]: tempArr,
    });
    return tempArr;
  };
  getWaitType = (triggeredMessage) => {
    if (triggeredMessage && triggeredMessage.wait) {
      return "wait";
    }
    if (triggeredMessage && triggeredMessage.delivery_window) {
      return "time";
    }
    if (triggeredMessage && triggeredMessage.wait === 0) {
      return "asap";
    }
    return "";
  };
  onSelectTrigger = (trigger_type, trigger_data) => () => {
    // if (!this.disableClickOnRow) {

    const { triggeredMessage } = this.props;
    if (!this.state.isEditMode) {
      this.setState(
        {
          trigger_type,
          data_1: trigger_data,
          showTriggerModal: false,
        },
        () => {
          this.onSelectDelay(null, 18, "time");
          this.setState({
            deliveryTime: 18,
            waitTime: 30,
          });
        }
      );
    } else if (trigger_type !== triggeredMessage.trigger_type) {
      toastr.error(
        t("error-0"),
        t("cantChangeTriggerTypeWhenUpdatingAScheduledMessage")
      );
      return;
    } else if (trigger_type === triggeredMessage.trigger_type) {
      this.setState({
        trigger_type,
        data_1: trigger_data,
        showTriggerModal: false,
      });
    }
    this.props.onSelectTrigger(trigger_type, trigger_data)();

    // }
  };

  onSelectDelay = (wait, delivery_window, waitType) => {
    const { trigger_type, data_1 } = this.state;
    if (trigger_type === null || data_1 === null) {
      toastr.error(
        t("error-0"),
        t(
          "pleaseSelectWhenTheyWillReceiveThisMessageBeforeSelectingDelayOptions"
        )
      );
      return;
    }
    this.setState({
      wait,
      delivery_window,
      waitType,
    });
    console.log("delivery_window", delivery_window);
    this.props.onSelectDelay(wait, delivery_window, waitType);
  };
  disableClickOnTriggerRow = () => {
    this.disableClickOnRow = true;
    this.setState({
      dateDisabled: true,
    });
  };
  enableClickOnTriggerRow = () => {
    setTimeout(() => {
      this.disableClickOnRow = false;
      this.setState({
        dateDisabled: false,
      });
    }, 300);
  };
  handlePopOverClick = (event) => {
    event.stopPropagation();
    this.setState({
      anchorEl: event.currentTarget,
    });
  };
  handlePopOverClose = (event) => {
    event.stopPropagation();
    this.setState({
      anchorEl: null,
    });
  };
  handleDateChange = (date) => {
    this.setState(
      {
        [triggerTypes.ON_DATE]: date,
      },
      () => {
        this.onSelectTrigger(
          triggerTypes.ON_DATE,
          this.state[triggerTypes.ON_DATE]
        )();
      }
    );
    this.enableClickOnTriggerRow();
  };
  handleWeekDayChange = (day) => {
    const tempArr = this.state.selectedDays;
    this.disableClickOnTriggerRow();
    if (this.state.selectedDays.indexOf(day) === -1) {
      tempArr.push(day);
      this.setState(
        {
          selectedDays: tempArr,
          [triggerTypes.ON_EVERY_WEEKDAYS_FOR_N_WEEKS]: tempArr,
        },
        () => {
          this.enableClickOnTriggerRow();
          this.onSelectTrigger(
            triggerTypes.ON_EVERY_WEEKDAYS_FOR_N_WEEKS,
            this.state[triggerTypes.ON_EVERY_WEEKDAYS_FOR_N_WEEKS]
          )();
        }
      );
    } else {
      const position = this.state.selectedDays.indexOf(day);
      tempArr.splice(position, 1);
      this.setState(
        {
          selectedDays: tempArr,
          [triggerTypes.ON_EVERY_WEEKDAYS_FOR_N_WEEKS]: tempArr,
        },
        () => {
          this.enableClickOnTriggerRow();
          this.onSelectTrigger(
            triggerTypes.ON_EVERY_WEEKDAYS_FOR_N_WEEKS,
            this.state[triggerTypes.ON_EVERY_WEEKDAYS_FOR_N_WEEKS]
          )();
        }
      );
    }
  };
  showDelay = () => {
    const { trigger_type, data_1 } = this.state;
    if (trigger_type === triggerTypes.DAYS_AFTER_CREATION && data_1 === 0) {
      return true;
    }
    if (trigger_type === triggerTypes.DAYS_AFTER_JOINED_GROUP && data_1 === 0) {
      return true;
    }
    if (
      trigger_type === triggerTypes.AFTER_COMPLETING_NTH_WORKOUT ||
      trigger_type === triggerTypes.AFTER_NOT_LOGGING_WORKOUT_IN_PAST_N_DAYS
    ) {
      return true;
    }
    return false;
  };
  render() {
    const { anchorEl, isCheckinMessage, selectedDays } = this.state;
    const { filter } = this.props;
    const open = Boolean(anchorEl);
    let en = [
      triggerTypes.DAYS_AFTER_CREATION,
      triggerTypes.DAYS_AFTER_JOINED_GROUP,
      triggerTypes.AFTER_COMPLETING_NTH_WORKOUT,
      triggerTypes.AFTER_NOT_LOGGING_WORKOUT_IN_PAST_N_DAYS,
      triggerTypes.WEEK,
    ];
    let initValues = en.map((e) => {
      let initIdx = _.findIndex(
        this.choices[e],
        (x) => x.value === this.state[e]
      );
      return this.choices[e][initIdx].value;
    });
    const for_everyone = filter.forGroup
      ? filter.id == 0
        ? true
        : false
      : false;
    const afterJoiningStr =
      this.state.segment_id > 0 || !this.state.for_everyone
        ? t("afterJoiningTheGroupFor") + " "
        : t("afterJoiningFor");
    return (
      <ExpansionPanel style={styles.contentContainer}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon fontSize="large" />}
          style={
            isMobile
              ? {
                  padding: "0px 15px",
                }
              : {}
          }
        >
          <Typography style={styles.sectionHeading} variant="h2">
            {t("whenToSendThisMessage")}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={styles.column}>
          {/* REMOVED AFTER CLIENTS ACCOUNT CREATION FOR GROUPS */}
          {for_everyone && (
            <div
              className="triggerRow"
              style={{
                ...styles.listItem,
                ...(this.state.trigger_type ===
                  triggerTypes.DAYS_AFTER_CREATION && styles.selectedListItem),
              }}
              onClick={this.onSelectTrigger(
                triggerTypes.DAYS_AFTER_CREATION,
                this.state[triggerTypes.DAYS_AFTER_CREATION]
              )}
            >
              <RadioButton
                checked={
                  this.state.trigger_type === triggerTypes.DAYS_AFTER_CREATION
                }
                color="primary"
                style={styles.radioButton}
              />
              <Select
                onOpen={this.disableClickOnTriggerRow}
                onClose={this.enableClickOnTriggerRow}
                style={styles.selectBoxLeft}
                value={initValues[0]}
                clearable={false}
                searchable={false}
                onChange={(option) =>
                  this.setState(
                    {
                      [triggerTypes.DAYS_AFTER_CREATION]: option.value,
                    },
                    () => {
                      this.onSelectTrigger(
                        triggerTypes.DAYS_AFTER_CREATION,
                        this.state[triggerTypes.DAYS_AFTER_CREATION]
                      )();
                    }
                  )
                }
                classNamePrefix="trigger-row"
                options={this.choices[triggerTypes.DAYS_AFTER_CREATION]}
              />
              {t("afterClientsAccountCreation")}
            </div>
          )}
          {!for_everyone && (
            <div
              className="triggerRow"
              style={{
                ...styles.listItem,
                ...(this.state.trigger_type ===
                  triggerTypes.DAYS_AFTER_JOINED_GROUP &&
                  styles.selectedListItem),
              }}
              onClick={this.onSelectTrigger(
                triggerTypes.DAYS_AFTER_JOINED_GROUP,
                this.state[triggerTypes.DAYS_AFTER_JOINED_GROUP]
              )}
            >
              <RadioButton
                checked={
                  this.state.trigger_type ===
                  triggerTypes.DAYS_AFTER_JOINED_GROUP
                }
                color="primary"
                style={styles.radioButton}
              />
              <Select
                onOpen={this.disableClickOnTriggerRow}
                onClose={this.enableClickOnTriggerRow}
                style={styles.selectBoxLeft}
                value={initValues[1]}
                searchable={false}
                clearable={false}
                onChange={(option) =>
                  this.setState(
                    {
                      [triggerTypes.DAYS_AFTER_JOINED_GROUP]: option.value,
                    },
                    () => {
                      this.onSelectTrigger(
                        triggerTypes.DAYS_AFTER_JOINED_GROUP,
                        this.state[triggerTypes.DAYS_AFTER_JOINED_GROUP]
                      )();
                    }
                  )
                }
                options={this.choices[triggerTypes.DAYS_AFTER_JOINED_GROUP]}
              />{" "}
              {t("afterJoiningTheGroup")}
            </div>
          )}
          <div
            className="triggerRow"
            style={{
              ...styles.listItem,
              ...(this.state.trigger_type ===
                triggerTypes.AFTER_COMPLETING_NTH_WORKOUT &&
                styles.selectedListItem),
            }}
            onClick={this.onSelectTrigger(
              triggerTypes.AFTER_COMPLETING_NTH_WORKOUT,
              this.state[triggerTypes.AFTER_COMPLETING_NTH_WORKOUT]
            )}
          >
            <RadioButton
              checked={
                this.state.trigger_type ===
                triggerTypes.AFTER_COMPLETING_NTH_WORKOUT
              }
              color="primary"
              style={styles.radioButton}
            />
            {t("afterCompleting")}
            <Select
              onOpen={this.disableClickOnTriggerRow}
              onClose={this.enableClickOnTriggerRow}
              style={{
                ...styles.selectBoxRight,
                width: "135px",
              }}
              value={initValues[2]}
              searchable={false}
              clearable={false}
              onChange={(option) =>
                this.setState(
                  {
                    [triggerTypes.AFTER_COMPLETING_NTH_WORKOUT]: option.value,
                  },
                  () => {
                    this.onSelectTrigger(
                      triggerTypes.AFTER_COMPLETING_NTH_WORKOUT,
                      this.state[triggerTypes.AFTER_COMPLETING_NTH_WORKOUT]
                    )();
                  }
                )
              }
              options={this.choices[triggerTypes.AFTER_COMPLETING_NTH_WORKOUT]}
            />
          </div>
          <div
            className="triggerRow"
            style={{
              ...styles.listItem,
              ...(this.state.trigger_type ===
                triggerTypes.AFTER_NOT_LOGGING_WORKOUT_IN_PAST_N_DAYS &&
                styles.selectedListItem),
            }}
            onClick={this.onSelectTrigger(
              triggerTypes.AFTER_NOT_LOGGING_WORKOUT_IN_PAST_N_DAYS,
              this.state[triggerTypes.AFTER_NOT_LOGGING_WORKOUT_IN_PAST_N_DAYS]
            )}
          >
            <RadioButton
              checked={
                this.state.trigger_type ===
                triggerTypes.AFTER_NOT_LOGGING_WORKOUT_IN_PAST_N_DAYS
              }
              color="primary"
              style={styles.radioButton}
            />
            {t("afterNotLoggingWorkoutFor")}
            <Select
              onOpen={this.disableClickOnTriggerRow}
              onClose={this.enableClickOnTriggerRow}
              style={styles.selectBoxRight}
              value={initValues[3]}
              searchable={false}
              clearable={false}
              onChange={(option) =>
                this.setState(
                  {
                    [triggerTypes.AFTER_NOT_LOGGING_WORKOUT_IN_PAST_N_DAYS]:
                      option.value,
                  },
                  () => {
                    this.onSelectTrigger(
                      triggerTypes.AFTER_NOT_LOGGING_WORKOUT_IN_PAST_N_DAYS,
                      this.state[
                        triggerTypes.AFTER_NOT_LOGGING_WORKOUT_IN_PAST_N_DAYS
                      ]
                    )();
                  }
                )
              }
              options={
                this.choices[
                  triggerTypes.AFTER_NOT_LOGGING_WORKOUT_IN_PAST_N_DAYS
                ]
              }
            />
          </div>
          <div
            className="triggerRow"
            style={{
              ...styles.listItem,
              ...(this.state.trigger_type === triggerTypes.ON_DATE &&
                styles.selectedListItem),
            }}
            onClick={this.onSelectTrigger(
              triggerTypes.ON_DATE,
              this.state[triggerTypes.ON_DATE]
            )}
          >
            <RadioButton
              checked={this.state.trigger_type === triggerTypes.ON_DATE}
              color="primary"
              style={styles.radioButton}
            />
            {t("date")}
            <div className="date-picker">
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  onClose={this.enableClickOnTriggerRow}
                  onOpen={this.disableClickOnTriggerRow}
                  onChange={this.handleDateChange}
                  minDate={new Date()}
                  disabled={this.state.dateDisabled}
                  format="ll"
                  value={this.state[triggerTypes.ON_DATE] || new Date()}
                  style={{
                    marginLeft: 10,
                    width: 150,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          style={{
                            backgroundColor: "unset",
                            marginRight: -20,
                          }}
                        >
                          <Icon>arrow_drop_down</Icon>
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: {
                      paddingBottom: 2,
                    },
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div
            className="triggerRow"
            style={{
              ...styles.listItem,
              ...(this.state.trigger_type ===
                triggerTypes.ON_EVERY_WEEKDAYS_FOR_N_WEEKS &&
                styles.selectedListItem),
              ...(isMobile &&
                !isTablet && {
                  flexDirection: "column",
                }),
            }}
            onClick={(e) => {
              this.onSelectTrigger(
                triggerTypes.ON_EVERY_WEEKDAYS_FOR_N_WEEKS,
                this.state[triggerTypes.ON_EVERY_WEEKDAYS_FOR_N_WEEKS]
              )();
            }}
          >
            <div style={styles.row}>
              <RadioButton
                checked={
                  this.state.trigger_type ===
                  triggerTypes.ON_EVERY_WEEKDAYS_FOR_N_WEEKS
                }
                color="primary"
                style={styles.radioButton}
              />
              {t("every")}
              <div
                onClick={(e) => e.stopPropagation()}
                style={styles.multiSelectBox}
              >
                <div
                  style={{
                    width: "98%",
                  }}
                  onClick={this.handlePopOverClick}
                >
                  {/* {this.getSelectedWeekDaysString()} */}
                  {isMobile ? (
                    <Input
                      id="adornment-password"
                      type="text"
                      value={tmUtils.getSelectedWeekDaysString(selectedDays)}
                      readOnly
                      onClick={this.handlePopOverClick}
                      fullWidth
                      style={{
                        marginLeft: 10,
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            style={{
                              padding: "2px 0px",
                              borderRadius: "unset",
                            }}
                            aria-label="Toggle password visibility"
                          >
                            <Icon>arrow_drop_down</Icon>
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  ) : (
                    <Select
                      style={{
                        ...styles.selectBoxRight,
                        ...{
                          width: 265,
                        },
                      }}
                      value={{
                        label: tmUtils.getSelectedWeekDaysString(selectedDays),
                      }}
                      searchable={false}
                      clearable={false}
                    />
                  )}
                </div>
                <Popover
                  id="simple-popper"
                  open={open}
                  anchorEl={anchorEl}
                  onClose={this.handlePopOverClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  PaperProps={{
                    style: {
                      width: 308,
                      padding: 10,
                    },
                  }}
                >
                  {tmUtils.getWeekDaysList().map((day, index) => {
                    return (
                      <WeekDayBtn
                        key={day.value}
                        label={day.label}
                        btnStyle={
                          index !== 0
                            ? {
                                marginLeft: 5,
                                width: 30,
                                minWidth: 30,
                              }
                            : {
                                width: 30,
                                minWidth: 30,
                              }
                        }
                        labelStyle={{
                          padding: 0,
                        }}
                        selectDay={() => this.handleWeekDayChange(day.value)}
                        selected={
                          this.state.selectedDays.indexOf(day.value) >= 0
                        }
                      />
                    );
                  })}
                </Popover>
              </div>
            </div>
            <div
              style={{
                ...styles.row,
                ...(isMobile &&
                  !isTablet && {
                    width: "unset",
                  }),
              }}
            >
              <span>{afterJoiningStr}</span>
              <Select
                onOpen={this.disableClickOnTriggerRow}
                onClose={this.enableClickOnTriggerRow}
                style={{
                  ...styles.selectBoxRight,
                }}
                value={initValues[4]}
                searchable={false}
                clearable={false}
                onChange={(option) => {
                  this.setState({
                    [triggerTypes.WEEK]: option.value,
                    week_data: option.value,
                  });
                  this.props.onSelectWeek(option.value);
                }}
                options={this.choices[triggerTypes.WEEK]}
              />
            </div>
          </div>
          <Divider style={styles.dividerStyle} />
          <Typography
            style={{
              ...styles.sectionHeading,
              ...{
                marginBottom: 30,
                marginTop: 30,
              },
            }}
            variant="h2"
          >
            {t("timeOrDelay")}
          </Typography>
          {this.showDelay() && (
            <div
              className="triggerRow"
              style={{
                ...styles.listItem,
                ...(this.state.waitType === "asap" && styles.selectedListItem),
              }}
              onClick={() => this.onSelectDelay(0, null, "asap")}
            >
              <RadioButton
                checked={this.state.waitType === "asap"}
                color="primary"
                style={styles.radioButton}
              />
              {t("asSoonAsPossible")}
            </div>
          )}
          {this.showDelay() && (
            <div
              className="triggerRow"
              style={{
                ...styles.listItem,
                ...(this.state.waitType === "wait" && styles.selectedListItem),
              }}
              onClick={() => {
                this.onSelectDelay(this.state.waitTime, null, "wait");
              }}
            >
              <RadioButton
                checked={this.state.waitType === "wait"}
                color="primary"
                style={styles.radioButton}
              />
              <Select
                onOpen={this.disableClickOnTriggerRow}
                onClose={this.enableClickOnTriggerRow}
                style={styles.selectBoxLeft}
                value={this.state.waitTime}
                clearable={false}
                searchable={false}
                onChange={(option) => {
                  this.onSelectDelay(option.value, null, "wait");
                  this.setState({
                    waitTime: option.value,
                  });
                }}
                classNamePrefix="trigger-row"
                options={waitData}
              />
              {t("ofDelay")}
            </div>
          )}
          <div
            className="triggerRow"
            style={{
              ...styles.listItem,
              ...(this.state.waitType === "time" && styles.selectedListItem),
            }}
            onClick={() => {
              this.onSelectDelay(0, this.state.deliveryTime, "time");
            }}
          >
            <RadioButton
              checked={this.state.waitType === "time"}
              color="primary"
              style={styles.radioButton}
            />
            {t("at")}
            <div className="select-up">
              <Select
                onOpen={this.disableClickOnTriggerRow}
                onClose={this.enableClickOnTriggerRow}
                style={styles.selectBoxRight}
                value={this.state.deliveryTime}
                clearable={false}
                searchable={false}
                onChange={(option) => {
                  this.onSelectDelay(0, option.value, "time");
                  this.setState({
                    deliveryTime: option.value,
                  });
                }}
                classNamePrefix="trigger-row"
                options={timeData}
              />
            </div>
          </div>
          {this.state.trigger_type ===
            triggerTypes.ON_EVERY_WEEKDAYS_FOR_N_WEEKS &&
            this.state.isEditMode &&
            this.state.isCheckinMessage && (
              <div>
                <Divider style={styles.dividerStyle} />
                <Typography
                  style={{
                    ...styles.sectionHeading,
                    ...{
                      marginBottom: 30,
                      marginTop: 30,
                    },
                  }}
                  variant="h2"
                >
                  {t("isThisACheckinMessage")}
                </Typography>
                <div>
                  <RadioButton
                    checked={!isCheckinMessage}
                    color="primary"
                    style={{
                      ...styles.radioButton,
                      ...styles.checkinRadioButton,
                    }}
                    onChange={() => {
                      this.setState({
                        isCheckinMessage: false,
                      });
                      this.props.onCheckinMessageChange(false);
                    }}
                  />
                  {t("no")}
                </div>
                <div>
                  <RadioButton
                    checked={isCheckinMessage}
                    color="primary"
                    style={{
                      ...styles.radioButton,
                      ...styles.checkinRadioButton,
                    }}
                    onChange={() => {
                      this.setState({
                        isCheckinMessage: true,
                      });
                      this.props.onCheckinMessageChange(true);
                    }}
                  />
                  {t("yes")}
                </div>
                <Typography
                  style={{
                    fontSize: 14,
                    color: "#524D70",
                  }}
                  variant="subheading"
                >
                  {t(
                    "aClientWillBeAskedToReplyWithYesNoThisIsGreatForHabitCheckinsForExDidYouDrink2LitersOfWaterToday"
                  )}
                </Typography>
              </div>
            )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}
export default GroupOptions;
