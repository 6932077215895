import { t } from "../../../services/utils";
import React from "react";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import { HotKeys } from "react-hotkeys";
import { replace } from "connected-react-router";
import { UPDATE_PASSWORD } from "../../../constants/action-types";
import { toastr } from "react-redux-toastr";
import moment from "moment";
class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      retyped: "",
      passwordError: "",
      retypedError: "",
      showErrors: false,
    };
    this.requestSentTime = null;
    this.navigateToLoginPage = false;
  }
  componentDidMount = () => {
    this.passwordField.focus();
  };
  componentWillReceiveProps = (nextProps) => {
    if (this.requestSentTime !== null && nextProps.lastUpdatePasswordRequest) {
      if (!nextProps.lastUpdatePasswordRequest.inProcess) {
        if (
          moment(this.requestSentTime).isBefore(
            nextProps.lastUpdatePasswordRequest.completedAt
          )
        ) {
          if (nextProps.lastUpdatePasswordRequest.success) {
            this.navigateToLoginPage = true;
          } else if (
            nextProps.lastUpdatePasswordRequest.failedWithInvalidPasswordError
          ) {
            toastr.error(
              t("invalidPassword"),
              t(
                "passwordShouldBeMinimum_6CharactersLongAndContainOnlyAlphanumericLetters"
              )
            );
            this.setState(
              {
                password: "",
                retyped: "",
              },
              () => this.passwordField.focus()
            );
          }
          this.requestSentTime = null;
        }
      }
    }
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.navigateToLoginPage) {
      this.props.login();
    }
  };
  onPasswordChange = (e) => {
    this.setState({
      password: e.target.value,
    });
  };
  onRetypedChange = (e) => {
    this.setState({
      retyped: e.target.value,
    });
  };
  onResetClick = (e) => {
    e.preventDefault();
    this.onReset();
  };
  onEnter = (e) => {
    this.onReset();
  };
  onReset = () => {
    this.setState({
      passwordError: "",
      retypedError: "",
    });
    let hasError = false;
    const { password, retyped } = this.state;
    if (password.trim().length === 0) {
      hasError = true;
      this.setState({
        showErrors: true,
        passwordError: t("thisFieldIsRequired"),
      });
    }
    if (retyped.trim().length === 0) {
      hasError = true;
      this.setState({
        showErrors: true,
        retypedError: t("thisFieldIsRequired"),
      });
    }
    if (password.trim().length !== 0 && retyped.trim().length !== 0) {
      if (this.state.password.trim() !== this.state.retyped.trim()) {
        hasError = true;
        toastr.error(t("error-0"), t("passwordEnteredDoNotMatch"));
        this.setState(
          {
            password: "",
            retyped: "",
          },
          () => this.passwordField.focus()
        );
      }
    }
    if (hasError) {
      return;
    }
    this.requestSentTime = new Date();
    this.props.onReset(this.state.password.trim());
  };
  render() {
    const styles = {
      container: {
        minWidth: 320,
        maxWidth: 400,
        height: "auto",
        position: "absolute",
        top: "20%",
        left: 0,
        right: 0,
        margin: "auto",
        display: "flex",
        flexDirection: "column",
      },
      paper: {
        padding: 20,
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
      },
      logo: {
        alignSelf: "center",
        marginBottom: 24,
      },
      fieldContainer: {
        display: "flex",
        flexDirection: "column",
      },
      toolbar: {
        display: "flex",
        marginTop: 16,
        flexDirection: "row-reverse",
      },
      resetBtn: {
        marginLeft: 24,
      },
      field: {
        marginBottom: 20,
      },
    };
    return (
      <div style={styles.container}>
        <img style={styles.logo} src={ require( "../../../img/logo.png") } />

        <Paper style={styles.paper}>
          <HotKeys
            style={styles.fieldContainer}
            handlers={{
              return: this.onEnter,
            }}
          >
            <TextField
              label={t("chooseNewPassword")}
              fullWidth={true}
              type="password"
              onChange={this.onPasswordChange}
              value={this.state.password}
              inputRef={(passwordField) => {
                this.passwordField = passwordField;
              }}
              InputProps={{
                style: {
                  paddingTop: 2,
                  paddingBottom: 4,
                },
              }}
              helperText={this.state.showErrors ? this.state.passwordError : ""}
              error={this.state.passwordError ? true : false}
              style={styles.field}
            />
          </HotKeys>

          <HotKeys
            style={styles.fieldContainer}
            handlers={{
              return: this.onEnter,
            }}
          >
            <TextField
              label={t("confirmPassword")}
              fullWidth={true}
              type="password"
              onChange={this.onRetypedChange}
              value={this.state.retyped}
              inputRef={(retypedField) => {
                this.retypedField = retypedField;
              }}
              InputProps={{
                style: {
                  paddingTop: 2,
                  paddingBottom: 4,
                },
              }}
              helperText={this.state.showErrors ? this.state.retypedError : ""}
              error={this.state.retypedError ? true : false}
              style={styles.field}
            />
          </HotKeys>

          <div style={styles.toolbar}>
            <Button
              variant="contained"
              color="primary"
              style={styles.resetBtn}
              onClick={this.onResetClick}
              disabled={
                this.props.lastUpdatePasswordRequest &&
                this.props.lastUpdatePasswordRequest.inProcess
              }
            >
              {t("resetPassword")}
            </Button>
          </div>
        </Paper>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    lastUpdatePasswordRequest: state.requests.lastUpdatePasswordRequest,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onReset: (password) => {
      dispatch({
        type: UPDATE_PASSWORD.REQUEST,
        payload: {
          password: password,
        },
      });
    },
    login: () => {
      dispatch(replace("/"));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
