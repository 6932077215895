import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas";
import * as Sentry from "@sentry/browser";
import routes from "./routes";
import { combineReducers, compose } from "redux";
import { accessToken } from "./data/access-token/reducer";
import {
  clients,
  me,
  clientIds,
  appLinks,
  clientAppSettings,
  newsFeedSettings,
  inAppPurchaseSettings,
} from "./data/user/reducer";
import { trainers } from "./data/trainers/reducer";
import { assessments } from "./data/assessments/reducer";
import { reducer as toastrReducer } from "react-redux-toastr";
import { loadingBarReducer } from "react-redux-loading-bar";
import {
  DELETE_ACCESS_TOKEN,
  RESET_APP_REQUEST,
} from "./constants/action-types";
import {
  programs,
  programDayNotes,
  programsDayDrillOrder,
  programDrills,
  getProgramDayDrillSequence as getPDDSequence,
  workoutTemplates,
  clientProgramIds,
  orderedProgramLists,
  orderedProgramListItems,
  clientExerciseHistory,
  clientProgramsLoadedSchedule,
} from "./data/program/reducer";
import { enableBatching } from "redux-batched-actions";
import {
  exercises,
  recentExercises,
  orderedExercises,
} from "./data/exercise/reducer";
import { appTitle, chatState } from "./data/app/reducer";
import { skills } from "./data/skill/reducer";
import { persistStore, autoRehydrate } from "redux-persist";
import { requests } from "./data/requests/reducer";
import { registers, savedRegisters } from "./data/register/reducer";
import { account } from "./data/account/reducer";
import { defaults } from "./data/defaults/reducer";
import { groups, groupMembers } from "./data/groups/reducer";
import { triggeredMessages } from "./data/triggered-messaging/reducer";
import { campaigns } from "./data/campaigns/reducer";
import { stories, storyIndex, hasNewStories } from "./data/stories/reducer";
import {
  newNotificationsCount,
  notifications,
} from "./data/notifications/reducer";
import {
  signupForms,
  connectedToStripe,
  preClientSignupCheck,
  signupFormOrderedList,
} from "./data/signup-forms/reducer";
import { pendingClientSignups } from "./data/client-signups/reducer";
import { messages } from "./data/messaging/reducer";
import { pendingBrandedApp } from "./data/pending-branded-app/reducer";
import { leaderBoard } from "./data/leaderBoard/reducer";
import { scheduledMessages } from "./data/scheduled-messages/reducer";
import { notes } from "./data/notes/reducer";
import createSentryMiddleware from "redux-sentry-middleware";
import {
  subscription,
  subscriptionUpdatePreview,
} from "./data/subscription/reducer";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
export const history = createBrowserHistory({
  initialEntries: routes,
  basename: "/web",
});

import {geo} from './data/geo/reducer'

// const { reducer, middleware, enhancer } = routerForBrowser({
//   routes,
//   basename: "/web",
// });

const appReducer = combineReducers({
  loadingBar: loadingBarReducer,
  router: connectRouter(history),
  toastr: toastrReducer,
  accessToken: accessToken,
  clients: clients,
  trainers: trainers,
  clientIds: clientIds,
  me: me,
  programs: programs,
  programDrills: programDrills,
  programsDayDrillOrder: programsDayDrillOrder,
  programDayNotes: programDayNotes,
  exercises: exercises,
  recentExercises: recentExercises,
  orderedExercises: orderedExercises,
  workoutTemplates: workoutTemplates,
  appTitle: appTitle,
  chatState: chatState,
  skills: skills,
  requests: requests,
  clientProgramIds: clientProgramIds,
  clientProgramsLoadedSchedule: clientProgramsLoadedSchedule,
  registers: registers,
  savedRegisters: savedRegisters,
  orderedProgramLists: orderedProgramLists,
  orderedProgramListItems: orderedProgramListItems,
  account: account,
  defaults: defaults,
  groups: groups,
  groupMembers: groupMembers,
  triggeredMessages: triggeredMessages,
  campaigns: campaigns,
  stories: stories,
  storyIndex: storyIndex,
  hasNewStories: hasNewStories,
  newNotificationsCount: newNotificationsCount,
  notifications: notifications,
  signupForms: signupForms,
  signupFormOrderedList: signupFormOrderedList,
  pendingClientSignups: pendingClientSignups,
  assessments: assessments,
  messages: messages,
  pendingBrandedApp: pendingBrandedApp,
  leaderBoard: leaderBoard,
  scheduledMessages: scheduledMessages,
  notes: notes,
  connectedToStripe,
  preClientSignupCheck,
  subscription,
  subscriptionUpdatePreview,
  clientExerciseHistory: clientExerciseHistory,
  appLinks: appLinks,
  clientAppSettings: clientAppSettings,
  newsFeedSettings: newsFeedSettings,
  inAppPurchaseSettings: inAppPurchaseSettings,
  geo: geo
});
const rootReducer = (state, action) => {
  if (
    action.type === DELETE_ACCESS_TOKEN.SUCCESS ||
    action.type === RESET_APP_REQUEST
  ) {
    const { loadingBar, router, toastr, readNotifications } = state;
    state = {
      loadingBar,
      router,
      toastr,
      readNotifications,
    };
  }
  return appReducer(state, action);
};
const sagaMiddleware = createSagaMiddleware({
  //   onError: error => RavenJS.captureException(error)
});
const store = createStore(
  enableBatching(rootReducer),
  {},
  compose(
    applyMiddleware(
      sagaMiddleware,
      routerMiddleware(history),
      createSentryMiddleware(Sentry, {
        // Optionally pass some options here.
      })
    ),
    autoRehydrate(),
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

sagaMiddleware.run(rootSaga);

// const initialLocation = store.getState().router;
// if (initialLocation) {
//   store.dispatch(initializeCurrentLocation(initialLocation));
// }

export const getProgramDayDrillSequence = (state, programId, day, date) => {
  return getPDDSequence(state.programsDayDrillOrder, programId, day, date);
};
let ps = persistStore(store, {
  whitelist: ["skills", "defaults", "savedRegisters"],
});
// ps.purge();

export default store;
