import { t } from "../../services/utils";
import {
  GET_ASSESSMENTS,
  ADD_CUSTOM_ASSESSMENT,
  ADD_ASSESSMENT_MEASURE,
  DELETE_CUSTOM_ASSESSMENT,
  DELETE_GOAL,
  DELETE_ASSESSMENT_MEASURE,
  GET_EXERCISE_PROGRESSION,
  GET_EX_PROGRESSION_DETAILS,
  SET_GOAL,
  GET_PROGRESS_PHOTOS,
  GET_CHECKINS,
  ADD_PROGRESS_PHOTO,
  GET_CLIENT_DAILY_NUTRITION,
  SAVE_CLIENT_DAILY_NUTRITION,
} from "../../constants/action-types";
import moment from "moment";
import uuidv4 from "uuid/v4";
export function assessments(state = null, action) {
  switch (action.type) {
    case GET_ASSESSMENTS.SUCCESS:
    case GET_PROGRESS_PHOTOS.SUCCESS:
    case GET_EXERCISE_PROGRESSION.SUCCESS:
    case GET_CHECKINS.SUCCESS:
    case GET_CLIENT_DAILY_NUTRITION.SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case GET_EX_PROGRESSION_DETAILS.SUCCESS: {
      const { exercise_id } = action.payload;
      return {
        ...state,
        exercise_progression: {
          ...state.exercise_progression,
          [exercise_id]: {
            ...state.exercise_progression[exercise_id],
            ...action.payload,
          },
        },
      };
    }
    case ADD_CUSTOM_ASSESSMENT.SUCCESS: {
      const { id, name } = action.payload;
      return {
        ...state,
        custom: {
          ...state.custom,
          [id]: {
            assessments: [],
            id,
            name,
          },
        },
      };
    }
    case ADD_ASSESSMENT_MEASURE.SUCCESS: {
      const {
        id,
        date,
        measure,
        type,
        overwrite,
        assessment_type_id,
        assessmentType,
      } = action.payload;
      let assessements = [];
      if (overwrite) {
        assessements = state[assessmentType][
          assessment_type_id
        ].assessments.filter((val) => val[0] != +id);
        console.log(assessements);
      } else {
        assessements = state[assessmentType][assessment_type_id].assessments;
      }
      const assessmentsMeasure = [...assessements, [id, date, measure]].sort(
        (a, b) => {
          return new Date(a[1]) - new Date(b[1]);
        }
      );
      return {
        ...state,
        [assessmentType]: {
          ...state[assessmentType],
          [assessment_type_id]: {
            ...state[assessmentType][assessment_type_id],
            assessments: assessmentsMeasure,
          },
        },
      };
    }
    case DELETE_CUSTOM_ASSESSMENT.SUCCESS: {
      const { id, type } = action.payload;
      const { [id]: deletedItem, ...remainingItems } = state[type];
      return {
        ...state,
        [type]: remainingItems,
      };
    }
    case DELETE_ASSESSMENT_MEASURE.SUCCESS: {
      const { clientId, measureId, type, assessmentId } = action.payload;
      const filteredData = state[type][assessmentId].assessments.filter(
        (data) => data[0] !== measureId
      );
      return {
        ...state,
        [type]: {
          ...state[type],
          [assessmentId]: {
            ...state[type][assessmentId],
            assessments: filteredData,
          },
        },
      };
    }
    case SET_GOAL.SUCCESS: {
      const { assessmentType, goal, assessment_type_id, end_date, id } =
        action.payload;
      const days_left = -1 * moment().diff(end_date, "days") + 2;
      return {
        ...state,
        [assessmentType]: {
          ...state[assessmentType],
          [assessment_type_id]: {
            ...state[assessmentType][assessment_type_id],
            goal: {
              completed: 0,
              id: id,
              days_left,
              goal,
            },
          },
        },
      };
    }
    case DELETE_GOAL.SUCCESS: {
      const { clientId, goalId, type, assessmentId } = action.payload;
      return {
        ...state,
        [type]: {
          ...state[type],
          [assessmentId]: {
            ...state[type][assessmentId],
            goal: undefined,
          },
        },
      };
    }
    case ADD_PROGRESS_PHOTO.SUCCESS: {
      const { progressPhoto } = action.payload;
      return {
        ...state,
        progress_photos: [...state.progress_photos, progressPhoto],
      };
    }
    case SAVE_CLIENT_DAILY_NUTRITION.SUCCESS: {
      const { nutrition } = action.payload;
      return {
        ...state,
        nutrition: {
          ...state.nutrition,
          ...nutrition,
        },
      };
    }
    default:
      return state;
  }
}
