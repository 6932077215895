import { t } from "../../services/utils";
import { GET_CAMPAIGNS, ADD_CAMPAIGN, DELETE_CAMPAIGN } from "../../constants/action-types";
import { takeEvery } from "redux-saga/effects";
import { getAPI, putAPI, deleteAPI } from "../api";
function* getCampaigns(action) {
  yield* getAPI(action.type, "/campaigns");
}
function* addCampaign(action) {
  yield* putAPI(action.type, "/campaigns/new", {
    params: action.payload,
    getSuccessPayload: resp => {
      return {
        ...action.payload,
        id: resp.id
      };
    }
  });
}
function* deleteCampaign(action) {
  yield* deleteAPI(action.type, `/campaigns/${action.payload.id}`);
}
const sagas = function* sagas() {
  yield takeEvery(GET_CAMPAIGNS.REQUEST, getCampaigns);
  yield takeEvery(ADD_CAMPAIGN.REQUEST, addCampaign);
  yield takeEvery(DELETE_CAMPAIGN.REQUEST, deleteCampaign);
};
export default sagas;