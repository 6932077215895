import { t } from "../../services/utils";
import { GET_CLIENT_NOTES, ADD_NOTE, DELETE_CLIENT_NOTE, EDIT_CLIENT_NOTE, ADD_CLIENT_NOTE } from "../../constants/action-types";
export function notes(state = {}, action) {
  switch (action.type) {
    case GET_CLIENT_NOTES.SUCCESS:
      {
        const {
          channelId,
          messages
        } = action.payload;
        return {
          ...state,
          [channelId]: messages
        };
      }
    case ADD_NOTE:
      {
        const {
          channelId,
          message
        } = action.payload;
        const messages = state[channelId];
        return {
          ...state,
          [channelId]: [message].concat(messages)
        };
      }
    case ADD_CLIENT_NOTE.SUCCESS:
      {
        const {
          messageId,
          channelId,
          message
        } = action.payload;
        const messages = state[channelId];
        // replace temp message id with response from server
        const newMessages = messages.map(m => {
          if (m._id === message._id) {
            return {
              ...m,
              _id: messageId
            };
          }
          return m;
        });
        return {
          ...state,
          [channelId]: newMessages
        };
      }
    case DELETE_CLIENT_NOTE.SUCCESS:
      {
        const {
          channelId,
          messageId
        } = action.payload;
        const messages = state[channelId];
        const newMessages = messages.filter(message => message._id !== messageId);
        return {
          ...state,
          [channelId]: newMessages
        };
      }
    case EDIT_CLIENT_NOTE.SUCCESS:
      {
        const {
          channelId,
          messageId,
          message
        } = action.payload;
        const messages = state[channelId];
        const newMessages = messages.map(m => {
          if (m._id === messageId) {
            return {
              ...m,
              text: message,
              message
            };
          }
          return m;
        });
        return {
          ...state,
          [channelId]: newMessages
        };
      }
    default:
      return state;
  }
}