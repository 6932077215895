import { t } from "../services/utils";
import React from "react";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";
import mime from "mime-types";
import IconMoon from "./IconMoon";
import { primaryDark60 } from "../constants/colors";
function handleClick(url) {
  window.open(url, "_blank");
}
const FileChip = ({
  name,
  style,
  infoStyle,
  handleRequestDelete,
  editable,
  url,
  info,
  downloadable,
  deleteable,
}) => {
  let styles = {
    row: {
      padding: "8px 10px",
      marginBottom: 10,
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      justifyContent: "space-between",
      border: "1px solid #CCC9D5",
      borderRadius: 6,
      ...style,
    },
    info: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      maxWidth: deleteable ? "75%" : "100%",
      ...infoStyle,
    },
    image: {
      fontSize: 32,
      color: primaryDark60,
    },
    details: {
      marginLeft: 15,
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
    size: {
      paddingRight: 5,
      borderRight: "1px solid #524D70",
      fontSize: 13,
      color: "#524D70",
    },
    name: {
      fontSize: 14,
      display: "block",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    time: {
      fontSize: 13,
      color: "#524D70",
      // marginLeft: 5,
    },
  };

  let fileSize;
  let time = moment();
  if (info) {
    console.log("info is", info);
    fileSize = parseFloat(info.size / 1024).toFixed(2);
    if (info.sourceInfo && info.sourceInfo.file) {
      time = info.sourceInfo.file.lastModifiedDate;
    } else if (
      info.originalImageInfo &&
      info.originalImageInfo.datetime_original
    ) {
      time = info.originalImageInfo.datetime_original;
    }
  }
  const mimeType = mime.lookup(name);
  let faIcon = "fa-file";
  if (mimeType !== false) {
    if (mimeType.indexOf("image") > -1) {
      faIcon = "fa-file-image-o";
    } else if (mimeType.indexOf("zip") > -1) {
      faIcon = "fa-file-zip-o";
    } else if (mimeType.indexOf("pdf") > -1) {
      faIcon = "fa-file-pdf-o";
    } else if (mimeType.indexOf("video") > -1) {
      faIcon = "fa-file-video-o";
    } else if (mimeType.indexOf("audio") > -1) {
      faIcon = "fa-file-audio-o";
    } else if (mimeType.indexOf("msword") > -1) {
      faIcon = "fa-file-word-o";
    } else if (mimeType.indexOf("vnd.ms-excel") > -1) {
      faIcon = "fa-file-excel-o";
    }
  }
  return (
    <div style={styles.row} onClick={() => handleClick(url)}>
      <div style={styles.info}>
        {/* <img style={styles.image} src={url} /> */}
        <i className={`fa ${faIcon}`} style={styles.image} aria-hidden="true" />
        <div style={styles.details}>
          <span style={styles.name}>{name}</span>
          {/* {fileSize && <span style={styles.size}>{`${fileSize} kb`}</span>} */}
          {time && (
            <span style={styles.time}>
              {moment(time).format("D.M.YYYY, h:mm a")}
            </span>
          )}
        </div>
      </div>
      {deleteable && (
        <IconButton
          onClick={handleRequestDelete}
          style={{
            marginLeft: 5,
          }}
        >
          <IconMoon icon="delete" />
        </IconButton>
      )}
      {/* {downloadable && (
        <span style={{ color: "unset" }} download="attachment">
          <IconButton>
            <Icon className={"material-icons"}>get_app</Icon>
          </IconButton>
        </span>
       )} */}
    </div>
  );
};
export default FileChip;
