import { t } from "../../services/utils";
import { UPDATE_APP_TITLE_REQUEST, UPDATE_CHAT_STATE } from "../../constants/action-types";
import channelTypes from "../../constants/channel-type";
export function appTitle(state = "", action) {
  switch (action.type) {
    case UPDATE_APP_TITLE_REQUEST:
      return action.payload.title;
    default:
      return state;
  }
}
const initialChatState = {
  isOpen: false,
  channelType: channelTypes.CHAT,
  clientId: null
};
export function chatState(state = initialChatState, action) {
  switch (action.type) {
    case UPDATE_CHAT_STATE:
      {
        if (state.isOpen && action.payload.chatState.isOpen) {
          import("react-chat-widget").then(chat => {
            chat.toggleWidget();
            setTimeout(chat.toggleWidget, 100);
          });
        }
        return {
          ...state,
          ...action.payload.chatState
        };
      }
    default:
      return state;
  }
}