import { t } from "../../services/utils";
import { GET_PENDING_CLIENT_SIGNUP } from '../../constants/action-types';
export function pendingClientSignups(state = {}, action) {
  switch (action.type) {
    case GET_PENDING_CLIENT_SIGNUP.SUCCESS:
      return {
        ...state,
        [action.payload.token]: {
          isExistingClient: action.payload.is_existing_client
        }
      };
    default:
      return state;
  }
}