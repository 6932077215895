import { t } from "../../services/utils";
import {
  GOOGLE_SIGNUP,
  PROVISION_NEW_COMPANY,
} from "../../constants/action-types";
import { takeEvery, put } from "redux-saga/effects";
import { postAPI } from "../api";
import { toastr } from "react-redux-toastr";
import { replace } from "connected-react-router";
import errorCodes from "../../constants/error-codes";
import bowser from "bowser";
const isPhone = typeof bowser.mobile !== "undefined";
function* provisionNewCompany(action) {
    let params = {
      company: `${action.payload.firstName} PT`,
      first_name: action.payload.firstName,
      last_name: action.payload.lastName,
      email: action.payload.email,
      password: action.payload.password,
      plan: action.payload.plan,
      recaptcha_token: action.payload.recaptchaToken
    }
    if(action.payload.fbc) {
      params.fbc = action.payload.fbc;
    }
    if(action.payload.fbp) {
      params.fbp = action.payload.fbp;
    }
  const {
    ok,
    status,
    jsonResp
  } = yield* postAPI(action.type, '/provisioned_companies/new', {
    params: params
  });
  if (ok) {
    if (isPhone) {
      yield put(replace("/signup/free-trial/thankyou"));
    } else {
      yield put(replace("/"));
    }
  } else if (status === 400) {
    let errorCode = jsonResp.error_code;
    if (errorCode === errorCodes.USER_WITH_EMAIL_EXIST) {
      toastr.error("Error", "User with this email already exist.");
    } else if (errorCode === errorCodes.INCORRECT_PASSWORD) {
      toastr.error("Error", "Invalid Password");
    } else if (errorCode === errorCodes.INVALID_VALUE) {
      toastr.error("Error", "Invalid name, email, or password provided.");
    }
  }
}

function* signUpWithGoogle(action) {
  let params = {
      company: `${action.payload.company}`,
      id_token: action.payload.id_token,
      plan: action.payload.plan,
      recaptcha_token: action.payload.recaptchaToken,
  }
  if(action.payload.fbc) {
    params.fbc = action.payload.fbc;
  }
  if(action.payload.fbp) {
    params.fbp = action.payload.fbp;
  }
  const { ok, status, jsonResp } = yield* postAPI(
    action.type,
    "/provisioned_companies/new",
    {
      params: params
    }
  );

  if (ok) {
    yield put(replace("/"));
  } else if (status === 400) {
    let errorCode = jsonResp.error_code;

    if (errorCode === errorCodes.USER_WITH_EMAIL_EXIST) {
      toastr.error("Error", "User with this email already exist.");
    } else if (errorCode === errorCodes.INCORRECT_PASSWORD) {
      toastr.error("Error", "Invalid Password");
    } else if (errorCode === errorCodes.INVALID_VALUE) {
      toastr.error("Error", "Invalid name, email, or password provided.");
    }
  }
}

const sagas = function* sagas() {
  yield takeEvery(PROVISION_NEW_COMPANY.REQUEST, provisionNewCompany);
  yield takeEvery(GOOGLE_SIGNUP.REQUEST, signUpWithGoogle);
};
export default sagas;
