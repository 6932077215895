import { t } from "../../services/utils";
import { GET_NOTIFICATIONS, GET_NEW_NOTIFICATIONS_COUNT, MARK_NOTIFICATION_READ } from "../../constants/action-types";
import { takeEvery } from "redux-saga/effects";
import { getAPI, postAPI } from "../api";
import notificationTypes from "../../constants/notificationTypes";
function* getNotifications(action) {
  yield* getAPI(action.type, "/users/myself/notifications", {
    getSuccessPayload: resp => {
      let notifications = [];
      for (let i = 0; i < resp.notifications.length; i++) {
        let nt = resp.notifications[i].notification_type;
        if (nt === notificationTypes.NEW_MESSAGE || nt === notificationTypes.STORY_LIKED || nt === notificationTypes.STORY_COMMENT_ADDED || nt === notificationTypes.CLIENT_FIRST_LOGIN || nt === notificationTypes.CLIENT_IGNORED_INVITE || nt === notificationTypes.TEST_DRIVE_CLIENT_APP || nt === notificationTypes.TEST_DRIVE_MOBILE_APP || nt === notificationTypes.NEW_CLIENT_SIGNUP || nt === notificationTypes.TRAINERFU_ENGAGEMENT_MESSAGE || nt === notificationTypes.GMAIL_API_TOKEN_EXPIRED || nt === notificationTypes.CLIENT_CANCELLED_SUBSCRIPTION || nt === notificationTypes.PLAN_TEMPLATE_COPIED || nt === notificationTypes.DELETE_CLIENT_ACCOUNT_REQUEST || nt === notificationTypes.CLIENT_ACCOUNT_DEACTIVATED_IAP || nt === notificationTypes.CLIENT_ACCOUNT_REACTIVATED_IAP || nt === notificationTypes.CLIENT_ACCOUNT_ACCESS_EXTENDED_IAP || nt === notificationTypes.IAP_EXPIRED_CLIENT_ACCOUNT_NOT_DEACTIVATED) {
          notifications.push(resp.notifications[i]);
        }
      }
      return {
        notifications: notifications
      };
    }
  });
}
function* getNewNotificationsCount(action) {
  yield* getAPI(action.type, "/users/myself/new_notifications_count", {
    showLoadingIndicator: false,
    showErrorToast: false
  });
}
function* markNotificationRead(action) {
  let notification = action.payload.notification;
  yield* postAPI(action.type, `/users/myself/notifications/${notification.id}/read_status`, {
    showLoadingIndicator: false,
    showErrorToast: false
  });
  let nt = notification.notification_type;
  if (nt === notificationTypes.STORY_COMMENT_ADDED || nt === notificationTypes.STORY_LIKED) {
    yield* getNotifications({
      type: GET_NOTIFICATIONS.REQUEST
    });
  }
}
const sagas = function* sagas() {
  yield takeEvery(GET_NOTIFICATIONS.REQUEST, getNotifications);
  yield takeEvery(GET_NEW_NOTIFICATIONS_COUNT.REQUEST, getNewNotificationsCount);
  yield takeEvery(MARK_NOTIFICATION_READ.REQUEST, markNotificationRead);
};
export default sagas;