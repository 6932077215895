import { t } from "../../services/utils";
import { GET_EXERCISES, GET_RECENT_EXERCISES, CREATE_EXERCISE, UPDATE_EXERCISE, DELETE_EXERCISE } from "../../constants/action-types";
import _ from "lodash";
export function exercises(state = {}, action) {
  switch (action.type) {
    case GET_EXERCISES.SUCCESS:
      {
        let data = {};
        for (let exercise of action.payload.exercises) {
          data[exercise.id] = exercise;
        }
        return data;
      }
    case CREATE_EXERCISE.SUCCESS:
      {
        return {
          ...state,
          [action.payload.id]: action.payload
        };
      }
    case UPDATE_EXERCISE.SUCCESS:
      {
        return {
          ...state,
          [action.payload.id]: action.payload
        };
      }
    default:
      return state;
  }
}
function removeFromArray(arr, elem) {
  let idx = _.findIndex(arr, e => e === elem);
  if (idx >= 0) {
    let copy = [...arr];
    copy.splice(idx, 1);
    return copy;
  } else {
    return arr;
  }
}
export function recentExercises(state = [], action) {
  switch (action.type) {
    case GET_RECENT_EXERCISES.SUCCESS:
      return action.payload.map(function (exercise) {
        return exercise.id;
      });
    case CREATE_EXERCISE.SUCCESS:
      return [action.payload.id, ...state];
    case DELETE_EXERCISE.REQUEST:
      return removeFromArray(state, action.payload.id);
    default:
      return state;
  }
}
export function orderedExercises(state = [], action) {
  switch (action.type) {
    case GET_EXERCISES.SUCCESS:
      return action.payload.exercises.map(function (exercise) {
        return exercise.id;
      });
    case DELETE_EXERCISE.REQUEST:
      return removeFromArray(state, action.payload.id);
    default:
      return state;
  }
}