import React from "react";
import exerciseScale from "../constants/exercise-scale";
import programDrillType from "../constants/program-drill-type";
import calculateSize from "calculate-size/lib/index";
import moment from "moment";
import mime from "mime-types";
import i18n from "i18next";
import helpArticles from "../constants/help-articles";

export const t = (key, options) => i18n.t(key, options);
export function getDrillPrescription(drill, includeInstructions = true) {
  if (drill.program_drill_type === programDrillType.EXERCISE) {
    let scales = [
      {
        id: exerciseScale.SETS,
        title: t("sets"),
      },
      {
        id: exerciseScale.REP,
        title: t("reps"),
      },
      {
        id: exerciseScale.WEIGHT,
        title: t("weight"),
      },
      {
        id: exerciseScale.DISTANCE,
        title: t("distance"),
      },
      {
        id: exerciseScale.DURATION,
        title: t("duration"),
      },
    ];
    let prescription = [];
    let measures = drill.measures;
    for (let scale of scales) {
      let key = scale.id.toString();
      if (key in measures && measures[key] !== "0" && measures[key] !== "0.0") {
        prescription.push(`${scale.title} ${measures[key]}`);
      }
    }
    if (
      drill.rest_time &&
      drill.rest_time !== "0" &&
      drill.rest_time !== "0.0"
    ) {
      let localeRestStr = t("rest");
      prescription.push(`${localeRestStr}: ${drill.rest_time}`);
    }
    let note = "";
    if (drill.note) {
      note = note + drill.note + ". ";
    }
    if (includeInstructions) {
      if (drill.no_note_instructions) {
        note = note + drill.no_note_instructions;
      }
    }
    if (note) {
      let localeNoteStr = t("note");
      prescription.push(`${localeNoteStr} ${note}`);
    }
    if (prescription.length > 0) {
      return prescription.join(" \u00B7 ");
    }
  } else if (
    drill.program_drill_type === programDrillType.START_SUPERSET ||
    drill.program_drill_type === programDrillType.START_CIRCUIT
  ) {
    let key = exerciseScale.SETS.toString();
    if (drill.measures[key] !== "0") {
      if (drill.program_drill_type === programDrillType.START_SUPERSET) {
        let localeSetsStr = t("sets");
        return `${localeSetsStr} ${drill.measures[key]}`;
      } else {
        let localeRoundsStr = t("rounds");
        return `${localeRoundsStr}: ${drill.measures[key]}`;
      }
    }
    return null;
  }
  return null;
}
export function calculateTextHeight(text, width, options) {
  text = text ? text : "";
  let height = 0;
  let split = text.split("\n");
  let font = "Roboto, sans-serif";
  let fontSize = "14px";
  if (options) {
    if (options.font) {
      font = options.font;
    }
    if (options.fontSize) {
      fontSize = options.fontSize;
    }
  }
  for (let i = 0; i < split.length; i++) {
    const size = calculateSize(split[i].trim() === "" ? "dummy" : split[i], {
      font: font,
      fontSize: fontSize,
      width: `${width}px`,
    });
    height = height + size.height;
  }
  return height;
}
export function htmlLines(text) {
  let split = text.split("\n");
  let lines = [];
  for (let i = 0; i < split.length; i++) {
    if (i > 0) {
      lines.push(<br key={`b-${i}`} />);
    }
    lines.push(<span key={`l-${i}`}>{split[i]}</span>);
  }
  return lines;
}
export function buildInviteEmailForClient(params) {
  params["newline"] = "%0D%0A";
  return t("clientWelcomeEmail", params);
}
export function buildInviteEmailForTrainer(params) {
  params["newline"] = "%0D%0A";
  return t("trainerWelcomeEmail", params);
}
export function getPhotoUrl(photoId) {
  let photoUrl = `/users_photos/${photoId}`;
  if (process.env.NODE_ENV === "stage") {
    photoUrl = process.env.ROOT_URL + photoUrl;
  }
  return photoUrl;
}
export function getAbsoluteUrl(url) {
  let absoluteUrl = url;
  if (process.env.NODE_ENV === "stage") {
    absoluteUrl = process.env.ROOT_URL + absoluteUrl;
  }
  return absoluteUrl;
}
export function extractMediaLink(content) {
  const mimeType = mime.lookup(content);
  if (mimeType) {
    const resourceType = mimeType.substr(0, 5);
    switch (resourceType) {
      case "image":
        return {
          image: content,
        };
      case "video":
        return {
          video: content,
        };
      default:
        return {
          text: content,
        };
    }
  }
  return {
    text: content,
  };
}
export function formatMessageObject(message) {
  const { text, image, video } = extractMediaLink(message.message);
  return {
    _id: message.id,
    text,
    image,
    video,
    createdAt: moment.utc(message.date_time).local(),
    user: {
      _id: message.user_id,
      name: message.first_name,
      avatar: message.photo_id
        ? getPhotoUrl(`/users_photos/${message.photo_id}`)
        : "",
    },
    mimeType: message.mime_type,
    message: message.message,
    files: message.files,
    isCheckin: message.is_checkin,
    didCheckin: message.did_checkin,
    featuredLink: message.featured_link,
  };
}
export function hasSurrogateCharacters(str) {
  const SURROGATE_REGEX = /[\ud800-\udbff][\udc00-\udfff]/g;
  return SURROGATE_REGEX.test(str);
}

export const getLocale = () => {
  return i18n.resolvedLanguage || "en";
};

export function convertNumberToLocaleString(n) {
  let locale = (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language;
  return new Intl.NumberFormat(locale).format(n);
}

export function convertLocaleStringToNumber(s) {
  let locale = (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language;
  let thousandSeparator = Intl.NumberFormat(locale).formatToParts(11111)[1].value;
  let decimalSeparator = Intl.NumberFormat(locale).formatToParts(1.1)[1].value;

  return parseFloat(s
      .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
      .replace(new RegExp('\\' + decimalSeparator), '.')
  );
}

export function getHelpArticleURL(ha) {
  let lng = getLocale();

  if(lng === 'es') {
    if(ha === helpArticles.APP_LINKS_COLLECTION) {
      return 'https://help.trainerfu.com/es/collections/3850612-enlaces-de-aplicaciones';
    } else if (ha === helpArticles.AUTOMATED_PARQ_WAIVER) {
      return 'https://help.trainerfu.com/es/collections/3850593-automatizacion-par-q-waiver-onboarding-recordatorio-contenido'
    } else if(ha === helpArticles.HABIT_COACHING_COLLECTION) {
      return 'https://help.trainerfu.com/es/collections/3848111-coaching-de-habitos';
    } else if(ha === helpArticles.SEND_CONTENT_USING_AUTORESPONDER) {
      return 'https://help.trainerfu.com/es/articles/6974219-como-entregar-contenido-valioso-a-sus-clientes-de-forma-automatica-utilizando-autoresponder';
    } else if(ha === helpArticles.SET_MFP_INTEGRATION) {
      return 'https://help.trainerfu.com/es/articles/6974199-como-ver-el-registro-de-comidas-de-myfitnesspal-de-su-cliente-dentro-de-su-aplicacion-de-entrenador';
    } else if(ha === helpArticles.TRAINING_PACKAGE_TUTORIAL) {
      return 'https://help.trainerfu.com/es/collections/3848365-vender-paquetes-y-cobrar-pagos';
    } else {
      return 'https://help.trainerfu.com/es/';
    }
  } else {
    if(ha === helpArticles.APP_LINKS_COLLECTION) {
      return 'https://help.trainerfu.com/en/collections/3850612-app-links';
    } else if (ha === helpArticles.AUTOMATED_PARQ_WAIVER) {
      return 'https://help.trainerfu.com/en/collections/3850593-automation-par-q-waiver-onboarding-reminder-content';
    } else if(ha === helpArticles.HABIT_COACHING_COLLECTION) {
      return 'https://help.trainerfu.com/en/collections/3848111-habit-coaching';
    } else if(ha === helpArticles.SEND_CONTENT_USING_AUTORESPONDER) {
      return 'https://help.trainerfu.com/en/articles/6974219-how-to-deliver-valuable-content-to-your-clients-automatically-using-autoresponder';
    } else if(ha === helpArticles.SET_MFP_INTEGRATION) {
      return 'https://help.trainerfu.com/en/articles/6974199-how-to-see-your-client-s-myfitnesspal-meal-log-inside-your-trainer-app';
    } else if(ha === helpArticles.TRAINING_PACKAGE_TUTORIAL) {
      return 'https://help.trainerfu.com/en/collections/3848365-sell-packages-collect-payments';
    } else {
      return 'https://help.trainerfu.com/en/';
    }
  }

}