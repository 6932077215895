import { t } from "../../services/utils";
import { GET_ALL_SIGNUP_FORMS, GET_SIGNUP_FORM, CREATE_SIGNUP_FORM, UPDATE_SIGNUP_FORM, DELETE_SIGNUP_FORM, CONNECT_STRIPE_ACCOUNT, PRE_CLIENT_SIGNUP_CHECK } from '../../constants/action-types';
import { takeEvery } from 'redux-saga/effects';
import { getAPI, putAPI, postAPI, deleteAPI } from '../api';
function* getAllSignupForms(action) {
  yield* getAPI(action.type, '/signup_forms');
}
function* preClientSignupCheck(action) {
  yield* getAPI(action.type, `/pre_client_signup_check`, {
    params: action.payload
  });
}
function* connectStripeAccount(action) {
  yield* putAPI(action.type, '/new_stripe_connection', {
    params: action.payload
  });
}
function* getSignupForm(action) {
  yield* getAPI(action.type, `/signup_forms/${action.payload.ticker}`, {
    getSuccessPayload: resp => {
      if (resp.not_found) {
        return {
          ticker: action.payload.ticker,
          not_found: true
        };
      } else {
        return resp;
      }
    }
  });
}
function* createSignupForm(action) {
  yield* putAPI(action.type, "/signup_forms/new", {
    params: action.payload
  });
}
function* updateSignupForm(action) {
  yield* postAPI(action.type, `/signup_forms/${action.payload.ticker}`, {
    params: action.payload
  });
}
function* deleteSignupForm(action) {
  yield* deleteAPI(action.type, `/signup_forms/${action.payload.ticker}`);
}
const sagas = function* sagas() {
  yield takeEvery(GET_SIGNUP_FORM.REQUEST, getSignupForm);
  yield takeEvery(CREATE_SIGNUP_FORM.REQUEST, createSignupForm);
  yield takeEvery(UPDATE_SIGNUP_FORM.REQUEST, updateSignupForm);
  yield takeEvery(DELETE_SIGNUP_FORM.REQUEST, deleteSignupForm);
  yield takeEvery(GET_ALL_SIGNUP_FORMS.REQUEST, getAllSignupForms);
  yield takeEvery(CONNECT_STRIPE_ACCOUNT.REQUEST, connectStripeAccount);
  yield takeEvery(PRE_CLIENT_SIGNUP_CHECK.REQUEST, preClientSignupCheck);
};
export default sagas;