import { t } from "../../services/utils";
import { WORKER_START, WORKER_STOP, GET_HAS_NEW_STORIES, GET_NEW_NOTIFICATIONS_COUNT } from '../../constants/action-types';
import { call, put, take, race } from 'redux-saga/effects';
import { delay } from 'redux-saga';
function* doWork() {
  while (true) {
    yield put({
      type: GET_HAS_NEW_STORIES.REQUEST
    });
    yield put({
      type: GET_NEW_NOTIFICATIONS_COUNT.REQUEST
    });
    yield call(delay, 30000);
  }
}
export default function* sagas() {
  while (true) {
    yield take(WORKER_START);
    yield race([call(doWork), take(WORKER_STOP)]);
  }
}