import { t } from "./utils";
export function validateEmail(email) {
  let re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}
export function validatePassword(password) {
  let re = /^([A-Za-z0-9!-~]{6,})$/;
  return re.test(password);
}
export function splitName(name) {
  name = name.trim();
  let split = name.split(/\s+/);
  let firstName = name;
  let lastName = "-";
  if (split.length > 1) {
    let slice = split.slice(0, split.length - 1);
    firstName = slice.join(" ");
    lastName = split[split.length - 1];
  }
  return [firstName, lastName];
}
export function validateRegistrationValues(name, email, password, lastName) {
  let errors = {};
  if (name.trim().length === 0) {
    errors.nameError = t("firstNameIsRequired");
  }
  if (lastName.trim().length === 0) {
    errors.lastNameError = t("lastNameIsRequired");
  }
  if (email.trim().length === 0) {
    errors.emailError = t("emailIsRequired");
  } else {
    if (!validateEmail(email.trim())) {
      errors.emailError = t("invalidEmail");
    }
  }
  if (!validatePassword(password)) {
    errors.passwordError = t(
      "invalidPasswordRulesGreaterThanMinimum_6CharactersAlphanumericAndSpecialCharacters"
    );
  }
  return {
    isValid: Object.keys(errors).length === 0,
    errors: errors,
  };
}
