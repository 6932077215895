import { t } from "../services/utils";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  PUT_EMAIL_INTEGRATION,
  GET_EMAIL_INTEGRATION,
  DELETE_EMAIL_INTEGRATION,
} from "../constants/action-types";
import { isMobile, isTablet } from "react-device-detect";
import { primary } from "../constants/colors";
class GmailLogin extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    if (!isMobile || isTablet) {
      window.gapi.load("client:auth2", this.initClient);
    }
  }
  initClient = async () => {
    const DISCOVERY_DOCS = [
      "https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest",
    ];
    const SCOPES =
      "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/gmail.send";
    const resp = await gapi.client.init({
      clientId: process.env.OAUTH_CLIENT_ID,
      discoveryDocs: DISCOVERY_DOCS,
      scope: SCOPES,
      access_type: "offline",
    });
  };
  onLoginClick = () => {
    const _this = this;
    gapi.auth2
      .getAuthInstance()
      .grantOfflineAccess()
      .then((res) => {
        this.props.putEmailIntegration({
          auth_code: res.code,
        });
      })
      .catch((err) => {
        console.log("error is", err);
      });
  };
  render() {
    const { disabled } = this.props;
    return (
      <div
        style={{
          cursor: "pointer",
        }}
        onClick={!disabled ? this.onLoginClick : () => {}}
      >
        <img
          src={
            disabled
              ? require( "../img/signin-with-google-disabled.png")
              : require( "../img/signin-with-google.png")
          }
          style={{
            height: 36,
            cursor: "pointer",
          }}
        />
        {/* Click here{" "} */}
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    emailIntegration: state.me.email_integration,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    putEmailIntegration: (params) => {
      dispatch({
        type: PUT_EMAIL_INTEGRATION.REQUEST,
        payload: params,
      });
    },
    getEmailIntegration: () => {
      dispatch({
        type: GET_EMAIL_INTEGRATION.REQUEST,
      });
    },
    deleteEmailIntegration: () => {
      dispatch({
        type: DELETE_EMAIL_INTEGRATION.REQUEST,
      });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(GmailLogin);
