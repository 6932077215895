import { t } from "../services/utils";
import React from "react";
import { Dots } from "react-activity";
const LoadingOverlay = (props) => {
  let styles = {
    container: {
      position: "fixed",
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      display: "flex",
      zIndex: 100,
    },
    activityIndicator: {
      flex: "0 0 auto",
      marginLeft: "auto",
      marginRight: "auto",
      alignSelf: "center",
    },
  };
  return (
    <div style={styles.container}>
      <div style={styles.activityIndicator}>
        <Dots size={20} color="white" />
      </div>
    </div>
  );
};
export default LoadingOverlay;
