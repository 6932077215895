import { t } from "../../services/utils";
import { GET_LEADERBOARD_CHECKINS, GET_LEADERBOARD_PROGRESS_PHOTOS, GET_LEADERBOARD_WEIGHT_DATA, GET_LEADERBOARD_WORKOUTLOGGED, GET_ASSESSMENT_TYPES } from "../../constants/action-types";
import { takeEvery, put } from "redux-saga/effects";
import { getAPI, putAPI, postAPI, deleteAPI } from "../api";
function* getAssessmentTypes(action) {
  const data = yield* getAPI(action.type, `/assessment_types`, {
    getSuccessPayload: resp => {
      const {
        custom,
        girth,
        sites,
        basic
      } = resp.assessment_types;
      const basicAssessments = {};
      const girthAssessMents = {};
      basic.map(assessment => {
        basicAssessments[assessment[1].toLowerCase()] = {
          id: assessment[0],
          name: assessment[1]
        };
      });
      girth.map(assessment => {
        girthAssessMents[assessment[1].toLowerCase()] = {
          id: assessment[0],
          name: assessment[1]
        };
      });
      return {
        assessmentTypes: {
          ...resp.assessment_types,
          basic: basicAssessments,
          girth: girthAssessMents
        }
      };
    }
  });
}
function* getCheckins(action) {
  const urlSuffix = process.env.NODE_ENV == "stage" ? "_dummy" : "";
  const data = yield* getAPI(action.type, `/leaderboard/checkins`, {
    getSuccessPayload: resp => {
      return {
        checkins: resp.leaderboard
      };
    }
  });
}
function* getProgressPhotos(action) {
  const data = yield* getAPI(action.type, `/leaderboard/progress_photos`, {
    getSuccessPayload: resp => {
      return {
        progressPhotos: resp.leaderboard
      };
    }
  });
}
function* getWeightData(action) {
  const {
    assessmentTypeId
  } = action.payload;
  const data = yield* getAPI(action.type, `/leaderboard/${assessmentTypeId}`, {
    getSuccessPayload: resp => {
      return {
        weightData: resp.leaderboard
      };
    }
  });
}
function* getWorkoutLogged(action) {
  const data = yield* getAPI(action.type, `/leaderboard/workout_logged`, {
    getSuccessPayload: resp => {
      return {
        workoutLogged: resp.leaderboard
      };
    }
  });
}
const sagas = function* sagas() {
  yield takeEvery(GET_LEADERBOARD_CHECKINS.REQUEST, getCheckins);
  yield takeEvery(GET_LEADERBOARD_PROGRESS_PHOTOS.REQUEST, getProgressPhotos);
  yield takeEvery(GET_LEADERBOARD_WEIGHT_DATA.REQUEST, getWeightData);
  yield takeEvery(GET_LEADERBOARD_WORKOUTLOGGED.REQUEST, getWorkoutLogged);
  yield takeEvery(GET_ASSESSMENT_TYPES.REQUEST, getAssessmentTypes);
};
export default sagas;