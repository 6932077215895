import _ from "lodash";
export const trialData = _.range(1, 31).map((i) => {
  let date = i < 10 ? "0" + i : i;
  let value = `1970-01-${date}`;
  let label;
  if (i === 1) {
    label = "1-day free trial";
  } else {
    label = `${i}-days free trial`;
  }
  return {
    value,
    label,
  };
});
