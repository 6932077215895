import { t, getLocale } from "../../../services/utils";
import React from "react";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { createAccessTokenRequest } from "../../../data/access-token/actions";
import { HotKeys } from "react-hotkeys";
import { push } from "connected-react-router";
import { GoogleLogin } from "@react-oauth/google";
import moment from "moment";
import {
  primaryDark40,
  primaryDark80,
  primary,
} from "../../../constants/colors";
import TextField from "../../../components/TextField";
import {
  GET_LOGIN_SERVICE,
  GOOGLE_LOGIN,
  CREATE_ALTERNATE_PASSWORD,
} from "../../../constants/action-types";
import IconMoon from "../../../components/IconMoon";
import { NewDialog } from "../../../components/Dialog";

class Login extends React.Component {
  state = {
    email: "",
    password: "",
    showErrors: false,
    emailError: null,
    passwordError: null,
    showEmail: true,
    loginText: t("Continue"),
  };
  componentDidMount = () => {
    this.emailField.focus();
  };
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.accessToken.error_code) {
      this.setState({
        password: "",
      });
      // this.state = { ...this.state, password: "" };
      // if (this.state.email.length === 0) {
      //   this.emailField.focus();
      // } else if (this.state.password.length === 0) {
      //   this.passwordField.focus();
      // }
    }
    if (nextProps.accessToken.loginService) {
      this.setState({
        showEmail: false,
        loginText: t("Login"),
      });
    }

    if (this.requestSentTime !== null && nextProps.lastForgotPasswordRequest) {
      if (!nextProps.lastForgotPasswordRequest.inProcess) {
        if (
          moment(this.requestSentTime).isBefore(
            nextProps.lastForgotPasswordRequest.completedAt
          )
        ) {
          if (nextProps.lastForgotPasswordRequest.success) {
            this.setState({ showSuccessDialog: true });
          } else if (
            nextProps.lastForgotPasswordRequest.failedWithUserNotFoundError
          ) {
            toastr.error(
              "Email does not exist",
              "Please make sure that the email you entered is correct."
            );
          }
          this.requestSentTime = null;
        }
      }
    }
  };

  // componentDidUpdate = () => {
  //   if (this.props.accessToken.error_code) {
  //     if (this.state.email.length === 0) {
  //       this.emailField.focus();
  //     } else if (this.state.password.length === 0) {
  //       this.passwordField.focus();
  //     }
  //   }
  // };

  onEmailChange = (e) => {
    this.setState({
      email: e.target.value,
    });
  };
  onPasswordChange = (e) => {
    this.setState({
      password: e.target.value,
    });
  };
  onLogin = (e) => {
    let hasError = false;
    if (!this.props.accessToken.loginService || this.state.showEmail) {
      const { email } = this.state;
      if (email.trim().length === 0) {
        hasError = true;
        this.setState({
          showErrors: true,
          emailError: "This field is required",
        });
      }
      if (hasError) {
        return;
      }
      this.props.getLoginService(this.state.email);
      return;
    }

    e.preventDefault();
    this.setState({
      emailError: "",
      passwordError: "",
    });
    const { email, password } = this.state;
    if (email.trim().length === 0) {
      hasError = true;
      this.setState({
        showErrors: true,
        emailError: t("thisFieldIsRequired"),
      });
    }
    if (password.trim().length === 0) {
      hasError = true;
      this.setState({
        showErrors: true,
        passwordError: t("thisFieldIsRequired"),
      });
    }
    if (hasError) {
      return;
    }
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const redirectTo = params.get("redirectTo");
    this.props.onLogin(this.state.email, this.state.password, redirectTo);
  };
  onEnter = (e) => {
    this.onLogin(e);
    // this.props.onLogin(this.state.email, this.state.password);
  };

  onSuccessLogin = (credentialResponse) => {
    this.props.onGoogleLogin(credentialResponse?.code);
  };

  onSend = (e) => {
    e.preventDefault();
    this.setState({
      emailError: "",
    });
    let hasError = false;
    const { email } = this.state;
    if (email.trim().length === 0) {
      hasError = true;
      this.setState({
        showErrors: true,
        emailError: "This field is required",
      });
    }
    if (hasError) {
      return;
    }
    this.send();
  };

  send = () => {
    this.requestSentTime = new Date();
    this.props.onSend(this.state.email.trim());
  };

  render() {
    const styles = {
      container: {
        minWidth: 320,
        maxWidth: 1440,
        height: "auto",
        position: "absolute",
        left: 0,
        right: 0,
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        padding: "25px 100px",
        paddingRight: "7%",
        paddingLeft: "7%",
      },
      loginContainer: {
        minWidth: 320,
        maxWidth: 400,
        height: "auto",
        position: "absolute",
        top: "20%",
        left: 0,
        right: 0,
        margin: "auto",
        display: "flex",
        flexDirection: "column",
      },
      paper: {
        width: "36%",
        minWidth: 320,
        maxWidth: 442,
        padding: 50,
        marginLeft: 10,
        marginRight: 10,
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        // padding: 20,
        // overflow: "auto",
        // display: "flex",
        // flexDirection: "column",
      },

      // logo: {
      //   alignSelf: "center",
      //   marginBottom: 24,
      // },

      fieldContainer: {
        display: "flex",
        flexDirection: "column",
      },
      toolbar: {
        display: "flex",
        marginTop: 16,
        flexDirection: "row-reverse",
        alignItems: "center",
      },
      loginBtn: {
        marginLeft: "auto",
      },
      linksContainer: {
        display: "flex",
        flexDirection: "column",
      },
      link: {
        fontSize: "14px",
        cursor: "pointer",
        color: "#7B62E1",
      },
      field: {
        marginBottom: 20,
      },
      row: {
        display: "flex",
        justifyContent: "space-between",
      },
      loginForm: {
        marginTop: "15%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      },
      logo: {
        display: "flex",
        alignItems: "center",
      },
      logoImg: {
        height: 29,
        width: 29,
      },
      logoText: {
        fontSize: 24,
        fontWeight: "bold",
        marginLeft: 4,
        marginTop: 10,
      },
      signUp: {
        alignSelf: "center",
        fontSize: "16px",
        cursor: "pointer",
        color: "#343AB4",
      },
      title: {
        // fontFamily: "'Montserrat', sans-serif",
        fontSize: "2.5vw",
        fontWeight: "bold",
        color: primaryDark80,
        marginBottom: 35,
      },
      orText: {
        textAlign: "left",
        marginTop: 20,
        fontSize: 12,
        color: primaryDark40,
        marginBottom: 20,
      },
    };

    const loginService = this.props.accessToken.loginService;
    const { showEmail, loginText } = this.state;

    return (
      <div style={styles.container} className="login-container-responsive">
        <div
          style={{
            ...styles.row,
            ...{
              alignItems: "center",
            },
          }}
        >
          <div style={styles.logo}>
            <img
              style={styles.logoImg}
              src={require("../../../img/logo2.png")}
            />
            <span style={styles.logoText}>{t("trainerFu")}</span>
          </div>
          <div>
            {/* <MediaQuery minDeviceWidth={600}>
              <span style={{ color: "#787878" }}>
                Already Have an account?{" "}
              </span>
             </MediaQuery> */}
            <span
              style={{
                color: "#787878",
              }}
              className="header-text"
            >
              {t("dontHaveAnAccount")}{" "}
            </span>
            <span
              style={styles.signUp}
              className="hover-primary"
              onClick={this.props.onSignup}
            >
              {t("signUp")}
            </span>
          </div>
        </div>

        <div style={styles.loginForm}>
          <Paper
            style={styles.paper}
            elevation={5}
            className="login-form-responsive"
          >
            <div style={styles.title} className="signup-title-responsive">
              {t("login")}
            </div>

            {loginService === 2 && !showEmail && (
              <div>
                <div style={{ marginBottom: 10 }}>
                  <Button
                    variant="text"
                    color="primary"
                    fullWidth={false}
                    style={{ marginLeft: -12 }}
                    onClick={() => {
                      this.setState({
                        showEmail: true,
                        loginText: t("Continue"),
                      });
                    }}
                    startIcon={<IconMoon color={primary} icon="back" />}
                  >
                    Email
                  </Button>
                </div>
                <div>
                  <GoogleLogin
                    theme={"filled_blue"}
                    locale={getLocale()}
                    width={330}
                    onSuccess={(credentialResponse) => {
                      this.props.onGoogleLogin(credentialResponse?.credential);
                    }}
                    onError={() => {
                      console.log("Login Failed");
                    }}
                  />
                </div>
              </div>
            )}
            {showEmail && (
              <HotKeys
                style={styles.fieldContainer}
                handlers={{
                  return: this.onEnter,
                }}
              >
                <TextField
                  className="login-email"
                  label="E-mail"
                  fullWidth={true}
                  onChange={this.onEmailChange}
                  value={this.state.email}
                  inputRef={(emailField) => {
                    this.emailField = emailField;
                  }}
                  helperText={
                    this.state.showErrors ? this.state.emailError : ""
                  }
                  error={this.state.emailError ? true : false}
                  style={styles.field}
                />
              </HotKeys>
            )}
            {loginService === 1 && !showEmail && (
              <>
                <div style={{ marginBottom: 10 }}>
                  <Button
                    variant="text"
                    color="primary"
                    fullWidth={false}
                    style={{ marginLeft: -12 }}
                    onClick={() => {
                      this.setState({
                        showEmail: true,
                        loginText: t("Continue"),
                      });
                    }}
                    startIcon={<IconMoon color={primary} icon="back" />}
                  >
                    Email
                  </Button>
                </div>
                <HotKeys
                  style={styles.fieldContainer}
                  handlers={{
                    return: this.onEnter,
                  }}
                >
                  <TextField
                    autoFocus
                    className="login-password"
                    label="Password"
                    fullWidth={true}
                    type="password"
                    onChange={this.onPasswordChange}
                    value={this.state.password}
                    inputRef={(passwordField) => {
                      this.passwordField = passwordField;
                    }}
                    helperText={
                      this.state.showErrors ? this.state.passwordError : ""
                    }
                    error={this.state.passwordError ? true : false}
                    inputProps={{
                      className: "modal-input",
                      style: {
                        paddingLeft: 20,
                        fontSize: 14,
                        lineHeight: "20px",
                        paddingTop: 9,
                        paddingBottom: 9,
                      },
                    }}
                    style={styles.field}
                  />
                </HotKeys>
              </>
            )}
            {(loginService === 1 || showEmail) && (
              <div style={styles.toolbar}>
                <Button
                  className="login-btn"
                  variant="contained"
                  color="primary"
                  style={styles.loginBtn}
                  onClick={this.onLogin}
                >
                  {loginText}
                </Button>

                {!showEmail && (
                  <div style={styles.linksContainer}>
                    {/* <div
                  style={{ ...styles.link, marginBottom: 5 }}
                  className="hover-primary"
                  onClick={this.props.onSignup}
                 >
                  Sign Up
                 </div> */}

                    <div
                      style={styles.link}
                      className="hover-primary"
                      onClick={this.onSend}
                    >
                      {t("resetPassword")}
                    </div>
                  </div>
                )}
              </div>
            )}
          </Paper>
        </div>
        <NewDialog
          title={t("Success")}
          message={t("We have sent you an email with your new password.")}
          actions={[
            {
              label: "Ok",
              primary: true,
              onClick: (e) => {
                e.stopPropagation();
                this.setState({ showSuccessDialog: false, showEmail: true });
              },
            },
          ]}
          open={this.state.showSuccessDialog}
          onRequestClose={() =>
            this.setState({ showSuccessDialog: false, showEmail: true })
          }
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    accessToken: state.accessToken,
    loginService: state.loginService,
    lastForgotPasswordRequest: state.requests.lastForgotPasswordRequest,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getLoginService: (email) => {
      dispatch({
        type: GET_LOGIN_SERVICE.REQUEST,
        payload: {
          email,
        },
      });
    },
    onGoogleLogin: (credential) => {
      dispatch({
        type: GOOGLE_LOGIN.REQUEST,
        payload: {
          id_token: credential,
        },
      });
    },
    onLogin: (email, password, redirectTo) => {
      dispatch(createAccessTokenRequest(email, password, redirectTo));
    },
    onForgotPassword: () => {
      dispatch(push("/forgot-password"));
    },
    onSignup: () => {
      dispatch(push("/signup"));
    },
    onSend: (email) => {
      dispatch({
        type: CREATE_ALTERNATE_PASSWORD.REQUEST,
        payload: {
          email,
        },
      });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
