import { t } from "../services/utils";
import React, { Component } from "react";
import { toastr } from "react-redux-toastr";
import uploadcare from "uploadcare-widget";
import Modal from "react-modal";
import moment from "moment";
import {
  Editor,
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromRaw,
  CompositeDecorator,
} from "draft-js";
import { hasSurrogateCharacters } from "../services/utils";
import { getModalStyle } from "../services/modal-util";
import AddLink from "./AddLink";
import BlockStyleControls, { InlineStyleControls } from "./editorComponents";
class EditorComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: props.editorState,
      showURLInput: false,
      urlValue: "",
    };
  }
  toggleBlockType = (blockType) => {
    const { onChange, editorState } = this.props;
    onChange(RichUtils.toggleBlockType(editorState, blockType));
  };
  toggleInlineStyle = (inlineStyle) => {
    const { onChange, editorState } = this.props;
    onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };
  insertLink = () => {
    const { editorState } = this.props;
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const startKey = editorState.getSelection().getStartKey();
      const startOffset = editorState.getSelection().getStartOffset();
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
      let url = "";
      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey);
        url = linkInstance.getData().url;
      }
      this.setState({
        showURLInput: true,
        urlValue: url,
        editLink: url ? true : false,
      });
    }
  };
  confirmLink = () => {
    const { editorState, onChange } = this.props;
    const { urlValue } = this.state;
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "LINK",
      "MUTABLE",
      {
        url: urlValue,
      }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    this.setState(
      {
        editorState: RichUtils.toggleLink(
          newEditorState,
          newEditorState.getSelection(),
          entityKey
        ),
        showURLInput: false,
        urlValue: "",
      },
      () => {
        onChange(this.state.editorState);
        setTimeout(() => this.refs.editor.focus(), 100);
      }
    );
  };
  handleBeforeInput = (input) => {
    if (hasSurrogateCharacters(input)) {
      toastr.error("Error", "Emojis or unrecognized character.");
      return "handled";
    }
    return false;
  };
  handlePastedText = (input) => {
    if (hasSurrogateCharacters(input)) {
      toastr.error(
        "Error",
        "Emojis or unrecognized character in the pasted text."
      );
      return "handled";
    }
    return false;
  };
  removeLink = () => {
    const { editorState, onChange } = this.props;
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      this.setState(
        {
          editorState: RichUtils.toggleLink(editorState, selection, null),
          showURLInput: false,
          urlValue: "",
        },
        () => {
          onChange(this.state.editorState);
        }
      );
    }
  };
  handleKeyCommand = (command) => {
    const { onChange, editorState } = this.props;
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChange(newState);
      return true;
    }
    return false;
  };
  onLinkInputKeyDown = (e) => {
    if (e.which === 13) {
      e.preventDefault();
      e.stopPropagation();
      this.confirmLink(e);
    }
  };
  onURLChange = (e) =>
    this.setState({
      urlValue: e.target.value,
    });
  render() {
    let height = 300;
    let modalStyle = getModalStyle(375, height);
    const { onChange, editorState, fileUpload } = this.props;
    let className = "RichEditor-editor";
    var contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== "unstyled") {
        className += " RichEditor-hidePlaceholder";
      }
    }
    let placeholder = t(
        "tipYouCanUsePlaceholderFirstnameAndLastnameInTheMessageThePlaceholderWillBeReplacedByClientsFirstAndLastNameBeforeSending"
    );
    if (this.props.placeholder) {
      placeholder = this.props.placeholder;
    }
    return (
      <div>
        <div
          style={{
            borderRadius: 4,
            borderColor: "#A0A0A0",
          }}
          className="RichEditor-root RichEditor-root-training"
        >
          <BlockStyleControls
            editorState={this.state.editorState}
            onToggle={this.toggleBlockType}
            onFileUpload={fileUpload}
            onInsertLink={this.insertLink}
            onRemoveLink={this.removeLink}
            hideAttachment={this.props.hideAttachment}
          />
          <InlineStyleControls
            editorState={this.state.editorState}
            onToggle={this.toggleInlineStyle}
          />
          <div className={className}>
            <Editor
              editorState={editorState}
              onChange={onChange}
              handleKeyCommand={this.handleKeyCommand}
              handleBeforeInput={this.handleBeforeInput}
              handlePastedText={this.handlePastedText}
              placeholder={placeholder}
              ref="editor"
              autoCapitalize={"none"}
              autoComplete={"off"}
              autoCorrect={"off"}
            />
          </div>
        </div>
        <Modal
          isOpen={this.state.showURLInput}
          onRequestClose={() => {
            this.setState({
              showURLInput: false,
            });
          }}
          style={modalStyle}
          contentLabel="Modal"
        >
          <AddLink
            urlValue={this.state.urlValue}
            onURLChange={this.onURLChange}
            onLinkInputKeyDown={this.onLinkInputKeyDown}
            confirmLink={this.confirmLink}
            onCancel={() => {
              this.setState({
                showURLInput: false,
              });
            }}
            editLink={this.state.editLink}
            removeLink={this.removeLink}
          />
        </Modal>
      </div>
    );
  }
}
EditorComponent.defaultprops = {
  hideAttachment: false,
};
export default EditorComponent;
