// ForceUpdateWrapper.js
import React, { useContext } from "react";
import { LanguageChangeContext } from "./LanguageChangeProvider";

const ForceUpdateWrapper = ({ children }) => {
  const { tick } = useContext(LanguageChangeContext);
  console.log("tick", tick);

  // Return the children wrapped in a React fragment
  return <div>{children}</div>;
};

export default ForceUpdateWrapper;
