import { t } from "../../services/utils";
import { GET_PROGRAM_DAY_DETAILS, GET_PROGRAM_DAY_DRILL, GET_PROGRAM_DAY_NOTE, GET_PROGRAM_DAY_DRILLS_ORDER, UPDATE_PROGRAM_DRILL, UPDATE_PROGRAM_DAY_NOTE, CREATE_PROGRAM_DRILL_TASK, CREATE_PROGRAM_DRILL, UPDATE_PROGRAM_DAY_DRILL_ORDER, MOVE_PROGRAM_DRILL_TASK, GET_WORKOUT_TEMPLATE_LIST, COPY_PASTE_PROGRAM_DAY, COPY_PASTE_PROGRAM_DAY_TO_NEW_TEMPLATE, CREATE_WORKOUT_TEMPLATE, COPY_PASTE_PROGRAM, DELETE_PROGRAM_DRILL_TASK, DELETE_PROGRAM_DRILL, GET_PROGRAM, LOAD_PROGRAM_TASK, LOAD_CLIENT_PROGRAMS_TASK, GET_CLIENT_PROGRAMS, UPDATE_PROGRAM_NOTE, UPDATE_PROGRAM_ACTIVATION_DATE, DELETE_CLIENT_PROGRAM, CREATE_CLIENT_PROGRAM, GET_ORDERED_PROGRAM_LISTS, GET_ORDERED_PROGRAM_LIST_ITEMS, LOAD_ORDERED_PROGRAM_LIST_ITEMS_TASK, CREATE_ORDERED_PROGRAM_LIST_ITEM, DELETE_ORDERED_PROGRAM_LIST_ITEM, COPY_FROM_PLAN_TEMPLATE, COPY_FROM_PLAN_TEMPLATE_STARTED, COPY_FROM_PLAN_TEMPLATE_ENDED, DELETE_WORKOUT_TEMPLATE, EDIT_WORKOUT_TEMPLATE_NAME, CREATE_ORDERED_PROGRAM_LIST, DELETE_ORDERED_PROGRAM_LIST, EDIT_PLAN_TEMPLATE_NAME, CLEAR_CACHED_CLIENTS_PROGRAMS_TASK, GET_EXERCISES_HISTORY, DELETE_WORKOUT, EMAIL_PLAN_TO_CLIENT, LOAD_SCHEDULED_CLIENT_PROGRAMS_TASK, DELETE_SCHEDULED_PROGRAM } from "../../constants/action-types";
import { put, takeEvery, select } from "redux-saga/effects";
import { getAPI, postAPI, putAPI, deleteAPI } from "../api";
import { batchActions } from "redux-batched-actions";
import { getExercise, getProgramDayDrillsOrder, getProgramDrills } from "../../selectors";
import programDrillType from "../../constants/program-drill-type";
import exerciseScales from "../../constants/exercise-scale";
import programTypes from "../../constants/program-type";
import { today, toISOfromDate } from "../../services/date-utils";
import moment from "moment";
import { toastr } from "react-redux-toastr";
function* getProgramDayDetails(action) {
  let url = `/programs/${action.payload.programId}/days/${action.payload.day}/drills`;
  if (action.payload.date !== "1970-01-01") {
    url = `/programs/${action.payload.programId}/days/${action.payload.date}/drills`;
  }
  const {
    ok,
    status,
    jsonResp
  } = yield* getAPI(action.type, url);
  if (ok) {
    let actions = generateActionForProgramDay(action.payload.programId, action.payload.day, jsonResp, action.payload.date);
    actions.push({
      type: GET_PROGRAM.SUCCESS,
      payload: {
        id: action.payload.programId,
        program_type: jsonResp.program_type,
        name: jsonResp.name,
        owner_id: jsonResp.user_id
      }
    });
    yield put(batchActions(actions));
  }
}
function generateActionForProgramDay(programId, day, data, date) {
  let actions = [{
    type: GET_PROGRAM_DAY_NOTE.SUCCESS,
    payload: {
      programId: programId,
      day: day,
      note: data.note === null || data.note.trim() === "" ? null : data.note.trim(),
      date: date
    }
  }];
  let drills = data.drills;
  let order = {};
  for (let drill of drills) {
    actions.push({
      type: GET_PROGRAM_DAY_DRILL.SUCCESS,
      payload: drill
    });
    order[drill.drill_id] = drill.seq;
  }
  actions.push({
    type: GET_PROGRAM_DAY_DRILLS_ORDER.SUCCESS,
    payload: {
      programId: programId,
      day: day,
      order: order,
      date: date
    }
  });
  return actions;
}
function* copyPasteProgram(action) {
  const {
    ok,
    status,
    jsonResp
  } = yield* putAPI(action.type, "/copy_program_task", {
    params: {
      from_program_id: action.payload.fromProgramId,
      to_program_id: action.payload.toProgramId,
      from_program_sd: action.payload.fromProgramStartDate,
      to_program_sd: action.payload.toProgramStartDate
    }
  });
  if (ok) {
    let actions = [];
    if (action.payload.toProgramStartDate === "1970-01-01") {
      // if the date is 1970-01-01, then it means day based program

      yield* loadProgram({
        type: LOAD_PROGRAM_TASK,
        payload: {
          programId: action.payload.toProgramId
        }
      });
    } else {
      // add 7 days to start date
      yield* loadScheduledProgramByDateRange({
        type: LOAD_SCHEDULED_CLIENT_PROGRAMS_TASK,
        payload: {
          clientId: action.payload.clientId,
          startDate: action.payload.toProgramStartDate,
          endDate: moment(action.payload.toProgramStartDate).add(7, "days").format("YYYY-MM-DD")
        }
      });
    }
    yield put(batchActions(actions));
  }
}
function* copyPasteProgramDay(action) {
  const {
    ok,
    status,
    jsonResp
  } = yield* putAPI(action.type, "/copy_program_day_task", {
    params: {
      from_program_id: action.payload.fromProgramId,
      from_day: action.payload.fromDay,
      to_program_id: action.payload.toProgramId,
      to_day: action.payload.toDay,
      delete_existing_data: action.payload.deleteExistingDrills,
      from_date: action.payload.fromDate,
      to_date: action.payload.toDate
    }
  });
  if (ok) {
    let actions = [];
    if (action.payload.toDate === "1970-01-01") {
      // if the date is 1970-01-01, then it means day based program
      for (let i = 0; i < 7; i++) {
        actions = actions.concat(generateActionForProgramDay(action.payload.toProgramId, i + 1, jsonResp[i], "1970-01-01"));
      }
    } else {
      // get the day from the date
      // jsonresp array starts from monday
      // get data from jsonresp[day - 1]
      let day = moment(action.payload.toDate).day();
      actions = actions.concat(generateActionsForScheduledProgramDay(action.payload.toProgramId, jsonResp[day - 1], action.payload.toDate, action.payload.toDay));
    }
    for (let i = 0; i < 7; i++) {
      actions = actions.concat(generateActionForProgramDay(action.payload.toProgramId, i + 1, jsonResp[i], "1970-01-01"));
    }
    yield put(batchActions(actions));
  }
}
function* copyPasteProgramDayToNewTemplate(action) {
  const {
    ok,
    status,
    jsonResp
  } = yield* putAPI(action.type, "/copy_program_day_task", {
    params: {
      from_program_id: action.payload.fromProgramId,
      from_day: action.payload.fromDay,
      to_new_program_with_name: action.payload.templateName,
      to_day: 1,
      from_date: action.payload.date
    }
  });
  if (ok) {
    toastr.success("Success", "Template Created Successfully", {
      timeOut: 2000,
      showCloseButton: false
    });
    yield* getWorkoutTemplates({
      type: GET_WORKOUT_TEMPLATE_LIST.REQUEST
    });
  }
}
function* createNewWorkoutTemplate(action) {
  const {
    ok,
    status,
    jsonResp
  } = yield* putAPI(action.type, `/users/${action.payload.ownerId}/programs/new`, {
    params: {
      program_type: programTypes.WORKOUT_TEMPLATE,
      name: action.payload.name
    }
  });
  if (ok) {
    yield* getWorkoutTemplates({
      type: GET_WORKOUT_TEMPLATE_LIST.REQUEST
    });
  }
}
function* deleteWorkoutTemplate(action) {
  yield* deleteAPI(DELETE_WORKOUT_TEMPLATE.REQUEST, `/programs/${action.payload.id}`);
}
function* editWorkoutTemplateName(action) {
  const {
    id,
    name
  } = action.payload;
  const {
    ok,
    status,
    jsonResp
  } = yield* postAPI(EDIT_WORKOUT_TEMPLATE_NAME.REQUEST, `/programs/${id}/name`, {
    params: {
      name
    }
  });
  if (ok) {
    yield* getWorkoutTemplates({
      type: GET_WORKOUT_TEMPLATE_LIST.REQUEST
    });
  }
}
function* updateProgramDrill(action) {
  yield* postAPI(action.type, `/program_drills/${action.payload.drill_id}`, {
    params: {
      ...action.payload.measures,
      rest_time: action.payload.rest_time,
      note: action.payload.note
    }
  });
}
function* updateProgramNote(action) {
  yield* postAPI(action.type, `/programs/${action.payload.id}`, {
    params: {
      note: action.payload.note
    }
  });
}
function* updateProgramActivationDate(action) {
  yield* postAPI(action.type, `/programs/${action.payload.id}/activation_date`, {
    params: {
      activation_date: moment(action.payload.activationDate).format("YYYY-MM-DD")
    }
  });
}
function* updateProgramDayNote(action) {
  let url = `/programs/${action.payload.programId}/days/${action.payload.day}/note`;
  if (action.payload.date !== "1970-01-01") {
    url = `/programs/${action.payload.programId}/days/${action.payload.date}/note`;
  }
  yield* postAPI(action.type, url, {
    params: {
      note: action.payload.note
    }
  });
}
class UniqueSessionIdGenerator {
  constructor() {
    this.id = 1000000000000;
  }
  next = () => {
    this.id = this.id + 1;
    return this.id;
  };
}
const uniqueSessionIdGenerator = new UniqueSessionIdGenerator();
function findStartEndIndexForDrill(drillId, drills) {
  let startIndex = -1;
  let endIndex = -1;
  let lookingForEndDrill = false;
  for (let i = 0; i < drills.length; i++) {
    if (lookingForEndDrill) {
      if (drills[i].program_drill_type === programDrillType.END_SUPERSET || drills[i].program_drill_type === programDrillType.END_CIRCUIT) {
        endIndex = i + 1;
        break;
      } else {
        continue;
      }
    }
    if (drills[i].drill_id === drillId) {
      if (drills[i].program_drill_type === programDrillType.EXERCISE) {
        startIndex = i;
        endIndex = i + 1;
        break;
      } else {
        // start group drill
        startIndex = i;
        lookingForEndDrill = true;
      }
    }
  }
  return [startIndex, endIndex];
}
function* moveDrillTask(action) {
  let order = yield select(getProgramDayDrillsOrder, action.payload.programId, action.payload.day, action.payload.date);
  if (!order) {
    order = {};
  }
  let keys = Object.keys(order);
  keys.sort((a, b) => order[a] - order[b]);
  let drills = yield select(getProgramDrills, keys.map(k => parseInt(k)));
  let drillId = action.payload.drillId;
  let [startIndex, endIndex] = findStartEndIndexForDrill(drillId, drills);
  let slice = drills.slice(startIndex, endIndex);
  drills.splice(startIndex, endIndex - startIndex);
  let prevDrillIdIndex = -1;
  if (action.payload.prevDrillId !== null) {
    prevDrillIdIndex = drills.findIndex(d => d.drill_id === action.payload.prevDrillId);
  }
  let newOrder;
  if (prevDrillIdIndex == -1) {
    newOrder = [...slice, ...drills];
  } else if (prevDrillIdIndex === drills.length - 1) {
    newOrder = [...drills, ...slice];
  } else {
    newOrder = [...drills.slice(0, prevDrillIdIndex + 1), ...slice, ...drills.slice(prevDrillIdIndex + 1)];
  }
  let newOrderWithSeq = {};
  let seq = 200;
  for (let d of newOrder) {
    newOrderWithSeq[d.drill_id] = seq;
    seq += 200;
  }
  yield put({
    type: UPDATE_PROGRAM_DAY_DRILL_ORDER.REQUEST,
    payload: {
      programId: action.payload.programId,
      day: action.payload.day,
      date: action.payload.date,
      order: newOrderWithSeq
    }
  });
  let url = `/programs/${action.payload.programId}/days/${action.payload.day}/drills/order`;
  if (action.payload.date !== "1970-01-01") {
    url = `/programs/${action.payload.programId}/days/${action.payload.date}/drills/order`;
  }
  yield* postAPI(UPDATE_PROGRAM_DAY_DRILL_ORDER.PENDING, url, {
    params: {
      drills_with_sequence_number: newOrderWithSeq
    }
  });
}
function sortedOrder(pddOrder) {
  let keys = Object.keys(pddOrder);
  let sorted = keys.map(k => {
    return {
      id: parseInt(k),
      seq: pddOrder[k]
    };
  });
  sorted.sort((a, b) => a.seq - b.seq);
  return sorted;
}
function* deleteProgramDrillTask(action) {
  let order = yield select(getProgramDayDrillsOrder, action.payload.programId, action.payload.day, action.payload.date);
  let sorted = sortedOrder(order);
  let drills = yield select(getProgramDrills, sorted.map(s => s.id));
  let newOrder = {
    ...order
  };
  let [startIndex, endIndex] = findStartEndIndexForDrill(action.payload.drillId, drills);
  for (let i = startIndex; i < endIndex; i++) {
    delete newOrder[drills[i].drill_id.toString()];
  }
  yield put({
    type: UPDATE_PROGRAM_DAY_DRILL_ORDER.REQUEST,
    payload: {
      programId: action.payload.programId,
      day: action.payload.day,
      date: action.payload.date,
      order: newOrder
    }
  });
  yield* deleteAPI(DELETE_PROGRAM_DRILL.REQUEST, `/program_drills/${action.payload.drillId.toString()}`);
}
function* createProgramDrillTask(action) {
  let findGap = (sortedOrder, prevDrillId) => {
    let beforeSeqNo = 0;
    let afterSeqNo = 400;
    if (prevDrillId === null) {
      if (sortedOrder.length > 0) {
        afterSeqNo = sortedOrder[0].seq;
      }
    } else {
      let prevIdx = sortedOrder.findIndex(o => o.id === prevDrillId);
      beforeSeqNo = sortedOrder[prevIdx].seq;
      if (prevIdx === sortedOrder.length - 1) {
        afterSeqNo = beforeSeqNo + 400;
      } else {
        afterSeqNo = sortedOrder[prevIdx + 1].seq;
      }
    }
    return [beforeSeqNo, afterSeqNo, afterSeqNo - beforeSeqNo - 1];
  };
  let pdt = action.payload.programDrillType;
  let order = yield select(getProgramDayDrillsOrder, action.payload.programId, action.payload.day, action.payload.date);
  if (!order) {
    order = {};
  }
  let keys = Object.keys(order);
  let sorted = keys.map(k => {
    return {
      id: parseInt(k),
      seq: order[k]
    };
  });
  sorted.sort((a, b) => a.seq - b.seq);
  let [beforeSeqNo, afterSeqNo, gap] = findGap(sorted, action.payload.prevDrillId);
  let skipServerSideReorder = true;
  if (pdt == programDrillType.EXERCISE && gap < 1 || pdt == programDrillType.START_SUPERSET && gap < 2 || pdt == programDrillType.START_CIRCUIT && gap < 2) {
    skipServerSideReorder = false;
    let seq = 200;
    for (let o of sorted) {
      o.seq = seq;
      seq = seq + 200;
    }
    [beforeSeqNo, afterSeqNo, gap] = findGap(sorted, action.payload.prevDrillId);
  }
  let updatedOrderForServer = {};
  for (let uo of sorted) {
    updatedOrderForServer[uo.id] = uo.seq;
  }
  let updatedOrderForClient = {
    ...updatedOrderForServer
  };
  let actions = [];
  let seq = [];
  let tempDrillIds = [];
  let {
    measures,
    restTime,
    note
  } = action.payload;
  if (pdt == programDrillType.EXERCISE) {
    let exercise = yield select(getExercise, action.payload.exerciseId);
    tempDrillIds.push(uniqueSessionIdGenerator.next());
    seq.push(Math.floor((gap - 1) / 2) + beforeSeqNo);
    actions.push({
      type: CREATE_PROGRAM_DRILL.REQUEST,
      payload: {
        drill_id: tempDrillIds[0],
        seq: seq[0],
        program_drill_type: programDrillType.EXERCISE,
        exercise_id: action.payload.exerciseId,
        exercise_name: exercise.name,
        rest_time: restTime || "0",
        measures: exercise.scales === 14 ? {
          [exerciseScales.REP.toString()]: measures && measures[exerciseScales.REP] || "0",
          [exerciseScales.SETS.toString()]: measures && measures[exerciseScales.SETS] || "0",
          [exerciseScales.WEIGHT.toString()]: measures && measures[exerciseScales.WEIGHT] || "0"
        } : {
          [exerciseScales.DISTANCE.toString()]: measures && measures[exerciseScales.DISTANCE] || "0",
          [exerciseScales.DURATION.toString()]: measures && measures[exerciseScales.DURATION] || "0"
        },
        note: note || null,
        youtube_url: exercise.youtube_url
      }
    });
    updatedOrderForClient[tempDrillIds[0].toString()] = seq[0];
  } else {
    tempDrillIds.push(uniqueSessionIdGenerator.next());
    tempDrillIds.push(uniqueSessionIdGenerator.next());
    let space = Math.floor((gap - 2) / 3);
    seq.push(beforeSeqNo + 1 + space);
    seq.push(beforeSeqNo + 2 + 2 * space);
    actions.push({
      type: CREATE_PROGRAM_DRILL.REQUEST,
      payload: {
        drill_id: tempDrillIds[0],
        seq: seq[0],
        program_drill_type: pdt === programDrillType.START_SUPERSET ? programDrillType.START_SUPERSET : programDrillType.START_CIRCUIT,
        exercise_id: 1,
        exercise_name: "Group",
        rest_time: restTime || "0",
        measures: {
          [exerciseScales.REP.toString()]: measures && measures[exerciseScales.REP] || "0",
          [exerciseScales.SETS.toString()]: measures && measures[exerciseScales.SETS] || "0",
          [exerciseScales.WEIGHT.toString()]: measures && measures[exerciseScales.WEIGHT] || "0"
        },
        note: note || null,
        youtube_url: null
      }
    });
    actions.push({
      type: CREATE_PROGRAM_DRILL.REQUEST,
      payload: {
        drill_id: tempDrillIds[1],
        seq: seq[1],
        program_drill_type: pdt === programDrillType.START_SUPERSET ? programDrillType.END_SUPERSET : programDrillType.END_CIRCUIT,
        exercise_id: 1,
        exercise_name: "Group",
        rest_time: "0",
        measures: {
          [exerciseScales.REP.toString()]: "0",
          [exerciseScales.SETS.toString()]: "0",
          [exerciseScales.WEIGHT.toString()]: "0"
        },
        note: null,
        youtube_url: null
      }
    });
    updatedOrderForClient[tempDrillIds[0].toString()] = seq[0];
    updatedOrderForClient[tempDrillIds[1].toString()] = seq[1];
  }
  actions.push({
    type: UPDATE_PROGRAM_DAY_DRILL_ORDER.REQUEST,
    payload: {
      programId: action.payload.programId,
      day: action.payload.day,
      date: action.payload.date,
      order: updatedOrderForClient
    }
  });
  yield put(batchActions(actions));
  let url = `/programs/${action.payload.programId}/days/${action.payload.day}/drills/new/created_drills_view`;
  if (action.payload.date === "1970-01-01") {
    url = `/programs/${action.payload.programId}/days/${action.payload.day}/drills/new/created_drills_view`;
  } else {
    url = `/programs/${action.payload.programId}/days/${action.payload.date}/drills/new/created_drills_view`;
  }
  const {
    ok,
    status,
    jsonResp
  } = yield* putAPI(CREATE_PROGRAM_DRILL.PENDING, url, {
    params: {
      ...action.payload.measures,
      exercise_id: action.payload.exerciseId,
      program_drill_type: pdt,
      seq: seq,
      note: action.payload.note
    },
    getSuccessPayload: resp => {
      return {
        programId: action.payload.programId,
        day: action.payload.day,
        date: action.payload.date,
        tempIdsMap: tempDrillIds.reduce((m, id, i) => {
          m[id] = resp[i];
          return m;
        }, {})
      };
    }
  });
  if (!skipServerSideReorder) {
    yield* postAPI(UPDATE_PROGRAM_DAY_DRILL_ORDER.PENDING, `/programs/${action.payload.programId}/days/${action.payload.day}/drills/order`, {
      params: {
        drills_with_sequence_number: updatedOrderForServer
      }
    });
  }
}
function* getWorkoutTemplates(action) {
  const {
    ok,
    status,
    jsonResp
  } = yield* getAPI(action.type, "/programs", {
    params: {
      include_all_programs: true,
      program_type: programTypes.WORKOUT_TEMPLATE
    },
    getSuccessPayload: jsonResp => jsonResp["programs"]
  });
}
function* getOrderedProgramLists(action) {
  yield* getAPI(action.type, "/ordered_program_lists", {
    getSuccessPayload: jsonResp => {
      return jsonResp["programs"];
    }
  });
}
function* loadOrderedProgramListItems(action) {
  const {
    ok,
    status,
    jsonResp
  } = yield* getAPI(action.type, `/ordered_program_lists/${action.payload.orderedProgramListId}/details`);
  if (ok) {
    yield* loadPrograms(action, jsonResp);
  }
}
function* loadScheduledPrograms(action, jsonResp) {
  let actions = [];
  let startDate = action.payload.startDate;
  let endDate = action.payload.endDate;
  let noOfweeks = moment(endDate).diff(moment(startDate), "weeks");
  let schedule = jsonResp.schedule;
  let programId = jsonResp.program_id;
  let programs = [];
  for (let i = 0; i < noOfweeks + 1; i++) {
    programs.push({
      id: moment(startDate).add(i, "weeks").format("YYYY-MM-DD"),
      startDate: moment(startDate).utc().add(i, "weeks").toDate(),
      activation_date: moment(startDate).utc().add(i, "weeks").toDate(),
      owner_id: jsonResp.owner_id
    });
  }
  actions.push({
    type: GET_CLIENT_PROGRAMS.SUCCESS,
    payload: {
      clientId: action.payload.clientId,
      programs,
      clientProgramId: programId,
      startDate,
      endDate
    }
  });
  Object.entries(schedule).forEach(([date, data]) => {
    let dayActions = generateActionsForScheduledProgramDay(programId, data, date, 1 // day will always be sunday for scheduled programs
    );

    Array.prototype.push.apply(actions, dayActions);
  });
  yield put(batchActions(actions));
}
function generateActionsForScheduledProgramDay(programId, data, date, day) {
  let actions = [{
    type: GET_PROGRAM_DAY_NOTE.SUCCESS,
    payload: {
      programId: programId,
      day,
      // day will always be sunday for scheduled programs
      note: data.note === null || data.note.trim() === "" ? null : data.note.trim(),
      date
    }
  }];
  let drills = data.drills;
  let order = {};
  for (let drill of drills) {
    actions.push({
      type: GET_PROGRAM_DAY_DRILL.SUCCESS,
      payload: drill
    });
    order[drill.drill_id] = drill.seq;
  }
  actions.push({
    type: GET_PROGRAM_DAY_DRILLS_ORDER.SUCCESS,
    payload: {
      programId: programId,
      day,
      // day will always be sunday for scheduled programs
      order: order,
      date: date
    }
  });
  return actions;
}
function* loadPrograms(action, jsonResp) {
  let actions = [];
  let programs = jsonResp.programs;
  let normalized = programs.map(p => {
    return {
      id: p.id,
      activation_date: p.activation_date === null ? null : moment(p.activation_date).toDate(),
      ad_mod_ts: p.ad_mod_ts,
      created_ts: p.created_ts,
      owner_id: p.owner_id,
      note: p.note
    };
  });
  if (action.type === LOAD_CLIENT_PROGRAMS_TASK) {
    actions.push({
      type: GET_CLIENT_PROGRAMS.SUCCESS,
      payload: {
        clientId: action.payload.clientId,
        clientProgramsLoadStatus: action.payload.currentAndUpcoming ? "partial" : "all",
        programs: normalized
      }
    });
  } else if (action.type === LOAD_ORDERED_PROGRAM_LIST_ITEMS_TASK) {
    actions.push({
      type: GET_ORDERED_PROGRAM_LIST_ITEMS.SUCCESS,
      payload: {
        orderedProgramListId: action.payload.orderedProgramListId,
        programs: normalized
      }
    });
  } else if (action.type === LOAD_PROGRAM_TASK) {
    // FOR PRINT VIEW
    actions.push({
      type: GET_PROGRAM.SUCCESS,
      payload: normalized[0]
    });
  }
  for (let p of programs) {
    for (let i = 0; i < 7; i++) {
      let dayActions = generateActionForProgramDay(p.id, i + 1, p.days[i], "1970-01-01");
      Array.prototype.push.apply(actions, dayActions);
    }
  }
  yield put(batchActions(actions));
}
function* loadProgram(action) {
  const {
    ok,
    status,
    jsonResp
  } = yield* getAPI(action.type, `/programs/${action.payload.programId}/details2`);
  if (ok) {
    yield* loadPrograms(action, {
      programs: [jsonResp]
    });
  }
}
function* loadClientPrograms(action) {
  let url = "";
  if (action.payload.currentAndUpcoming) {
    url = `/users/${action.payload.clientId}/programs/current_and_upcoming_with_details`;
  } else {
    url = `/users/${action.payload.clientId}/programs/details`;
  }
  const {
    ok,
    status,
    jsonResp
  } = yield* getAPI(action.type, url, {
    params: {
      program_type: programTypes.CLIENT_PROGRAM,
      date: toISOfromDate(today())
    }
  });
  if (ok) {
    yield* loadPrograms(action, jsonResp);
  }
}
function* loadScheduledProgramByDateRange(action) {
  let url = "";
  url = `/users/${action.payload.clientId}/scheduled_workouts/${action.payload.startDate}_${action.payload.endDate}`;
  const {
    ok,
    jsonResp
  } = yield* getAPI(action.type, url, {
    params: {
      program_type: programTypes.CLIENT_PROGRAM,
      date: toISOfromDate(today())
    }
  });
  if (ok) {
    let actions = [];
    let schedule = jsonResp.schedule;
    let programId = jsonResp.program_id;

    // it should delete workout for dates that are not in the schedule
    let existingDates = Object.keys(schedule);

    let allDates = [];
    let startDate = moment(action.payload.startDate);
    let endDate = moment(action.payload.endDate);
    for (let m = moment(startDate); m.isBefore(endDate); m.add(1, "days")) {
      allDates.push(m.format("YYYY-MM-DD"));
    }

    let datesToDelete = allDates.filter((d) => !existingDates.includes(d));

    for (let date of datesToDelete) {
      actions.push({
        type: DELETE_WORKOUT.REQUEST,
        payload: {
          programId: programId,
          day: 1,
          date: date,
        },
      });
    }

    Object.entries(schedule).forEach(([date, data]) => {
      let dayActions = generateActionsForScheduledProgramDay(programId, data, date, 1 // day will always be sunday for scheduled programs
      );

      Array.prototype.push.apply(actions, dayActions);
    });
    yield put(batchActions(actions));
  }
}
function* loadClientScheduledPrograms(action) {
  let url = "";
  let startDate = action.payload.startDate;
  let endDate = action.payload.endDate;
  url = `/users/${action.payload.clientId}/scheduled_workouts/${startDate}_${endDate}`;
  const {
    ok,
    status,
    jsonResp
  } = yield* getAPI(action.type, url, {
    params: {
      program_type: programTypes.CLIENT_PROGRAM,
      date: toISOfromDate(today())
    }
  });
  if (ok) {
    yield* loadScheduledPrograms(action, jsonResp);
  }
}
function* deleteClientProgram(action) {
  yield* deleteAPI(DELETE_CLIENT_PROGRAM.REQUEST, `/programs/${action.payload.programId.toString()}`);
}
function* deleteScheduledProgram(action) {

  const { clientId, startDate, endDate, programId } = action.payload;
  const { ok } = yield* deleteAPI(
    DELETE_SCHEDULED_PROGRAM.REQUEST,
    `/users/${clientId}/scheduled_workouts/${startDate}_${endDate}`
  );

  if (ok) {
    let actions = [];
    for (let i = 0; i < 7; i++) {
      actions.push({
        type: DELETE_WORKOUT.REQUEST,
        payload: {
          programId: programId,
          day: 1,
          date: moment(startDate).add(i, "days").format("YYYY-MM-DD")
        }
      });
    }
    yield put(batchActions(actions));
  }
}
function* deleteOrderedProgramListItem(action) {
  yield* deleteAPI(DELETE_CLIENT_PROGRAM.REQUEST, `/ordered_program_lists/${action.payload.orderedProgramListId}/items/${action.payload.programId}`);
}
function* createClientProgram(action) {
  yield* putAPI(CREATE_CLIENT_PROGRAM.REQUEST, `/users/${action.payload.clientId}/programs/new`, {
    params: {
      view_type: "id_only",
      program_type: programTypes.CLIENT_PROGRAM
    },
    getSuccessPayload: resp => {
      return {
        id: resp.id,
        activation_date: null,
        ad_mod_ts: null,
        created_ts: Math.floor(moment.utc().format("x")),
        owner_id: action.payload.clientId,
        note: null
      };
    }
  });
}
function* createOrderedProgramList(action) {
  yield* putAPI(CREATE_ORDERED_PROGRAM_LIST.REQUEST, "/ordered_program_lists/new", {
    params: {
      name: action.payload.name
    },
    getSuccessPayload: resp => {
      return {
        id: resp.id,
        name: action.payload.name
      };
    }
  });
}
function* deleteOrderedProgramList(action) {
  yield* deleteAPI(DELETE_ORDERED_PROGRAM_LIST.REQUEST, `/ordered_program_lists/${action.payload.id}`);
}
function* editPlanTemplateName(action) {
  const {
    id,
    name
  } = action.payload;
  const {
    ok,
    status,
    jsonResp
  } = yield* postAPI(DELETE_ORDERED_PROGRAM_LIST.REQUEST, `/ordered_program_lists/${id}`, {
    params: {
      name
    }
  });
  if (ok) {
    yield* getOrderedProgramLists({
      type: GET_ORDERED_PROGRAM_LISTS.REQUEST
    });
  }
}
function* createOrderedProgramListItem(action) {
  yield* putAPI(CREATE_ORDERED_PROGRAM_LIST_ITEM.REQUEST, `/ordered_program_lists/${action.payload.orderedProgramListId}/items/new`, {
    params: {
      view_type: "id_only"
    },
    getSuccessPayload: resp => {
      return {
        id: resp.id,
        activation_date: null,
        ad_mod_ts: null,
        created_ts: Math.floor(moment.utc().format("x")),
        note: null,
        orderedProgramListId: action.payload.orderedProgramListId
      };
    }
  });
}
function* getClientExerciseHistory(action) {
  yield* getAPI(GET_EXERCISES_HISTORY.REQUEST, `/users/${action.payload.clientId}/exercise_stats/all_logged_exercises_history`, {
    getSuccessPayload: resp => {
      return {
        history: resp.history,
        clientId: action.payload.clientId
      };
    }
  });
}
function* copyFromPlanTemplate(action) {
  let payload = action.payload;
  const endDate = action.payload.endDate;
  let params = {
    ordered_plan_list_id: action.payload.orderedProgramListId,
    date: moment(action.payload.startDate).format("YYYY-MM-DD"),
    delete_existing_data: action.payload.deleteExisting,
    start_week: action.payload.startWeek
  };
  if ("clientId" in payload) {
    params["client_id"] = payload.clientId;
  } else if ("clientIds" in payload) {
    params["client_ids"] = payload.clientIds;
  } else if ("segmentIds" in payload) {
    params["segment_ids"] = payload.segmentIds;
  }
  const {
    ok,
    status,
    jsonResp
  } = yield* putAPI(COPY_FROM_PLAN_TEMPLATE.REQUEST, "/copy_plan_template_task/ver2", {
    params: params
  });
  if (ok) {
    if ("clientId" in payload) {
      if (endDate === "1970-01-01") {
        // if the date is 1970-01-01, then it means day based program
        yield* loadClientPrograms({
          type: LOAD_CLIENT_PROGRAMS_TASK,
          payload: {
            clientId: action.payload.clientId,
            currentAndUpcoming: action.payload.currentAndUpcoming
          }
        });
      } else {
        yield* loadScheduledProgramByDateRange({
          type: LOAD_SCHEDULED_CLIENT_PROGRAMS_TASK,
          payload: {
            clientId: payload.clientId,
            startDate: moment(payload.startDate).format("YYYY-MM-DD"),
            endDate
          }
        });
      }
    } else {
      yield put({
        type: CLEAR_CACHED_CLIENTS_PROGRAMS_TASK
      });
    }
  }
}
function* deleteWorkout(action) {
  const {
    payload
  } = action;
  const {
    programId,
    day,
    date
  } = payload;
  let url = `/programs/${programId}/days/${day}`;
  if (date !== "1970-01-01") {
    url = `/programs/${programId}/days/${date}`;
  }
  yield* deleteAPI(DELETE_WORKOUT.REQUEST, url);
}
function* emailPlanToClient(action) {
  const {
    payload
  } = action;
  const {
    programId,
    clientId,
    date
  } = payload;
  const {
    ok,
    status,
    jsonResp
  } = yield* putAPI(EMAIL_PLAN_TO_CLIENT.REQUEST, `/email_program_task/ret_txt`, {
    params: {
      program_id: programId,
      to_user_id: clientId || null,
      send_to_self: false,
      start_date: date
    }
  });

  // console.log(ok, status, jsonResp);

  const email = jsonResp.email;
  const subject = jsonResp.subject;
  let message = jsonResp.message;
  message = message.replaceAll("\n", "%0D%0A");

  window.open(`mailto:${email}?subject=${subject}&body=${message}`, "_blank");

  // if (ok) {
  //   toastr.success("Success", "Email Sent Successfully", {
  //     timeOut: 2000,
  //     showCloseButton: false,
  //   });
  // }
}

export default function* sagas() {
  yield takeEvery(GET_PROGRAM_DAY_DETAILS.REQUEST, getProgramDayDetails);
  yield takeEvery(UPDATE_PROGRAM_DRILL.REQUEST, updateProgramDrill);
  yield takeEvery(UPDATE_PROGRAM_DAY_NOTE.REQUEST, updateProgramDayNote);
  yield takeEvery(CREATE_PROGRAM_DRILL_TASK, createProgramDrillTask);
  yield takeEvery(MOVE_PROGRAM_DRILL_TASK, moveDrillTask);
  yield takeEvery(GET_WORKOUT_TEMPLATE_LIST.REQUEST, getWorkoutTemplates);
  yield takeEvery(COPY_PASTE_PROGRAM_DAY.REQUEST, copyPasteProgramDay);
  yield takeEvery(COPY_PASTE_PROGRAM.REQUEST, copyPasteProgram);
  yield takeEvery(COPY_PASTE_PROGRAM_DAY_TO_NEW_TEMPLATE.REQUEST, copyPasteProgramDayToNewTemplate);
  yield takeEvery(CREATE_WORKOUT_TEMPLATE.REQUEST, createNewWorkoutTemplate);
  yield takeEvery(DELETE_PROGRAM_DRILL_TASK, deleteProgramDrillTask);
  yield takeEvery(LOAD_CLIENT_PROGRAMS_TASK, loadClientPrograms);
  yield takeEvery(LOAD_PROGRAM_TASK, loadProgram);
  yield takeEvery(UPDATE_PROGRAM_NOTE.REQUEST, updateProgramNote);
  yield takeEvery(UPDATE_PROGRAM_ACTIVATION_DATE.REQUEST, updateProgramActivationDate);
  yield takeEvery(DELETE_CLIENT_PROGRAM.REQUEST, deleteClientProgram);
  yield takeEvery(DELETE_SCHEDULED_PROGRAM.REQUEST, deleteScheduledProgram);
  yield takeEvery(CREATE_CLIENT_PROGRAM.REQUEST, createClientProgram);
  yield takeEvery(GET_ORDERED_PROGRAM_LISTS.REQUEST, getOrderedProgramLists);
  yield takeEvery(LOAD_ORDERED_PROGRAM_LIST_ITEMS_TASK, loadOrderedProgramListItems);
  yield takeEvery(CREATE_ORDERED_PROGRAM_LIST_ITEM.REQUEST, createOrderedProgramListItem);
  yield takeEvery(DELETE_ORDERED_PROGRAM_LIST_ITEM.REQUEST, deleteOrderedProgramListItem);
  yield takeEvery(COPY_FROM_PLAN_TEMPLATE.REQUEST, copyFromPlanTemplate);
  yield takeEvery(DELETE_WORKOUT_TEMPLATE.REQUEST, deleteWorkoutTemplate);
  yield takeEvery(EDIT_WORKOUT_TEMPLATE_NAME.REQUEST, editWorkoutTemplateName);
  yield takeEvery(CREATE_ORDERED_PROGRAM_LIST.REQUEST, createOrderedProgramList);
  yield takeEvery(DELETE_ORDERED_PROGRAM_LIST.REQUEST, deleteOrderedProgramList);
  yield takeEvery(EDIT_PLAN_TEMPLATE_NAME.REQUEST, editPlanTemplateName);
  yield takeEvery(GET_EXERCISES_HISTORY.REQUEST, getClientExerciseHistory);
  yield takeEvery(DELETE_WORKOUT.REQUEST, deleteWorkout);
  yield takeEvery(EMAIL_PLAN_TO_CLIENT.REQUEST, emailPlanToClient);
  yield takeEvery(LOAD_SCHEDULED_CLIENT_PROGRAMS_TASK, loadClientScheduledPrograms);
}
