import { t } from "../../../services/utils";
import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import { Icon, InputAdornment } from "@material-ui/core";
import moment from "moment";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import styles from "./styles";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
function handleClick(url) {
  window.open(url, "_blank");
}
export class Link extends Component {
  render() {
    let styles = {
      link: {
        color: "#15c",
        textDecoration: "underline",
      },
      linkPreview: {
        border: "1px solid #ccc",
        boxShadow: "0px 0px 5px #ddd",
        padding: "5px 12px",
      },
    };
    const { url } = this.props.contentState
      .getEntity(this.props.entityKey)
      .getData();
    return (
      <span>
        <a
          href={url}
          onClick={(e) => {
            e.stopPropagation();
          }}
          target="_blank"
          style={styles.link}
        >
          {this.props.children}
        </a>
      </span>
    );
  }
}
export const WeekDayBtn = (props) => {
  return (
    <div
      style={{
        cursor: "pointer",
      }}
      onClick={() => props.selectDay()}
    >
      <Checkbox
        checked={props.selected}
        // onChange={(e) => {
        //   e.stopPropagation();
        //   props.selectDay();
        // }}
        value="checkedB"
        color="primary"
      />
      {props.label}
    </div>
  );
};
export class AddLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      urlError: "",
    };
  }
  addLink = (e) => {
    try {
      new URL(this.props.urlValue);
      this.props.confirmLink();
    } catch (_) {
      this.setState({
        urlError: t("pleaseEnterCorrectUrlEgHttpWwwAbcCom"),
      });
      return false;
    }
  };
  render() {
    const {
      urlValue,
      onURLChange,
      onLinkInputKeyDown,
      confirmLink,
      onCancel,
      editLink,
      removeLink,
    } = this.props;
    return (
      <Paper elevation={1} style={styles.modalContainer}>
        <TextField
          label="Link To:"
          style={styles.field}
          value={urlValue}
          onChange={onURLChange}
          InputProps={{
            style: {
              paddingTop: 2,
              paddingBottom: 4,
            },
          }}
          onKeyDown={onLinkInputKeyDown}
          autoFocus
          helperText={this.state.urlError ? this.state.urlError : ""}
          error={this.state.urlError ? true : false}
        />
        <div style={styles.ctaContainer}>
          <Button
            variant="contained"
            color="primary"
            style={styles.ctaButton}
            onClick={this.addLink}
          >
            {t("add")}
          </Button>
          {editLink ? (
            <Button
              variant="contained"
              color="secondary"
              style={styles.ctaButton}
              onClick={removeLink}
            >
              {t("remove")}
            </Button>
          ) : (
            <Button
              variant="contained"
              style={styles.ctaButton}
              onClick={onCancel}
            >
              {t("cancel")}
            </Button>
          )}
        </div>
      </Paper>
    );
  }
}
