import { t } from "../../services/utils";
import { CREATE_APP_EVENT } from '../../constants/action-types';
import { takeEvery } from 'redux-saga/effects';
import { putAPI } from '../api';
function* createAppEvent(action) {
  yield* putAPI(action.type, '/app_events/new', {
    params: {
      ...action.payload
    },
    showLoadingIndicator: false
  });
}
export default function* sagas() {
  yield takeEvery(CREATE_APP_EVENT.REQUEST, createAppEvent);
}