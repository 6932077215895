import { t } from "../../services/utils";
import {
  CREATE_ACCESS_TOKEN,
  GET_LOGIN_SERVICE,
} from "../../constants/action-types";
import fetchState from "../../constants/fetch-state";

export function accessToken(state = {}, action) {
  switch (action.type) {
    case CREATE_ACCESS_TOKEN.PENDING:
      return {
        ...state,
        fetchState: fetchState.LOADING,
      };
    case CREATE_ACCESS_TOKEN.ERROR:
      return {
        ...state,
        fetchState: fetchState.ERROR,
        ...action.payload,
      };
    case CREATE_ACCESS_TOKEN.SUCCESS:
      return {
        fetchState: fetchState.LOADED,
      };
    case GET_LOGIN_SERVICE.SUCCESS:
      return {
        ...state,
        loginService: action.payload.id_service,
      };
    default:
      return state;
  }
}
