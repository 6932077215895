import { t } from "../../services/utils";
import {
  CREATE_ACCESS_TOKEN,
  DELETE_ACCESS_TOKEN,
  CREATE_ALTERNATE_PASSWORD,
  UPDATE_PASSWORD,
  GOOGLE_LOGIN,
  GET_LOGIN_SERVICE,
} from "../../constants/action-types";
import { put, takeEvery } from "redux-saga/effects";
import { replace } from "connected-react-router";
import { putAPI, deleteAPI, postAPI, getAPI } from "../api";
import { toastr } from "react-redux-toastr";
import errorCodes from "../../constants/error-codes";

function* createAccessToken(action) {
  const {
    ok,
    status
  } = yield* putAPI(action.type, "/access_tokens/new", {
    params: action.payload,
    redirectOn401: false
  });
  if (ok) {
    if (action.payload.redirectTo) {
      yield put(replace(action.payload.redirectTo));
    } else {
      yield put(replace("/"));
    }
  } else if (status === 401) {
    toastr.error("Login Error", "Wrong email or password. Try again.");
  } else if (status === 403) {
    yield put(replace("/"));
  }
}
function* deleteAccessToken(action) {
  yield* deleteAPI(action.type, "/access_tokens/my_token");
}
function* createAlternatePassword(action) {
  yield* putAPI(action.type, "/users/myself/alternate_password", {
    params: {
      email: action.payload.email
    }
  });
}
function* updatePassword(action) {
  yield* postAPI(action.type, "/users/myself/password", {
    params: {
      new_password: action.payload.password
    }
  });
}

function* getLoginService(action) {
  const { ok, status, jsonResp } = yield* getAPI(action.type, "/id_service", {
    params: action.payload,
  });

  if (!ok) {
    if (status === 400) {
      if (jsonResp.error_code === errorCodes.USER_NOT_FOUND) {
        toastr.error("Login Error", "User not found.");
      }
    }
  }
}

export default function* sagas() {
  yield takeEvery(CREATE_ACCESS_TOKEN.REQUEST, createAccessToken);
  yield takeEvery(DELETE_ACCESS_TOKEN.REQUEST, deleteAccessToken);
  yield takeEvery(CREATE_ALTERNATE_PASSWORD.REQUEST, createAlternatePassword);
  yield takeEvery(UPDATE_PASSWORD.REQUEST, updatePassword);
  yield takeEvery(GOOGLE_LOGIN.REQUEST, createAccessToken);
  yield takeEvery(GET_LOGIN_SERVICE.REQUEST, getLoginService);
}
