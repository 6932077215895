import { t } from "../../services/utils";
import { GET_PENDING_BRANDED_APP, GENERATE_BRANDED_APP_FILES, GENERATE_BRANDED_APP_FILES_WITH_TFA_CODE, POLL_PENDING_BRANDED_APP_FOR_END_STATE } from '../../constants/action-types';
import { takeEvery } from 'redux-saga/effects';
import { getAPI, postAPI } from '../api';
import { delay } from 'redux-saga';
import { call } from 'redux-saga/effects';
import appFilesGenStatus from "../../constants/app-files-gen-status";
function* getPendingBrandedApp(action) {
  yield* getAPI(action.type, `/pending_branded_apps/${action.payload.appId}`);
}
function* generateBrandedAppFiles(action) {
  const {
    ok,
    status,
    jsonResp
  } = yield* postAPI(action.type, `/pending_branded_apps/${action.payload.appId}/generate_app_files`, {
    params: {
      apple_id: action.payload.appleId,
      apple_id_password: action.payload.appleIdPassword
    },
    showErrorToast: false,
    showLoadingIndicator: false
  });
  if (status === -1 || status > 404) {
    while (true) {
      const {
        ok,
        status,
        jsonResp
      } = yield* getAPI(POLL_PENDING_BRANDED_APP_FOR_END_STATE.REQUEST, `/pending_branded_apps/${action.payload.appId}`, {
        showErrorToast: false,
        showLoadingIndicator: false
      });
      yield call(delay, 5000);
      if (ok) {
        if (jsonResp.app_files_gen_stage === appFilesGenStatus.ERROR || jsonResp.app_files_gen_stage === appFilesGenStatus.SUCCESS) {
          break;
        }
      }
    }
  }
}
function* generateBrandedAppFilesWithTFACode(action) {
  const {
    ok,
    status,
    jsonResp
  } = yield* postAPI(action.type, `/pending_branded_apps/${action.payload.appId}/generate_app_files_with_tfa_code`, {
    params: {
      code: action.payload.code,
      ip_address: action.payload.ip_address
    },
    showErrorToast: false,
    showLoadingIndicator: false
  });
  if (status === -1 || status > 404) {
    while (true) {
      const {
        ok,
        status,
        jsonResp
      } = yield* getAPI(POLL_PENDING_BRANDED_APP_FOR_END_STATE.REQUEST, `/pending_branded_apps/${action.payload.appId}`, {
        showErrorToast: false,
        showLoadingIndicator: false
      });
      yield call(delay, 5000);
      if (ok) {
        if (jsonResp.app_files_gen_stage === appFilesGenStatus.ERROR || jsonResp.app_files_gen_stage === appFilesGenStatus.SUCCESS) {
          break;
        }
      }
    }
  }
}
const sagas = function* sagas() {
  yield takeEvery(GET_PENDING_BRANDED_APP.REQUEST, getPendingBrandedApp);
  yield takeEvery(GENERATE_BRANDED_APP_FILES.REQUEST, generateBrandedAppFiles);
  yield takeEvery(GENERATE_BRANDED_APP_FILES_WITH_TFA_CODE.REQUEST, generateBrandedAppFilesWithTFACode);
};
export default sagas;