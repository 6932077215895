import { t } from "../services/utils";
import grey from "@material-ui/core/colors/grey";
import blue from "@material-ui/core/colors/blue";
import lightBlue from "@material-ui/core/colors/lightBlue";
import blueGrey from "@material-ui/core/colors/blueGrey";
import deepOrange from "@material-ui/core/colors/deepOrange";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import teal from "@material-ui/core/colors/teal";
import amber from "@material-ui/core/colors/amber";
import orange from "@material-ui/core/colors/orange";
export const primarycolor = "#7B62E1";
export const primary10 = "#F2EFFC";
export const primary5 = "#F8F7FE";
export const primary120 = "#6A4FD6";
export const lightPrimary = "#D6D8F0";
export const SelectedBackgroundColor = "#F8F7FE";
export const DefaultFontColor = "rgba(0, 0, 0, 0.87)";
export const AlternateToolbarBackground = "#F2EFFC";
export const grey100 = grey[100]; // #F5F5F5 -> primaryDark10
export const grey200 = grey[200]; // #EEEEEE
export const grey300 = grey[300]; // #E0E0E0
export const grey400 = grey[400]; // #BDBDBD -> primaryDark60
export const grey500 = grey[500]; // #9E9E9E -> primaryDark60
export const grey600 = grey[600]; // #757575
export const grey700 = grey[700]; // #616161 -> primaryDark80
export const grey800 = grey[800]; // #424242
export const grey900 = grey[900]; // #212121
export const grey50 = grey[50]; // #FAFAFA
export const blue50 = blue[50];
export const blue200 = blue[200];
export const blue400 = blue[400];
export const blue500 = blue[500];
export const blue700 = blue[700];
export const lightBlue900 = lightBlue[900];
export const blueGrey600 = blueGrey[600];
export const deepOrange700 = deepOrange[700];
export const green50 = green[50];
export const green700 = green[700];
export const green500 = green[500];
export const red50 = red[50];
export const red600 = red[600];
export const red700 = "#D81B60";
export const teal700 = teal[700];
export const amber50 = amber[50];
export const amberA700 = amber["A700"];
export const orangeA700 = orange["A700"];
export const primary = "#7B62E1";
export const textColor = "#2A234E";
export const lightText = "#524D70";
export const primaryColor = "#7B62E1";
export const primaryDark = "#2A234E";
export const primaryDark80 = "#524D70";
export const primaryDark60 = "#7B7692";
export const primaryDark40 = "#A39AB3";
export const primaryDark20 = "#CCC9D5";
export const primaryDark10 = "#EAE9EF";
export const primaryDark5 = "#F4F4F6";
export const secondarRed10 = "#F6E5E7";
export const secondarRed40 = "#F9B5BB";
export const secondarRed120 = "#DB3949";