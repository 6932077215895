import { t } from "../../../services/utils";
import { primaryDark60, grey900 } from "../../../constants/colors";
import { isMobile, isTablet } from "react-device-detect";
const styles = {
  scene: {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    overflow: "auto"
  },
  pageTitle: {
    paddingTop: 15,
    paddingBottom: 15,
    display: "flex",
    alignItems: "center"
  },
  title: {
    fontFamily: "'Inter', sans-serif",
    letterSpacing: 1,
    fontSize: 22
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "20px"
  },
  container: {
    display: "flex",
    width: 805,
    maxWidth: 805,
    flexDirection: "column",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "20px 2.5px 30px 2.5px"
  },
  contentContainer: {
    padding: "10px 15px",
    borderRadius: 3,
    marginTop: 5,
    marginBottom: 16
  },
  toolbar: {
    display: "flex",
    marginTop: 20,
    marginBottom: 20,
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between"
  },
  addClientButton: {
    marginLeft: "auto",
    width: 200,
    height: 40,
    alignSelf: "flex-end"
  },
  noClientsView: {
    fontSize: "16px",
    color: primaryDark60,
    padding: 30,
    margin: "0px auto 0ps auto"
  },
  addClientView: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  },
  field: {
    width: "100%",
    marginBottom: 20,
    marginTop: 20
  },
  groupsContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: 20
  },
  label: {
    fontSize: "16px",
    color: grey900
  },
  radioButton: {
    marginBottom: 16
  },
  selectBoxLeft: {
    width: "110px",
    border: "none",
    borderBottom: "1px solid #BBBBBB",
    marginRight: "10px",
    backgroundColor: "transparent"
  },
  selectBoxRight: {
    width: "110px",
    border: "none",
    borderBottom: "1px solid #BBBBBB",
    marginLeft: "10px",
    backgroundColor: "transparent"
  },
  multiSelectBox: {
    width: "285px",
    // alignItems: "center",
    // justifyContent: "center",
    display: "flex",
    overflow: "hidden"
    // minWidth: 100
  },

  dateInput: {
    width: "150px",
    border: "none",
    borderBottom: "1px solid #e2e2e2",
    marginLeft: 15,
    height: 30
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    padding: "7px 5px",
    cursor: "pointer",
    backgroundColor: "#f4f4f4",
    marginTop: 10,
    borderRadius: 4
  },
  itemSelected: {
    backgroundColor: "rgba(0, 255, 0, 0.1)"
  },
  groupsContainerRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20
  },
  subLabel: {
    fontSize: "14px",
    color: primaryDark60,
    marginTop: 3
  },
  subLabelLink: {
    fontSize: "14px",
    fontStyle: "italic",
    cursor: "pointer",
    color: primaryDark60
  },
  attachments: {
    marginTop: 10
    // display: "flex",
    // flexWrap: "wrap"
  },

  fileChip: {
    marginRight: 5
  },
  row: {
    width: "100%",
    display: "flex",
    alignItems: "center"
    // flexWrap: "wrap"
  },

  column: {
    flexDirection: "column",
    padding: isMobile && "8px 15px 24px"
  },
  hintStyle: {
    fontSize: 13,
    fontStyle: "italic",
    color: "rgba(0, 0, 0, 0.87)"
  },
  messageStyle: {
    maxHeight: 100,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  preview: {
    maxHeight: 100,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    borderWidth: 0,
    marginTop: 0
  },
  radioButton: {
    width: "unset",
    marginBottom: 0,
    padding: 12
  },
  checkinRadioButton: {
    paddingLeft: 0,
    marginLeft: -3,
    paddingBottom: 5,
    paddingTop: 3
  },
  heading: {
    fontSize: 24,
    fontWeight: "normal"
  },
  selectedListItem: {
    backgroundColor: "#E8E9F5"
  },
  sectionHeading: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#2A234E",
    letterSpacing: 0.45
  },
  dividerStyle: {
    margin: isMobile && !isTablet ? "35px 0px 10px 0px" : "35px 35px 10px -40px",
    width: 805
  },
  ctaContainer: {
    marginTop: 20,
    display: "flex",
    flexDirection: "row-reverse"
  },
  ctaButton: {
    marginLeft: 8
  },
  imgIcon: {
    height: 24,
    width: 24
  },
  paddingMobile: {
    padding: "8px 15px 24px"
  },
  disabled: {
    color: "grey"
  }
};
export default styles;