import AccessTokenSagas from "./data/access-token/api";
import UserSagas from "./data/user/api";
import ProgramSagas from "./data/program/api";
import ExerciseSagas from "./data/exercise/api";
import AppEventSagas from "./data/app-event/api";
import TrainersSagas from "./data/trainers/api";
import TriggeredMessaging from "./data/triggered-messaging/api";
import GroupsSagas from "./data/groups/api";
import Campaigns from "./data/campaigns/api";
import StoriesSagas from "./data/stories/api";
import WorkerSaga from "./data/worker/api";
import NotificationsSagas from "./data/notifications/api";
import CompanySagas from "./data/company/api";
import SignupFormsSagas from "./data/signup-forms/api";
import ClientSignupsSagas from "./data/client-signups/api";
import Messages from "./data/messaging/api";
import Assessmants from "./data/assessments/api";
import PendingBrandedAppSagas from "./data/pending-branded-app/api";
import LeaderBoardSagas from "./data/leaderBoard/api";
import ScheduledMessagesSagas from "./data/scheduled-messages/api";
import ClientNotesSagas from "./data/notes/api";
import SubscriptionSagas from "./data/subscription/api";
import Geo from './data/geo/api';

export default function* rootSaga() {
  yield [
    AccessTokenSagas(),
    UserSagas(),
    ProgramSagas(),
    ExerciseSagas(),
    AppEventSagas(),
    GroupsSagas(),
    TrainersSagas(),
    TriggeredMessaging(),
    Campaigns(),
    StoriesSagas(),
    WorkerSaga(),
    NotificationsSagas(),
    CompanySagas(),
    SignupFormsSagas(),
    ClientSignupsSagas(),
    Assessmants(),
    LeaderBoardSagas(),
    Messages(),
    PendingBrandedAppSagas(),
    ScheduledMessagesSagas(),
    ClientNotesSagas(),
    SubscriptionSagas(),
    Geo()
  ];
}
