import { t } from "../../services/utils";
import { GET_STORIES, UPDATE_STORY_PRIVACY, ARCHIVE_STORY, ADD_STORY_COMMENT, UNLIKE_STORY, LIKE_STORY, GET_HAS_NEW_STORIES, POST_NEWS, UPDATE_STORY } from "../../constants/action-types";
import { takeEvery, put, select } from "redux-saga/effects";
import { getAPI, putAPI, postAPI, deleteAPI } from "../api";
import storiesQueryTypes from "../../constants/stories-query-types";
import { today, toISOfromDate } from "../../services/date-utils";
function* getStories(action) {
  const {
    query
  } = action.payload;
  let params = {
    date: toISOfromDate(today())
  };
  switch (query.type) {
    case storiesQueryTypes.GET_ALL:
      {
        params["trained_by"] = -1;
        break;
      }
    case storiesQueryTypes.FOR_TRAINER:
      {
        params["trained_by"] = query.trainerId;
        break;
      }
    case storiesQueryTypes.FOR_GROUP:
      {
        params["segment_id"] = query.segmentId;
        break;
      }
    case storiesQueryTypes.FOR_CLIENT:
      {
        params["diary_user_id"] = query.clientId;
        break;
      }
    case storiesQueryTypes.FOR_SINGLE_STORY:
      {
        params["story_id"] = query.storyId;
        break;
      }
    case storiesQueryTypes.FOR_STORY_TYPE:
      {
        params["story_type"] = query.storyType;
        params["diary_user_id"] = query.clientId;
        break;
      }
  }
  yield* getAPI(action.type, "/stories2", {
    params: params,
    getSuccessPayload: resp => {
      return {
        query,
        stories: resp.stories
      };
    }
  });
}
function* updateStoryPrivacy(action) {
  yield* postAPI(action.type, "/stories2/" + action.payload.storyId, {
    params: {
      is_public: action.payload.isPublic
    }
  });
}
function* updateStory(action) {
  const {
    params
  } = action.payload;
  yield* postAPI(action.type, "/stories2/" + action.payload.storyId, {
    params
  });
}
function* archiveStory(action) {
  yield* postAPI(action.type, "/stories2/" + action.payload.storyId, {
    params: {
      archive: true
    }
  });
}
function* likeStory(action) {
  yield* putAPI(action.type, `/stories2/${action.payload.storyId}/likes/new`);
}
function* unlikeStory(action) {
  yield* deleteAPI(action.type, `/stories2/${action.payload.storyId}/likes/${action.payload.userId}`);
}
function* addStoryComment(action) {
  yield* putAPI(action.type, `/stories2/${action.payload.storyId}/comments/new`, {
    params: {
      comment: action.payload.comment
    }
  });
}
function* getHasNewStories(action) {
  yield* getAPI(action.type, "/users/myself/new_stories_notification/ver2", {
    showLoadingIndicator: false,
    showErrorToast: false
  });
}
function* postNews(action) {
  const {
    newPost,
    isPublic,
    isPinned,
    appNotification,
    pushNotification,
    pushNotificationMessage,
    segment_ids,
    files,
    image_url,
    image_width,
    image_height,
    me,
    story_type,
    date
  } = action.payload;
  yield* putAPI(action.type, "/stories2/new/", {
    params: {
      note: newPost,
      date,
      is_public: isPublic,
      pinned: isPinned,
      send_notification: appNotification,
      send_push_notification: pushNotification,
      push_notification_message: pushNotificationMessage,
      story_type,
      for_segments: segment_ids,
      files,
      image_url,
      image_width,
      image_height
    },
    getSuccessPayload: resp => {
      let response = resp;
      return {
        ...action.payload,
        story_id: response.id,
        delete_id: action.payload.story_id
      };
    }
  });
}
const sagas = function* sagas() {
  yield takeEvery(GET_STORIES.REQUEST, getStories);
  yield takeEvery(UPDATE_STORY_PRIVACY.REQUEST, updateStoryPrivacy);
  yield takeEvery(ARCHIVE_STORY.REQUEST, archiveStory);
  yield takeEvery(LIKE_STORY.REQUEST, likeStory);
  yield takeEvery(UNLIKE_STORY.REQUEST, unlikeStory);
  yield takeEvery(ADD_STORY_COMMENT.REQUEST, addStoryComment);
  yield takeEvery(GET_HAS_NEW_STORIES.REQUEST, getHasNewStories);
  yield takeEvery(POST_NEWS.REQUEST, postNews);
  yield takeEvery(UPDATE_STORY.REQUEST, updateStory);
};
export default sagas;