import { GET_GEO } from "../../constants/action-types";

export function geo(state = null, action) {
    switch (action.type) {
        case GET_GEO.SUCCESS:
        {
            return action.payload;
        }
        case GET_GEO.ERROR:
        {
           return {"country": "US"}
        }

        default:
            return state;
    }
}
