import { t } from "../../services/utils";
import { GET_TRIGGERED_MESSAGES, ADD_TRIGGERED_MESSAGE, UPDATE_TRIGGERED_MESSAGE, DELETE_TRIGGERED_MESSAGE } from "../../constants/action-types";
export function triggeredMessages(state = {}, action) {
  switch (action.type) {
    case GET_TRIGGERED_MESSAGES.SUCCESS:
      {
        const {
          triggered_messages
        } = action.payload;
        let triggeredMessages = {};
        triggered_messages.map(message => {
          triggeredMessages[message.id] = message;
        });
        return triggeredMessages;
      }
    case ADD_TRIGGERED_MESSAGE.SUCCESS:
    case UPDATE_TRIGGERED_MESSAGE.SUCCESS:
      {
        return {
          ...state,
          [action.payload.id]: {
            ...action.payload,
            modifiedTimestampThisSession: Date.now()
          }
        };
      }
    case DELETE_TRIGGERED_MESSAGE.SUCCESS:
      {
        const groups = Object.assign({}, state);
        delete groups[action.payload];
        return groups;
      }
    default:
      return state;
  }
}