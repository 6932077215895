import { getProgramDayKey } from "./data/program/reducer";
import { getSkillsStatus as ss } from "./data/skill/reducer";
export const getMe = (state) => {
  return state.me;
};
export const getExercise = (state, exerciseId) => {
  return state.exercises[exerciseId.toString()];
};
export const getProgramDayDrillsOrder = (state, programId, day, date) => {
  let key = getProgramDayKey(programId, day, date);
  return state.programsDayDrillOrder[key];
};
export const getProgramDrills = (state, ids) => {
  return ids.map((id) => {
    return state.programDrills[id.toString()];
  });
};
export const getWorkoutTemplates = (state) => {
  let workoutTemplates = state.workoutTemplates;
  if (workoutTemplates === null) {
    return null;
  }
  return state.workoutTemplates.map((tid) => state.programs[tid.toString()]);
};
export function getSkillsStatus(state, skills) {
  return ss(state.skills, skills);
}
export function getOrderedClientIds(clients) {
  if (clients == null) {
    return [];
  }
  let orderedClientIds = Object.keys(clients).map((cid) => parseInt(cid));
  orderedClientIds.sort((c1id, c2id) => {
    let c1 = clients[c1id];
    let c2 = clients[c2id];
    return nameCompare(c1, c2);
  });
  return orderedClientIds;
}
export function getOrderedGroupIds(groups, groupsOnly) {
  let filteredGroups = Object.values(groups);
  if (groupsOnly) {
    filteredGroups = filteredGroups.filter((g) => g.segment_type === 1);
  }
  filteredGroups.sort((g1, g2) => {
    return g1.name.toLowerCase() < g2.name.toLowerCase() ? -1 : 1;
  });
  let orderedGroupIds = filteredGroups.map((g) => g.id);
  return orderedGroupIds;
}
export function sortOrderedProgramLists(orderedProgramLists) {
  let opl = null;
  if (orderedProgramLists !== null) {
    let keys = Object.keys(orderedProgramLists);
    opl = keys.map((k) => orderedProgramLists[k]);
    opl.sort((l1, l2) => {
      return l1.name.toLowerCase() < l2.name.toLowerCase() ? -1 : 1;
    });
  }
  return opl;
}
function nameCompare(ob1, ob2) {
  if (ob1.first_name.toLowerCase() < ob2.first_name.toLowerCase()) return -1;
  if (ob1.first_name.toLowerCase() > ob2.first_name.toLowerCase()) return 1;
  return ob1.last_name.toLowerCase() < ob2.last_name.toLowerCase() ? -1 : 1;
}
