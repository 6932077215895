import { t, getLocale } from "./services/utils";
/* eslint-disable import/default */

import React from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import ThemeDefault from "./theme-default";
import { render } from "react-dom";
import { StripeProvider } from "react-stripe-elements";
import "core-js/stable";
import "regenerator-runtime/runtime";
require("./favicon.ico");
require("react-activity/lib/Dots/Dots.css");
require("react-dates/lib/css/_datepicker.css");
require("./img/logo.png");
require("./img/tf_pp.png");
require("./img/testdrive.png");
require("./img/download_ios_store.png");
require("./img/download_play_store.png");
require("./img/signup.jpg");
require("./img/c1.png");
require("./img/c2.png");
require("./img/c3.png");
require("./img/c4.png");
require("./img/c5.png");
require("./img/c6.png");
require("./img/c7.png");
require("./img/c8.png");
require("./img/logo2.png");
require("./img/logo3.png");
require("./img/logo4.png");
require("./img/paypalLogo.png");
require("./img/emptyState.png");
require("./img/comment.png");
require("./img/icon-add.png");
require("./img/icon-close.png");
require("./img/icon-edit.png");
require("./img/icon-skip-message.png");
require("./img/icon-warning.png");
require("./img/weight-small.png");
require("./img/goal.png");
require("./img/lock.png");
require("./img/goalUnlocked.png");
require("./img/log1stWorkout.png");
require("./img/log25Workouts.png");
require("./img/log75Workouts.png");
require("./img/log100Workouts.png");
require("./img/perfectHalfYear.png");
require("./img/perfectMonth.png");
require("./img/perfectQuarter.png");
require("./img/perfectWeek.png");
require("./img/perfectYear.png");
require("./img/personalRecord.png");
require("./img/stripeConnect.png");
require("./img/pattern1.png");
require("./img/iconHistory.png");
require("./img/play-video-white.png");
require("./img/play-video-icon.png");
require("./img/signin-with-google.png");
require("./img/emptyWorkout.png");
require("./img/onboarding-autoresponder.png");
require("./img/onboarding-client.png");
require("./img/onboarding-habits.png");
require("./img/onboarding-links.png");
require("./img/onboarding-packages.png");
require("./img/onboarding-triggered-messaging.png");
require("./img/onboarding-workout-templates.png");
require("./img/onboarding-groups.png");
require("./img/onboarding-news-feed.png");
require("./img/no-nutrition.png");
require("./img/no-record-found.png");
require("./img/resources.png");
require("./img/mobile-links.png");
require("./img/hand-icon.png");
require("./img/search-icon.png");
require("./img/left-arrow.png");
require("./img/right-arrow.png");
require("./img/anytime.png");
require("./img/burnboot.png");
require("./img/credit-card-info.svg");
require("./img/gold.png");
require("./img/lifetime.png");
require("./img/no-contracts.svg");
require("./img/no-fuss.svg");
require("./img/signup-bg.png");
require("./img/signup-img.png");
import MomentUtils from "@date-io/moment";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import "font-awesome/css/font-awesome.css";
import "flexboxgrid/css/flexboxgrid.css";
import "react-circular-progressbar/dist/styles.css";
require("./styles.css");
require("./draft.css");
import { Provider } from "react-redux";
import store, { history } from "./store";
import Login from "./scenes/login/components/Login";
import Signup from "./scenes/login/components/Signup";
import ClientSignup from "./scenes/signup-forms/components/ClientSignup";
import Thankyou from "./scenes/signup-forms/components/Thankyou";
import StripeCheckout from "./scenes/signup-forms/components/StripeCheckout";
import ForgotPassword from "./scenes/login/components/ForgotPassword";
import ResetPassword from "./scenes/login/components/ResetPassword";
import AddOrEditTM from "./scenes/triggered-messaging/components/AddOrEditTM";
import StripeSubscription from "./scenes/stripe-subscription";
import LoadingBar from "react-redux-loading-bar";
import ReduxToastr from "react-redux-toastr";
import { blue200, primary } from "./constants/colors";
import * as Sentry from "@sentry/browser";
import DownloadSupportedBrowser from "./scenes/misc/components/DownloadSupportedBrowser";
import DownloadApp from "./scenes/misc/components/DownloadApp";
import bowser from "bowser";
import "react-select/dist/react-select.css";
import LoadableLoadingView from "./components/LoadableLoadingView";
import { loadReCaptcha } from "react-recaptcha-v3";
import Loadable from "react-loadable";
import { ConnectedRouter } from "connected-react-router";
import { Route, Switch } from "react-router";
import LanguageChangeProvider from "./components/LanguageChangeProvider";
import ForceUpdateWrapper from "./components/ForceUpdateWrapper";
import "./i18n";
import { GoogleOAuthProvider } from "@react-oauth/google";
import moment from "moment";
import i18next from "i18next";

const App = Loadable({
  loader: () => import("./containers/App"),
  loading: LoadableLoadingView,
});
const STRIPE_API_KEY = process.env.STRIPE_API_KEY;
Sentry.init({
  dsn: "https://b50e116166ad4840b254915eaad97c2c@sentry.io/199892",
  release: process.env.SENTRY_RELEASE_VERSION,
});
const isPhone = typeof bowser.mobile !== "undefined";
const isBrowserSupported =
  bowser.chrome === true || bowser.firefox === true || bowser.safari === true;
const token = new URL(window.location.href).searchParams.get("token");
let lng = getLocale();
moment.locale(lng);
loadReCaptcha(process.env.RECAPTCHA_KEY);
render(
  <StripeProvider apiKey={STRIPE_API_KEY}>
    <Provider store={store}>
      <MuiThemeProvider theme={ThemeDefault}>
        <div>
          <GoogleOAuthProvider clientId={process.env.GOOGLE_CLIENT_ID}>
            <ConnectedRouter history={history}>
              <Route path="/">
                <div>
                  <LoadingBar
                    style={{
                      backgroundColor: primary,
                      zIndex: "12000",
                      position: "fixed",
                      top: 0,
                    }}
                    showFastActions
                  />
                  <Switch>
                    <Route path="/login">
                      <Login />
                    </Route>
                    <Route path="/forgot-password">
                      <ForgotPassword />
                    </Route>
                    <Route path="/reset-password">
                      <ResetPassword />
                    </Route>
                    <Route path="/download-app">
                      <DownloadApp />
                    </Route>
                    <Route path="/signup/:ticker/thankyou/:token">
                      <Thankyou />
                    </Route>
                    <Route
                      path="/signup/:ticker/thankyou"
                      render={(props) => (
                        <Thankyou ticker={props.match.params.ticker} />
                      )}
                    ></Route>
                    <Route path="/signup/:ticker/checkout">
                      <StripeCheckout />
                    </Route>
                    <Route
                      path="/signup/:ticker"
                      render={(props) => (
                        <ClientSignup ticker={props.match.params.ticker} />
                      )}
                    ></Route>
                    <Route path="/previewForm">
                      <ClientSignup preview={true} />
                    </Route>
                    <Route path="/signup">
                      <Signup />
                    </Route>
                    <Route path="/client-portal">
                      <StripeSubscription />
                    </Route>
                    {isBrowserSupported && isPhone && token && (
                      <Route path="/auto-messaging/:groupId/new">
                        <AddOrEditTM />
                      </Route>
                    )}

                    <Route path="*">
                      <div>
                        {!isBrowserSupported && <DownloadSupportedBrowser />}

                        {isBrowserSupported && isPhone && <DownloadApp />}

                        {isBrowserSupported && !isPhone && <App />}
                      </div>
                    </Route>
                  </Switch>
                  <ReduxToastr
                    timeOut={6000}
                    newestOnTop={true}
                    preventDuplicates={false}
                    position="top-right"
                    transitionIn="fadeIn"
                    transitionOut="fadeOut"
                    progressBar={false}
                    showCloseButton={true}
                  />
                </div>
              </Route>
            </ConnectedRouter>
          </GoogleOAuthProvider>
        </div>
      </MuiThemeProvider>
    </Provider>
  </StripeProvider>,
  document.getElementById("app")
);
