import { t } from "../services/utils";
const errorCodes = {
  AUTHENTICATION_FAILED: 1,
  FORBIDDEN: 2,
  SERVER_ERROR: 3,
  INVALID_EMAIL: 6,
  RESET_PASSWORD_REQUIRED: 7,
  NOT_AUTHENTICATED: 8,
  USER_NOT_FOUND: 9,
  INVALID_PASSWORD_FORMAT: 10,
  INCORRECT_PASSWORD: 11,
  INVALID_FIRST_NAME: 12,
  INVALID_LAST_NAME: 13,
  USER_WITH_EMAIL_EXIST: 14,
  SELF_DELETE: 15,
  INVALID_NAME: 16,
  OPERATION_FAILED: 17,
  INVALID_YOUTUBE_URL: 18,
  VERSION_NOT_SUPPORTED: 19,
  INVALID_VALUE: 20,
  ASSESSMENT_WITH_DATE_EXIST: 21,
  ADD_CLIENTS_NOT_ALLOWED: 22,
  SEND_INVITE_NOT_ALLOWED: 23,
  ASSESSMENT_MEASURE_EXIST: 24,
  INVALID_OPERATION: 25,
  TFA_ERROR: 26,
  APP_FILES_GEN_FAILED: 27,
  STRIPE_CONNECT_ERROR: 28,
  STRIPE_TRANSACTION_FAILED: 29,
  CHARGIFY_ERROR: 30,
  CHARGIFY_PAYMENT_ERROR: 31,
  PENDING_APPLE_AGREEMENT_ERROR: 32,
  INVALID_GMAIL_API_TOKEN: 33,
  STRIPE_CUSTOMER_DOESNT_EXIST: 34,
  DUPLICATE_COPY_PLAN_TEMPLATE_JOB: 35
};
export default errorCodes;