import { t } from "../../services/utils";
import { MESSAGES, ADD_MESSAGE } from '../../constants/action-types';
export function messages(state = {}, action) {
  switch (action.type) {
    case MESSAGES.SUCCESS:
      {
        const {
          channelId,
          messages
        } = action.payload;
        return {
          ...state,
          [channelId]: messages
        };
      }
    case ADD_MESSAGE:
      {
        const {
          channelId,
          message
        } = action.payload;
        const messages = state[channelId];
        return {
          ...state,
          [channelId]: [message].concat(messages)
        };
      }
    default:
      return state;
  }
}