// LanguageChangeProvider.js
import React, { createContext, useState, useEffect } from "react";
import i18n from "../i18n";

export const LanguageChangeContext = createContext();

const LanguageChangeProvider = ({ children }) => {
  const [language, setLanguage] = useState(i18n.language);
  const [tick, setTick] = useState(0);

  useEffect(() => {
    console.log("LanguageChangeProvider mounted");
    console.log("Current language", i18n.language);
    const handleLanguageChange = (lng) => {
      console.log("Language changed to inside provider", lng);
      setLanguage(lng);
      setTick((tick) => tick + 1); // Force re-render
    };

    i18n.on("languageChanged", handleLanguageChange);

    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, []);

  return (
    <LanguageChangeContext.Provider value={{ language, tick }}>
      {children}
    </LanguageChangeContext.Provider>
  );
};

export default LanguageChangeProvider;
